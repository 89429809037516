import React from "react";
import { useNavigate } from "react-router-dom";
import { Image, Table } from "semantic-ui-react";
import placeholder from "../../../../../../images/placeholder.png";
import moment from "moment";
import { AUCTION_PATH } from "../../../../../../constants/paths";

const AuctionHouseAuctionTile = ({ auction }) => {
  const navigate = useNavigate();

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Table.Row onClick={(e) => onRowClick(e, `${AUCTION_PATH}/${auction._id}`)}>
      <Table.Cell>
        <Image src={auction.image_url || placeholder} avatar />
        {auction.name}
      </Table.Cell>
      <Table.Cell>
        {auction.start_date &&
          moment(auction.start_date).format("MMM Do, YYYY")}
      </Table.Cell>
      <Table.Cell>
        {auction.end_date && moment(auction.end_date).format("MMM Do, YYYY")}
      </Table.Cell>
    </Table.Row>
  );
};

export default AuctionHouseAuctionTile;
