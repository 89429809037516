import React, { useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { updateAppraisal } from "../../../redux/actions/appraisal";
import { connect } from "react-redux";
import ErrorMessage from "../../ErrorMessage";
import FormDatePicker from "../../Forms/FormDatePicker";
import { currencyOptions } from "../../../common";

const EditAppraisalModal = ({
  pieceId,
  appraisal,
  update,
  onAppraisalUpdated,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(appraisal);
  const [notes, setNotes] = useState(appraisal.notes);
  const [value, setValue] = useState(appraisal.value.value);
  const [currency, setCurrency] = useState(appraisal.value.currency);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () =>
    setModalOpen(false, setError(null), setLoading(false));

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const { payload } = await update(appraisal._id, {
        ...data,
        value,
        currency,
        notes,
        piece_id: pieceId,
      });

      onAppraisalUpdated(payload.data);
      handleClose();
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal
      trigger={
        <Button basic icon="edit" floated="right" onClick={handleOpen} />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => handleClose()}
      closeIcon
    >
      <Modal.Header content="Edit Appraisal" />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form>
          <FormDatePicker
            label="Date"
            value={data.date}
            onChange={(date) => setData({ ...data, date })}
          />
          <Form.Field>
            <label>Value</label>
            <Form.Group>
              <Form.Select
                fluid
                name="currency"
                placeholder="Currency"
                defaultValue={currency}
                options={currencyOptions}
                autoComplete={"off"}
                onChange={(e, { value }) => setCurrency(value)}
                search
                width={4}
              />
              <Form.Input
                fluid
                name="value"
                placeholder="Value"
                defaultValue={value}
                type="number"
                step="0.01"
                autoComplete={"off"}
                onChange={(e, { value }) => setValue(value)}
                width={12}
              />
            </Form.Group>
          </Form.Field>
          <Form.TextArea
            label="Notes"
            placeholder="Notes"
            defaultValue={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          negative
          icon="remove"
          content="Cancel"
          onClick={handleClose}
        />
        <Button
          basic
          positive
          icon="checkmark"
          loading={loading}
          content="Submit"
          onClick={onSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  update: (id, data) => dispatch(updateAppraisal(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAppraisalModal);
