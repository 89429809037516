import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../../../utils/firebase";

import { Button, Form, Grid, Header, Segment, Card } from "semantic-ui-react";

import { loginWithFacebook, register } from "../../../../redux/actions/auth";
import login_image from "../../../../images/login.jpg";
import ErrorMessage from "../../../../components/ErrorMessage";
import { LOGIN_PATH, PASSWORD_RECOVER_PATH } from "../../../../constants/paths";
import NonAuthLayout from "../../../../layouts/NonAuthLayout";
import { accountTypeOptions } from "../../../../common";
import { createReferral } from "../../../../redux/actions/referral";

const RegisterPage = ({ register, trackReferral }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const referral = new URLSearchParams(window.location.search).get("referral");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [type, setType] = useState("collector");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      if (referral) {
        await trackReferral({ referral, email });
      }
    } catch (e) {}

    try {
      const { payload } = await register({
        first_name: firstName,
        last_name: lastName,
        type,
        business_name: businessName,
        email,
        password,
      });
      const { firebase_token } = payload.data;
      await auth.signInWithCustomToken(firebase_token);

      let path = state?.from?.pathname || DASHBOARD_PATH;
      if (referral) {
        path += `?referral=${referral}`;
      }
      navigate(path, { replace: true });
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <NonAuthLayout
      title={"Register | ArtCollection.io"}
      description={"Register for an account with Art Collection."}
    >
      <Grid
        stackable
        style={{
          margin: 0,
          height: "100vh",
          backgroundColor: "white",
        }}
        verticalAlign={"middle"}
      >
        <Grid.Column
          computer={8}
          tablet={4}
          only="computer table"
          style={{
            backgroundImage: `url(${login_image})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
          }}
        />
        <Grid.Column computer={8} tablet={12} mobile={16}>
          <Segment
            loading={loading}
            style={{
              marginTop: "3em",
              padding: "3em",
              margin: 0,
            }}
          >
            <Header
              style={{
                fontSize: "2em",
                fontWeight: "bold",
                lineHeight: "1em",
                marginTop: "0.5em",
              }}
              content={"Art Collection"}
            />
            {error && <ErrorMessage error={error} />}
            <Form onSubmit={onSubmit}>
              <Form.Field>
                <Header as={"h1"} size={"large"} content={"Register"} />
              </Form.Field>
              <Form.Group widths={"equal"}>
                <Form.Input
                  label={"First Name"}
                  placeholder={"First Name"}
                  onChange={(e, { value }) => setFirstName(value)}
                />
                <Form.Input
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  onChange={(e, { value }) => setLastName(value)}
                />
              </Form.Group>
              {(type === "advisor" || type === "gallery") && (
                <Form.Input
                  label={"Business Name"}
                  placeholder={"Business Name"}
                  onChange={(e, { value }) => setBusinessName(value)}
                />
              )}
              <Form.Input
                label={"Email"}
                placeholder={"Email"}
                onChange={(e, { value }) => setEmail(value)}
              />
              <Form.Input
                label={"Password"}
                placeholder={"Password"}
                type={"password"}
                onChange={(e, { value }) => setPassword(value)}
              />
              <Form.Select
                label={"Account Type"}
                placeholder={"Account Type"}
                defaultValue={type}
                options={accountTypeOptions}
                onChange={(e, { value }) => setType(value)}
              />
              <Form.Field>
                <Button
                  fluid
                  loading={loading}
                  type={"submit"}
                  color={"black"}
                  content={"Get Started"}
                  size={"large"}
                />
              </Form.Field>
              <Form.Field>
                <Header size="small" textAlign="center">
                  <p>
                    Already have an account? <Link to={LOGIN_PATH}>Login</Link>
                  </p>
                </Header>
              </Form.Field>
              <Form.Field>
                <p>
                  By providing your information and clicking Get Started, you
                  agree to our{" "}
                  <a
                    href={"https://www.artcollection.io/terms"}
                    target={"_blank"}
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href={"https://www.artcollection.io/privacy"}
                    target={"_blank"}
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </Form.Field>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </NonAuthLayout>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  trackReferral: (referral) => dispatch(createReferral(referral)),
  register: (data) => dispatch(register(data)),
  loginWithFacebook: (facebook_id, facebook_token) =>
    dispatch(loginWithFacebook({ facebook_id, facebook_token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
