import { Mixpanel } from "./../../utils/Mixpanel";
import { auth } from "./../../utils/firebase";
import {
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  REGISTER,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SWITCH_ROLE,
} from "../actions/auth";
// --------------------------- Action constants --------------------------

// --------------------------- Reducer function --------------------------

const INITIAL_STATE = {
  role: localStorage.getItem("role") || "user",
  onboard: localStorage.getItem("onboard") === "true",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return state;
    case REGISTER:
      return state;
    case LOGOUT:
      return { ...state, isLoading: true, error: null };
    case LOGIN_SUCCESS:
      Mixpanel.track("login");
      return { ...state, isLoading: false };
    case REGISTER_SUCCESS:
      Mixpanel.track("sign_up");
      return { ...state, isLoading: false };
    case RESET_PASSWORD_SUCCESS:
      const { user, token } = action.payload.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      return { ...state, isLoading: false };
    case LOGOUT_SUCCESS:
      Mixpanel.track("logout");

      try {
        auth.signOut();
      } catch (e) {
        console.error(e);
      }

      localStorage.clear();
      window.location.reload();
      return { ...state, isLoading: false };

    case LOGIN_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case REGISTER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case LOGOUT_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case RECOVER_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case RESET_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case SWITCH_ROLE:
      const { role } = action;
      localStorage.setItem("role", role);

      return {
        ...state,
        role,
      };
    default:
      return state;
  }
};
