import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import Attachment from "../../../../../../../../components/Attachment";

const Exhibition = ({ piece }) => {
  const { exhibited_text, exhibition_attachments } = piece;

  if (
    !exhibited_text &&
    (!exhibition_attachments || exhibition_attachments.length === 0)
  ) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Exhibitions"} dividing />
        <p>{exhibited_text}</p>
        {(exhibition_attachments || []).map((attachment) => (
          <Attachment key={attachment._id} attachment={attachment} />
        ))}
      </Grid.Column>
    </Grid.Row>
  );
};

Exhibition.propTypes = {
  piece: PropTypes.object,
};

export default Exhibition;
