import React, { useState } from "react";
import { Grid, Header, Menu, Segment } from "semantic-ui-react";

import ArtistAuctionRecordsRate from "../ArtistAuctionRecordRates";
import ArtistSellThroughRates from "../ArtistSellThroughRates";
import ArtistSoldLotPerformanceAgainstEstimate from "../ArtistSoldLotPerformanceAgainstEstimate";
import UpgradeOverlay from "../../../../../../components/UpgradeOverlay";

const ArtistStatistics = ({ artistId }) => {
  const [section, setSection] = useState("artworks_sold");

  return (
    <Segment compact basic>
      <Header
        size={"huge"}
        content={"Market Insights"}
        subheader={"Averages over the last 36 months"}
        textAlign={"left"}
      />
      <UpgradeOverlay>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Menu
              pointing
              secondary
              stackable
              fluid
              style={{ justifyContent: "center" }}
            >
              <Menu.Item
                key={"artworks_sold"}
                active={section === "artworks_sold"}
                content={"Artworks Sold"}
                onClick={() => setSection("artworks_sold")}
              />
              <Menu.Item
                key={"sell_through_rate"}
                active={section === "sell_through_rate"}
                content="Sell-through rate"
                onClick={() => setSection("sell_through_rate")}
              />
              <Menu.Item
                key={"slpae"}
                active={section === "slpae"}
                content="Lot Performance"
                onClick={() => setSection("slpae")}
              />
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            {section === "artworks_sold" && (
              <ArtistAuctionRecordsRate artistId={artistId} />
            )}
            {section === "sell_through_rate" && (
              <ArtistSellThroughRates artistId={artistId} />
            )}
            {section === "slpae" && (
              <ArtistSoldLotPerformanceAgainstEstimate artistId={artistId} />
            )}
          </Grid.Column>
        </Grid.Row>
      </UpgradeOverlay>
    </Segment>
  );
};

export default ArtistStatistics;
