const url = "/api/v4/private_rooms";

// Actions

export const RESET_PRIVATE_ROOM_STATE = "RESET_PRIVATE_ROOM_STATE";

export const GET_PRIVATE_ROOM_LIST = "GET_PRIVATE_ROOM_LIST";
export const GET_PRIVATE_ROOM_LIST_SUCCESS = "GET_PRIVATE_ROOM_LIST_SUCCESS";
export const GET_PRIVATE_ROOM_LIST_FAILURE = "GET_PRIVATE_ROOM_LIST_FAILURE";

export const GET_PRIVATE_ROOM = "GET_PRIVATE_ROOM";
export const GET_PRIVATE_ROOM_SUCCESS = "GET_PRIVATE_ROOM_SUCCESS";
export const GET_PRIVATE_ROOM_FAILURE = "GET_PRIVATE_ROOM_FAILURE";

export const GET_PIECE_LIST_FOR_PRIVATE_ROOM =
  "GET_PIECE_LIST_FOR_PRIVATE_ROOM";
export const GET_PIECE_LIST_FOR_PRIVATE_ROOM_SUCCESS =
  "GET_PIECE_LIST_FOR_PRIVATE_ROOM_SUCCESS";
export const GET_PIECE_LIST_FOR_PRIVATE_ROOM_FAILURE =
  "GET_PIECE_LIST_FOR_PRIVATE_ROOM_FAILURE";

export const CREATE_PRIVATE_ROOM = "CREATE_PRIVATE_ROOM";
export const CREATE_PRIVATE_ROOM_SUCCESS = "CREATE_PRIVATE_ROOM_SUCCESS";
export const CREATE_PRIVATE_ROOM_FAILURE = "CREATE_PRIVATE_ROOM_FAILURE";

export const UPDATE_PRIVATE_ROOM = "UPDATE_PRIVATE_ROOM";
export const UPDATE_PRIVATE_ROOM_SUCCESS = "UPDATE_PRIVATE_ROOM_SUCCESS";
export const UPDATE_PRIVATE_ROOM_FAILURE = "UPDATE_PRIVATE_ROOM_FAILURE";

export const DELETE_PRIVATE_ROOM = "DELETE_PRIVATE_ROOM";
export const DELETE_PRIVATE_ROOM_SUCCESS = "DELETE_PRIVATE_ROOM_SUCCESS";
export const DELETE_PRIVATE_ROOM_FAILURE = "DELETE_PRIVATE_ROOM_FAILURE";

export const SORT_PRIVATE_ROOM_LIST_BY_KEY = "SORT_PRIVATE_ROOM_LIST_BY_KEY";
export const FILTER_PRIVATE_ROOM_LIST_BY_TEXT =
  "FILTER_PRIVATE_ROOM_LIST_BY_TEXT";
export const TOGGLE_PRIVATE_ROOM_LIST_DISPLAY =
  "TOGGLE_PRIVATE_ROOM_LIST_DISPLAY";

// Networking

export const getPrivateRoomList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_PRIVATE_ROOM_LIST,
      GET_PRIVATE_ROOM_LIST_SUCCESS,
      GET_PRIVATE_ROOM_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const createPrivateRoom = (data) => {
  return {
    types: [
      CREATE_PRIVATE_ROOM,
      CREATE_PRIVATE_ROOM_SUCCESS,
      CREATE_PRIVATE_ROOM_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updatePrivateRoom = (id, data) => {
  return {
    types: [
      UPDATE_PRIVATE_ROOM,
      UPDATE_PRIVATE_ROOM_SUCCESS,
      UPDATE_PRIVATE_ROOM_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deletePrivateRoom = (id, data) => {
  return {
    types: [
      DELETE_PRIVATE_ROOM,
      DELETE_PRIVATE_ROOM_SUCCESS,
      DELETE_PRIVATE_ROOM_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const getPrivateRoom = (id) => {
  return {
    types: [
      GET_PRIVATE_ROOM,
      GET_PRIVATE_ROOM_SUCCESS,
      GET_PRIVATE_ROOM_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const getPrivateRoomPiecesList = (id) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_PRIVATE_ROOM,
      GET_PIECE_LIST_FOR_PRIVATE_ROOM_SUCCESS,
      GET_PIECE_LIST_FOR_PRIVATE_ROOM_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pieces`,
      },
    },
  };
};

export const sortPrivateRoomListByKey = (clickedColumn) => {
  return {
    type: SORT_PRIVATE_ROOM_LIST_BY_KEY,
    clickedColumn,
  };
};

export const filterPrivateRoomListByText = (search) => {
  return {
    type: FILTER_PRIVATE_ROOM_LIST_BY_TEXT,
    search,
  };
};

export const togglePrivateRoomListDisplay = (display) => {
  return {
    type: TOGGLE_PRIVATE_ROOM_LIST_DISPLAY,
    display,
  };
};

export const resetPrivateRoomState = () => {
  return {
    type: RESET_PRIVATE_ROOM_STATE,
  };
};
