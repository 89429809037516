import React from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";

import { getAuctionHouseAuctionsList } from "../../../../../../redux/actions/auction_house";

import AuctionHouseAuctionTile from "../AuctionHouseAuctionTile";
import InfiniteScroll from "react-infinite-scroller";

const AuctionHouseAuctionsList = ({
  auctionHouseId,
  auctions,
  hasMoreItems,
  fetchData,
}) => {
  return (
    <>
      <Header size={"large"} content={"Past Auctions"} />
      <InfiniteScroll
        initialLoad={true}
        loadMore={(page) => fetchData(auctionHouseId, page)}
        hasMore={hasMoreItems}
        loader={
          <Header
            key={"loader"}
            textAlign={"center"}
            size={"small"}
            content={"Loading ..."}
          />
        }
      >
        {(auctions || []).length !== 0 && (
          <Table selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={3}>Start Date</Table.HeaderCell>
                <Table.HeaderCell width={3}>End Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(auctions || []).map((auction) => {
                return (
                  <AuctionHouseAuctionTile
                    key={auction._id}
                    auction={auction}
                  />
                );
              })}
            </Table.Body>
          </Table>
        )}
      </InfiniteScroll>
    </>
  );
};

const mapStateToProps = ({ auction_house }) => ({
  hasMoreItems: auction_house.hasMoreAuctions,
  auctions: auction_house.auctions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (auction, page) =>
    dispatch(getAuctionHouseAuctionsList(auction, page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuctionHouseAuctionsList);
