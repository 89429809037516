import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import placeholder from "../../images/placeholder.png";
import { useNavigate } from "react-router-dom";
import { ARTIST_PATH } from "../../constants/paths";

const ArtistCard = ({ artist }) => {
  const navigate = useNavigate();
  const { _id, name, medium_image_url } = artist;

  return (
    <Card onClick={() => navigate(`${ARTIST_PATH}/${_id}`)}>
      <img
        src={medium_image_url || placeholder}
        style={{
          objectFit: "cover",
          objectPosition: "top",
          height: "100%",
          maxHeight: 240,
        }}
        alt={name}
      />
      <Card.Content>
        <Card.Header>{name}</Card.Header>
      </Card.Content>
    </Card>
  );
};

ArtistCard.propTypes = {
  artist: PropTypes.object,
};

export default ArtistCard;
