import React, { useRef, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { updateCurrentUserPassword } from "../../../../../../../redux/actions/me";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../../components/ErrorMessage";
import { auth } from "../../../../../../../utils/firebase";
import firebase from "firebase/compat/app";

const State = {
  Password: "password",
  Code: "code",
};

const ChangePasswordModal = ({ updatePassword }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [state, setState] = useState(State.Password);

  const [verificationId, setVerificationId] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [resolver, setResolver] = useState(null);
  const captchaRef = useRef(null);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      await auth.signInWithEmailAndPassword(
        auth.currentUser.email,
        currentPassword,
      );
      await auth.currentUser.updatePassword(newPassword);
      onClose();
    } catch (e) {
      handleError(e);
    }

    setLoading(false);
  };

  const onCaptcha = () => {};

  const handleError = async (error) => {
    switch (error.code) {
      case "auth/multi-factor-auth-required":
        const resolver = error.resolver;
        setResolver(resolver);

        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          captchaRef.current,
          {
            size: "invisible",
            callback: (response) => {
              onCaptcha();
            },
          },
        );
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          },
          recaptchaVerifier,
        );
        setVerificationId(verificationId);
        setState(State.Code);
        break;
      default:
        setError(error);
        break;
    }
  };

  const onSubmitCode = async () => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
      await resolver.resolveSignIn(multiFactorAssertion);
      await auth.currentUser.updatePassword(newPassword);
      onClose();
    } catch (e) {
      await handleError(e);
    }
  };

  const onClose = () => {
    setModalOpen(false);
    setNewPassword(null);
    setCurrentPassword(null);
  };

  return (
    <Modal
      open={modalOpen}
      trigger={
        <Button
          floated={"left"}
          content={"Change password"}
          onClick={() => setModalOpen(true)}
        />
      }
    >
      <Modal.Header size="huge" content={"Change password"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        {state === State.Password && (
          <>
            <div id="recaptcha-container" ref={captchaRef} />
            <Form onSubmit={onSubmit}>
              <Form.Group>
                <Form.Field width={4}>
                  <label>Current Password</label>
                </Form.Field>
                <Form.Input
                  width={12}
                  placeholder={"Current Password"}
                  autoComplete={"off"}
                  type={"password"}
                  onChange={(e, { value }) => setCurrentPassword(value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field width={4}>
                  <label>New Password</label>
                </Form.Field>
                <Form.Input
                  width={12}
                  placeholder={"New Password"}
                  autoComplete={"off"}
                  type={"password"}
                  onChange={(e, { value }) => setNewPassword(value)}
                />
              </Form.Group>
            </Form>
          </>
        )}
        {state === State.Code && (
          <>
            <Form onSubmit={onSubmitCode}>
              <Form.Input
                label="Code"
                placeholder="Code"
                onChange={(e, { value }) => setVerificationCode(value)}
              />
            </Form>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content={"Cancel"} onClick={onClose} />
        {state === State.Password && (
          <Button
            primary
            loading={loading}
            disabled={!newPassword || !currentPassword}
            content={"Change password"}
            onClick={onSubmit}
          />
        )}
        {state === State.Code && (
          <Button
            primary
            loading={loading}
            disabled={!verificationCode}
            content={"Verify"}
            onClick={onSubmitCode}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (data) => dispatch(updateCurrentUserPassword(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordModal);
