import React, { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "./UserContext";
import { firestore } from "../utils/firebase";
import { onSnapshot, query, collection } from "firebase/firestore";
import { useAuth } from "./AuthContext";

const CurrenciesContext = createContext(undefined);

export const useCurrencies = () => {
  const context = useContext(CurrenciesContext);
  if (!context) {
    throw new Error("useCurrencies must be used within a CurrenciesProvider");
  }
  return context;
};

export const CurrenciesProvider = ({ children }) => {
  const { user } = useAuth();
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) return;
    const docRef = query(collection(firestore, "currencies"));
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        const currenciesData = docSnapshot.docs.map((doc) => ({
          ...doc.data(),
          _id: doc.id,
        }));
        setCurrencies(currenciesData);
        setLoading(false);
        console.log("Currencies data: ", currenciesData[0]);
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
        setLoading(false);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <CurrenciesContext.Provider
      value={{
        currencies,
        loading,
        error,
      }}
    >
      {children}
    </CurrenciesContext.Provider>
  );
};
