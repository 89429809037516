import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import { getArtworksList } from "../../../../../../redux/actions/artist";
import ArtistArtworkRecordTile from "../ArtistArtworkRecordTile";
import InfiniteScroll from "react-infinite-scroller";
import EmptyState from "../../../../../../components/EmptyState";
import empty_state from "../../../../../../images/empty_state/piece.png";

const ArtistArtworkRecords = ({ artworks, artistId, hasMoreItems, fetch }) => {
  useEffect(() => {
    fetch(artistId, 1); // Fetch initial page when artistId changes
  }, [artistId, fetch]);

  const artworkTiles = useMemo(
    () =>
      artworks.map((record) => (
        <ArtistArtworkRecordTile key={record._id} artwork={record} />
      )),
    [artworks],
  );

  return (
    <InfiniteScroll
      initialLoad={true}
      loadMore={(page) => fetch(artistId, page)}
      hasMore={hasMoreItems}
      loader={
        <Header
          key={"loader"}
          textAlign={"center"}
          size={"small"}
          content={"Loading ..."}
        />
      }
    >
      {artworks.length === 0 ? (
        <EmptyState
          image={empty_state}
          title={"No Artwork"}
          detail={
            "At the moment, we don't have any artwork records for this artist."
          }
        />
      ) : (
        <Table striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8} content={"Title"} />
              <Table.HeaderCell width={4} content={"Artist"} />
              <Table.HeaderCell width={4} content={"Museum"} />
            </Table.Row>
          </Table.Header>
          <Table.Body>{artworkTiles}</Table.Body>
        </Table>
      )}
    </InfiniteScroll>
  );
};

const mapStateToProps = ({ artist }) => ({
  hasMoreItems: artist.hasMoreArtworks,
  artworks: artist.artworks,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (artist, page) => dispatch(getArtworksList(artist, page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArtistArtworkRecords);
