import React, { Component, useRef, useState } from "react";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
import { deleteTwoFactorSetting } from "../../../../../../../redux/actions/two_factor_setting";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../../components/ErrorMessage";
import { auth } from "../../../../../../../utils/firebase";
import firebase from "firebase/compat/app";

const State = {
  UnEnroll: "unEnroll",
  Password: "password",
  Code: "code",
};

const VerifyTwoFactorSetting = ({ userHasUpdatedEnrollment }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState(State.UnEnroll);

  const [password, setPassword] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [resolver, setResolver] = useState(null);
  const captchaRef = useRef(null);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const options = auth.currentUser.multiFactor.enrolledFactors;
      await auth.currentUser.multiFactor.unenroll(options[0]);
      setModalOpen(false);
    } catch (e) {
      await handleError(e);
    }

    setLoading(false);
  };

  const onSubmitPassword = async () => {
    setError(null);
    setLoading(true);

    try {
      const email = auth.currentUser.email;
      await auth.signInWithEmailAndPassword(email, password);
      await onSubmit();
    } catch (e) {
      await handleError(e);
    }
    setLoading(false);
  };

  const onSubmitCode = async () => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
      await resolver.resolveSignIn(multiFactorAssertion);
      await onSubmit();
    } catch (e) {
      await handleError(e);
    }
  };

  const onCaptcha = () => {};

  const handleError = async (error) => {
    switch (error.code) {
      case "auth/multi-factor-auth-required":
        const resolver = error.resolver;
        setResolver(resolver);

        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          captchaRef.current,
          {
            size: "invisible",
            callback: (response) => {
              onCaptcha();
            },
          },
        );
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          },
          recaptchaVerifier,
        );
        setVerificationId(verificationId);
        setState(State.Code);
        userHasUpdatedEnrollment();
        break;
      case "auth/requires-recent-login":
        setState(State.Password);
        break;
      default:
        setError(error);
        break;
    }
  };

  const renderBody = () => {
    switch (state) {
      case State.Code:
        return (
          <>
            <Header content={"Please verify 2FA"} />
            <Form>
              <Form.Input
                label="Code"
                placeholder="Code"
                onChange={(e, { value }) => setVerificationCode(value)}
              />
            </Form>
          </>
        );
      case State.Password:
        return (
          <>
            <Header content={"Please verify password"} />
            <Form>
              <Form.Input
                label="Password"
                type="password"
                placeholder="Password"
                onChange={(e, { value }) => setPassword(value)}
              />
            </Form>
          </>
        );
      case State.UnEnroll:
        return (
          <>
            <p>Are you sure you want to delete this setting?</p>
          </>
        );
    }
  };

  const renderButtons = () => {
    switch (state) {
      case State.Code:
        return (
          <Button
            primary
            loading={loading}
            content={"Verify"}
            onClick={onSubmitCode}
          />
        );
      case State.Password:
        return (
          <Button
            primary
            loading={loading}
            content={"Login"}
            onClick={onSubmitPassword}
          />
        );
      case State.UnEnroll:
        return (
          <Button
            negative
            loading={loading}
            content={"Un-Enroll"}
            onClick={onSubmit}
          />
        );
    }
  };

  return (
    <Modal
      open={modalOpen}
      trigger={
        <Button
          floated={"right"}
          icon={"close"}
          onClick={() => setModalOpen(true)}
        />
      }
    >
      <Modal.Header
        size="huge"
        content={"Delete Two Factor Setting"}
        subheader={"Are you sure"}
      />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <div id="recaptcha-container" ref={captchaRef} />
        {renderBody()}
      </Modal.Content>
      <Modal.Actions>
        <Button content={"No"} onClick={() => setModalOpen(false)} />
        {renderButtons()}
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteTwoFactorSetting(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyTwoFactorSetting);
