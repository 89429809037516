import React, { useState } from "react";
import { Grid, Menu } from "semantic-ui-react";

import ArtistPieceRecords from "../ArtistPieceRecords";
import ArtistArtworkRecords from "../ArtistArtworkRecords";
import ArtistAuctionRecords from "../ArtistAuctionRecords";
import ArtistStatistics from "../ArtistStatistics";
import ArtistMarketInsights from "../ArtistMarketInsights/ArtistMarketInsights";
import ArtistGroupings from "../ArtistGroupings";

const ArtistExtra = ({ artist }) => {
  const { counts } = artist || {};
  const [section, setSection] = useState("my_pieces");

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Menu
            pointing
            secondary
            stackable
            fluid
            style={{ justifyContent: "center" }}
          >
            <Menu.Item
              key={"my_pieces"}
              active={section === "my_pieces"}
              content={"My Artworks"}
              onClick={() => setSection("my_pieces")}
            />
            <Menu.Item
              key={"artworks"}
              active={section === "artworks"}
              content={"Museum Artworks"}
              onClick={() => setSection("artworks")}
            />
            <Menu.Item
              key={"auction_records"}
              active={section === "auction_records"}
              content={"Auction Records"}
              onClick={() => setSection("auction_records")}
            />
            {counts && counts.auction_records > 0 && (
              <Menu.Item
                key={"groupings"}
                active={section === "groupings"}
                content={"Series"}
                onClick={() => setSection("groupings")}
              />
            )}
            {counts && counts.auction_records > 0 && (
              <Menu.Item
                key={"statistics"}
                active={section === "statistics"}
                content={"Statistics"}
                onClick={() => setSection("statistics")}
              />
            )}
          </Menu>
        </Grid.Column>
      </Grid.Row>
      {section === "my_pieces" && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <ArtistPieceRecords key={"my_pieces"} artistId={artist._id} />
          </Grid.Column>
        </Grid.Row>
      )}
      {section === "auction_records" && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <ArtistAuctionRecords artistId={artist._id} />
          </Grid.Column>
        </Grid.Row>
      )}
      {section === "artworks" && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <ArtistArtworkRecords artistId={artist._id} />
          </Grid.Column>
        </Grid.Row>
      )}
      {section === "statistics" && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <ArtistMarketInsights artistId={artist._id} />
          </Grid.Column>
        </Grid.Row>
      )}
      {section === "statistics" && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <ArtistStatistics artistId={artist._id} />
          </Grid.Column>
        </Grid.Row>
      )}
      {section === "groupings" && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <ArtistGroupings artistId={artist._id} />
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default ArtistExtra;
