import { connect } from "react-redux";
import React from "react";
import Attachments from "../BasicInfo/Attachments";
import PropTypes from "prop-types";

const ConditionConservationAttachments = ({
  attachments,
  onAttachmentsUpdated,
}) => (
  <Attachments
    attachments={attachments}
    type={"condition_conservation"}
    onAttachmentsUpdated={onAttachmentsUpdated}
  />
);

ConditionConservationAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
};

export default ConditionConservationAttachments;
