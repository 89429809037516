import React from "react";
import { Image, Table } from "semantic-ui-react";
import placeholder from "../../../../../../images/placeholder.png";
import { AUCTION_RECORD_PATH } from "../../../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../../../contexts/UserContext";
import { useCurrencies } from "../../../../../../contexts/CurrenciesContext";

const ArtistAuctionRecordTile = ({ auctionRecord }) => {
  const { currentUser: user } = useUser();
  const { currencies } = useCurrencies();
  const navigate = useNavigate();

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Table.Row
      style={{ cursor: "pointer" }}
      onClick={(e) =>
        onRowClick(e, `${AUCTION_RECORD_PATH}/${auctionRecord._id}`)
      }
    >
      <Table.Cell>
        <Image avatar src={auctionRecord.small_image_url || placeholder} />
        {auctionRecord.title}
      </Table.Cell>
      <Table.Cell>
        <div>{auctionRecord.auction && auctionRecord.auction.name}</div>
        <div>{auctionRecord.auction && auctionRecord.auction.dateString()}</div>
      </Table.Cell>
      <Table.Cell
        content={auctionRecord.estimatePriceString(currencies, user)}
      />
      <Table.Cell content={auctionRecord.hammerPriceString(currencies, user)} />
    </Table.Row>
  );
};

export default ArtistAuctionRecordTile;
