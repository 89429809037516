import React from "react";
import { Button, Divider, Header, Table } from "semantic-ui-react";

const Files = ({ piece }) => {
  const { files } = piece;

  if (!files || files.length === 0) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Additional Files"} dividing />
        <Table>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell width={2} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(files || []).map(({ _id, name, url }) => {
              return (
                <Table.Row key={_id}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>
                    <Button.Group floated={"right"}>
                      <Button
                        basic
                        icon="download"
                        as="a"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Files;
