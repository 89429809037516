import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import ManageBilling from "../ManageBilling";
import { useUser } from "../../../../../../../contexts/UserContext";

const ProAccount = () => {
  const { currentUser: user } = useUser();

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Segment.Inline>You are on a Pro Account</Segment.Inline>
        </Segment>
        <ManageBilling user={user} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default ProAccount;
