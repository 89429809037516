import React from "react";
import PropTypes from "prop-types";
import Navbar from "../containers/Navbar/Navbar";

const CoreLayout = ({ children }) => {
  return <Navbar>{children}</Navbar>;
};

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CoreLayout;
