import mixpanel from "mixpanel-browser";
import { analytics } from "./firebase";

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_TOKEN);

const env_check = process.env.NODE_ENV === "production";

let actions = {
  identify: (id, identifyTraits) => {
    try {
      if (env_check) {
        window.analytics.identify(id, identifyTraits);
        analytics.setUserId(id);
      } else {
        console.info(
          `USER: ${id} \n TRAITS: ${
            identifyTraits && JSON.stringify(identifyTraits)
          }`,
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
  alias: (id) => {
    try {
      if (env_check) {
        analytics.alias(id);
      }
    } catch (e) {
      console.error(e);
    }
  },
  track: (name, props) => {
    try {
      if (env_check) {
        window.analytics.track(name, props);
        analytics.logEvent(name, props);
      } else {
        console.info(
          `EVENT: ${name} \n PROPS: ${props && JSON.stringify(props)}`,
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
  page: (name, props) => {
    try {
      if (env_check) {
        window.analytics.page(name, props);
        analytics.setCurrentScreen(name);
      } else {
        console.info(
          `PAGE: ${name} \n PROPS: ${props && JSON.stringify(props)}`,
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
};

export const Mixpanel = actions;
