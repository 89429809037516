import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Menu } from "semantic-ui-react";
import { createTag } from "../../../../redux/actions/tag";
import { connect } from "react-redux";
import ErrorMessage from "../../../ErrorMessage";

const AddTagModal = ({ defaultName, create }) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [name, setName] = useState(defaultName || "");

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await create({ name });
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal
      trigger={
        <Menu.Item
          color={"black"}
          icon={"plus"}
          content={"Create Tag"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      size={"small"}
      closeIcon
    >
      <Modal.Header content={"Add Tag"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={name}
              width={12}
              placeholder={"Name"}
              autoComplete={"off"}
              onChange={(e, { value }) => setName(value)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          icon={"checkmark"}
          loading={loading}
          content={"Create"}
          onClick={onSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createTag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTagModal);
