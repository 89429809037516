import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Checkbox,
  Image,
  Input,
  Loader,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import empty_state from "../../../../../../images/empty_state/piece.png";
import EmptyState from "../../../../../../components/EmptyState";
import Piece from "../../../../../../models/piece";
import EditMultiplePiecesModal from "../../../../../../components/Modals/EditMultiplePiecesModal";
import DashboardModal from "../../../../../../components/Modals/DashboardModal";
import ExportModal from "../../../../../../components/Modals/ExportModal";
import { PIECE_PATH } from "../../../../../../constants/paths";
import { usePieces } from "../../../../../../contexts/PiecesContext";

const GalleryPieceRecords = ({ galleryId, auth, user }) => {
  const navigate = useNavigate();
  const [selectedPieceIDs, setSelectedPieceIDs] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState(["title", "asc"]);
  const { pieces, loading } = usePieces();

  useEffect(() => {}, [galleryId]);

  const onSearchChanged = (e, { value }) => {
    setSearchTerm(value);
  };

  const handleSelect = (pieceID) => {
    if (selectedPieceIDs.has(pieceID)) {
      selectedPieceIDs.delete(pieceID);
    } else {
      selectedPieceIDs.add(pieceID);
    }
    setSelectedPieceIDs(new Set(selectedPieceIDs));
  };

  const handSelectAll = (checked) => {
    if (checked) {
      const pieceIDs = pieces.map((piece) => piece._id);
      setSelectedPieceIDs(new Set(pieceIDs));
    } else {
      setSelectedPieceIDs(new Set());
    }
  };

  if (loading) return <Segment basic loading />;

  const filteredPieces = (pieces || [])
    .map((data) => new Piece(data))
    .filter((piece) => piece?.gallery?._id === galleryId)
    .filter(
      (piece) =>
        piece.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ((piece.artist && piece.artist.name.toLowerCase()) ?? "").includes(
          searchTerm.toLowerCase(),
        ) ||
        ((piece.location && piece.location.name.toLowerCase()) ?? "").includes(
          searchTerm.toLowerCase(),
        ),
    );

  if (!filteredPieces || filteredPieces.length === 0)
    return (
      <EmptyState
        image={empty_state}
        title={"No Artworks"}
        detail={
          "At the moment, we don't have any artwork records for this gallery."
        }
      />
    );

  return (
    <>
      <Menu secondary stackable>
        <Menu.Item style={{ paddingLeft: 0 }}>
          <EditMultiplePiecesModal currentSelectedPieces={selectedPieceIDs} />
        </Menu.Item>
        <Menu.Item style={{ flexGrow: 2, paddingLeft: 0 }}>
          <Input
            icon={"search"}
            placeholder={"Search..."}
            onChange={onSearchChanged}
          />
        </Menu.Item>
        <Menu.Menu position={"right"}>
          <DashboardModal pieces={(pieces || []).map((p) => new Piece(p))} />
          <Menu.Item style={{ paddingRight: 0 }}>
            <div>
              <ExportModal
                activeTab={"all"}
                currentPieces={pieces}
                currentSelectedPieces={selectedPieceIDs}
              />
            </div>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Table sortable striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              key={"check"}
              textAlign={"center"}
              verticalAlign={"middle"}
              style={{ width: "1%" }}
            >
              <Checkbox onChange={(_, { checked }) => handSelectAll(checked)} />
            </Table.HeaderCell>
            <Table.HeaderCell width={5} content={"Title"} />
            <Table.HeaderCell width={4} content={"Artist"} />
            <Table.HeaderCell width={4} content={"Location"} />
            <Table.HeaderCell width={2} content={"Price"} />
            <Table.HeaderCell width={2} content={"Purchase Date"} />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredPieces.map((piece) => (
            <Table.Row key={piece._id}>
              <Table.Cell textAlign={"center"}>
                <Checkbox
                  checked={selectedPieceIDs.has(piece._id)}
                  onChange={(_, { checked }) => handleSelect(piece._id)}
                />
              </Table.Cell>
              <Table.Cell
                onClick={() => navigate(`${PIECE_PATH}/${piece._id}`)}
              >
                <Image src={piece.smallImageUrl()} avatar />
                {piece.title}
              </Table.Cell>

              <Table.Cell
                onClick={() => navigate(`${PIECE_PATH}/${piece._id}`)}
              >
                {piece.artist && piece.artist.name}
              </Table.Cell>

              <Table.Cell
                onClick={() => navigate(`${PIECE_PATH}/${piece._id}`)}
              >
                {piece.location && piece.location.name}
              </Table.Cell>

              <Table.Cell
                onClick={() => navigate(`${PIECE_PATH}/${piece._id}`)}
              >
                {piece.purchasePriceString()}
              </Table.Cell>

              <Table.Cell
                onClick={() => navigate(`${PIECE_PATH}/${piece._id}`)}
              >
                {piece.purchaseDateString}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GalleryPieceRecords);
