const url = "/api/v4/artworks";

export const RESET_ARTWORK_STATE = "RESET_ARTWORK_STATE";

export const CREATE_ARTWORK = "CREATE_ARTWORK";
export const CREATE_ARTWORK_SUCCESS = "CREATE_ARTWORK_SUCCESS";
export const CREATE_ARTWORK_FAILURE = "CREATE_ARTWORK_FAILURE";

export const UPDATE_ARTWORK = "UPDATE_ARTWORK";
export const UPDATE_ARTWORK_SUCCESS = "UPDATE_ARTWORK_SUCCESS";
export const UPDATE_ARTWORK_FAILURE = "UPDATE_ARTWORK_FAILURE";

export const DELETE_ARTWORK = "DELETE_ARTWORK";
export const DELETE_ARTWORK_SUCCESS = "DELETE_ARTWORK_SUCCESS";
export const DELETE_ARTWORK_FAILURE = "DELETE_ARTWORK_FAILURE";

export const GET_ARTWORK_LIST = "GET_ARTWORK_LIST";
export const GET_ARTWORK_LIST_SUCCESS = "GET_ARTWORK_LIST_SUCCESS";
export const GET_ARTWORK_LIST_FAILURE = "GET_ARTWORK_LIST_FAILURE";

export const GET_ARTWORK = "GET_ARTWORK";
export const GET_ARTWORK_SUCCESS = "GET_ARTWORK_SUCCESS";
export const GET_ARTWORK_FAILURE = "GET_ARTWORK_FAILURE";

export const getArtworkList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_ARTWORK_LIST,
      GET_ARTWORK_LIST_SUCCESS,
      GET_ARTWORK_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArtwork = (id) => {
  return {
    types: [GET_ARTWORK, GET_ARTWORK_SUCCESS, GET_ARTWORK_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createArtwork = (data) => {
  return {
    types: [CREATE_ARTWORK, CREATE_ARTWORK_SUCCESS, CREATE_ARTWORK_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateArtwork = (id, data) => {
  return {
    types: [UPDATE_ARTWORK, UPDATE_ARTWORK_SUCCESS, UPDATE_ARTWORK_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteArtwork = (id, data) => {
  return {
    types: [DELETE_ARTWORK, DELETE_ARTWORK_SUCCESS, DELETE_ARTWORK_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const resetArtworkState = () => {
  return {
    type: RESET_ARTWORK_STATE,
  };
};
