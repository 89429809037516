import {
  CREATE_PRIVATE_ROOM_SUCCESS,
  DELETE_PRIVATE_ROOM_SUCCESS,
  GET_PRIVATE_ROOM_LIST,
  GET_PRIVATE_ROOM_LIST_FAILURE,
  GET_PRIVATE_ROOM_LIST_SUCCESS,
  UPDATE_PRIVATE_ROOM_SUCCESS,
} from "../actions/private_room";

const INITIAL_STATE = {
  display: localStorage.getItem("privateRoomDisplay") || "table",
  isLoadingPrivateRoomList: true,
  privateRooms: [],
  hasMorePrivateRooms: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRIVATE_ROOM_LIST:
      return {
        ...state,
        isLoadingPrivateRoomList: true,
        privateRooms:
          action.payload.request.params.page <= 1 ? [] : state.privateRooms,
      };
    case GET_PRIVATE_ROOM_LIST_SUCCESS:
      return {
        ...state,
        isLoadingPrivateRoomList: false,
        privateRooms: state.privateRooms.concat(action.payload.data),
        hasMorePrivateRooms: action.payload.data.length === 25,
      };
    case GET_PRIVATE_ROOM_LIST_FAILURE:
      return {
        ...state,
        isLoadingPrivateRoomList: false,
        error: action.error,
        hasMorePrivateRooms: false,
      };

    case CREATE_PRIVATE_ROOM_SUCCESS:
      return {
        ...state,
        privateRooms: [action.payload.data, ...state.privateRooms],
      };

    case DELETE_PRIVATE_ROOM_SUCCESS:
      return {
        ...state,
        privateRooms: state.privateRooms.filter(
          (item) => item._id !== action.payload.data._id,
        ),
      };
    case UPDATE_PRIVATE_ROOM_SUCCESS:
      return {
        ...state,
        privateRooms: (state.privateRooms || [action.payload.data]).map(
          (privateRoom) => {
            if (privateRoom._id === action.payload.data._id) {
              return action.payload.data;
            }
            return privateRoom;
          },
        ),
      };
    default:
      return state;
  }
};
