import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header } from "semantic-ui-react";
import Attachment from "../../../../../../../../components/Attachment";

const Provenance = ({ piece }) => {
  const { provenance_text, provenance_attachments } = piece;

  if (
    !provenance_text &&
    (!provenance_attachments || provenance_attachments.length === 0)
  ) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Provenance"} dividing />
        <p>{provenance_text}</p>
        {(provenance_attachments || []).map((attachment) => (
          <Attachment key={attachment._id} attachment={attachment} />
        ))}
      </Grid.Column>
    </Grid.Row>
  );
};

Provenance.propTypes = {
  piece: PropTypes.object,
};

export default Provenance;
