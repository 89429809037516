import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Loader, Segment, Header } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { getGroupingPricePredictions } from "../../../redux/actions/grouping";
import ErrorMessage from "../../../components/ErrorMessage";
import { ResponsiveLine } from "@nivo/line";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const PricePredictions = ({ fetch, error }) => {
  const { groupingId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ past_data: [], future_data: [] });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { payload } = await fetch(groupingId);
        setData(payload.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
      setLoading(false);
    };
    fetchData();
  }, [groupingId, fetch]);

  if (!data) return null;

  const actualData = data.past_data.map((d) => ({
    x: d.year,
    y: d.predictedPrice / 100,
  }));
  const predictedData = data.future_data.map((d) => ({
    x: d.year,
    y: d.predictedPrice / 100,
  }));
  const isIncreasing = predictedData[1]?.y > predictedData[0]?.y;

  return (
    <>
      <Segment attached="top">
        <Header content="Market Trends" />
      </Segment>
      <Segment attached loading={loading}>
        {loading && <Loader active inline="centered" />}
        {error && <ErrorMessage error={error} />}
        <div style={{ height: 400 }}>
          <ResponsiveLine
            data={[
              { id: "Actual", data: actualData, color: "#e76f51" },
              {
                id: "Predicted",
                data: predictedData,
                color: isIncreasing ? "green" : "red",
              },
            ]}
            colors={({ color }) => color}
            margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear", min: 0, max: "auto", stacked: false }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              legendOffset: -40,
              legendPosition: "middle",
              format: (d) => `$${d.toLocaleString()}`,
            }}
            useMesh
            tooltip={({ point }) => (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                }}
              >
                <strong>{point.data.x}</strong>
                <div>{currencyFormatter.format(point.data.yFormatted)}</div>
              </div>
            )}
          />
        </div>
      </Segment>
      <Segment attached="bottom">
        <Header
          size="tiny"
          content="*Average annual growth rate, calculated from public auction data over the last 5 years."
        />
      </Segment>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getGroupingPricePredictions(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricePredictions);
