import React, { useState } from "react";
import { Button, Form, Grid, Header, Modal, Table } from "semantic-ui-react";
// Images
import SlidingPane from "react-sliding-pane";

import { connect } from "react-redux";
import {
  createAuctionResult,
  updateAuctionResult,
} from "../../../../../../redux/actions/auction_result";
import DropZone from "../../../../../../components/DropZone";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import FormDatePicker from "../../../../../../components/Forms/FormDatePicker";
import SelectArtistModal from "../../../../../../components/Modals/Artist/Select";
import {
  auctionRecordCategoryOptions,
  currencyOptions,
  lotPerformanceOptions,
} from "../../../../../../common";

const EditAuctionRecordSidePain = ({
  auctionRecord,
  children,
  update,
  create,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const [id] = useState(auctionRecord._id);
  const [imageUrl] = useState(auctionRecord.image_url || null);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState(auctionRecord.title || "");
  const [year, setYear] = useState(auctionRecord.year || "");
  const [artist, setArtist] = useState(auctionRecord.artist || "");

  const [lotNumber, setLotNumber] = useState(auctionRecord.lot_number || "");
  const [auctionIdentifier, setAuctionIdentifier] = useState(
    auctionRecord.auction_identifier || "",
  );
  const [hammerCurrency, setHammerCurrency] = useState(
    auctionRecord.hammer_currency,
  );
  const [hammerPrice, setHammerPrice] = useState(auctionRecord.hammer_price);
  const [estimateCurrency, setEstimateCurrency] = useState(
    auctionRecord.estimate_currency || "",
  );
  const [estimateLow, setEstimateLow] = useState(
    auctionRecord.estimate_low || "",
  );
  const [estimateHigh, setEstimateHigh] = useState(
    auctionRecord.estimate_high || "",
  );
  const [about, setAbout] = useState(auctionRecord.about || "");
  const [medium, setMedium] = useState(auctionRecord.medium || "");
  const [size, setSize] = useState(auctionRecord.size || "");
  const [heightCM, setHeightCM] = useState(auctionRecord.height_cm || "");
  const [widthCM, setWidthCM] = useState(auctionRecord.width_cm || "");
  const [depthCM, setDepthCM] = useState(auctionRecord.depth_cm || "");

  const [conditionReport, setConditionReport] = useState(
    auctionRecord.condition_report || "",
  );
  const [catalogue_note, setCatalogueNote] = useState(
    auctionRecord.catalogue_note || "",
  );
  const [exhibited, setExhibited] = useState(auctionRecord.exhibited || "");
  const [provenance, setProvenance] = useState(auctionRecord.provenance || "");
  const [literature, setLiterature] = useState(auctionRecord.literature || "");
  const [saleDate, setSaleDate] = useState(auctionRecord.sale_date);
  const [websiteUrl, setWebsiteUrl] = useState(auctionRecord.website_url || "");
  const [category, setCategory] = useState(auctionRecord.category);
  const [lotPerformance, setLotPerformance] = useState(
    auctionRecord.lot_performance || "",
  );
  const [groupings, setGroupings] = useState(auctionRecord.groupings || []);
  const [reference_id, setReferenceId] = useState(auctionRecord.reference_id);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = {
      lot_number: lotNumber,
      auction_identifier: auctionIdentifier,
      title,
      hammer_currency: hammerCurrency,
      hammer_price: hammerPrice,
      estimate_currency: estimateCurrency,
      estimate_low: estimateLow,
      estimate_high: estimateHigh,
      about,
      artist,
      medium,
      size,
      year,
      catalogue_note,
      exhibited,
      provenance,
      literature,
      condition_report: conditionReport,
      sale_date: saleDate,
      website_url: websiteUrl,
      category,
      height_cm: heightCM,
      width_cm: widthCM,
      depth_cm: depthCM,
      lot_performance: lotPerformance,
      groupings: groupings.map((grouping) => grouping._id),
      reference_id,
    };
    if (artist) data.artist = artist._id;

    try {
      if (id) {
        await update(id, data);
      } else {
        await create(formData);
      }

      setModalOpen(true);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={id ? "Edit Gallery" : "Create Gallery"}
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Form fluid>
          {error && <ErrorMessage error={error} />}

          <Modal open={modalOpen}>
            <Modal.Header size="huge" content="Thank you" />
            <Modal.Content>
              <p>
                Our team will review the proposed changes and make the necessary
                adjustments.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                content="Okay"
                onClick={() => {
                  setModalOpen(false);
                  setSidePainOpen(false);
                }}
              />
            </Modal.Actions>
          </Modal>

          <Form.Group>
            <Form.Field width={4}>
              <label>Image</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                accept="image/*"
                icon={"image"}
                image={(file && file.preview) || imageUrl}
                placeholder={"Drag artwork image here or click to upload"}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={4}>
              <label>Identifier & Lot Number</label>
            </Form.Field>
            <Form.Input
              width={6}
              name="auction_identifier"
              defaultValue={auctionIdentifier}
              placeholder="Auction Identifier"
              autoComplete={"off"}
              onChange={(e, { value }) => setAuctionIdentifier(value)}
            />
            <Form.Input
              width={6}
              fluid
              name="lot_number"
              defaultValue={lotNumber}
              placeholder="Lot Number"
              autoComplete={"off"}
              onChange={(e, { value }) => setLotNumber(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Title & Year</label>
            </Form.Field>
            <Form.Input
              width={8}
              name="title"
              defaultValue={title}
              placeholder="Title"
              autoComplete={"off"}
              onChange={(e, { value }) => setTitle(value)}
            />
            <Form.Input
              width={4}
              name="year"
              defaultValue={year}
              placeholder="Year"
              autoComplete={"off"}
              onChange={(e, { value }) => setYear(value)}
            />
          </Form.Group>

          <FormDatePicker
            name="sale_date"
            value={saleDate}
            label="Sale Date"
            handleChange={(e, { value }) => setSaleDate(value)}
          />
          <Form.Input
            fluid
            name="website_url"
            defaultValue={websiteUrl}
            label="Website"
            placeholder="Website"
            autoComplete={"off"}
            onChange={(e, { value }) => setWebsiteUrl(value)}
          />

          <Form.Field>
            <label>Artist</label>
            <SelectArtistModal
              artist={artist}
              onSelectArtist={(artist) => setArtist(artist)}
            />
          </Form.Field>

          <Form.Select
            name="lot_performance"
            defaultValue={lotPerformance}
            label="Lot Performance"
            placeholder="Lot Performance"
            autoComplete={"off"}
            options={lotPerformanceOptions}
            onChange={(e, { value }) => setLotPerformance(value)}
            search
          />

          <Form.Select
            name="category"
            defaultValue={category}
            label="Category"
            placeholder="Category"
            autoComplete={"off"}
            options={auctionRecordCategoryOptions}
            onChange={(e, { value }) => setCategory(value)}
            search
          />

          <Form.Input
            fluid
            name="medium"
            defaultValue={medium}
            label="Medium"
            placeholder="Medium"
            autoComplete={"off"}
            onChange={(e, { value }) => setMedium(value)}
          />

          <Form.Input
            fluid
            defaultValue={reference_id}
            label="Reference Id"
            placeholder="Reference Id"
            autoComplete={"off"}
            onChange={(e, { value }) => setReferenceId(value)}
          />

          <Form.TextArea
            fluid
            name="size"
            defaultValue={size}
            label="Size"
            placeholder="Size"
            autoComplete={"off"}
            onChange={(e, { value }) => setSize(value)}
          />

          <Form.Field>
            <label>Dimensions (cm.)</label>
            <Form.Group widths={"equal"}>
              <Form.Input
                fluid
                name={"height_cm"}
                defaultValue={heightCM}
                label="height_cm"
                placeholder="height_cm"
                autoComplete={"off"}
                onChange={(e, { value }) => setHeightCM(value)}
              />
              <Form.Input
                fluid
                name={"width_cm"}
                defaultValue={widthCM}
                label="width_cm"
                placeholder="width_cm"
                autoComplete={"off"}
                onChange={(e, { value }) => setWidthCM(value)}
              />
              <Form.Input
                fluid
                name={"depth_cm"}
                defaultValue={depthCM}
                label="depth_cm"
                placeholder="depth_cm"
                autoComplete={"off"}
                onChange={(e, { value }) => setDepthCM(value)}
              />
            </Form.Group>
          </Form.Field>

          <Form.Field>
            <label>Estimate</label>
            <Form.Group>
              <Form.Select
                fluid
                name="estimate_currency"
                placeholder="Currency"
                defaultValue={estimateCurrency}
                options={currencyOptions}
                autoComplete={"off"}
                onChange={(e, { value }) => setEstimateCurrency(value)}
                search
                width={4}
              />
              <Form.Input
                fluid
                name="estimate_low"
                placeholder="Low Estimate"
                defaultValue={estimateLow}
                type="number"
                step="0.01"
                autoComplete={"off"}
                onChange={(e, { value }) => setEstimateLow(value)}
                width={6}
              />
              <Form.Input
                fluid
                name="estimate_high"
                placeholder="High Estimate"
                defaultValue={estimateHigh}
                type="number"
                step="0.01"
                autoComplete={"off"}
                onChange={(e, { value }) => setEstimateHigh(value)}
                width={6}
              />
            </Form.Group>
          </Form.Field>

          <Form.Field>
            <label>Sale Price</label>
            <Form.Group>
              <Form.Select
                fluid
                name="hammer_currency"
                placeholder="Currency"
                defaultValue={hammerCurrency}
                options={currencyOptions}
                autoComplete={"off"}
                onChange={(e, { value }) => setHammerCurrency(value)}
                search
                width={4}
              />
              <Form.Input
                fluid
                name="hammer_price"
                placeholder="Sale Price"
                defaultValue={hammerPrice}
                type="number"
                step="0.01"
                autoComplete={"off"}
                onChange={(e, { value }) => setHammerPrice(value)}
                width={12}
              />
            </Form.Group>
          </Form.Field>

          <Form.TextArea
            name="about"
            defaultValue={about}
            label="Description"
            placeholder="Description"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setAbout(value)}
          />

          <Form.TextArea
            name="catalogue_note"
            defaultValue={catalogue_note}
            label="Catlogue Note"
            placeholder="Catalogue Notes"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setCatalogueNote(value)}
          />
          <Form.TextArea
            name="exhibited"
            defaultValue={exhibited}
            label="Exhibited"
            placeholder="Exhibited"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setExhibited(value)}
          />
          <Form.TextArea
            name="literature"
            defaultValue={literature}
            label="Literature"
            placeholder="Literature"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setLiterature(value)}
          />
          <Form.TextArea
            name="provenance"
            defaultValue={provenance}
            label="Provenance"
            placeholder="Provenance"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setProvenance(value)}
          />
          <Form.TextArea
            name="condition_report"
            defaultValue={conditionReport}
            label="Condition Report"
            placeholder="Condition Report"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setConditionReport(value)}
          />

          <Form.Group>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Grouping</Table.HeaderCell>
                  <Table.HeaderCell width={1} />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {groupings &&
                  groupings.map((grouping) => {
                    return (
                      <Table.Row key={grouping._id}>
                        <Table.Cell>{grouping.title}</Table.Cell>
                        <Table.Cell>
                          <Button
                            icon="trash"
                            onClick={() =>
                              setGroupings(
                                groupings.filter(
                                  (group) => group._id !== grouping._id,
                                ),
                              )
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Form.Group>
        </Form>
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createAuctionResult(data)),
  update: (id, data) => dispatch(updateAuctionResult(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAuctionRecordSidePain);
