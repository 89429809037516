const url = "/api/v4/two_factor_settings";

export const CREATE_TWO_FACTOR_SETTING = "CREATE_TWO_FACTOR_SETTING";
export const CREATE_TWO_FACTOR_SETTING_SUCCESS =
  "CREATE_TWO_FACTOR_SETTING_SUCCESS";
export const CREATE_TWO_FACTOR_SETTING_FAILURE =
  "CREATE_TWO_FACTOR_SETTING_FAILURE";

export const DELETE_TWO_FACTOR_SETTING = "DELETE_TWO_FACTOR_SETTING";
export const DELETE_TWO_FACTOR_SETTING_SUCCESS =
  "DELETE_TWO_FACTOR_SETTING_SUCCESS";
export const DELETE_TWO_FACTOR_SETTING_FAILURE =
  "DELETE_TWO_FACTOR_SETTING_FAILURE";

export const VERIFY_TWO_FACTOR_SETTING = "VERIFY_TWO_FACTOR_SETTING";
export const VERIFY_TWO_FACTOR_SETTING_SUCCESS =
  "VERIFY_TWO_FACTOR_SETTING_SUCCESS";
export const VERIFY_TWO_FACTOR_SETTING_FAILURE =
  "VERIFY_TWO_FACTOR_SETTING_FAILURE";

export const FETCH_TWO_FACTOR_SETTING_LIST = "FETCH_TWO_FACTOR_SETTING_LIST";
export const FETCH_TWO_FACTOR_SETTING_LIST_SUCCESS =
  "FETCH_TWO_FACTOR_SETTING_LIST_SUCCESS";
export const FETCH_TWO_FACTOR_SETTING_LIST_FAILURE =
  "FETCH_TWO_FACTOR_SETTING_LIST_FAILURE";

export const deleteTwoFactorSetting = (id) => {
  return {
    types: [
      DELETE_TWO_FACTOR_SETTING,
      DELETE_TWO_FACTOR_SETTING_SUCCESS,
      DELETE_TWO_FACTOR_SETTING_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const verifyTwoFactorSetting = (id, data) => {
  return {
    types: [
      VERIFY_TWO_FACTOR_SETTING,
      VERIFY_TWO_FACTOR_SETTING_SUCCESS,
      VERIFY_TWO_FACTOR_SETTING_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/verify`,
        method: "POST",
        data,
      },
    },
  };
};

export const fetchTwoFactorSettingList = (id) => {
  return {
    types: [
      FETCH_TWO_FACTOR_SETTING_LIST,
      FETCH_TWO_FACTOR_SETTING_LIST_SUCCESS,
      FETCH_TWO_FACTOR_SETTING_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}`,
        method: "GET",
      },
    },
  };
};

export const createTwoFactorSetting = (data) => {
  return {
    types: [
      CREATE_TWO_FACTOR_SETTING,
      CREATE_TWO_FACTOR_SETTING_SUCCESS,
      CREATE_TWO_FACTOR_SETTING_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}`,
        method: "POST",
        data,
      },
    },
  };
};
