import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Header, Item, Segment } from "semantic-ui-react";
import { getAuctionHouseList } from "../../../../redux/actions/auction_house";
import { Mixpanel } from "../../../../utils/Mixpanel";
import ErrorMessage from "../../../../components/ErrorMessage";
import AuctionHousesTile from "../AuctionHousesTile";
import AuthLayout from "../../../../layouts/AuthLayout";

const AuctionHousesList = ({ fetchAll, error, auctionHouses, loading }) => {
  useEffect(() => {
    fetchAll(1);
    Mixpanel.page("auction_house");
  }, []);

  return (
    <AuthLayout title={"Auction Houses"} bannersEnabled={false}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={16}>
            <Header size="huge" content="Auction Houses" />
            {error && <ErrorMessage error={error} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment loading={loading}>
              <Item.Group divided>
                {(auctionHouses || []).map((auctionHouse) => {
                  return (
                    <AuctionHousesTile
                      key={auctionHouse._id}
                      auctionHouse={auctionHouse}
                    />
                  );
                })}
              </Item.Group>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ auction_houses, firebase }) => ({
  auctionHouses: auction_houses.auctionHouses || [],
  loading: auction_houses.isLoadingAuctionHouseList,
  error: auction_houses.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: (page) => dispatch(getAuctionHouseList(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionHousesList);
