const url = "/api/v4/notifications";

// Actions
const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
const LOAD_NOTIFICATIONS_FAILURE = "LOAD_NOTIFICATIONS_FAILURE";

const initialState = {
  loading: true,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case LOAD_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        analytics: action.payload.data,
        error: null,
      };
    }

    case LOAD_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

export function fetchAll() {
  return {
    types: [
      LOAD_NOTIFICATIONS,
      LOAD_NOTIFICATIONS_SUCCESS,
      LOAD_NOTIFICATIONS_FAILURE,
    ],
    payload: {
      request: {
        url,
      },
    },
  };
}
