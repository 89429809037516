import {
  GET_CURRENCIES,
  GET_CURRENCIES_FAILURE,
  GET_CURRENCIES_SUCCESS,
} from "../actions/currencies";

const initialState = {
  isLoadingCurrencies: true,
  currency: localStorage.getItem("currency") || "USD",
  error: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CURRENCIES: {
      return {
        ...state,
        isLoadingCurrencies: true,
        error: null,
      };
    }

    case GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        isLoadingCurrencies: false,
        currencies: action.payload.data,
        error: null,
      };
    }

    case GET_CURRENCIES_FAILURE: {
      return {
        ...state,
        isLoadingCurrencies: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}
