import React from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import { getMuseumArtworksList } from "../../../../../../redux/actions/museum";
import MuseumArtworkTile from "../MuseumArtworkTile";
import InfiniteScroll from "react-infinite-scroller";

const MuseumArtworksList = ({
  museumId,
  artworks,
  hasMoreItems,
  fetchData,
}) => {
  return (
    <InfiniteScroll
      initialLoad={true}
      loadMore={(page) => fetchData(museumId, page)}
      hasMore={hasMoreItems}
      loader={
        <Header
          key={"loader"}
          textAlign={"center"}
          size={"small"}
          content={"Loading ..."}
        />
      }
    >
      {(artworks || []).length !== 0 && (
        <Table selectable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell content={"Title"} />
              <Table.HeaderCell content={"Artist"} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(artworks || []).map((artwork) => {
              return <MuseumArtworkTile key={artwork._id} artwork={artwork} />;
            })}
          </Table.Body>
        </Table>
      )}
    </InfiniteScroll>
  );
};

const mapStateToProps = ({ museum }) => ({
  hasMoreItems: museum.hasMoreArtworks,
  artworks: museum.artworks,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id, page) => dispatch(getMuseumArtworksList(id, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuseumArtworksList);
