import React, { useEffect, useState } from "react";
import { Button, Grid, Header, Image, Modal } from "semantic-ui-react";
import artfair from "../../../images/headers/artfair.png";
import { updateCurrentUser } from "../../../redux/actions/me";
import { connect } from "react-redux";
import ErrorMessage from "../../ErrorMessage";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";

const OnboardingModal = ({ update }) => {
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentUser) return;
    if (!currentUser.onboarded) {
      setModalOpen(true);
    }
  }, [currentUser]);

  const handleButtonClick = async (route) => {
    setLoading(true);
    setError(null);

    try {
      await update({ onboarded: true });
      setModalOpen(false);
      navigate(route);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal open={modalOpen}>
      <Modal.Content textAlign={"center"}>
        <Grid stackable>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Image src={artfair} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              {error && <ErrorMessage error={error} />}
              <Header
                size="huge"
                textAlign={"center"}
                content={`Welcome to ArtCollection.io`}
                subheader={
                  "The easiest way to document your art collection online"
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                size="small"
                textAlign={"center"}
                content={`To get started, please:`}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={6}>
              <Button
                primary
                size="huge"
                fluid
                content={"Add your first piece"}
                loading={loading}
                onClick={() => handleButtonClick("/piece?onboarding=true")}
              />
            </Grid.Column>
            <Grid.Column width={1}>
              <Header textAlign="center" size={"small"} content={"or"} />
            </Grid.Column>
            <Grid.Column width={6}>
              <Button
                primary
                size="huge"
                fluid
                content={"Import your collection"}
                loading={loading}
                onClick={() => handleButtonClick("/import")}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column textAlign={"center"}>
              <Button
                basic
                content={"Skip"}
                loading={loading}
                onClick={() => handleButtonClick("/")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingModal);
