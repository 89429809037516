import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { PIECE_PATH } from "../../constants/paths";

const PieceCard = ({ piece }) => {
  const navigate = useNavigate();
  const { _id, title, artist, medium_image_url } = piece;

  return (
    <Card onClick={() => navigate(`${PIECE_PATH}/${_id}`)}>
      <img
        src={medium_image_url}
        style={{
          objectFit: "cover",
          objectPosition: "top",
          height: "100%",
          maxHeight: 240,
        }}
        alt={title}
      />
      <Card.Content>
        <Card.Header content={title} />
        <Card.Description content={artist && artist.name} />
      </Card.Content>
    </Card>
  );
};

PieceCard.propTypes = {
  piece: PropTypes.object,
};

export default PieceCard;
