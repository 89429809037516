import React from "react";
import { Image, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import placeholder from "../../../../images/placeholder.png";
import moment from "moment";
import { AUCTION_HOUSE_PATH, AUCTION_PATH } from "../../../../constants/paths";

const Auction = ({ auction }) => {
  const navigate = useNavigate();

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const { _id, name, small_image_url, end_date, auction_house } = auction;

  return (
    <Table.Row key={_id} style={{ cursor: "pointer" }}>
      <Table.Cell onClick={(e) => onRowClick(e, `${AUCTION_PATH}/${_id}`)}>
        <Image src={small_image_url || placeholder} avatar />
        {name}
      </Table.Cell>
      <Table.Cell
        onClick={
          auction_house
            ? (e) => onRowClick(e, `${AUCTION_HOUSE_PATH}/${auction_house._id}`)
            : null
        }
      >
        {auction_house && auction_house.name}
      </Table.Cell>
      <Table.Cell onClick={(e) => onRowClick(e, `${AUCTION_PATH}/${_id}`)}>
        {`${moment(end_date).format("MMM D, YYYY")}`}
      </Table.Cell>
    </Table.Row>
  );
};

export default Auction;
