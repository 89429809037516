import {
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_FAILURE,
  GET_SUBSCRIPTION_LIST_SUCCESS,
} from "../actions/subscription";

const INITIAL_STATE = {
  isGettingSubscriptionList: false,
  payment: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        isGettingSubscriptionList: true,
        error: null,
      };

    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        isGettingSubscriptionList: false,
        subscriptions: action.payload.data,
      };

    case GET_SUBSCRIPTION_LIST_FAILURE:
      return {
        ...state,
        isGettingSubscriptionList: false,
        error: action.error,
      };

    case CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptions: [action.payload.data, ...(state.subscriptions || [])],
      };
    }

    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const subscriptions = (state.subscriptions || []).filter(
        (c) => c.id !== action.payload.data.id,
      );

      return {
        ...state,
        subscriptions,
      };
    }

    default:
      return state;
  }
};
