import {
  GET_ARTIST_PROPOSAL_LIST,
  GET_ARTIST_PROPOSAL_LIST_FAILURE,
  GET_ARTIST_PROPOSAL_LIST_SUCCESS,
} from "../actions/artist_proposal";

const INITIAL_STATE = {
  isLoadingArtistProposalList: true,
  artistProposals: [],
  hasMoreArtistProposals: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTIST_PROPOSAL_LIST:
      return {
        ...state,
        isLoadingArtistProposalList: true,
        hasMoreArtistProposals: true,
      };
    case GET_ARTIST_PROPOSAL_LIST_SUCCESS:
      return {
        ...state,
        isLoadingArtistProposalList: false,
        artistProposals: action.payload,
        hasMoreArtistProposals: action.payload.length > 0,
      };
    case GET_ARTIST_PROPOSAL_LIST_FAILURE:
      return {
        ...state,
        isLoadingArtistProposalList: false,
        hasMoreArtistProposals: false,
      };
    default:
      return state;
  }
};
