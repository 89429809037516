const url = "/api/v4/import_pieces";

// Actions

export const GET_IMPORT_PIECE_LIST = "GET_IMPORT_PIECE_LIST";
export const GET_IMPORT_PIECE_LIST_SUCCESS = "GET_IMPORT_PIECE_LIST_SUCCESS";
export const GET_IMPORT_PIECE_LIST_FAILURE = "GET_IMPORT_PIECE_LIST_FAILURE";

export const GET_IMPORT_PIECE = "GET_IMPORT_PIECE";
export const GET_IMPORT_PIECE_SUCCESS = "GET_IMPORT_PIECE_SUCCESS";
export const GET_IMPORT_PIECE_FAILURE = "GET_IMPORT_PIECE_FAILURE";

export const CREATE_IMPORT_PIECE = "CREATE_IMPORT_PIECE";
export const CREATE_IMPORT_PIECE_SUCCESS = "CREATE_IMPORT_PIECE_SUCCESS";
export const CREATE_IMPORT_PIECE_FAILURE = "CREATE_IMPORT_PIECE_FAILURE";

export const UPDATE_IMPORT_PIECE = "UPDATE_IMPORT_PIECE";
export const UPDATE_IMPORT_PIECE_SUCCESS = "UPDATE_IMPORT_PIECE_SUCCESS";
export const UPDATE_IMPORT_PIECE_FAILURE = "UPDATE_IMPORT_PIECE_FAILURE";

export const DELETE_IMPORT_PIECE = "DELETE_IMPORT_PIECE";
export const DELETE_IMPORT_PIECE_SUCCESS = "DELETE_IMPORT_PIECE_SUCCESS";
export const DELETE_IMPORT_PIECE_FAILURE = "DELETE_IMPORT_PIECE_FAILURE";

export const RESET_IMPORT_PIECE_STATE = "RESET_IMPORT_PIECE_STATE";

// Networking

export const getImportPieceList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_IMPORT_PIECE_LIST,
      GET_IMPORT_PIECE_LIST_SUCCESS,
      GET_IMPORT_PIECE_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getImportPiece = (id) => {
  return {
    types: [
      GET_IMPORT_PIECE,
      GET_IMPORT_PIECE_SUCCESS,
      GET_IMPORT_PIECE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createImportPiece = (data) => {
  return {
    types: [
      CREATE_IMPORT_PIECE,
      CREATE_IMPORT_PIECE_SUCCESS,
      CREATE_IMPORT_PIECE_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateImportPiece = (id, data) => {
  return {
    types: [
      UPDATE_IMPORT_PIECE,
      UPDATE_IMPORT_PIECE_SUCCESS,
      UPDATE_IMPORT_PIECE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteImportPiece = (id, data) => {
  return {
    types: [
      DELETE_IMPORT_PIECE,
      DELETE_IMPORT_PIECE_SUCCESS,
      DELETE_IMPORT_PIECE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};
