import {
  CREATE_IMPORT_PIECE_SUCCESS,
  DELETE_IMPORT_PIECE_SUCCESS,
  GET_IMPORT_PIECE_LIST,
  GET_IMPORT_PIECE_LIST_FAILURE,
  GET_IMPORT_PIECE_LIST_SUCCESS,
  UPDATE_IMPORT_PIECE_SUCCESS,
} from "../actions/import_piece";

const INITIAL_STATE = {
  display: "table",

  isLoadingPieceList: true,
  import_pieces: [],
  hasMorePieces: true,

  isLoadingPiece: true,
  piece: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_IMPORT_PIECE_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_IMPORT_PIECE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingPieceList: false,
        import_pieces: action.payload.data,
        data: action.payload.data,
        hasMorePieces: action.payload.data.length === 25,
      };
      break;
    case GET_IMPORT_PIECE_LIST_FAILURE:
      return {
        ...state,
        isLoadingPieceList: false,
        error: action.error,
        hasMorePieces: false,
      };

      break;
    case CREATE_IMPORT_PIECE_SUCCESS:
      {
        return {
          ...state,
          import_pieces: [action.payload.data, ...state.import_pieces],
        };
      }
      break;
    case UPDATE_IMPORT_PIECE_SUCCESS:
      {
        return {
          ...state,
          import_pieces: (state.import_pieces || [action.payload.data]).map(
            (piece) => {
              if (piece._id === action.payload.data._id) {
                return action.payload.data;
              }
              return piece;
            },
          ),
          data: (state.data || [action.payload.data]).map((piece) => {
            if (piece._id === action.payload.data._id) {
              return action.payload.data;
            }
            return piece;
          }),
        };
      }
      break;
    case DELETE_IMPORT_PIECE_SUCCESS:
      {
        const import_pieces = (state.import_pieces || []).filter(
          (piece) => piece._id !== action.payload.data._id,
        );
        const data = (state.data || []).filter(
          (piece) => piece._id !== action.payload.data._id,
        );

        return {
          ...state,
          import_pieces,
          data,
        };
      }
      break;
    default:
      return state;
  }
};
