import React from "react";
import {
  Checkbox,
  Header,
  Image,
  Segment,
  Table,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import Piece from "../../../../models/piece";
import PieceCell from "../../../../components/Cells/piece";
import empty_state from "../../../../images/empty_state/piece.png";
import { useUser } from "../../../../contexts/UserContext";

const PiecesTable = ({
  pieces,
  orderBy,
  handleOrderByChange,
  selectedPieceIDs = new Set(),
  handSelectAllPieces,
  handleSelectPieceID,
}) => {
  const { currentUser } = useUser();
  const configuration = currentUser?.piece_list_configuration ?? {};

  if (!pieces || pieces.length === 0) {
    return (
      <Segment basic textAlign={"center"}>
        <Image size={"small"} src={empty_state} centered />
        <Header
          textAlign={"center"}
          size={"large"}
          content={"No Pieces"}
          subheader={"Please add your first piece to get started"}
        />
      </Segment>
    );
  }

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            key={"check"}
            textAlign={"center"}
            verticalAlign={"middle"}
            style={{ width: "1%" }}
          >
            <Checkbox
              onChange={(_, { checked }) => handSelectAllPieces(checked)}
            />
          </Table.HeaderCell>
          <Table.HeaderCell key={"is_flagged"}>
            <Icon name="flag" color="red" />
          </Table.HeaderCell>
          {configuration.inventory_identifier && (
            <Table.HeaderCell
              key={"inventory_identifier"}
              active
              content={"ID"}
              sorted={
                orderBy[0] === "inventory_identifier"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["inventory_identifier", "desc"]
                    : ["inventory_identifier", "asc"],
                )
              }
            />
          )}
          <Table.HeaderCell
            key={"title"}
            content={"Title"}
            sorted={
              orderBy[0] === "title"
                ? orderBy[1] === "asc"
                  ? "ascending"
                  : "descending"
                : null
            }
            onClick={() =>
              handleOrderByChange(
                orderBy[1] === "asc" ? ["title", "desc"] : ["title", "asc"],
              )
            }
          />
          {configuration.artist && (
            <Table.HeaderCell
              key={"artist"}
              width={3}
              active
              content={"Artist"}
              sorted={
                orderBy[0] === "artist_name"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["artist_name", "desc"]
                    : ["artist_name", "asc"],
                )
              }
            />
          )}
          {configuration.gallery && (
            <Table.HeaderCell
              key={"gallery"}
              width={3}
              content={"Gallery"}
              sorted={
                orderBy[0] === "gallery_name"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["gallery_name", "desc"]
                    : ["gallery_name", "asc"],
                )
              }
            />
          )}
          {configuration.location && (
            <Table.HeaderCell
              key={"location"}
              width={3}
              content={"Location"}
              sorted={
                orderBy[0] === "location_name"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["location_name", "desc"]
                    : ["location_name", "asc"],
                )
              }
            />
          )}
          {configuration.purchase_price && (
            <Table.HeaderCell
              key={"price"}
              width={2}
              content={"Price"}
              sorted={
                orderBy[0] === "purchase_price"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["purchase_price", "desc"]
                    : ["purchase_price", "asc"],
                )
              }
            />
          )}
          {configuration.valuation_price && (
            <Table.HeaderCell
              key={"valuation"}
              width={2}
              content={"Valuation"}
              sorted={
                orderBy[0] === "valuation_price"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["valuation_price", "desc"]
                    : ["valuation_price", "asc"],
                )
              }
            />
          )}
          {configuration.category && (
            <Table.HeaderCell
              key={"category"}
              width={2}
              content={"Category"}
              sorted={
                orderBy[0] === "category"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["category", "desc"]
                    : ["category", "asc"],
                )
              }
            />
          )}
          {configuration.status && (
            <Table.HeaderCell
              key={"status"}
              width={2}
              content={"Status"}
              sorted={
                orderBy[0] === "status"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc" ? ["status", "desc"] : ["status", "asc"],
                )
              }
            />
          )}
          {configuration.owned_by && (
            <Table.HeaderCell
              key={"owned_by"}
              width={2}
              content={"Owned By"}
              sorted={
                orderBy[0] === "owned_by"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["owned_by", "desc"]
                    : ["owned_by", "asc"],
                )
              }
            />
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(pieces || [])
          .map((piece) => new Piece(piece))
          .map((piece) => (
            <PieceCell
              key={piece._id}
              selected={selectedPieceIDs.has(piece._id)}
              pieceId={piece._id}
              piece={piece}
              handleSelectPieceID={handleSelectPieceID}
              configuration={configuration}
            />
          ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PiecesTable);
