export const CREATE_BILLING_PORTAL_SESSION = "CREATE_BILLING_PORTAL_SESSION";
export const CREATE_BILLING_PORTAL_SESSION_SUCCESS =
  "CREATE_BILLING_PORTAL_SESSION_SUCCESS";
export const CREATE_BILLING_PORTAL_SESSION_FAILURE =
  "CREATE_BILLING_PORTAL_SESSION_FAILURE";

export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const CREATE_CHECKOUT_SESSION_SUCCESS =
  "CREATE_CHECKOUT_SESSION_SUCCESS";
export const CREATE_CHECKOUT_SESSION_FAILURE =
  "CREATE_CHECKOUT_SESSION_FAILURE";

export const createBillingPortalSession = (data) => {
  return {
    types: [
      CREATE_BILLING_PORTAL_SESSION,
      CREATE_BILLING_PORTAL_SESSION_SUCCESS,
      CREATE_BILLING_PORTAL_SESSION_FAILURE,
    ],
    payload: {
      request: {
        url: "/api/v5/billing_portal",
        method: "POST",
        data,
      },
    },
  };
};

export const createCheckoutSession = (data) => {
  return {
    types: [
      CREATE_CHECKOUT_SESSION,
      CREATE_CHECKOUT_SESSION_SUCCESS,
      CREATE_CHECKOUT_SESSION_FAILURE,
    ],
    payload: {
      request: {
        url: "/api/v5/checkout_sessions",
        method: "POST",
        data,
      },
    },
  };
};
