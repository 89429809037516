import {
  CREATE_ARTIST,
  CREATE_ARTIST_FAILURE,
  CREATE_ARTIST_SUCCESS,
  DELETE_ARTIST,
  DELETE_ARTIST_FAILURE,
  DELETE_ARTIST_SUCCESS,
  GET_ARTIST,
  GET_ARTIST_FAILURE,
  GET_ARTIST_SUCCESS,
  GET_ARTWORKS_LIST_FOR_ARTIST,
  GET_ARTWORKS_LIST_FOR_ARTIST_FAILURE,
  GET_ARTWORKS_LIST_FOR_ARTIST_SUCCESS,
  GET_AUCTION_RECORDS_LIST_FOR_ARTIST,
  GET_AUCTION_RECORDS_LIST_FOR_ARTIST_FAILURE,
  GET_AUCTION_RECORDS_LIST_FOR_ARTIST_SUCCESS,
  GET_MY_PIECE_LIST_FOR_ARTIST,
  GET_MY_PIECE_LIST_FOR_ARTIST_FAILURE,
  GET_MY_PIECE_LIST_FOR_ARTIST_SUCCESS,
  RESET_ARTIST_STATE,
  UPDATE_ARTIST,
  UPDATE_ARTIST_FAILURE,
  UPDATE_ARTIST_SUCCESS,
} from "../actions/artist";

import { Mixpanel } from "../../utils/Mixpanel";
import Artist from "../../models/artist";

const INITIAL_STATE = {
  isLoadingArtist: true,
  artist: null,

  auctionRecords: [],
  pieces: [],
  myPieces: [],
  artworks: [],

  isCreatingArtist: false,
  isUpdatingArtist: false,
  isDeletingArtist: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ARTIST_STATE:
    case GET_ARTIST:
      return { ...INITIAL_STATE };
    case GET_ARTIST_SUCCESS:
      return {
        ...state,
        isLoadingArtist: false,
        artist: new Artist(action.payload.data),
        hasMoreAuctionRecords: true,
        hasMoreArtworks: true,
      };
    case GET_ARTIST_FAILURE:
      return {
        ...state,
        isLoadingArtist: false,
        error: action.error,
      };

    case GET_AUCTION_RECORDS_LIST_FOR_ARTIST:
      return { ...state, isLoadingAuctionRecords: true };
    case GET_AUCTION_RECORDS_LIST_FOR_ARTIST_SUCCESS: {
      return {
        ...state,
        isLoadingAuctionRecords: false,
        auctionRecords: [...state.auctionRecords, ...action.payload.data],
        hasMoreAuctionRecords: action.payload.data.length === 25,
      };
    }
    case GET_AUCTION_RECORDS_LIST_FOR_ARTIST_FAILURE:
      return {
        ...state,
        isLoadingAuctionRecords: false,
        error: action.error,
        hasMoreAuctionRecords: false,
      };

    case GET_ARTWORKS_LIST_FOR_ARTIST:
      return { ...state, isLoadingArtworks: true };
    case GET_ARTWORKS_LIST_FOR_ARTIST_SUCCESS: {
      return {
        ...state,
        isLoadingArtworks: false,
        artworks: [...state.artworks, ...action.payload.data],
        hasMoreArtworks: action.payload.data.length === 25,
      };
    }
    case GET_ARTWORKS_LIST_FOR_ARTIST_FAILURE:
      return {
        ...state,
        isLoadingArtworks: false,
        error: action.error,
        hasMoreArtworks: false,
      };

    case CREATE_ARTIST:
      return {
        ...state,
        isCreatingArtist: true,
      };

    case CREATE_ARTIST_SUCCESS:
      Mixpanel.track("artist_created");
      return {
        ...state,
        isCreatingArtist: false,
      };
    case CREATE_ARTIST_FAILURE:
      Mixpanel.track("artist_created_failure");
      return {
        ...state,
        isCreatingArtist: false,
      };

    case UPDATE_ARTIST:
      return {
        ...state,
        isUpdatingArtist: true,
      };

    case UPDATE_ARTIST_SUCCESS:
      Mixpanel.track("artist_updated");
      return {
        ...state,
        isUpdatingArtist: false,
      };
    case UPDATE_ARTIST_FAILURE:
      Mixpanel.track("artist_updated_failure");
      return {
        ...state,
        isUpdatingArtist: false,
      };

    case DELETE_ARTIST:
      return {
        ...state,
        isDeletingArtist: true,
      };

    case DELETE_ARTIST_SUCCESS:
      Mixpanel.track("artist_deleted");
      return {
        ...state,
        isDeletingArtist: false,
      };
    case DELETE_ARTIST_FAILURE:
      Mixpanel.track("artist_deleted_failure");
      return {
        ...state,
        isDeletingArtist: false,
      };

    case GET_MY_PIECE_LIST_FOR_ARTIST:
      return { ...state, isLoadingMyPieces: true };
    case GET_MY_PIECE_LIST_FOR_ARTIST_SUCCESS: {
      return {
        ...state,
        isLoadingMyPieces: false,
        myPieces: [...state.myPieces, ...action.payload.data],
        hasMoreMyPieces: action.payload.data.length === 25,
      };
    }
    case GET_MY_PIECE_LIST_FOR_ARTIST_FAILURE:
      return {
        ...state,
        isLoadingMyPieces: false,
        myPieces: [],
        error: action.error,
        hasMoreMyPieces: false,
      };
    default:
      return state;
  }
};
