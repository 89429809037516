const url = "/api/v5/invitations";

export const CREATE_INVITATION = "CREATE_INVITATION";
export const CREATE_INVITATION_SUCCESS = "CREATE_INVITATION_SUCCESS";
export const CREATE_INVITATION_FAILURE = "CREATE_INVITATION_FAILURE";

export const DELETE_INVITATION = "DELETE_INVITATION";
export const DELETE_INVITATION_SUCCESS = "DELETE_INVITATION_SUCCESS";
export const DELETE_INVITATION_FAILURE = "DELETE_INVITATION_FAILURE";

export const GET_INVITATION = "GET_INVITATION";
export const GET_INVITATION_SUCCESS = "GET_INVITATION_SUCCESS";
export const GET_INVITATION_FAILURE = "GET_INVITATION_FAILURE";

export const GET_INVITATION_LIST = "GET_INVITATION_LIST";
export const GET_INVITATION_LIST_SUCCESS = "GET_INVITATION_LIST_SUCCESS";
export const GET_INVITATION_LIST_FAILURE = "GET_INVITATION_LIST_FAILURE";

export const UPDATE_INVITATION = "UPDATE_INVITATION";
export const UPDATE_INVITATION_SUCCESS = "UPDATE_INVITATION_SUCCESS";
export const UPDATE_INVITATION_FAILURE = "UPDATE_INVITATION_FAILURE";

export const createInvitation = (data) => {
  return {
    types: [
      CREATE_INVITATION,
      CREATE_INVITATION_SUCCESS,
      CREATE_INVITATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}`,
        method: "POST",
        data,
      },
    },
  };
};

export const getInvitation = (id) => {
  return {
    types: [GET_INVITATION, GET_INVITATION_SUCCESS, GET_INVITATION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const getInvitationList = (status = "pending") => {
  return {
    types: [
      GET_INVITATION_LIST,
      GET_INVITATION_LIST_SUCCESS,
      GET_INVITATION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          status,
        },
      },
    },
  };
};

export const deleteInvitation = (id) => {
  return {
    types: [
      DELETE_INVITATION,
      DELETE_INVITATION_SUCCESS,
      DELETE_INVITATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const updateInvitation = (id, data) => {
  return {
    types: [
      UPDATE_INVITATION,
      UPDATE_INVITATION_SUCCESS,
      UPDATE_INVITATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};
