import React, { Children, cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Table,
} from "semantic-ui-react";
// Images
import SlidingPane from "react-sliding-pane";

import { connect, useSelector } from "react-redux";
import {
  createPrivateRoom,
  updatePrivateRoom,
} from "../../../../redux/actions/private_room";
import { getPieceList } from "../../../../redux/actions/piece";
import UpgradeModal from "../../../../components/UpgradeModal";
import Piece from "../../../../models/piece";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useUser } from "../../../../contexts/UserContext";
import { usePieces } from "../../../../contexts/PiecesContext";

const CreatePrivateRoomSidePane = ({
  currentPrivateRoom,
  auth,
  modalOpen,
  onCloseModal,
  children,
  update,
  create,
  fetchAll,
}) => {
  const { currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(currentPrivateRoom.name);
  const [about, setAbout] = useState(currentPrivateRoom.about);
  const [pieces, setPieces] = useState(
    new Set((currentPrivateRoom.pieces || []).map((a) => a._id)),
  );
  const [artist, setArtist] = useState(
    (currentPrivateRoom.fields && currentPrivateRoom.fields.artist) || true,
  );
  const [gallery, setGallery] = useState(
    (currentPrivateRoom.fields && currentPrivateRoom.fields.gallery) || true,
  );
  const [purchasePrice, setPurchasePrice] = useState(
    (currentPrivateRoom.fields && currentPrivateRoom.fields.purchase_price) ||
      false,
  );

  const { pieces: potential } = usePieces();

  const loadData = async () => {
    await fetchAll();
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const data = {
        name,
        about,
        pieces: Array.from(pieces),
        fields: {
          artist,
          gallery,
          purchase_price: purchasePrice,
        },
      };

      if (currentPrivateRoom._id) {
        await update(currentPrivateRoom._id, data);
      }

      onCloseModal(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      let all = potential.flatMap((a) => a._id);
      setPieces(new Set(all));
    } else {
      setPieces(new Set());
    }
  };

  const handleCheck = (id) => {
    if (pieces.has(id)) {
      pieces.delete(id);
    } else {
      pieces.add(id);
    }

    setPieces(new Set(pieces));
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={modalOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={
                    currentPrivateRoom._id
                      ? "Edit Private Room"
                      : "Create Private Room"
                  }
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => onCloseModal(false)}
      >
        <Grid stackable>
          <Grid.Row columns={1}>
            <Grid.Column>
              {error && (
                <Message
                  negative
                  header={error.error}
                  content={error.message}
                />
              )}
              <Form>
                <Form.Input
                  defaultValue={name}
                  label={"Name"}
                  placeholder="Name"
                  autoComplete={"off"}
                  onChange={(e, { value }) => setName(value)}
                />

                <Form.TextArea
                  rows={5}
                  placeholder="About"
                  defaultValue={about}
                  label="About"
                  autoComplete={"off"}
                  onChange={(e, { value }) => setAbout(value)}
                />

                <Form.Field>
                  <label>Items to show</label>
                  <Form.Group>
                    <Form.Checkbox
                      defaultChecked={artist}
                      label="Artist"
                      onChange={(e, { checked }) => setArtist(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={gallery}
                      label="Gallery"
                      onChange={(e, { checked }) => setGallery(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={purchasePrice}
                      label="Purchase Price"
                      onChange={(e, { checked }) => setPurchasePrice(checked)}
                    />
                  </Form.Group>
                </Form.Field>
                <Form.Field>
                  <label>Pieces</label>
                  <Table sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>
                          <Checkbox
                            checked={pieces.size === (potential || []).length}
                            onChange={(e, { checked }) =>
                              handleSelectAll(checked)
                            }
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell content={"Title"} />
                        <Table.HeaderCell content={"Artist"} />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {(potential || [])
                        .map((d) => new Piece(d))
                        .map(({ _id, artist, images, title }) => {
                          return (
                            <Table.Row key={_id}>
                              <Table.Cell>
                                <Checkbox
                                  checked={pieces.has(_id)}
                                  onChange={() => handleCheck(_id)}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Image
                                  src={
                                    images &&
                                    images[0] &&
                                    images[0].small_image_url
                                  }
                                  avatar
                                />
                                {title}
                              </Table.Cell>
                              <Table.Cell>{artist && artist.name}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingPane>

      <UpgradeModal>
        {Children.map(children, (child) => {
          return cloneElement(
            child,
            {
              onClick: () => onCloseModal(true),
            },
            null,
          );
        })}
      </UpgradeModal>
    </>
  );
};

CreatePrivateRoomSidePane.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = ({ firebase, pieces, privateRoom }) => ({
  error: privateRoom.error,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPrivateRoom(data)),
  update: (id, data) => dispatch(updatePrivateRoom(id, data)),
  fetchAll: (page, limit, sort, direction) =>
    dispatch(getPieceList(page, limit, sort, direction)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePrivateRoomSidePane);
