class Location {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.name = data.name;

    this.image_url = data.image_url;
    this.medium_image_url = data.medium_image_url;
    this.small_image_url = data.small_image_url;

    this.about = data.about;

    this.phone = data.phone;
    this.fax = data.fax;
    this.email = data.email;
    this.website_url = data.website_url;
    this.address = data.address;
    this.number_of_pieces = data.number_of_pieces;
  }
}

export default Location;
