import React from "react";
import { Table } from "semantic-ui-react";
import PrivateRoomCell from "../../../../components/Cells/private_room";

const PrivateRoomsTable = ({ privateRooms }) => {
  if (!privateRooms || privateRooms.size === 0) return null;

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content={"Name"} />
          <Table.HeaderCell content={"Number of Pieces"} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {privateRooms.map((privateRoom) => (
          <PrivateRoomCell
            key={privateRoom._id}
            privateRoomId={privateRoom._id}
            privateRoom={privateRoom}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default PrivateRoomsTable;
