const url = "/api/v4/tags";

// Actions

export const RESET_TAG_STATE = "RESET_TAG_STATE";

export const GET_TAG_LIST = "GET_TAG_LIST";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_FAILURE = "GET_TAG_LIST_FAILURE";

export const GET_TAG = "GET_TAG";
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS";
export const GET_TAG_FAILURE = "GET_TAG_FAILURE";

export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILURE = "CREATE_TAG_FAILURE";

export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILURE = "UPDATE_TAG_FAILURE";

export const UPDATE_TAG_PIECES = "UPDATE_TAG_PIECES";
export const UPDATE_TAG_PIECES_SUCCESS = "UPDATE_TAG_PIECES_SUCCESS";
export const UPDATE_TAG_PIECES_FAILURE = "UPDATE_TAG_PIECES_FAILURE";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "DELETE_TAG_FAILURE";

export const GET_PIECE_LIST_FOR_TAG = "GET_PIECE_LIST_FOR_TAG";
export const GET_PIECE_LIST_FOR_TAG_SUCCESS = "GET_PIECE_LIST_FOR_TAG_SUCCESS";
export const GET_PIECE_LIST_FOR_TAG_FAILURE = "GET_PIECE_LIST_FOR_TAG_FAILURE";

// NETWORKING

export const resetTagState = () => {
  return {
    type: RESET_TAG_STATE,
  };
};

export const getTagList = (page = 1, limit = 250) => {
  return {
    types: [GET_TAG_LIST, GET_TAG_LIST_SUCCESS, GET_TAG_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getTag = (id) => {
  return {
    types: [GET_TAG, GET_TAG_SUCCESS, GET_TAG_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createTag = (data) => {
  return {
    types: [CREATE_TAG, CREATE_TAG_SUCCESS, CREATE_TAG_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateTag = (id, data) => {
  return {
    types: [UPDATE_TAG, UPDATE_TAG_SUCCESS, UPDATE_TAG_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const updateTagPieces = (id, data) => {
  return {
    types: [
      UPDATE_TAG_PIECES,
      UPDATE_TAG_PIECES_SUCCESS,
      UPDATE_TAG_PIECES_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pieces`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteTag = (id) => {
  return {
    types: [DELETE_TAG, DELETE_TAG_SUCCESS, DELETE_TAG_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getTagPiecesList = (id, page = 1, limit = 250) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_TAG,
      GET_PIECE_LIST_FOR_TAG_SUCCESS,
      GET_PIECE_LIST_FOR_TAG_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pieces`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};
