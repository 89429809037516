import React from "react";
import { Divider, Form, Grid, Header } from "semantic-ui-react";
import InsuranceAttachments from "../Attachments/InsuranceAttachments";
import { currencyOptions } from "../../../../../common";

const Insurance = ({ piece, onUpdate }) => {
  const {
    is_insured,
    insurance_notes,
    insurance_attachments,
    insurance_currency,
    insurance_value,
  } = piece;

  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });
  const handleCheck = (e, { name, checked }) => onUpdate({ [name]: checked });

  return (
    <Form>
      <Form.Group>
        <Header size="large" content={"Insurance"} />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Insured</label>
        </Form.Field>
        <Form.Field width={12}>
          <Form.Checkbox
            name={"is_insured"}
            label={"This item is insured"}
            defaultChecked={is_insured === true}
            onChange={handleCheck}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Insurance Value</label>
        </Form.Field>
        <Form.Select
          fluid
          name="insurance_currency"
          placeholder="$"
          defaultValue={insurance_currency || "USD"}
          options={currencyOptions}
          autoComplete={"off"}
          onChange={handleChange}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="insurance_value"
          placeholder="Insurance Value"
          defaultValue={insurance_value}
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={handleChange}
          width={10}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name={"insurance_notes"}
          placeholder="Insurance Notes"
          defaultValue={insurance_notes}
          onChange={handleChange}
        />
      </Form.Group>
      <InsuranceAttachments
        attachments={insurance_attachments}
        onAttachmentsUpdated={(attachments) =>
          onUpdate({ insurance_attachments: attachments })
        }
      />
    </Form>
  );
};

export default Insurance;
