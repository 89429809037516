import React from "react";
import PropTypes from "prop-types";
import { Card, Message } from "semantic-ui-react";
import ArtistCard from "../../../../components/GridCells/ArtistCard";

const ArtistsGrid = ({ artists, user }) => {
  if (!artists || artists.length === 0) return null;

  return (
    <Card.Group stackable centered itemsPerRow={4}>
      {artists.map((artist) => (
        <ArtistCard key={artist._id} artist={artist} user={user} />
      ))}
    </Card.Group>
  );
};

ArtistsGrid.propTypes = {
  user: PropTypes.object,
  artists: PropTypes.array,
};

export default ArtistsGrid;
