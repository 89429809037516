import {
  GET_AUCTION_RECORD_LIST,
  GET_AUCTION_RECORD_LIST_FAILURE,
  GET_AUCTION_RECORD_LIST_SUCCESS,
  TOGGLE_AUCTION_RECORD_LIST_DISPLAY,
} from "../actions/auction_result";

const INITIAL_STATE = {
  display: localStorage.getItem("auctionRecordDisplay") || "table",
  isLoadingAuctionRecordList: true,
  auctionRecords: [],
  hasMoreAuctionRecords: true,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AUCTION_RECORD_LIST:
      return {
        ...state,
        isLoadingAuctionRecordList: true,
        auctionRecords:
          action.payload.request.params.page <= 1 ? [] : state.auctionRecords,
      };
    case GET_AUCTION_RECORD_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAuctionRecordList: false,
        auctionRecords: state.auctionRecords.concat(action.payload.data),
        hasMoreAuctionRecords: action.payload.data.length === 25,
      };
    case GET_AUCTION_RECORD_LIST_FAILURE:
      return {
        ...state,
        isLoadingAuctionRecordList: false,
        error: action.error,
        hasMoreAuctionRecords: false,
      };

    case TOGGLE_AUCTION_RECORD_LIST_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    default:
      return state;
  }
};
