import moment from "moment";
import React from "react";
import { Form } from "semantic-ui-react";

const FormDatePicker = ({ label, name, handleChange, value, width }) => {
  const formattedDate = value
    ? moment.utc(value).local().format("YYYY-MM-DD")
    : "";

  const handleDateChange = (e, { name, value }) => {
    const utcDate = moment(value, "YYYY-MM-DD").utc().toISOString(); // Convert selected date to UTC
    handleChange(e, { name, value: utcDate }); // Pass the converted date back
  };

  return (
    <Form.Input
      width={width}
      label={label}
      type="date"
      name={name}
      defaultValue={formattedDate}
      onChange={handleDateChange}
    />
  );
};

export default FormDatePicker;
