import {
  GET_AUCTION_LIST,
  GET_AUCTION_LIST_FAILURE,
  GET_AUCTION_LIST_SUCCESS,
} from "../actions/auction";

const INITIAL_STATE = {
  isLoadingAuctionList: true,
  auctions: [],
  hasMoreAuctions: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AUCTION_LIST:
      return {
        ...state,
        isLoadingAuctionList: true,
        hasMoreAuctions: true,
        auctions: action.payload.page === 1 ? [] : state.auctions,
      };

    case GET_AUCTION_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAuctionList: false,
        hasMoreAuctions: action.payload.data.length > 0,
        auctions: state.auctions.concat(action.payload.data),
      };

    case GET_AUCTION_LIST_FAILURE:
      return {
        ...state,
        isLoadingAuctionList: false,
        hasMoreAuctions: false,
      };
    default:
      return state;
  }
};
