import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import SelectLocationModal from "../../../../../components/Modals/Location/Select";

const Location = ({ piece, onUpdate }) => {
  const { location, location_detail, location_history, location_notes } = piece;

  const onSelectLocation = (location) => onUpdate({ location });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header floated={"left"} size="large" content="Location" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form>
            <Form.Group>
              <Form.Field width={4}>
                <label>Current Location</label>
              </Form.Field>
              <Form.Field width={12}>
                <SelectLocationModal
                  location={location}
                  onSelectLocation={onSelectLocation}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={4}>
                <label>Location Detail</label>
              </Form.Field>
              <Form.Input
                width={8}
                name={"location_detail"}
                placeholder="Location Detail"
                defaultValue={location_detail}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
              <Form.Field width={4}>
                <span className={"foot-note"}>e.g: Living Room</span>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={4}>
                <label>Location History</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name={"location_history"}
                placeholder="Location History"
                defaultValue={location_history}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field width={4}>
                <label>Location Notes</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name={"location_notes"}
                placeholder="Location Notes"
                defaultValue={location_notes}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Location;
