import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { updateTag } from "../../../../../../redux/actions/tag";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const AddTagModal = ({ tag, update }) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [name, setName] = useState(tag.name || "");

  useEffect(() => {
    setName(tag.name);
  }, [tag]);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await update(tag._id, { name });
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal
      trigger={<Button icon={"edit"} onClick={() => setModalOpen(true)} />}
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      size={"small"}
      closeIcon
    >
      <Modal.Header content={"Update Tag"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={name}
              width={12}
              placeholder={"Name"}
              autoComplete={"off"}
              onChange={(e, { value }) => setName(value)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary loading={loading} content={"Save"} onClick={onSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (id, data) => dispatch(updateTag(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTagModal);
