import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Header,
  Loader,
  Transition,
  Image,
  Segment,
  Card,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getGroupings } from "../../redux/actions/grouping";
import ErrorMessage from "../../components/ErrorMessage";
import AuthLayout from "../../layouts/AuthLayout";
import placeholder from "../../images/profile_placeholder.png";
import { GROUPINGS_PATH } from "../../constants/paths";
import InfiniteScroll from "react-infinite-scroll-component";

const GroupingList = ({ fetch }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [groupings, setGroupings] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { payload } = await fetch(page);
      setGroupings([...groupings, ...payload.data]);
      setPage(page + 1);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <AuthLayout title={"Groupings"}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={"h1"} content={"Groupings"} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <InfiniteScroll
              dataLength={groupings.length} //This is important field to render the next data
              next={fetchData}
              hasMore={true}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              // below props only if you need pull down functionality
              refreshFunction={() => fetchData(0)}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              pullDownToRefreshContent={
                <h3 style={{ textAlign: "center" }}>
                  &#8595; Pull down to refresh
                </h3>
              }
              releaseToRefreshContent={
                <h3 style={{ textAlign: "center" }}>
                  &#8593; Release to refresh
                </h3>
              }
            >
              <Card.Group itemsPerRow={4}>
                {(groupings || []).map((grouping) => {
                  return (
                    <Card
                      key={grouping._id}
                      onClick={() =>
                        navigate(`${GROUPINGS_PATH}/${grouping._id}`)
                      }
                    >
                      <img
                        src={
                          grouping.medium_image_url
                            ? grouping.medium_image_url
                            : placeholder
                        }
                        style={{ width: "100%", height: "200px" }}
                      />
                      <Card.Content>
                        <Card.Header>{grouping.title}</Card.Header>
                        <Card.Meta>
                          {grouping.artist && grouping.artist.name}
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  );
                })}
              </Card.Group>
            </InfiniteScroll>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, artist }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (page) => dispatch(getGroupings(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupingList);
