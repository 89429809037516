import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";

const Notes = ({ piece }) => {
  const { notes } = piece;

  if (!notes) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Notes"} dividing />
        <p>{notes}</p>
      </Grid.Column>
    </Grid.Row>
  );
};

Notes.propTypes = {
  piece: PropTypes.object,
};

export default Notes;
