import React, { useState } from "react";
import { Form } from "semantic-ui-react";

const geocodeByAddress = (address) => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

const getLatLng = (result) => {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };
      resolve(latLng);
    } catch (e) {
      reject(e);
    }
  });
};

const LocationSearchInput = ({ formatted_address, handleChange }) => {
  const [error, setError] = useState(formatted_address);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([
    {
      key: formatted_address,
      value: formatted_address,
      text: formatted_address,
    },
  ]);
  const [timer, setTimer] = useState(null);

  const handleSearchChange = async (e, { searchQuery }) => {
    setLoading(true);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        searchGoogle(searchQuery);
      }, 1000),
    );
  };

  const addressSelected = async (e, { value }) => {
    const results = await geocodeByAddress(value);

    const latLng = await getLatLng(results[0]);
    const formatted_address = results[0].formatted_address;

    const address_components = results[0].address_components;

    let address1;

    const streetNumber = address_components.filter((a) =>
      a.types.includes("street_number"),
    )[0];
    const street = address_components.filter((a) =>
      a.types.includes("route"),
    )[0];
    if (streetNumber && street)
      address1 = `${streetNumber.long_name} ${street.short_name}`;
    if (!streetNumber && street) address1 = `${street.long_name}`;

    let city;
    const locality = address_components.filter((a) =>
      a.types.includes("locality"),
    )[0];
    if (locality) city = locality.long_name;

    let state;
    const level = address_components.filter((a) =>
      a.types.includes("administrative_area_level_1"),
    )[0];
    if (level) state = level.short_name;

    let country;
    const c = address_components.filter((a) => a.types.includes("country"))[0];
    if (c) country = c.short_name.toLowerCase();

    let zip_code;
    const postal = address_components.filter((a) =>
      a.types.includes("postal_code"),
    )[0];
    if (postal) zip_code = postal.short_name;

    handleChange({
      formatted_address,
      latitude: latLng.lat,
      longitude: latLng.lng,
      address1,
      city,
      state,
      country,
      zip_code,
    });
  };

  const searchGoogle = (search) => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: search },
      function (predictions, e) {
        setError(e.error.response.data);
        setLoading(false);
        setResults(
          (predictions || []).map((s) => ({
            key: s.description,
            value: s.description,
            text: s.description,
          })),
        );
      },
    );
  };

  return (
    <Form.Group>
      <Form.Field width={4}>
        <label>Address</label>
      </Form.Field>
      <Form.Select
        width={12}
        search
        clearable
        autoComplete={"off"}
        placeholder={"Address"}
        defaultValue={formatted_address}
        loading={loading}
        onSearchChange={handleSearchChange}
        onChange={addressSelected}
        options={results}
      />
    </Form.Group>
  );
};

export default LocationSearchInput;
