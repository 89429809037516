const url = "/api/v4/currencies";

// Actions

export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";

// Networking

export function getCurrencies() {
  return {
    types: [GET_CURRENCIES, GET_CURRENCIES_SUCCESS, GET_CURRENCIES_FAILURE],
    payload: {
      request: {
        url,
      },
    },
  };
}
