import React, { useState } from "react";
import { Divider, Form, Header, Image } from "semantic-ui-react";
import { categoryOptions, statusOptions } from "../../../../common";
import SelectArtistModal from "../../../../components/Modals/Artist/Select";
import { connect } from "react-redux";
import TagFormField from "../../../../components/Forms/TagFormField";

const Overview = ({ onUpdate }) => {
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });

  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <label>Artwork Title</label>
        </Form.Field>
        <Form.Input
          fluid
          name={"title"}
          width={9}
          placeholder={"Title"}
          autoComplete={"off"}
          onChange={handleChange}
        />
        <Form.Input
          fluid
          name={"date"}
          width={3}
          placeholder={"Year"}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Artist</label>
        </Form.Field>
        <Form.Field width={12}>
          <SelectArtistModal
            onSelectArtist={(value) => onUpdate({ artist: value })}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Category & Medium</label>
        </Form.Field>
        <Form.Select
          width={6}
          name="category"
          search
          placeholder="Category"
          options={categoryOptions}
          onChange={handleChange}
        />
        <Form.Input
          width={6}
          name="medium"
          placeholder="Medium"
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Description</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="description"
          placeholder="Description"
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Status</label>
        </Form.Field>
        <Form.Select
          width={12}
          name="status"
          placeholder="Status"
          options={statusOptions}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Tags</label>
        </Form.Field>
        <TagFormField width={12} onChange={(tags) => onUpdate({ tags })} />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Signed</label>
        </Form.Field>
        <Form.Field width={12}>
          <Form.Checkbox
            name="is_signed"
            label={"The artwork is signed"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default Overview;
