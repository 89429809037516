class Grouping {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.title = data.title;
    this.image_url = data.image_url;
    this.medium_image_url = data.medium_image_url;
    this.small_image_url = data.small_image_url;
  }
}

export default Grouping;
