import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import PrivateRoomCard from "../../../../components/GridCells/PrivateRoomCard";

const PrivateRoomsGrid = ({ privateRooms, user }) => {
  if (!privateRooms || privateRooms.size === 0) return null;

  return (
    <Card.Group stackable centered itemsPerRow={4}>
      {privateRooms.map((privateRoom) => (
        <PrivateRoomCard
          key={privateRoom._id}
          storagePrivateRoom={privateRoom}
          user={user}
        />
      ))}
    </Card.Group>
  );
};

PrivateRoomsGrid.propTypes = {
  user: PropTypes.object,
  privateRooms: PropTypes.array,
};

export default PrivateRoomsGrid;
