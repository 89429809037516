import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import ManageBilling from "../ManageBilling";
import { useUser } from "../../../../../../../contexts/UserContext";

const PremiumAccount = () => {
  const { currentUser } = useUser();

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Segment.Inline>You are on a Premium Account</Segment.Inline>
        </Segment>
        <ManageBilling user={currentUser} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default PremiumAccount;
