import React, { Component, useState } from "react";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
import { createInvitation } from "../../../../../../redux/actions/invitation";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const CreateInvitationModal = ({ create, floated }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState(null);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      await create({ email });
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={modalOpen}
      trigger={
        <Button
          floated={floated}
          icon={"plus"}
          content={"Add User"}
          onClick={() => setModalOpen(true)}
        />
      }
    >
      <Modal.Header size="huge" content={"Invite User to Account"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form>
          <Form.Input
            placeholder={"Email"}
            autoComplete={"off"}
            defaultValue={email}
            onChange={(e, { value }) => setEmail(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content={"Close"} onClick={() => setModalOpen(false)} />
        <Button primary loading={loading} content={"Send"} onClick={onSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createInvitation(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateInvitationModal);
