import React from "react";
import { Card } from "semantic-ui-react";
import PieceCard from "../../../../components/GridCells/PieceCard";
import Piece from "../../../../models/piece";

const PiecesGrid = ({ pieces }) => {
  if (!pieces || pieces.length === 0) return null;
  return (
    <Card.Group stackable centered itemsPerRow={4}>
      {pieces.map((piece) => (
        <PieceCard key={piece._id} piece={new Piece(piece)} />
      ))}
    </Card.Group>
  );
};

export default PiecesGrid;
