import {
  GET_CONSIGNMENT,
  GET_CONSIGNMENT_FAILURE,
  GET_CONSIGNMENT_LIST,
  GET_CONSIGNMENT_LIST_FAILURE,
  GET_CONSIGNMENT_LIST_SUCCESS,
  GET_CONSIGNMENT_SUCCESS,
  REMOVE_CONSIGNMENT,
  REMOVE_CONSIGNMENT_FAILURE,
  REMOVE_CONSIGNMENT_SUCCESS,
} from "../actions/consignment";

const INITIAL_STATE = {
  isLoadingConsignmentList: true,
  consignments: [],
  hasMoreConsignments: true,

  isLoadingPieces: true,
  pieces: [],
  hasMorePieces: true,

  isLoadingAuctionRecords: true,
  auctionRecords: [],
  hasMoreAuctionRecords: true,

  error: null,

  isLoadingConsignment: true,
  consignment: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONSIGNMENT_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_CONSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoadingConsignmentList: false,
        consignments: [...state.consignments, ...action.payload.data],
        hasMoreConsignments: action.payload.data.length === 25,
      };
    case GET_CONSIGNMENT_LIST_FAILURE:
      return {
        ...state,
        isLoadingConsignmentList: false,
        error: action.error,
        hasMoreConsignments: false,
      };

    case GET_CONSIGNMENT:
      return { ...INITIAL_STATE };
    case GET_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoadingConsignment: false,
        consignment: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionPieces: true,
      };
    case GET_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isLoadingConsignment: false,
        error: action.error,
      };
    case REMOVE_CONSIGNMENT:
      return { ...state, isDeletingConsignment: true };
    case REMOVE_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isDeletingConsignment: false,
        consignments: state.consignments.filter(
          (c) => c._id !== action.payload.data._id,
        ),
      };
    case REMOVE_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isDeletingConsignment: false,
        error: action.error,
      };
    default:
      return state;
  }
};
