import {
  CREATE_REFERRAL_SUCCESS,
  GET_REFERRAL_LIST,
  GET_REFERRAL_LIST_FAILURE,
  GET_REFERRAL_LIST_SUCCESS,
} from "../actions/referral";

const INITIAL_STATE = {
  isLoadingReferralList: true,
  referrals: null,
  hasMoreReferrals: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REFERRAL_LIST:
      return { ...state };
    case GET_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        isLoadingReferralList: false,
        referrals: action.payload.data,
        data: action.payload.data,
        hasMoreReferrals: action.payload.data.length === 25,
      };
      break;
    case GET_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        isLoadingReferralList: false,
        error: action.error,
        hasMoreReferrals: false,
      };
      break;

    case CREATE_REFERRAL_SUCCESS: {
      return {
        ...state,
        referrals: [action.payload.data, ...state.referrals],
      };
    }
    default:
      return state;
  }
};
