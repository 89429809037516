export const sortPieces = (pieces, orderBy) => {
  if (!pieces) return [];

  const [field, order = "asc"] = orderBy;

  // Function to extract field value considering nested properties
  const fieldValue = (piece, field) => {
    return field.split(".").reduce((o, key) => o[key] || "", piece);
  };

  // Generalized sort function
  const sortFunction = (a, b) => {
    const valueA = fieldValue(a, field);
    const valueB = fieldValue(b, field);

    if (typeof valueA === "string" && typeof valueB === "string") {
      return order === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    } else {
      return order === "asc" ? valueA - valueB : valueB - valueA;
    }
  };

  return [...pieces].sort(sortFunction);
};
