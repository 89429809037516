import {
  GET_ARTIST_LIST,
  GET_ARTIST_LIST_FAILURE,
  GET_ARTIST_LIST_SUCCESS,
  TOGGLE_ARTIST_LIST_DISPLAY,
} from "../actions/artist";

const INITIAL_STATE = {
  display: localStorage.getItem("artistListDisplay") || "grid",
  isLoadingArtistList: true,
  artists: [],
  hasMoreArtists: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTIST_LIST:
      return {
        ...state,
        isLoadingArtistList: true,
        artists: action.payload.request.params.page <= 1 ? [] : state.artists,
      };
    case GET_ARTIST_LIST_SUCCESS:
      console;
      return {
        ...state,
        isLoadingArtistList: false,
        hasMoreArtists: action.payload.data.length > 0,
        artists: state.artists.concat(action.payload.data),
      };
    case GET_ARTIST_LIST_FAILURE:
      return {
        ...state,
        isLoadingArtistList: false,
        hasMoreArtists: false,
        error: action.error,
      };

    case TOGGLE_ARTIST_LIST_DISPLAY: {
      localStorage.setItem("artistListDisplay", action.display);
      return {
        ...state,
        display: action.display,
      };
    }
    default:
      return state;
  }
};
