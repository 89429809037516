import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Image,
  Segment,
  Transition,
  Loader,
  Confirm,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";

import {
  getGallery,
  deleteGallery,
  resetGalleryState,
} from "../../../../../../redux/actions/gallery";
import placeholder from "../../../../../../images/profile_placeholder.png";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import EditGallerySidePane from "../EditGallerySidePane";
import GalleryPieceRecords from "../GalleryPieceRecords";
import AuthLayout from "../../../../../../layouts/AuthLayout";
import {
  DASHBOARD_PATH,
  GALLERY_PATH,
} from "../../../../../../constants/paths";
import Notes from "../../../../../../components/Modals/Notes";
import { useUser } from "../../../../../../contexts/UserContext";

const GalleryDetail = ({ gallery, fetch, reset, loading, remove }) => {
  const { currentUser: user } = useUser();
  const { galleryId } = useParams();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(galleryId);
  }, [galleryId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleDelete = async () => {
    setError(null);
    try {
      await remove(gallery._id);
      navigate(-1);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <AuthLayout
      title={gallery ? gallery.name : "Loading..."}
      description={gallery && gallery.about && gallery.about.slice(0, 100)}
    >
      {loading && <Loader active inline={"centered"} />}
      {!loading && (
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                <Breadcrumb>
                  <Breadcrumb.Section
                    as={Link}
                    to={DASHBOARD_PATH}
                    content={"Home"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    as={Link}
                    to={GALLERY_PATH}
                    content={"Galleries"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    active
                    content={gallery && gallery.name}
                  />
                </Breadcrumb>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              {gallery &&
                user &&
                user.roles &&
                user.roles.includes("admin") && (
                  <>
                    <Button
                      negative
                      icon={"trash"}
                      floated="right"
                      content={"Delete"}
                      onClick={() => setConfirmDeleteOpen(true)}
                    />
                    <Confirm
                      open={confirmDeleteOpen}
                      onCancel={() => setConfirmDeleteOpen(false)}
                      onConfirm={handleDelete}
                      content="Are you sure you want to delete this gallery and all of their associated records?"
                    />
                  </>
                )}
              {gallery && (
                <EditGallerySidePane gallery={gallery}>
                  <Button
                    basic
                    floated={"right"}
                    icon={"edit"}
                    content={"Edit Gallery"}
                    labelPosition={"left"}
                  />
                </EditGallerySidePane>
              )}
            </Grid.Column>
          </Grid.Row>

          {error && (
            <Grid.Row>
              <Grid.Column width={16}>
                <ErrorMessage error={error} />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column width={8}>
              <Transition.Group
                style={{ maxHeight: "40em", minHeight: "20em" }}
                as={Image}
                animation={"fade left"}
                duration={600}
                visible={gallery}
                centered
                src={(gallery && gallery.image_url) || placeholder}
              />
              <Transition.Group
                basic
                as={Segment}
                animation={"fade up"}
                duration={600}
                visible={gallery}
              >
                <div style={{ textAlign: "center", marginTop: "1em" }}>
                  {gallery && gallery.website_url && (
                    <Button
                      basic
                      icon={"globe"}
                      labelPosition={"left"}
                      content={"View Website"}
                      as="a"
                      href={gallery.website_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  {gallery && gallery.email && (
                    <Button
                      basic
                      icon={"mail"}
                      labelPosition={"left"}
                      content={"Contact Gallery"}
                      as="a"
                      href={gallery.email}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  <Notes galleryID={galleryId} />
                </div>
              </Transition.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <Transition.Group
                basic
                as={Segment}
                animation={"fade down"}
                duration={600}
                visible={gallery}
              >
                {gallery && <Header size="huge" content={gallery.name} />}
              </Transition.Group>
              <Transition.Group
                as={Segment}
                animation={"fade up"}
                duration={600}
                visible={gallery}
              >
                {gallery && <p>{gallery.about}</p>}
              </Transition.Group>
            </Grid.Column>
          </Grid.Row>
          {galleryId && (
            <Grid.Row>
              <Grid.Column width={16}>
                <GalleryPieceRecords galleryId={galleryId} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, gallery }) => ({
  gallery: gallery.gallery,
  error: gallery.error,
  loading: gallery.isLoadingGallery,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getGallery(id)),
  remove: (id) => dispatch(deleteGallery(id)),
  reset: () => dispatch(resetGalleryState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryDetail);
