import React from "react";
import { Image, Modal } from "semantic-ui-react";
// Images
import placeholder from "../../../images/placeholder.png";

const ImageModal = ({ image, open, handleClose }) => {
  return (
    <Modal key={image._id} open={open} onClose={handleClose} closeIcon>
      <Modal.Content>
        <Image
          centered
          key={image._id}
          src={image.image_url || placeholder}
          size="huge"
        />
      </Modal.Content>
    </Modal>
  );
};

export default ImageModal;
