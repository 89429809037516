import React, { Component } from "react";

import { Form } from "semantic-ui-react";
// Images
// Components
import DropZone from "../DropZone";
import LocationSearchInput from "../Input/LocationSearchInput";

class EditMuseumForm extends Component {
  state = {};

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  onDrop = (acceptedFiles) =>
    this.setState((state) => ({ ...state, file: acceptedFiles[0] }));
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  getFormData() {
    const values = this.state;

    const data = new FormData();

    for (let key in values) {
      if (values[key]) {
        data.append(key, values[key]);
      }
    }

    return data;
  }

  render() {
    const { file } = this.state;
    const museum = this.props.museum || {};
    const {
      name,
      image_url,
      website_url,
      about,
      email,
      phone,
      formatted_address,
      twitter_username,
      instagram_username,
    } = museum;
    const url = (file && file.preview) || image_url;

    return (
      <Form fluid>
        <Form.Group>
          <Form.Field width={4}>
            <label>Image</label>
          </Form.Field>
          <Form.Field width={12}>
            <DropZone
              onDrop={this.onDrop}
              multiple={false}
              accept="image/*"
              icon={"image"}
              image={url}
              placeholder={"Drag image here or click to upload"}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={4}>
            <label>Name</label>
          </Form.Field>
          <Form.Input
            fluid
            name={"name"}
            width={12}
            placeholder={"Name"}
            defaultValue={name}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Field width={4}>
            <label>Website</label>
          </Form.Field>
          <Form.Input
            fluid
            name="website_url"
            defaultValue={website_url}
            width={12}
            placeholder="Website"
            autoComplete={"off"}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Field width={4}>
            <label>Email & Phone</label>
          </Form.Field>
          <Form.Input
            fluid
            name="email"
            defaultValue={email}
            width={6}
            placeholder="Email"
            autoComplete={"off"}
            onChange={this.handleChange}
          />
          <Form.Input
            fluid
            name="phone"
            defaultValue={phone}
            width={6}
            placeholder="Phone"
            autoComplete={"off"}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Field width={4}>
            <label>Social</label>
          </Form.Field>
          <Form.Input
            fluid
            name="instagram_username"
            defaultValue={instagram_username}
            width={6}
            placeholder="Instagram"
            autoComplete={"off"}
            onChange={this.handleChange}
          />
          <Form.Input
            fluid
            name="twitter_username"
            defaultValue={twitter_username}
            width={6}
            placeholder="Twitter"
            autoComplete={"off"}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Field width={4}>
            <label>About</label>
          </Form.Field>
          <Form.TextArea
            width={12}
            rows={5}
            name="about"
            placeholder="About"
            defaultValue={about}
            onChange={this.handleChange}
          />
        </Form.Group>

        <LocationSearchInput
          formatted_address={formatted_address}
          handleChange={(data) => this.setState({ ...data })}
        />
      </Form>
    );
  }
}

export default EditMuseumForm;
