import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Header, Segment, Image, Card, Grid, Loader } from "semantic-ui-react";
import { getGroupings } from "../../../../../../redux/actions/artist";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { GROUPINGS_PATH } from "../../../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const ArtistGroupings = ({ artistId, fetchData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    try {
      const response = await fetchData(artistId);
      setData(response.payload.data);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!artistId) return;
    fetch();
  }, [artistId]);

  const groupCards = useMemo(
    () =>
      data.map((grouping) => (
        <Card
          key={grouping._id}
          onClick={() => navigate(`${GROUPINGS_PATH}/${grouping._id}`)}
        >
          <Image
            src={grouping.medium_image_url}
            height={200}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
            }}
            alt={grouping.title}
          />
          <Card.Content>
            <Card.Header>{grouping.title}</Card.Header>
          </Card.Content>
        </Card>
      )),
    [data, navigate],
  );

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        {error && <ErrorMessage error={error} />}
        {isLoading && <Loader active inline={"centered"} />}
        {!isLoading && data.length === 0 && (
          <Segment>
            <Header
              textAlign={"center"}
              size={"large"}
              content={"No Series"}
              subheader={"We haven't identified any series for this artist yet"}
            />
          </Segment>
        )}
        {!isLoading && data.length > 0 && (
          <Card.Group stackable itemsPerRow={4}>
            {groupCards}
          </Card.Group>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getGroupings(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistGroupings);
