import React from "react";
import { Table } from "semantic-ui-react";
import GalleryCell from "../../../../components/Cells/GalleryCell";

const GalleriesTable = ({ galleries }) => {
  if (!galleries || galleries.length === 0) return null;

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content={"Name"} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {galleries.map((gallery) => (
          <GalleryCell
            key={gallery._id}
            gallery_id={gallery._id}
            gallery={gallery}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default GalleriesTable;
