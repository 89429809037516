import React, { useState, useEffect } from "react";
import { Grid, Header, Segment, Table } from "semantic-ui-react";
import CreateInvitationModal from "../CreateInvitationModal";
import InvitationCell from "./InvitationCell";
import Invitation from "../../../../../../../models/invitation";
import { useUser } from "../../../../../../../contexts/UserContext";
import { firestore } from "../../../../../../../utils/firebase";
import { onSnapshot, query, collection } from "firebase/firestore";

const Invitations = ({ accountID }) => {
  const { currentUser } = useUser();
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    if (!currentUser) return;
    const docRef = query(
      collection(firestore, "accounts", accountID, "invitations"),
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        const invitationsData = docSnapshot.docs.map((doc) => ({
          ...doc.data(),
          _id: doc.id,
        }));
        setInvitations(invitationsData);
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
        setLoading(false);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [currentUser]);
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment loading={!invitations}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header
                  size={"medium"}
                  content={"Users"}
                  subheader={accountID}
                  textAlign={"left"}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <CreateInvitationModal floated={"right"} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Role</Table.HeaderCell>
                      <Table.HeaderCell>Invitation Status</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {invitations &&
                      invitations
                        .map((invitation) => new Invitation(invitation))
                        .map((invitation) => (
                          <InvitationCell
                            key={invitation._id}
                            invitation={invitation}
                          />
                        ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Invitations;
