import {
  GET_MUSEUM_LIST,
  GET_MUSEUM_LIST_FAILURE,
  GET_MUSEUM_LIST_SUCCESS,
} from "../actions/museum";

const INITIAL_STATE = {
  display: localStorage.getItem("museumDisplay") || "table",
  isLoadingMuseumList: true,
  museums: [],
  hasMoreMuseums: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MUSEUM_LIST:
      return {
        ...state,
        isLoadingMuseumList: true,
        museums: action.payload.request.params.page <= 1 ? [] : state.museums,
      };
    case GET_MUSEUM_LIST_SUCCESS:
      return {
        ...state,
        isLoadingMuseumList: false,
        museums: state.museums.concat(action.payload.data),
        hasMoreMuseums: action.payload.data.length === 25,
      };
    case GET_MUSEUM_LIST_FAILURE:
      return {
        ...state,
        isLoadingMuseumList: false,
        error: action.error,
        hasMoreMuseums: false,
      };
    default:
      return state;
  }
};
