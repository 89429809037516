import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_SUCCESS,
} from "../actions/payment_source";

import { GET_CURRENT_USER_SUCCESS } from "../actions/me";

const INITIAL_STATE = {
  isCreatingPayment: false,
  payment: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payment: action.payload.data && action.payload.data.payment,
      };

    case CREATE_PAYMENT:
      return {
        ...state,
        isCreatingPayment: true,
        error: null,
      };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        isCreatingPayment: false,
        payment: action.payload.data,
      };

    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        isCreatingPayment: false,
        error: action.error,
      };

    default:
      return state;
  }
};
