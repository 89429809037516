import React from "react";
import { Image, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
// Images
import placeholder from "../../images/placeholder.png";
import { PRIVATE_ROOM_PATH } from "../../constants/paths";

const PrivateRoom = ({ privateRoom, match }) => {
  const navigate = useNavigate();

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const { _id, name, image_url, pieces } = privateRoom;

  return (
    <Table.Row onClick={(e) => onRowClick(e, `${PRIVATE_ROOM_PATH}/${_id}`)}>
      <Table.Cell>
        <Image src={image_url || placeholder} avatar />
        {name}
      </Table.Cell>
      <Table.Cell content={pieces.length} />
    </Table.Row>
  );
};

export default PrivateRoom;
