import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import GalleryCard from "../../../../components/GridCells/GalleryCard";

const GalleriesGrid = ({ galleries, user }) => {
  if (!galleries || galleries.length === 0) return null;

  return (
    <Card.Group stackable centered itemsPerRow={4}>
      {galleries.map((gallery) => (
        <GalleryCard key={gallery._id} gallery={gallery} user={user} />
      ))}
    </Card.Group>
  );
};

GalleriesGrid.propTypes = {
  user: PropTypes.object,
  galleries: PropTypes.array,
};

export default GalleriesGrid;
