import { Button, Grid, Header, Image, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const EmptyState = ({ title, detail, image, link, link_text }) => {
  if (!title) return null;

  return (
    <Segment basic>
      <Grid centered stackable>
        <Grid.Row columns={1}>
          <Grid.Column textAlign={"center"}>
            {image && <Image size={"small"} src={image} centered />}
            <Header
              size="huge"
              content={title}
              subheader={detail}
              textAlign="center"
            />
            {link && (
              <Button
                basic
                size={"large"}
                color={"black"}
                as={Link}
                to={link}
                content={link_text}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  link_text: PropTypes.string,
};

export default EmptyState;
