import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { createGallery } from "../../../../redux/actions/gallery";
import { connect } from "react-redux";
import ErrorMessage from "../../../ErrorMessage";

const AddGalleryModal = ({
  children,
  defaultName,
  create,
  onGalleryCreated,
}) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [name, setName] = useState(defaultName || "");
  const [websiteUrl, setWebsiteUrl] = useState("");

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { payload } = await create({
        name,
        website_url: websiteUrl,
      });
      onGalleryCreated(payload.data);
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal
      trigger={<div onClick={() => setModalOpen(true)}>{children}</div>}
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      size={"small"}
      closeIcon
    >
      <Modal.Header content={"Add Gallery"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={name}
              width={12}
              placeholder={"Name"}
              autoComplete={"off"}
              onChange={(e, { value }) => setName(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Website</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={websiteUrl}
              width={12}
              placeholder={"Website"}
              autoComplete={"off"}
              onChange={(e, { value }) => setWebsiteUrl(value)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          icon={"checkmark"}
          loading={loading}
          content={"Create"}
          onClick={onSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createGallery(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGalleryModal);
