import React from "react";
import { Table } from "semantic-ui-react";
import Artist from "../../../../components/Cells/artist";

const ArtistsTable = ({ artists }) => {
  if (!artists || artists.length === 0) return null;

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content={"Name"} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {artists.map((artist) => (
          <Artist key={artist._id} artist_id={artist._id} artist={artist} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ArtistsTable;
