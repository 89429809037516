import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Loader,
  Segment,
} from "semantic-ui-react";
import DeleteAccount from "../delete_account";

const DangerZone = ({ user, update }) => {
  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            size={"huge"}
            content={"Danger Zone"}
            subheader={"Irreversible and destructive actions"}
          />
        </Grid.Column>
      </Grid.Row>
      <DeleteAccount />
    </Grid>
  );
};

export default DangerZone;
