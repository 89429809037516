import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Image, Table } from "semantic-ui-react";

import {
  deleteArtistFromCurrentUserArtistList,
  getCurrentUserArtistList,
} from "../../../../../../redux/actions/me";
import placeholder from "../../../../../../images/placeholder.png";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { ARTIST_PATH } from "../../../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const Artists = ({ artists, error, pieces, unFollow, fetchAll }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (artists) return;
    fetchAll();
  });

  const collected_artists = (pieces || [])
    .map((p) => p.artist)
    .filter((n) => n)
    .map((a) => a._id);

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header size={"huge"} content={"Artists"} dividing />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          {error && <ErrorMessage error={error} />}
          <Table>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell content={"Email"} />
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(artists || []).map(({ _id, name, small_image_url }) => (
                <Table.Row key={_id} style={{ cursor: "pointer" }}>
                  <Table.Cell onClick={() => navigate(`${ARTIST_PATH}/${_id}`)}>
                    <Image src={small_image_url || placeholder} avatar />
                    {name}
                  </Table.Cell>
                  <Table.Cell>
                    {!collected_artists.includes(_id) && (
                      <Button
                        basic
                        floated="right"
                        content={"Unfollow"}
                        onClick={() => unFollow(_id)}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ me, firebase }) => ({
  artists: me.artists,
  loading: me.isLoadingArtistList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(getCurrentUserArtistList()),
  unFollow: (id) => dispatch(deleteArtistFromCurrentUserArtistList(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
