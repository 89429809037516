import React from "react";
import { Button, Header, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FirebaseError } from "@firebase/util";
import { PRODUCT_PATH } from "../constants/paths";
import { AxiosError } from "axios";

const ErrorMessage = ({ error }) => {
  let currentError = error?.error || error;

  let header = "Error";
  let content = "An unknown error has occurred";

  if (currentError instanceof FirebaseError) {
    switch (error.code) {
      case "auth/missing-verification-code":
        header = "Missing Verification Code";
        content = "Please enter a verification code.";
        break;
      case "auth/invalid-verification-code":
        header = "Invalid Verification Code";
        content = "Please enter a valid verification code.";
        break;
      case "auth/invalid-verification-id":
        header = "Invalid Verification ID";
        content = "Please enter a valid verification ID.";
        break;
      case "auth/missing-email":
        header = "Missing Email";
        content = "Please enter an email address.";
        break;
      case "auth/email-already-in-use":
        header = "Email Already In Use";
        content = "Please use a different email address.";
        break;
      case "auth/invalid-email":
        header = "Invalid Email";
        content = "Please enter a valid email address.";
        break;
      case "auth/weak-password":
        header = "Weak Password";
        content = "Please enter a stronger password.";
        break;
      case "auth/user-not-found":
        header = "User Not Found";
        content = "Please check your email address.";
        break;
      case "auth/wrong-password":
        header = "Wrong Password";
        content = "Please check your password.";
        break;
      case "auth/too-many-requests":
        header = "Too Many Requests";
        content = "Please wait a few minutes before trying again.";
        break;
      case "auth/network-request-failed":
        header = "Network Request Failed";
        content = "Please check your internet connection.";
        break;
      case "auth/operation-not-allowed":
        header = "Operation Not Allowed";
        content = "Please contact support.";
        break;
      case "auth/missing-android-pkg-name":
        header = "Missing Android Package Name";
        content = "Please contact support.";
        break;
      case "auth/missing-continue-uri":
        header = "Missing Continue URI";
        content = "Please contact support.";
        break;
      case "auth/account-exists-with-different-credential":
        header = "Account Exists With Different Credential";
        content = "Please login via email or contact support.";
        break;
      case "auth/invalid-credential":
        header = "Invalid Credential";
        content = "Please contact support.";
        break;
      case "permission-denied":
        header = "Permission Denied";
        content = "Please contact support.";
        break;
      default:
        header = error.code;
        content = error.message;
        break;
    }
  } else if (currentError instanceof AxiosError) {
    header = currentError.response?.data?.error || "Error";
    content =
      currentError.response?.data?.message || "An unknown error has occurred";
  } else if (currentError instanceof Error) {
    header = currentError.name;
    content = currentError.message;
  }

  switch (header) {
    case ("Membership Required", "Payment Required"):
      return (
        <Segment>
          <Header
            content={"Membership Required"}
            subheader={
              "Please upgrade your membership to add more pieces to your collection."
            }
          />
          <Button
            as={Link}
            to={PRODUCT_PATH}
            color={"black"}
            content={"View Plans"}
          />
        </Segment>
      );
    default:
      return <Message negative header={header} content={content} />;
  }
};

export default ErrorMessage;
