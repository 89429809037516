import React from "react";
import { Button, Item } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { MUSEUM_PATH } from "../../../../constants/paths";

const AuctionHouseTile = ({ museum }) => {
  const navigate = useNavigate();

  const onButtonClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Item
      key={museum._id}
      header={museum.name}
      image={museum.medium_image_url}
      description={museum.about}
      extra={
        <Button
          primary
          floated={"right"}
          content={"View"}
          onClick={(e) => onButtonClick(e, `${MUSEUM_PATH}/${museum._id}`)}
        />
      }
    />
  );
};

export default AuctionHouseTile;
