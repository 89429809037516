import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Card, Header, Segment } from "semantic-ui-react";
import { AUCTION_PATH } from "../../../../../../constants/paths";

import { getAuctionHouseAuctionsList } from "../../../../../../redux/actions/auction_house";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const UpcomingAuctionsList = ({
  auctionHouseId,
  fetch,
  filter = "upcoming",
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [auctions, setAuctions] = useState([]);

  const fetchData = async () => {
    try {
      const { payload } = await fetch(auctionHouseId, 1, 10, filter);
      setAuctions(payload.data);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [auctionHouseId]);

  if (!auctions || auctions.length === 0) return null;
  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Header size={"large"} content={"Upcoming Auctions"} />
      <Slider
        infinite={false}
        dots
        speed={500}
        slidesToShow={3}
        sidesToScroll={1}
      >
        {auctions.map(({ _id, end_date, name, image_url, auction_house }) => (
          <Segment basic key={_id}>
            <Card
              key={_id}
              fluid
              style={{
                backgroundImage: `url(${image_url})`,
                backgroundSize: "cover",
                height: "20em",
              }}
              onClick={() => navigate(`${AUCTION_PATH}/${_id}`)}
            />
            <Header
              content={name}
              subheader={`${
                end_date && moment(end_date).format("MMM D, YYYY")
              } @ ${auction_house.name}`}
              textAlign={"center"}
              style={{
                height: "1em",
                verticalAlign: "middle",
                position: "relative",
                marginLeft: 20,
                marginRight: 20,
                top: "40%",
              }}
            />
          </Segment>
        ))}
      </Slider>
    </>
  );
};

const mapStateToProps = ({ auction_house }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id, page, limit, filter) =>
    dispatch(getAuctionHouseAuctionsList(id, page, limit, filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingAuctionsList);
