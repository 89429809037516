import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  Grid,
  Header,
  Image,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import { connect } from "react-redux";

const ManagedAccount = ({ account }) => {
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment attached>
          <Header size={"large"} content={"You're on a Pro Account"} />
          <Header content={`You're account is managed by ${account.owner}`} />
        </Segment>
        <Segment attached>
          *If you would like to manage your own account, please contact your
          account manager or reach out to our Support Team.
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

ManagedAccount.propTypes = {
  account: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ManagedAccount);
