import {
  GET_ANALYTICS,
  GET_ANALYTICS_FAILURE,
  GET_ANALYTICS_SUCCESS,
} from "../actions/analytics";

const initialState = {
  isLoadingAnalytics: true,
  currency: localStorage.getItem("currency") || "USD",
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ANALYTICS:
      return {
        ...state,
        isLoadingAnalytics: true,
        error: null,
      };
    case GET_ANALYTICS_SUCCESS: {
      localStorage.setItem("currency", action.payload.data.currency);
      return {
        ...state,
        isLoadingAnalytics: false,
        analytics: action.payload.data,
        currency: action.payload.data.currency,
      };
    }

    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        isLoadingAnalytics: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
