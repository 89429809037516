import React, { useState } from "react";
import { auth } from "../../../../utils/firebase";
import { Button, Form } from "semantic-ui-react";
import ErrorMessage from "../../../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";

const VerifyEmail = ({ oobCode, onChangeMode }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const onSubmit = async () => {
    if (!oobCode) return;
    setLoading(true);
    setError(null);

    try {
      await auth.applyActionCode(oobCode);
      if (firebase.auth().currentUser) {
        navigate("/");
      } else {
        onChangeMode("login");
      }
      window.location.reload();
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <p>One final step! Please click the button below to verify your email</p>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <Button
            fluid
            primary
            loading={loading}
            type={"submit"}
            content={"Verify Email"}
            size={"large"}
          />
        </Form.Field>
      </Form>
    </>
  );
};
export default VerifyEmail;
