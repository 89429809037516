import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import ProvenanceAttachments from "../Attachments/ProvenanceAttachments";
import ExhibitionAttachments from "../Attachments/ExhibitionAttachments";
import LiteratureAttachments from "../Attachments/LiteratureAttachments";

const Additional = ({ piece, onUpdate }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header
            floated={"left"}
            size="large"
            content={"Additional Details"}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form>
            <Form.Group>
              <Form.Field width={4}>
                <label>Provenance</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name="provenance_text"
                placeholder="Provenance"
                defaultValue={piece.provenance_text}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>

            <ProvenanceAttachments
              attachments={piece.provenance_attachments}
              onAttachmentsUpdated={(attachments) =>
                onUpdate({ provenance_attachments: attachments })
              }
            />

            <Form.Group>
              <Form.Field width={4}>
                <label>Exhibited</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name="exhibited_text"
                placeholder="Exhibited"
                defaultValue={piece.exhibited_text}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <ExhibitionAttachments
              attachments={piece.exhibition_attachments}
              onAttachmentsUpdated={(attachments) =>
                onUpdate({ exhibition_attachments: attachments })
              }
            />

            <Form.Group>
              <Form.Field width={4}>
                <label>Literature</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name="literature_text"
                placeholder="Literature"
                defaultValue={piece.literature_text}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <LiteratureAttachments
              attachments={piece.literature_attachments}
              onAttachmentsUpdated={(attachments) =>
                onUpdate({ literature_attachments: attachments })
              }
            />

            <Form.Group>
              <Form.Field width={4}>
                <label>Notes</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name="notes"
                placeholder="Notes"
                defaultValue={piece.notes}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Additional;
