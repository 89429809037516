import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Grid, Header, Input, Menu, Segment } from "semantic-ui-react";
import PiecesTable from "../PiecesTable";
import {
  createPiece,
  setOrderBy,
  setPieceListFilters,
  togglePieceListDisplay,
} from "../../../../redux/actions/piece";
import PiecesGrid from "../PiecesGrid";
import { Mixpanel } from "../../../../utils/Mixpanel";
import AuthLayout from "../../../../layouts/AuthLayout";
import Piece from "../../../../models/piece";
import ExportModal from "../../../../components/Modals/ExportModal";
import PieceFields from "../PieceFilters";
import EditPieceSidePane from "../EditPieceSidePane";
import { useNavigate } from "react-router-dom";
import TagMenu from "../TagMenu/TagMenu";
import SelectPiecesForTagModal from "./components/SelectPiecesForTagModal";
import DashboardModal from "../../../../components/Modals/DashboardModal";
import { sortPieces } from "../../../../utils/sort";
import EditMultiplePiecesModal from "../../../../components/Modals/EditMultiplePiecesModal";
import ErrorMessage from "../../../../components/ErrorMessage";
import { usePieces } from "../../../../contexts/PiecesContext";
import { useUser } from "../../../../contexts/UserContext";
import DashboardOverview from "../DashboardOverview";
import moment from "moment";

const PiecesList = ({
  display,
  toggleDisplay,
  changeOrderBy,
  orderBy,
  activeTab,
}) => {
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedPieceIDs, setSelectedPieceIDs] = useState(new Set());
  const { pieces, error, loading, year } = usePieces();

  const filteredPieces = (pieces || []).filter((piece) => {
    if (!year) return true;
    return moment(piece.purchase_date).year() === year;
  }).filter((piece) => {
    if (activeTab === "all") return true;
    return piece.tagIds.includes(activeTab);
  });

  useEffect(() => {
    Mixpanel.page("piece");
  }, []);

  const onSearchChanged = (e, { value }) => {
    setSearchTerm(value);
  };

  const handSelectAllPieces = (checked) => {
    if (checked) {
      const pieceIDs = sortPieces(
        !searchTerm || searchTerm.length === 0
          ? filteredPieces
          : filteredPieces.filter(
              (piece) =>
                piece.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (
                  (piece.artist && piece.artist.name.toLowerCase()) ??
                  ""
                ).includes(searchTerm.toLowerCase()) ||
                (
                  (piece.gallery && piece.gallery.name.toLowerCase()) ??
                  ""
                ).includes(searchTerm.toLowerCase()) ||
                (
                  (piece.inventory_identifier &&
                    piece.inventory_identifier.toLowerCase()) ??
                  ""
                ).includes(searchTerm.toLowerCase()),
            ),
        orderBy,
      ).map((piece) => piece._id);
      setSelectedPieceIDs(new Set(pieceIDs));
    } else {
      setSelectedPieceIDs(new Set());
    }
  };

  const handleSelectPieceID = (pieceID) => {
    if (selectedPieceIDs.has(pieceID)) {
      selectedPieceIDs.delete(pieceID);
    } else {
      selectedPieceIDs.add(pieceID);
    }
    setSelectedPieceIDs(new Set(selectedPieceIDs));
  };

  return (
    <AuthLayout title={"Artworks"} bannersEnabled={true}>
      {error && <ErrorMessage error={error} />}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment basic>
              <Header size="huge" content="Artworks" />
            </Segment>
          </Grid.Column>
          <Grid.Column width={6} floated={"right"}>
            <Segment basic>
              <Button
                icon={"upload"}
                content={"Import Artwork"}
                labelPosition={"left"}
                floated={"right"}
                onClick={() => navigate("/import")}
              />
              <EditPieceSidePane
                currentPiece={
                  new Piece({
                    tags: activeTab === "all" ? [] : [activeTab],
                  })
                }
              >
                <Button
                  color={"black"}
                  icon={"plus"}
                  content={"New Artwork"}
                  labelPosition={"left"}
                  floated={"right"}
                />
              </EditPieceSidePane>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <DashboardOverview />

        <Grid.Row>
          <Grid.Column>
            {currentUser?.current_user_id && <TagMenu />}
            <Menu secondary stackable>
              <Menu.Item style={{ paddingLeft: 0 }}>
                <EditMultiplePiecesModal
                  currentSelectedPieces={selectedPieceIDs}
                />
              </Menu.Item>
              <Menu.Item style={{ flexGrow: 2, paddingLeft: 0 }}>
                <Input
                  icon={"search"}
                  placeholder={"Search..."}
                  onChange={onSearchChanged}
                />
              </Menu.Item>
              <Menu.Menu position={"right"}>
                <DashboardModal pieces={filteredPieces} />
                <Menu.Item
                  icon={display === "grid" ? "list" : "grid layout"}
                  onClick={() =>
                    toggleDisplay(display === "grid" ? "table" : "grid")
                  }
                />
                <Menu.Item style={{ paddingRight: 0 }}>
                  <div>
                    <ExportModal
                      selectedPiece
                      currentPieces={filteredPieces}
                      currentSelectedPieces={selectedPieceIDs}
                    />
                    <PieceFields
                      configuration={
                        currentUser?.piece_list_configuration ?? {}
                      }
                    />
                  </div>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment basic style={{ padding: 0 }} loading={loading}>
              {display === "table" && (
                <PiecesTable
                  orderBy={orderBy}
                  handleOrderByChange={(key) => changeOrderBy(key)}
                  selectedPieceIDs={selectedPieceIDs}
                  handSelectAllPieces={handSelectAllPieces}
                  handleSelectPieceID={handleSelectPieceID}
                  pieces={sortPieces(
                    !searchTerm || searchTerm.length === 0
                      ? filteredPieces
                      : filteredPieces.filter(
                          (piece) =>
                            piece.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.artist &&
                                piece.artist.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.gallery &&
                                piece.gallery.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.location &&
                                piece.location.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.inventory_identifier &&
                                piece.inventory_identifier.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()),
                        ),
                    orderBy,
                  )}
                />
              )}
              {display === "grid" && (
                <PiecesGrid
                  pieces={sortPieces(
                    !searchTerm || searchTerm.length === 0
                      ? filteredPieces
                      : filteredPieces.filter(
                          (piece) =>
                            piece.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.artist &&
                                piece.artist.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.gallery &&
                                piece.gallery.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.location &&
                                piece.location.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.inventory_identifier &&
                                piece.inventory_identifier.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()),
                        ),
                    orderBy,
                  )}
                />
              )}
              {activeTab !== "all" && (
                <SelectPiecesForTagModal
                  tag={activeTab}
                  currentSelectedPieces={(pieces || []).map(
                    (piece) => piece.id,
                  )}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ pieces, firebase }) => ({
  orderBy: pieces.orderBy,
  display: pieces.display,
  activeTab: pieces.activeTab || "all",
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisplay: (name) => dispatch(togglePieceListDisplay(name)),
  create: (data) => dispatch(createPiece(data)),
  changeOrderBy: (data) => dispatch(setOrderBy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PiecesList);
