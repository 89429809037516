import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
// Components
import GalleriesTable from "../GalleriesTable";
import empty_state from "../../../../images/empty_state/gallery.png";
import GalleriesGrid from "../GalleriesGrid";
import {
  getGalleryList,
  toggleGalleryListDisplay,
} from "../../../../redux/actions/gallery";
import ErrorMessage from "../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../utils/Mixpanel";
import EmptyState from "../../../../components/EmptyState";
import AuthLayout from "../../../../layouts/AuthLayout";

const GalleriesList = ({
  galleries,
  loading,
  error,
  display,
  toggleDisplay,
  getGalleries,
  hasMore,
}) => {
  const loadData = async () => {
    if (!hasMore) return;
    await getGalleries();
  };

  useEffect(() => {
    Mixpanel.page("gallery");
    loadData();
  }, []);

  return (
    <AuthLayout title={"Galleries"} bannersEnabled={true}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={16}>
            <Header size="huge" content="Galleries" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={14}>
            Found {galleries && galleries.length} galleries
          </Grid.Column>
          <Grid.Column width={2}>
            <Button.Group floated={"right"}>
              <Button
                basic
                icon={"grid layout"}
                active={display === "grid"}
                onClick={() => toggleDisplay("grid")}
              />
              <Button
                basic
                icon={"list"}
                active={display === "table"}
                onClick={() => toggleDisplay("table")}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {error && <ErrorMessage error={error} />}
            <Segment basic loading={loading}>
              {!loading &&
                galleries &&
                galleries.length > 0 &&
                display === "table" && <GalleriesTable galleries={galleries} />}

              {!loading &&
                galleries &&
                galleries.length > 0 &&
                display === "grid" && <GalleriesGrid galleries={galleries} />}

              {!loading && (!galleries || galleries.length === 0) && (
                <EmptyState
                  image={empty_state}
                  title={"No Galleries"}
                  detail={"Please document a piece of art to get started"}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ galleries, firebase }) => ({
  display: galleries.display || "table",
  loading: galleries.isLoadingGalleryList,
  hasMore: galleries.hasMoreGalleries,
  error: galleries.error,
  galleries: galleries.galleries,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisplay: (name) => dispatch(toggleGalleryListDisplay(name)),
  getGalleries: () => dispatch(getGalleryList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleriesList);
