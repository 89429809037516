import React, { useState } from "react";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
// Images
import SlidingPane from "react-sliding-pane";

import { connect } from "react-redux";
import {
  createAuctionHouse,
  updateAuctionHouse,
} from "../../../../../../redux/actions/auction_house";
import DropZone from "../../../../../../components/DropZone";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const EditAuctionHouseSidePane = ({
  auctionHouse,
  children,
  update,
  create,
}) => {
  const [loading, setLoading] = useState(false);
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const [id] = useState(auctionHouse._id);
  const [imageUrl] = useState(auctionHouse.image_url || null);
  const [file, setFile] = useState(null);
  const [name, setName] = useState(auctionHouse.name || "");
  const [websiteUrl, setWebsiteUrl] = useState(auctionHouse.website_url || "");
  const [about, setAbout] = useState(auctionHouse.about || "");

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = {
      name,
      file,
      about,
      website_url: websiteUrl,
    };

    var formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      if (id) {
        await update(id, formData);
      } else {
        await create(formData);
      }

      setModalOpen(true);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={id ? "Edit Auction" : "Create Auction"}
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Form fluid>
          {error && <ErrorMessage error={error} />}

          <Modal open={modalOpen}>
            <Modal.Header size="huge" content="Thank you" />
            <Modal.Content>
              <p>
                Our team will review the proposed changes and make the necessary
                adjustments.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                content="Okay"
                onClick={() => {
                  setModalOpen(false);
                  setSidePainOpen(false);
                }}
              />
            </Modal.Actions>
          </Modal>

          <Form.Group>
            <Form.Field width={4}>
              <label>Image</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                accept="image/*"
                icon={"image"}
                image={(file && file.preview) || imageUrl}
                placeholder={"Drag auctionHouse image here or click to upload"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={name}
              width={12}
              placeholder="Name"
              autoComplete={"off"}
              onChange={(e, { value }) => setName(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Website</label>
            </Form.Field>
            <Form.Input
              fluid
              name="website_url"
              defaultValue={websiteUrl}
              width={12}
              placeholder="Website"
              autoComplete={"off"}
              onChange={(e, { value }) => setWebsiteUrl(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>About</label>
            </Form.Field>
            <Form.TextArea
              defaultValue={about}
              width={12}
              placeholder="About"
              rows={8}
              autoComplete={"off"}
              onChange={(e, { value }) => setAbout(value)}
            />
          </Form.Group>
        </Form>
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createAuctionHouse(data)),
  update: (id, data) => dispatch(updateAuctionHouse(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAuctionHouseSidePane);
