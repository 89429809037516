const clean = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") clean(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
    else if (obj[key] === null) delete obj[key];
    else if (obj[key] === "") delete obj[key];
  });
  return obj;
};

module.exports = { clean };
