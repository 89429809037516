import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Confirm, Table } from "semantic-ui-react";
import { deleteTag } from "../../../../../../redux/actions/tag";
import EditTagModal from "./EditTagModal";

const TagCell = ({ tag, remove }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { _id, name, number_of_pieces } = tag;

  const onDelete = (_id) => {
    remove(_id);
    setConfirmDelete(false);
  };

  return (
    <Table.Row key={_id}>
      <Table.Cell content={name} />
      <Table.Cell content={number_of_pieces} />
      <Table.Cell width={2}>
        <EditTagModal tag={tag} />
        <Button
          basic
          color={"red"}
          icon={"trash"}
          onClick={() => setConfirmDelete(true)}
        />
        <Confirm
          open={confirmDelete}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={() => onDelete(_id)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteTag(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagCell);
