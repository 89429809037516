import React, { useState, useEffect } from "react";
import { Dropdown, Input } from "semantic-ui-react";

const options = [
  { key: "0", text: "0", value: "0" },
  { key: "0.1667", text: "1/6", value: "0.1667" },
  { key: "0.1250", text: "1/8", value: "0.1250" },
  { key: "0.1875", text: "3/16", value: "0.1875" },
  { key: "0.25", text: "1/4", value: "0.25" },
  { key: "0.3125", text: "5/16", value: "0.3125" },
  { key: "0.3750", text: "3/8", value: "0.3750" },
  { key: "0.4375", text: "7/16", value: "0.4375" },
  { key: "0.5000", text: "1/2", value: "0.5" },
  { key: "0.5625", text: "9/16", value: "0.5625" },
  { key: "0.6250", text: "5/8", value: "0.6250" },
  { key: "0.6875", text: "11/16", value: "0.6875" },
  { key: "0.75", text: "3/4", value: "0.75" },
  { key: "0.8125", text: "13/16", value: "0.8125" },
  { key: "0.8750", text: "7/8", value: "0.8750" },
  { key: "0.9375", text: "15/16", value: "0.9375" },
];

const measurement = (value) => {
  if (!value) {
    return [0, 0];
  }

  const total = Number(value).toFixed(4);
  const whole = Math.trunc(total);
  const fraction = total % 1;
  return [whole, `${fraction}`];
};

const MeasurementInput = (props) => {
  const [state, setState] = useState({
    whole: measurement(props.defaultValue || 0)[0],
    decimal: measurement(props.defaultValue || `0`)[1],
  });

  useEffect(() => {
    if (props.units === "metric" && state.decimal) {
      delete state["decimal"];
      setState({ ...state });
      refreshAfterChange(state);
    }
  }, [props.units]);

  const handleChange = (e, { name, value }) => {
    const data = {
      ...state,
      [name]: value,
    };
    setState(data);
    refreshAfterChange(data);
  };

  const refreshAfterChange = (data) => {
    const total = Object.keys(data).reduce((previous, key) => {
      previous += Number(data[key]);
      return previous;
    }, 0);
    props.onChange(this, {
      name: props.name,
      value: total,
    });
  };

  return (
    <Input
      fluid
      name={"whole"}
      placeholder={props.title}
      labelPosition={props.units !== "metric" ? "right" : null}
      value={state.whole || null}
      type={"number"}
      autoComplete={"off"}
      label={
        props.units !== "metric" ? (
          <Dropdown
            name={"decimal"}
            value={state.decimal ? `${state.decimal}` : `0`}
            options={options}
            onChange={handleChange}
          />
        ) : null
      }
      onChange={handleChange}
    />
  );
};

export default MeasurementInput;
