import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Confirm,
  Grid,
  Header,
  Image,
  List,
  Loader,
  Segment,
  Transition,
} from "semantic-ui-react";
import { Mixpanel } from "../../../../../../utils/Mixpanel";

import {
  deleteArtwork,
  getArtwork,
  resetArtworkState,
} from "../../../../../../redux/actions/artwork";
// Images
import placeholder from "../../../../../../images/placeholder.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import AuthLayout from "../../../../../../layouts/AuthLayout";
import {
  ARTIST_PATH,
  DASHBOARD_PATH,
  MUSEUM_PATH,
} from "../../../../../../constants/paths";
import EditArtworkSidePane from "../EditArtworkSidePane/EditArtworkSidePane";

const Cell = ({ header, content, image, link }) => {
  return (
    <Grid.Column style={{ whiteSpace: "pre-line" }}>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            {!link && (
              <List.Description>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
            {link && (
              <List.Description as={Link} to={{ pathname: link }}>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

const ArtworkDetail = ({
  match,
  artwork,
  fetch,
  reset,
  remove,
  loading,
  user,
}) => {
  const { artworkId } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    fetch(artworkId);
    Mixpanel.page("artwork", { artwork: artworkId });
  }, [artworkId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleConfirm = async () => {
    setError(null);

    try {
      await remove(artworkId);
      navigate(-1);
    } catch (e) {
      setError(e);
    }
  };

  if (loading) return <Loader active inline="centered" />;
  if (error) return <ErrorMessage error={error} />;

  const { title, artist, museum, medium, size, about } = artwork;

  return (
    <AuthLayout
      title={`${title} | ${artist && artist.name}`}
      description={medium && medium.slice(0, 100)}
    >
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment basic>
              <Breadcrumb>
                <Breadcrumb.Section
                  as={Link}
                  to={DASHBOARD_PATH}
                  content={"Home"}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  as={Link}
                  to={MUSEUM_PATH}
                  content={"Museums"}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  as={Link}
                  to={`${MUSEUM_PATH}/${museum._id}`}
                  content={museum.name}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section active content={title} />
              </Breadcrumb>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={9}>
            <Transition.Group
              basic
              as={Segment}
              animation={"fade down"}
              duration={600}
              visible={artist}
            >
              {artwork && (
                <Header
                  size="huge"
                  content={artwork.title}
                  subheader={artwork.date}
                />
              )}
            </Transition.Group>
            <Transition.Group
              basic
              as={Segment}
              animation={"fade up"}
              duration={600}
              visible={artist}
            >
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Cell
                    header={"Artist"}
                    content={artist && artist.name}
                    image={artist && artist.image_url}
                    link={artwork && `${ARTIST_PATH}/${artist && artist._id}`}
                  />
                  <Cell
                    header={"Museum"}
                    content={museum && museum.name}
                    image={museum && museum.image_url}
                    link={museum && `${MUSEUM_PATH}/${museum._id}`}
                  />
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Cell header={"Size"} content={size} />
                </Grid.Row>

                {medium && (
                  <Grid.Row columns={1}>
                    <Cell header={"Medium"} content={medium} />
                  </Grid.Row>
                )}
                {about && (
                  <Grid.Row columns={1}>
                    <Cell header={"About"} content={about} />
                  </Grid.Row>
                )}
              </Grid>
            </Transition.Group>
          </Grid.Column>

          <Grid.Column width={7}>
            <Transition.Group
              style={{ maxHeight: "40em", minHeight: "20em" }}
              as={Image}
              animation={"fade left"}
              duration={600}
              visible={artist}
              centered
              src={(artwork && artwork.image_url) || placeholder}
            />
            <Transition.Group
              basic
              as={Segment}
              animation={"fade up"}
              duration={600}
              visible={artwork}
            >
              <div style={{ textAlign: "center", marginTop: "1em" }}>
                {artwork && artwork.website_url && (
                  <Button
                    basic
                    icon={"globe"}
                    labelPosition={"left"}
                    content={`View at ${museum.name}`}
                    as="a"
                    href={artwork.website_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
                {user && user.roles && user.roles.includes("admin") && (
                  <EditArtworkSidePane artwork={artwork}>
                    <Button primary content={"Edit"} />
                  </EditArtworkSidePane>
                )}
                {user && user.roles && user.roles.includes("admin") && (
                  <>
                    <Button
                      negative
                      content={"Delete"}
                      onClick={() => setConfirmOpen(true)}
                    />
                    <Confirm
                      open={confirmOpen}
                      onCancel={() => setConfirmOpen(false)}
                      onConfirm={handleConfirm}
                      content="Are you sure you want to delete this artist and all of their associated records?"
                    />
                  </>
                )}
              </div>
            </Transition.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, artwork }) => ({
  loading: artwork.isLoadingArtwork,
  artwork: artwork.artwork,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getArtwork(id)),
  remove: (id) => dispatch(deleteArtwork(id)),
  reset: () => dispatch(resetArtworkState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkDetail);
