import React, { useState } from "react";
import {
  Button,
  Confirm,
  Grid,
  Header,
  Item,
  Segment,
} from "semantic-ui-react";
import { deleteMe, deleteArtwork } from "../../../../../../redux/actions/me";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../components/ErrorMessage";


const DeleteAccount = ({ remove, removeArt }) => {
  const [loading, setLoading] = useState(false);

  const [deleteArtworkError, setDeleteArtworkError] = useState(null);
  const [deleteArtworkOpen, setDeleteArtworkOpen] = useState(false);

  const [deleteAccountError, setDeleteAccountError] = useState(null);
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const handleDeleteAccount = async () => {
    setDeleteAccountError(null);
    setLoading(true);

    try {
      await remove();
      setDeleteAccountOpen(false);
    } catch (e) {
      setDeleteAccountError(e);
    }

    setLoading(false);
  };

  const handleDeleteAllArwork = async () => {
    setDeleteArtworkError(null);
    setLoading(true);

    try {
      await removeArt();
      setDeleteArtworkOpen(false);
    } catch (e) {
      setDeleteArtworkError(e);
    }

    setLoading(false);
  }

  return (
    <>
      <Grid.Row>
        <Grid.Column>
        <Segment loading={loading}>
            <Header size={"large"} content={"Delete All Artwork"} />
            {deleteArtworkError && (
              <ErrorMessage
                error={deleteArtworkError}
                header={"Error deleting all artwork"}
              />
            )}
            <p>
              Once you delete all your artwork, there is no going back. Please be
              certain.
            </p>
            <Button
              negative
              content={"Delete All Artwork"}
              onClick={() => setDeleteArtworkOpen(true)}
            />
            <Confirm
              open={deleteArtworkOpen}
              onCancel={() => setDeleteArtworkOpen(false)}
              onConfirm={handleDeleteAllArwork}
              header="Delete All Artwork?"
              content="Are you sure you want to delete all your artwork? This action cannot be undone."
            />
          </Segment>
          <Segment loading={loading}>
            <Header size={"large"} content={"Delete account"} />
            {deleteAccountError && (
              <ErrorMessage
                error={deleteAccountError}
                header={"Error deleting account"}
              />
            )}
            <p>
              Once you delete your user, there is no going back. Please be
              certain.
            </p>
            <Button
              negative
              content={"Delete Account"}
              onClick={() => setDeleteAccountOpen(true)}
            />
            <Confirm
              open={deleteAccountOpen}
              onCancel={() => setDeleteAccountOpen(false)}
              onConfirm={handleDeleteAccount}
              header="Delete Account?"
              content="Are you sure you want to delete your account? This action cannot be undone."
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: () => dispatch(deleteMe()),
  removeArt: () => dispatch(deleteArtwork()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
