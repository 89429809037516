import {
  GET_PLAN_LIST,
  GET_PLAN_LIST_FAILURE,
  GET_PLAN_LIST_SUCCESS,
} from "../actions/plan";

const INITIAL_STATE = {
  isGettingPlanList: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PLAN_LIST:
      return {
        ...state,
        isGettingPlanList: true,
        error: null,
      };

    case GET_PLAN_LIST_SUCCESS:
      return {
        ...state,
        isGettingPlanList: false,
        plans: action.payload.data,
      };

    case GET_PLAN_LIST_FAILURE:
      return {
        ...state,
        isGettingPlanList: false,
        error: action.error,
      };

    default:
      return state;
  }
};
