import React, { useEffect, useState } from "react";
import { Divider, Form, Header } from "semantic-ui-react";
import { measurementOptions } from "../../common";
import MeasurementInput from "../Input/MeasurementInput";
import FramingAttachments from "../../routes/Pieces/components/EditPieceSidePane/Attachments/FramingAttachments";

const DimensionEditionForm = ({
  piece,
  handleCheck,
  handleChange,
  onUpdate,
}) => {
  const [isFramed, setIsFramed] = useState(piece && piece.is_framed);
  const [isEdition, setIsEdition] = useState(piece && piece.is_edition);
  const [measurementUnits, setMeasurementUnits] = useState(
    piece && piece.measurement_units,
  );

  useEffect(() => {
    setIsFramed(piece.is_framed);
    setIsEdition(piece.is_edition);
    setMeasurementUnits(piece.measurement_units);
  }, [piece]);

  const {
    measurement_units,
    height,
    width,
    depth,

    edition_number,
    edition_size,

    framed_measurement_units,
    framed_height,
    framed_width,
    framed_depth,
    frame_details,
  } = piece;

  return (
    <>
      <Divider />
      <Header size="large" content={"Editions & Size"} />
      <Form.Group>
        <Form.Field width={4}>
          <label>Dimensions (H × W × D)</label>
        </Form.Field>
        <Form.Field width={3}>
          <MeasurementInput
            units={measurement_units}
            title="Height"
            name="height"
            defaultValue={height}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field width={3}>
          <MeasurementInput
            units={measurement_units}
            title="Width"
            name="width"
            defaultValue={width}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field width={3}>
          <MeasurementInput
            units={measurement_units}
            title="Depth"
            name="depth"
            defaultValue={depth}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Select
          fluid
          name="measurement_units"
          placeholder="Units"
          defaultValue={measurement_units || "imperial"}
          options={measurementOptions}
          onChange={handleChange}
          search
          width={3}
        />
      </Form.Group>

      {!isFramed && (
        <Form.Button
          fluid
          content={"Add Framed Dimensions"}
          onClick={(e) => {
            setIsFramed(true);
            handleCheck(e, { name: "is_framed", checked: true });
          }}
        />
      )}
      {isFramed && (
        <>
          <Form.Group>
            <Form.Field width={4}>
              <label>Framed Dimensions (H × W × D)</label>
            </Form.Field>
            <Form.Field width={3}>
              <MeasurementInput
                units={framed_measurement_units}
                title="Height"
                name="framed_height"
                defaultValue={framed_height}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field width={3}>
              <MeasurementInput
                units={framed_measurement_units}
                title="Width"
                name="framed_width"
                defaultValue={framed_width}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field width={3}>
              <MeasurementInput
                units={framed_measurement_units}
                title="Depth"
                name="framed_depth"
                defaultValue={framed_depth}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Select
              fluid
              name="framed_measurement_units"
              placeholder="Framed Units"
              defaultValue={framed_measurement_units || "imperial"}
              options={measurementOptions}
              onChange={handleChange}
              search
              width={3}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field width={4}>
              <label>Frame Details</label>
            </Form.Field>
            <Form.TextArea
              width={12}
              rows={4}
              name="frame_details"
              placeholder="Frame Details"
              defaultValue={frame_details}
              onChange={handleChange}
            />
          </Form.Group>
          <FramingAttachments
            attachments={piece.framing_attachments}
            onAttachmentsUpdated={(attachments) =>
              onUpdate({ framing_attachments: attachments })
            }
          />
          <Form.Button
            fluid
            basic
            content={"Remove Framed Dimensions"}
            onClick={(e) => {
              setIsFramed(false);
              handleCheck(e, { name: "is_framed", checked: false });
            }}
          />
        </>
      )}

      {!isEdition && (
        <Form.Button
          fluid
          content={"Add Editions"}
          onClick={(e) => {
            setIsEdition(true);
            handleCheck(e, { name: "is_edition", checked: true });
          }}
        />
      )}
      {isEdition && (
        <>
          <Form.Group>
            <Form.Field width={4}>
              <label>Edition</label>
            </Form.Field>
            <Form.Input
              width={6}
              name="edition_number"
              placeholder="Edition Number"
              defaultValue={edition_number}
              autoComplete={"off"}
              onChange={handleChange}
            />
            <Form.Input
              width={6}
              name="edition_size"
              placeholder="Edition Size"
              defaultValue={edition_size}
              autoComplete={"off"}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Button
            fluid
            basic
            content={"Remove Editions"}
            onClick={(e) => {
              setIsEdition(false);
              handleCheck(e, { name: "is_edition", checked: false });
            }}
          />
        </>
      )}
    </>
  );
};

export default DimensionEditionForm;
