import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Divider,
  Grid,
  Header,
  Item,
  Loader,
  Segment,
} from "semantic-ui-react";
import { createCheckoutSession } from "../../../../../../redux/actions/billing_portal";
import {
  getProduct,
  getProductPlansList,
} from "../../../../../../redux/actions/product";
import { priceString } from "../../../../../../utils/string";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import { useParams } from "react-router-dom";
import Layout from "../../../../../../layouts/AuthLayout";
import { createReferral } from "../../../../../../redux/actions/referral";

const ProductList = ({
  loading,
  fetchProduct,
  fetchProductPlans,
  checkout,
}) => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState(false);
  const [checkoutError, setCheckoutError] = useState(null);
  const referral = new URLSearchParams(window.location.search).get("referral");

  const fetchData = useCallback(async () => {
    if (!productId) return;

    try {
      const productData = await fetchProduct(productId);
      setProduct(productData.payload.data);

      const planData = await fetchProductPlans(productId);
      const fetchedPlans = planData.payload.data;
      setPlans(fetchedPlans);

      if (fetchedPlans.length > 0) {
        setSelectedPlan(fetchedPlans[1] || fetchedPlans[0]);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  }, [fetchProduct, fetchProductPlans, productId]);

  useEffect(() => {
    Mixpanel.page("product", { product: productId });
    fetchData();
  }, [productId, fetchData]);

  const getBenefits = useCallback((data) => {
    return Object.keys(data)
      .filter((key) => key.startsWith("benefit_"))
      .sort((a, b) => Number(a.split("_")[1]) - Number(b.split("_")[1]))
      .map((key) => JSON.parse(data[key]));
  }, []);

  const formattedCost = useMemo(() => {
    if (!selectedPlan) return "$0.00";
    return priceString(selectedPlan.amount / 100, selectedPlan.currency);
  }, [selectedPlan]);

  const handleCheckout = async () => {
    setIsCreatingCheckoutSession(true);
    try {
      const response = await checkout({
        return_url: window.location.href,
        price_id: selectedPlan.id,
        referral,
      });
      const { url } = response.payload.data;
      window.location.assign(url);
    } catch (error) {
      setCheckoutError(error);
    } finally {
      setIsCreatingCheckoutSession(false);
    }
  };

  return (
    <Layout title={`${product?.name} Membership`}>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              size="large"
              content={`Your ${product?.name} membership includes`}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              <Item.Group>
                {product?.metadata &&
                  getBenefits(product.metadata).map((benefit, index) => (
                    <Item key={index}>
                      <Item.Image size="tiny" src={benefit.image_url} />
                      <Item.Content verticalAlign="middle">
                        <Item.Header content={benefit.title} />
                        <Item.Description content={benefit.description} />
                      </Item.Content>
                    </Item>
                  ))}
              </Item.Group>
              <Divider />
              <Header
                content="Payment Plan"
                subheader="Please select how you would like to be billed:"
              />
              {loading ? (
                <Loader active inline="centered" />
              ) : (
                <Card.Group itemsPerRow={2}>
                  {plans.map((plan) => {
                    const isSelected = selectedPlan?.id === plan.id;
                    return (
                      <Card
                        key={plan.id}
                        raised={isSelected}
                        style={{ opacity: isSelected ? 1 : 0.5 }}
                        header={priceString(plan.amount / 100, plan.currency)}
                        description={plan.nickname}
                        onClick={() => setSelectedPlan(plan)}
                      />
                    );
                  })}
                </Card.Group>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic>
              <Header size="large" content="Summary" />
              <div style={{ height: "2.5em" }}>
                <Header size="small" floated="left" content="Package" />
                <Header size="small" floated="right" content={product?.name} />
              </div>
              <div style={{ height: "2.5em" }}>
                <Header size="small" floated="left" content="Payment Plan" />
                <Header
                  size="small"
                  floated="right"
                  content={selectedPlan?.nickname}
                />
              </div>
              <Divider />
              <div style={{ height: "2.5em" }}>
                <Header size="small" floated="left" content="Amount" />
                <Header size="small" floated="right" content={formattedCost} />
              </div>
              <div
                style={{ marginTop: "4em", marginBottom: "2em", height: "4em" }}
              >
                <Header size="large" floated="left" content="Total Amount" />
                <Header size="large" floated="right" content={formattedCost} />
              </div>
              {checkoutError && <ErrorMessage error={checkoutError} />}
              <Button
                color="black"
                disabled={!selectedPlan}
                loading={isCreatingCheckoutSession}
                size="huge"
                content="Make Payment"
                onClick={handleCheckout}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
});

const mapDispatchToProps = (dispatch) => ({
  trackReferral: (data) => dispatch(createReferral(data)),
  checkout: (data) => dispatch(createCheckoutSession(data)),
  fetchProduct: (id) => dispatch(getProduct(id)),
  fetchProductPlans: (id) => dispatch(getProductPlansList(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
