import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useUser } from "./UserContext";
import { firestore } from "../utils/firebase";
import { onSnapshot, query, collection } from "firebase/firestore";
import Tag from "../models/tag";

const TagsContext = createContext(undefined);

export const useTags = () => {
  const context = useContext(TagsContext);
  if (!context) {
    throw new Error("useTags must be used within a TagsProvider");
  }
  return context;
};

export const TagsProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!currentUser) return;
    const docRef = query(
      collection(
        firestore,
        "tags",
        currentUser.current_user_id || currentUser._id,
        "tags",
      ),
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        const tagsData = docSnapshot.docs.map((doc) => ({
          ...doc.data(),
          _id: doc.id,
        }));
        setTags(tagsData.map((tag) => new Tag(tag)));
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  return (
    <TagsContext.Provider
      value={{
        tags,
        error,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};
