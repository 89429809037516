import {
  GET_AUCTION,
  GET_AUCTION_FAILURE,
  GET_AUCTION_RECORDS_LIST_FOR_AUCTION,
  GET_AUCTION_RECORDS_LIST_FOR_AUCTION_FAILURE,
  GET_AUCTION_RECORDS_LIST_FOR_AUCTION_SUCCESS,
  GET_AUCTION_SUCCESS,
  RESET_AUCTION_STATE,
} from "../actions/auction";

const INITIAL_STATE = {
  isLoading: true,
  auctions: [],
  error: null,
  hasMoreItems: true,
  isLoadingAuction: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_AUCTION_STATE:
      return INITIAL_STATE;
    case GET_AUCTION:
      return { ...state, isLoadingAuction: true };
    case GET_AUCTION_SUCCESS:
      return {
        ...state,
        isLoadingAuction: false,
        auction: action.payload.data,
        auction_records: [],
        hasMoreAuctionRecords: true,
      };
    case GET_AUCTION_FAILURE:
      return {
        ...state,
        isLoadingAuction: false,
        error: action.error,
        hasMoreAuctionRecords: false,
      };

    case GET_AUCTION_RECORDS_LIST_FOR_AUCTION:
      return { ...state };
    case GET_AUCTION_RECORDS_LIST_FOR_AUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auction_records: [...state.auction_records, ...action.payload.data],
        hasMoreAuctionRecords: action.payload.data.length === 25,
      };
    case GET_AUCTION_RECORDS_LIST_FOR_AUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        hasMoreAuctionRecords: false,
      };
    default:
      return state;
  }
};
