const url = "/api/v5/notes";

// Actions

export const RESET_NOTE_STATE = "RESET_NOTE_STATE";

export const GET_NOTE_LIST = "GET_NOTE_LIST";
export const GET_NOTE_LIST_SUCCESS = "GET_NOTE_LIST_SUCCESS";
export const GET_NOTE_LIST_FAILURE = "GET_NOTE_LIST_FAILURE";

export const GET_NOTE = "GET_NOTE";
export const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS";
export const GET_NOTE_FAILURE = "GET_NOTE_FAILURE";

export const CREATE_NOTE = "CREATE_NOTE";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAILURE = "CREATE_NOTE_FAILURE";

export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILURE = "UPDATE_NOTE_FAILURE";

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILURE = "DELETE_NOTE_FAILURE";

// NETWORKING

export const resetNoteState = () => {
  return {
    type: RESET_NOTE_STATE,
  };
};

export const getNoteList = (page = 1, limit = 250) => {
  return {
    types: [GET_NOTE_LIST, GET_NOTE_LIST_SUCCESS, GET_NOTE_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getNote = (id) => {
  return {
    types: [GET_NOTE, GET_NOTE_SUCCESS, GET_NOTE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createNote = (data) => {
  return {
    types: [CREATE_NOTE, CREATE_NOTE_SUCCESS, CREATE_NOTE_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateNote = (id, data) => {
  return {
    types: [UPDATE_NOTE, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteNote = (id) => {
  return {
    types: [DELETE_NOTE, DELETE_NOTE_SUCCESS, DELETE_NOTE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};
