import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import TwoFactorSettings from "../TwoFactorSettings";

const Security = () => {
  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            size={"large"}
            content={"Two-step authentication"}
            subheader={
              "Increase security for your account by using multiple authentication steps."
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <TwoFactorSettings />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
export default Security;
