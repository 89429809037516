import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header, Image, List } from "semantic-ui-react";
import Attachment from "../../../../../../../../components/Attachment";
import { GALLERY_PATH } from "../../../../../../../../constants/paths";
import { Cell } from "../Cell";

const Insurance = ({ piece }) => {
  const {
    is_insured,
    insurance_value,
    insurance_notes,
    insurance_attachments,
  } = piece;

  if (
    !is_insured &&
    !insurance_value &&
    !insurance_notes &&
    (!insurance_attachments || insurance_attachments.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header content={"Insurance"} dividing />
        </Grid.Column>
        {insurance_value && (
          <Cell
            header={"Insurance Value"}
            content={piece.insuranceValueString()}
          />
        )}
        {
          insurance_notes && (
            <Cell
              header={"Insurance Notes"}
              content={insurance_notes}
            />
          )
        }
        {insurance_attachments && insurance_attachments.length > 0 && (
          <Grid.Column>
            {(insurance_attachments || []).map((attachment) => (
              <Attachment key={attachment._id} attachment={attachment} />
            ))}
          </Grid.Column>
        )}
      </Grid.Row>
    </>
  );
};

Insurance.propTypes = {
  piece: PropTypes.object,
};

export default Insurance;
