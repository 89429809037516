import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import ConditionConservationAttachments from "../Attachments/ConditionConservationAttachments";
import ConditionMaterialsAttachments from "../Attachments/ConditionMaterialsAttachments";
import ConditionManufacturerAttachments from "../Attachments/ConditionManufacturerAttachments";

const Condition = ({ piece, onUpdate }) => {
  const {
    condition_details,
    condition_conservation,
    condition_materials,
    condition_manufacturer,
  } = piece;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header floated={"left"} size="large" content="Condition" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form>
            <Form.Group>
              <Form.Field width={4}>
                <label>Condition</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name="condition_details"
                placeholder="Condition"
                defaultValue={condition_details}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field width={4}>
                <label>Conservation</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name={"condition_conservation"}
                placeholder="Conservation"
                defaultValue={condition_conservation}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <ConditionConservationAttachments
              attachments={piece.condition_conservation_attachments}
              onAttachmentsUpdated={(attachments) =>
                onUpdate({ condition_conservation_attachments: attachments })
              }
            />
            <Form.Group>
              <Form.Field width={4}>
                <label>Materials</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name={"condition_materials"}
                placeholder={"Materials"}
                defaultValue={condition_materials}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <ConditionMaterialsAttachments
              attachments={piece.condition_materials_attachments}
              onAttachmentsUpdated={(attachments) =>
                onUpdate({ condition_materials_attachments: attachments })
              }
            />
            <Form.Group>
              <Form.Field width={4}>
                <label>Manufacturer</label>
              </Form.Field>
              <Form.TextArea
                width={12}
                rows={4}
                name={"condition_manufacturer"}
                placeholder="Manufacturer"
                defaultValue={condition_manufacturer}
                onChange={(e, { name, value }) => onUpdate({ [name]: value })}
              />
            </Form.Group>
            <ConditionManufacturerAttachments
              attachments={piece.condition_manufacturer_attachments}
              onAttachmentsUpdated={(attachments) =>
                onUpdate({ condition_manufacturer_attachments: attachments })
              }
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Condition;
