import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Loader, Menu } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { updateCurrentUser } from "../../redux/actions/me";
import { useUser } from "../../contexts/UserContext";
import { useAccount } from "../../contexts/AccountContext";

const ClientSelector = ({ update }) => {
  const { currentUser } = useUser();
  const { account, loading: accountLoading } = useAccount();
  const [loading, setLoading] = useState(false);

  const switchUser = async (userID) => {
    setLoading(true);
    try {
      await update({ current_user: userID });
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  if (accountLoading) return <Loader />;
  if (!account) return null;
  const { users } = account;
  const keys = Object.keys(users);
  const selectedUser = users[currentUser.current_user_id || currentUser.uid];

  return (
    <Menu.Item>
      <Dropdown text={selectedUser.name} loading={loading}>
        <Dropdown.Menu>
          {(keys || [])
            .filter((key) => key !== currentUser.current_user_id)
            .map((key) => (
              <Dropdown.Item
                key={key}
                content={users[key].name}
                onClick={() => switchUser(key)}
              />
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

ClientSelector.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelector);
