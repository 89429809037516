import React from "react";
import Attachments from "../BasicInfo/Attachments";
import PropTypes from "prop-types";

const ConditionMaterialsAttachments = ({
  attachments,
  onAttachmentsUpdated,
}) => (
  <Attachments
    attachments={attachments}
    type={"condition_materials"}
    onAttachmentsUpdated={onAttachmentsUpdated}
  />
);

ConditionMaterialsAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
};

export default ConditionMaterialsAttachments;
