import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Image, Modal, Tab, Table } from "semantic-ui-react";
import { createLocation } from "../../../redux/actions/location";
import placeholder from "../../../images/profile_placeholder.png";
import ErrorMessage from "../../ErrorMessage";
import DropZone from "../../DropZone";
import { useLocations } from "../../../contexts/LocationsContext";

const SelectLocation = ({ location, onSelectLocation }) => {
  const [currentLocation, setCurrentLocation] = useState(location);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [error, setError] = useState(null);
  const { locations } = useLocations();
  const dispatch = useDispatch();

  const handleLocationSelect = (l) => {
    onSelectLocation(l);
    closeModal();
    setCurrentLocation(l);
  };

  const onLocationCreated = async () => {
    setIsCreating(true);
    const data = new FormData();
    data.append("name", name);
    data.append("file", file);
    data.append("about", about);

    try {
      await dispatch(createLocation(data));
      handleLocationSelect({
        name,
        about,
        small_image_url: file ? file.preview : placeholder,
      });
    } catch (e) {
      setError(e.toString());
    } finally {
      setIsCreating(false);
    }
  };

  const handleRemoveLocation = () => {
    setCurrentLocation(undefined);
    onSelectLocation(undefined);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsAdding(false);
    setError(null);
    setName("");
    setAbout("");
    setFile(null);
  };

  return (
    <Modal
      trigger={
        <div
          role="button"
          onClick={() => setModalOpen(true)}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Image src={currentLocation?.small_image_url || placeholder} avatar />
          <span style={{ marginRight: "10px" }}>
            {currentLocation?.name || "Select Location"}
          </span>
          {currentLocation && (
            <Button
              icon="remove"
              onClick={(e) => {
                e.stopPropagation(); // Prevents opening the modal
                handleRemoveLocation();
              }}
              size="mini"
            />
          )}
        </div>
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={closeModal}
      closeIcon
    >
      <Modal.Header
        content={isAdding ? "Create Location" : "Select Location"}
      />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        {isAdding ? (
          <Form>
            <Form.Field>
              <label>Image</label>
              <DropZone
                onDrop={(files) => setFile(files[0])}
                multiple={false}
                accept="image/*"
              />
            </Form.Field>
            <Form.Input
              label="Name"
              placeholder="Name"
              onChange={(e, { value }) => setName(value)}
            />
            <Form.TextArea
              label="About"
              placeholder="About"
              onChange={(e, { value }) => setAbout(value)}
            />
          </Form>
        ) : (
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Locations</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Button
                    icon="add"
                    content="New"
                    onClick={() => setIsAdding(true)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(locations ?? []).map((l) => (
                <Table.Row key={l._id} onClick={() => handleLocationSelect(l)}>
                  <Table.Cell>
                    <Image src={l.image_url || placeholder} avatar />
                    {l.name}
                  </Table.Cell>
                  <Table.Cell textAlign="right" />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
      {isAdding && (
        <Modal.Actions>
          <Button
            primary
            content="Save"
            loading={isCreating}
            onClick={onLocationCreated}
          />
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default SelectLocation;
