import React, { Component, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Header,
  Image,
  Loader,
  Segment,
} from "semantic-ui-react";
import {
  getInvitation,
  updateInvitation,
} from "../../../../../../redux/actions/invitation";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import artFair from "../../../../../../images/headers/artfair.png";

const InvitationDetail = ({ fetch, update }) => {
  const { invitationID } = useParams();

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  useEffect(() => {
    if (!invitationID) return;
    fetchData();
  }, [invitationID]);

  async function fetchData() {
    const data = await fetch(invitationID);
    setInvitation(data.payload.data);
  }

  const onSubmit = async (status) => {
    setError(null);

    switch (status) {
      case "accepted":
        setIsAccepting(true);
        break;
      case "declined":
        setIsDeclining(true);
        break;
      default:
        break;
    }

    try {
      await update(invitationID, { status });
      navigate("/");
    } catch (e) {
      setError(e);
    }

    setIsAccepting(false);
    setIsDeclining(false);
  };

  if (!invitation) {
    return <Loader active inline="centered" />;
  }

  return (
    <Grid centered style={{ height: "800px" }}>
      <Grid.Row verticalAlign={"middle"}>
        <Grid.Column largeScreen={6} tablet={8} mobile={16}>
          <Segment textAlign={"center"}>
            {error && <ErrorMessage error={error} />}
            <Image size={"huge"} src={artFair} />
            <Header
              size={"huge"}
              content={"Account Invitation"}
              subheader={`You have been invited to an account managed by ${
                invitation.created_by && invitation.created_by.name
              }`}
              textAlign={"center"}
            />
            <Button
              negative
              size={"large"}
              content={"Decline"}
              loading={isDeclining}
              onClick={() => onSubmit("declined")}
            />
            <Button
              positive
              size={"large"}
              content={"Accept"}
              loading={isAccepting}
              onClick={() => onSubmit("accepted")}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getInvitation(id)),
  update: (id, data) => dispatch(updateInvitation(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationDetail);
