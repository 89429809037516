import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header } from "semantic-ui-react";
import Attachment from "../../../../../../../../components/Attachment";

const Publications = ({ piece }) => {
  const { literature_text, literature_attachments } = piece;

  if (
    !literature_text &&
    (!literature_attachments || literature_attachments.length === 0)
  ) {
    return null;
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <Header content={"Literature"} dividing />
        <p>{literature_text}</p>
        {(literature_attachments || []).map((attachment) => (
          <Attachment key={attachment._id} attachment={attachment} />
        ))}
      </Grid.Column>
    </Grid.Row>
  );
};

Publications.propTypes = {
  piece: PropTypes.object,
};

export default Publications;
