import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { capitalize } from "lodash";
import { ARTIST_PATH } from "../../../../../../../../constants/paths";
import { Cell } from "../Cell";

const PieceInformation = ({ piece }) => {
  const {
    title,
    inventory_identifier,
    date,
    category,
    medium,
    artist,
    description,
    is_framed,
    is_signed,
    dimensionString,
    editionString,
    framedDimensionString,
    framing_attachments = [],
  } = piece;

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content="Information" dividing />
        <Cell header="Title" content={title || "Untitled"} />
        {date && <Cell header="Date" content={date} />}
        {inventory_identifier && (
          <Cell header="Inventory ID" content={inventory_identifier} />
        )}
        {artist && (
          <Cell
            header="Artist"
            content={artist.name}
            image={artist.small_image_url}
            link={`${ARTIST_PATH}/${artist._id}`}
          />
        )}
        {category && <Cell header="Type" content={capitalize(category)} />}
        {dimensionString && (
          <Cell header="Dimensions" content={dimensionString} />
        )}
        {is_framed &&
          (framedDimensionString || framing_attachments.length > 0) && (
            <Cell
              header="Framed Dimensions"
              content={framedDimensionString}
              attachments={framing_attachments}
            />
          )}
        {editionString && <Cell header="Edition" content={editionString} />}
        {is_signed && <Cell header="Signed" content="Yes" />}
        {medium && <Cell header="Medium" content={medium} />}
        {description && <Cell header="Description" content={description} />}
      </Grid.Column>
    </Grid.Row>
  );
};

PieceInformation.propTypes = {
  piece: PropTypes.shape({
    title: PropTypes.string,
    inventory_identifier: PropTypes.string,
    date: PropTypes.string,
    category: PropTypes.string,
    medium: PropTypes.string,
    artist: PropTypes.shape({
      name: PropTypes.string,
      small_image_url: PropTypes.string,
      _id: PropTypes.string,
    }),
    description: PropTypes.string,
    is_framed: PropTypes.bool,
    is_signed: PropTypes.bool,
    dimensionString: PropTypes.string,
    editionString: PropTypes.string,
    framedDimensionString: PropTypes.string,
    framing_attachments: PropTypes.array,
  }).isRequired,
};

export default PieceInformation;
