import React, { useState } from "react";
import { auth } from "../../../../utils/firebase";
import { Button, Form } from "semantic-ui-react";
import ErrorMessage from "../../../../components/ErrorMessage";

const PasswordReset = ({ oobCode, onChangeMode }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [password, setPassword] = useState(null);

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await auth.confirmPasswordReset(oobCode, password);
      onChangeMode("login");
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Form onSubmit={onSubmit}>
        <Form.Input
          label={"New Password"}
          type={"password"}
          autocomplete={"new-password"}
          placeholder={"New Password"}
          onChange={(e, { value }) => setPassword(value)}
        />
        <Form.Field>
          <Button
            fluid
            primary
            loading={loading}
            type={"submit"}
            content={"Confirm Password Reset"}
            size={"large"}
          />
        </Form.Field>
      </Form>
    </>
  );
};
export default PasswordReset;
