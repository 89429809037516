const url = "/api/v4/payment_sources";

export const GET_PAYMENT_SOURCE_LIST = "GET_PAYMENT_SOURCE_LIST";
export const GET_PAYMENT_SOURCE_LIST_SUCCESS =
  "GET_PAYMENT_SOURCE_LIST_SUCCESS";
export const GET_PAYMENT_SOURCE_LIST_FAILURE =
  "GET_PAYMENT_SOURCE_LIST_FAILURE";

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";

export const DELETE_PAYMENT_SOURCE = "DELETE_PAYMENT_SOURCE";
export const DELETE_PAYMENT_SOURCE_SUCCESS = "DELETE_PAYMENT_SOURCE_SUCCESS";
export const DELETE_PAYMENT_SOURCE_FAILURE = "DELETE_PAYMENT_SOURCE_FAILURE";

export const getPaymentList = () => {
  return {
    types: [
      GET_PAYMENT_SOURCE_LIST,
      GET_PAYMENT_SOURCE_LIST_SUCCESS,
      GET_PAYMENT_SOURCE_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
      },
    },
  };
};

export const createPayment = (data) => {
  return {
    types: [CREATE_PAYMENT, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const deletePaymentSource = (id, data) => {
  return {
    types: [
      DELETE_PAYMENT_SOURCE,
      DELETE_PAYMENT_SOURCE_SUCCESS,
      DELETE_PAYMENT_SOURCE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};
