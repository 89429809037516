import React, { useState } from "react";
import { Icon, Image, Segment, Header } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import ErrorMessage from "./ErrorMessage";

const DropZone = ({
  style,
  onDrop,
  multiple,
  accept,
  file,
  placeholder,
  icon,
  image,
  loading,
}) => {
  const [error, setError] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    maxSize: 100 * 1024 * 1024, // 100MB
    onDropAccepted: (acceptedFiles) => {
      setError(null); // Clear previous errors
      const filesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      onDrop(filesWithPreview);
    },
    onDropRejected: (fileRejections) => {
      // Display the first error from the rejected files
      setError(fileRejections[0]?.errors[0]?.message || "File rejected");
    },
  });

  return (
    <Segment
      textAlign="center"
      style={style}
      loading={loading}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {error && <ErrorMessage error={error.message} />}
      <Header as="h4" icon>
        {!file && !image && icon && <Icon name={icon} size="large" />}
        {!file && !image && placeholder && (
          <Header.Content>{placeholder}</Header.Content>
        )}
      </Header>
      {file && <p>{file.name}</p>}
      {image && <Image src={image} size="medium" centered />}
    </Segment>
  );
};

export default DropZone;
