import React from "react";
import { connect, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { createTag } from "../../redux/actions/tag";
import { useTags } from "../../contexts/TagsContext";

const TagFormField = ({
  defaultValue,
  auth,
  onChange,
  width,
  create,
  user,
}) => {
  const { tags } = useTags();

  const onAddItem = async (e, { value }) => {
    try {
      await create({ name: value });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Form.Select
      width={width}
      placeholder={"Select Tags"}
      search
      multiple
      defaultValue={defaultValue}
      allowAdditions
      onAddItem={onAddItem}
      onChange={(e, { value }) =>
        onChange(tags.filter((tag) => value.includes(tag._id)))
      }
      options={(tags || []).map((tag) => ({
        key: tag.id || tag._id,
        value: tag.id || tag._id,
        text: tag.name,
      }))}
    />
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createTag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagFormField);
