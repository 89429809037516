import {
  GET_DAILY_EVENT_LIST,
  GET_DAILY_EVENT_LIST_FAILURE,
  GET_DAILY_EVENT_LIST_SUCCESS,
} from "../actions/daily_event";

const INITIAL_STATE = {
  isLoadingDailyEvents: true,
  dailyEvents: [],
  hasMoreDailyEvents: true,
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_DAILY_EVENT_LIST:
      return {
        ...state,
        isLoadingDailyEvents: true,
        dailyEvents:
          action.payload.request.params.page <= 1 ? [] : state.dailyEvents,
      };
    case GET_DAILY_EVENT_LIST_SUCCESS:
      return {
        ...state,
        isLoadingDailyEvents: false,
        dailyEvents: state.dailyEvents.concat(action.payload.data),
        hasMoreDailyEvents: action.payload.data.length === 25,
      };

    case GET_DAILY_EVENT_LIST_FAILURE:
      return {
        ...state,
        isLoadingDailyEvents: false,
        error: action.error,
        hasMoreDailyEvents: false,
      };

    default:
      return state;
  }
}
