import React from "react";
import { Image, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import placeholder from "../../../../images/placeholder.png";
import moment from "moment";
import { AUCTION_HOUSE_PATH, AUCTION_PATH } from "../../../../constants/paths";

const AdminAuctionTile = ({ auction }) => {
  const navigate = useNavigate();

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const { _id, name, image_url, end_date, auction_house, state, reach } =
    auction;

  return (
    <Table.Row
      key={_id}
      warning={state === "documented" && moment(end_date) > moment()}
      negative={state === "documented" && moment(end_date) < moment()}
      positive={
        state !== "completed" &&
        state === "researched" &&
        moment(end_date) < moment()
      }
    >
      <Table.Cell onClick={(e) => onRowClick(e, `${AUCTION_PATH}/${_id}`)}>
        <Image src={image_url || placeholder} avatar />
        {name}
      </Table.Cell>
      <Table.Cell
        content={auction_house.name}
        onClick={(e) =>
          onRowClick(e, `${AUCTION_HOUSE_PATH}/${auction_house._id}`)
        }
      />
      <Table.Cell
        content={moment(end_date).format("MMM D, YYYY")}
        onClick={(e) => onRowClick(e, `${AUCTION_PATH}/${_id}`)}
      />
      <Table.Cell content={reach} />
      <Table.Cell content={state} />
    </Table.Row>
  );
};

export default AdminAuctionTile;
