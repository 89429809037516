import React from "react";
import { useSelector } from "react-redux";
import VerifyEmailMessage from "../../components/Messages/VerifyEmailMessage";
import UpgradeMessage from "../../components/Messages/UpgradeMessage";
import { useUser } from "../../contexts/UserContext";
import { useAuth } from "../../contexts/AuthContext";

const Banners = ({}) => {
  const { currentUser } = useUser();
  const { user } = useAuth();

  if (!currentUser) return null;
  if (currentUser && !user.emailVerified) return <VerifyEmailMessage />;
  if (currentUser && currentUser.account_level === "basic")
    return <UpgradeMessage />;
  return null;
};

export default Banners;
