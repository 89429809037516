import {
  CREATE_LOCATION,
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  GET_LOCATION,
  GET_LOCATION_FAILURE,
  GET_LOCATION_SUCCESS,
  GET_PIECE_LIST_FOR_LOCATION,
  GET_PIECE_LIST_FOR_LOCATION_FAILURE,
  GET_PIECE_LIST_FOR_LOCATION_SUCCESS,
  RESET_LOCATION_STATE,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
} from "../actions/location";
import { Mixpanel } from "../../utils/Mixpanel";
import Location from "../../models/location";

const INITIAL_STATE = {
  isLoadingLocationList: true,
  hasMoreLocations: true,

  isLoadingLocation: true,
  isCreatingLocation: false,
  isUpdatingLocation: false,
  isDeletingLocation: false,

  location: null,
  pieces: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_LOCATION_STATE:
      return INITIAL_STATE;

    case GET_LOCATION:
      return { ...INITIAL_STATE };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        isLoadingLocation: false,
        location: new Location(action.payload.data),
      };
    case GET_LOCATION_FAILURE:
      return {
        ...state,
        isLoadingLocation: false,
        error: action.error,
      };

    case CREATE_LOCATION:
      return {
        ...state,
        isCreatingLocation: true,
      };
    case CREATE_LOCATION_SUCCESS: {
      Mixpanel.track("location_created");

      return {
        ...state,
        isCreatingLocation: false,
        location: action.payload.data,
      };
    }

    case UPDATE_LOCATION:
      return {
        ...state,
        isUpdatingLocation: true,
      };
    case UPDATE_LOCATION_SUCCESS: {
      Mixpanel.track("location_updated");
      return {
        ...state,
        isUpdatingLocation: false,
        location: new Location(action.payload.data),
      };
    }
    case UPDATE_LOCATION_FAILURE: {
      return {
        ...state,
        isUpdatingLocation: false,
        error: action.error,
      };
    }

    case DELETE_LOCATION:
      return {
        ...state,
        isDeletingLocation: true,
      };
    case DELETE_LOCATION_SUCCESS: {
      Mixpanel.track("location_deleted");
      return {
        ...state,
        isDeletingLocation: false,
        location: action.payload.data,
      };
    }

    case GET_PIECE_LIST_FOR_LOCATION:
      return { ...state, isLoadingPieces: true };
    case GET_PIECE_LIST_FOR_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoadingPieces: false,
        pieces: [...state.pieces, ...action.payload.data],
        hasMorePieces: action.payload.data.length === 25,
      };
    }
    case GET_PIECE_LIST_FOR_LOCATION_FAILURE:
      return {
        ...state,
        isLoadingPieces: false,
        error: action.error,
        hasMorePieces: false,
      };

    default:
      return state;
  }
};
