import React, { useState } from "react";
import { Grid, Image, Table, Segment, Header, Button } from "semantic-ui-react";
import { Configure, InstantSearch } from "react-instantsearch/dom";
import { connectHits } from "react-instantsearch/connectors";
import placeholder from "../../images/profile_placeholder.png";
import SearchBox from "../SearchBox";
import AddGalleryModal from "../Modals/Gallery/Add/Modal";
import { searchClient } from "../../utils/algolia";

const CustomHits = connectHits(({ hits, onSelectGallery, search }) => {
  return (
    <>
      {hits && hits.length > 1 && (
        <Table selectable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {hits.map((hit) => (
              <Table.Row
                key={hit.objectID}
                onClick={(e) => onSelectGallery(hit)}
              >
                <Table.Cell>
                  <Image src={hit.small_image_url || placeholder} avatar />
                  {hit.name}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {(!hits || hits.length <= 5) && (
        <Segment textAlign={"center"}>
          <Header
            textAlign={"center"}
            content={"Gallery, not listed?"}
            subheader={"Please add them by clicking the button below"}
          />
          <AddGalleryModal
            defaultName={search}
            onGalleryCreated={(gallery) => onSelectGallery(gallery)}
          >
            <Button secondary size={"large"} content={"Create Gallery"} />
          </AddGalleryModal>
        </Segment>
      )}
    </>
  );
});

const SearchGallery = ({ onSelectGallery, addingDisabled }) => {
  const [search, setSearch] = useState("");

  return (
    <InstantSearch indexName={"gallery"} searchClient={searchClient}>
      <Configure hitsPerPage={7} />
      <Grid centered>
        <Grid.Row centered columns={1}>
          <Grid.Column>
            <SearchBox
              placeholer={"Search galleries..."}
              onSearchInputChanged={(s) => setSearch(s)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={1}>
          <Grid.Column>
            <CustomHits
              search={search}
              onSelectGallery={(hit) =>
                onSelectGallery({
                  _id: hit._id || hit.objectID,
                  name: hit.name,
                  image_url: hit.image_url,
                  medium_image_url: hit.medium_image_url,
                  small_image_url: hit.small_image_url,
                })
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InstantSearch>
  );
};

export default SearchGallery;
