const url = "/api/v4/museums";

// Actions
export const RESET_MUSEUM_STATE = "RESET_MUSEUM_STATE";

export const GET_MUSEUM_LIST = "GET_MUSEUM_LIST";
export const GET_MUSEUM_LIST_SUCCESS = "GET_MUSEUM_LIST_SUCCESS";
export const GET_MUSEUM_LIST_FAILURE = "GET_MUSEUM_LIST_FAILURE";

export const GET_MUSEUM = "GET_MUSEUM";
export const GET_MUSEUM_SUCCESS = "GET_MUSEUM_SUCCESS";
export const GET_MUSEUM_FAILURE = "GET_MUSEUM_FAILURE";

export const CREATE_MUSEUM = "CREATE_MUSEUM";
export const CREATE_MUSEUM_SUCCESS = "CREATE_MUSEUM_SUCCESS";
export const CREATE_MUSEUM_FAILURE = "CREATE_MUSEUM_FAILURE";

export const UPDATE_MUSEUM = "UPDATE_MUSEUM";
export const UPDATE_MUSEUM_SUCCESS = "UPDATE_MUSEUM_SUCCESS";
export const UPDATE_MUSEUM_FAILURE = "UPDATE_MUSEUM_FAILURE";

export const DELETE_MUSEUM = "DELETE_MUSEUM";
export const DELETE_MUSEUM_SUCCESS = "DELETE_MUSEUM_SUCCESS";
export const DELETE_MUSEUM_FAILURE = "DELETE_MUSEUM_FAILURE";

export const GET_MUSEUM_ARTWORK_LIST = "GET_MUSEUM_ARTWORK_LIST";
export const GET_MUSEUM_ARTWORK_LIST_SUCCESS =
  "GET_MUSEUM_ARTWORK_LIST_SUCCESS";
export const GET_MUSEUM_ARTWORK_LIST_FAILURE =
  "GET_MUSEUM_ARTWORK_LIST_FAILURE";

// Networking

export const getMuseumList = (page = 1, limit = 25) => {
  return {
    types: [GET_MUSEUM_LIST, GET_MUSEUM_LIST_SUCCESS, GET_MUSEUM_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getMuseum = (id) => {
  return {
    types: [GET_MUSEUM, GET_MUSEUM_SUCCESS, GET_MUSEUM_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createMuseum = (data) => {
  return {
    types: [CREATE_MUSEUM, CREATE_MUSEUM_SUCCESS, CREATE_MUSEUM_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateMuseum = (id, data) => {
  return {
    types: [UPDATE_MUSEUM, UPDATE_MUSEUM_SUCCESS, UPDATE_MUSEUM_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteMuseum = (id, data) => {
  return {
    types: [DELETE_MUSEUM, DELETE_MUSEUM_SUCCESS, DELETE_MUSEUM_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const getMuseumArtworksList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_MUSEUM_ARTWORK_LIST,
      GET_MUSEUM_ARTWORK_LIST_SUCCESS,
      GET_MUSEUM_ARTWORK_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/artworks`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetMuseumState = () => {
  return {
    type: RESET_MUSEUM_STATE,
  };
};
