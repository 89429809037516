const url = "/api/v4/auctions";

// Actions

export const RESET_AUCTION_STATE = "RESET_AUCTION_STATE";

export const GET_AUCTION_LIST_WITH_FILTER = "GET_AUCTION_LIST_WITH_FILTER";
export const GET_AUCTION_LIST_WITH_FILTER_SUCCESS =
  "GET_AUCTION_LIST_WITH_FILTER_SUCCESS";
export const GET_AUCTION_LIST_WITH_FILTER_FAILURE =
  "GET_AUCTION_LIST_WITH_FILTER_FAILURE";

export const GET_AUCTION_LIST = "GET_AUCTION_LIST";
export const GET_AUCTION_LIST_SUCCESS = "GET_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_LIST_FAILURE = "GET_AUCTION_LIST_FAILURE";

export const GET_AUCTION = "GET_AUCTION";
export const GET_AUCTION_SUCCESS = "GET_AUCTION_SUCCESS";
export const GET_AUCTION_FAILURE = "GET_AUCTION_FAILURE";

export const CREATE_AUCTION = "CREATE_AUCTION";
export const CREATE_AUCTION_SUCCESS = "CREATE_AUCTION_SUCCESS";
export const CREATE_AUCTION_FAILURE = "CREATE_AUCTION_FAILURE";

export const UPDATE_AUCTION = "UPDATE_AUCTION";
export const UPDATE_AUCTION_SUCCESS = "UPDATE_AUCTION_SUCCESS";
export const UPDATE_AUCTION_FAILURE = "UPDATE_AUCTION_FAILURE";

export const REMOVE_AUCTION = "REMOVE_AUCTION";
export const REMOVE_AUCTION_SUCCESS = "REMOVE_AUCTION_SUCCESS";
export const REMOVE_AUCTION_FAILURE = "REMOVE_AUCTION_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_AUCTION =
  "GET_AUCTION_RECORDS_LIST_FOR_AUCTION";
export const GET_AUCTION_RECORDS_LIST_FOR_AUCTION_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_AUCTION_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_AUCTION_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_AUCTION_FAILURE";

// Networking

export const getAuctionsWithFilter = (filter = "past") => {
  return {
    types: [
      GET_AUCTION_LIST_WITH_FILTER,
      GET_AUCTION_LIST_WITH_FILTER_SUCCESS,
      GET_AUCTION_LIST_WITH_FILTER_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          filter,
        },
      },
    },
  };
};

export const getAuctionList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_AUCTION_LIST,
      GET_AUCTION_LIST_SUCCESS,
      GET_AUCTION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getAuction = (id) => {
  return {
    types: [GET_AUCTION, GET_AUCTION_SUCCESS, GET_AUCTION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createAuction = (data) => {
  return {
    types: [CREATE_AUCTION, CREATE_AUCTION_SUCCESS, CREATE_AUCTION_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAuction = (id, data) => {
  return {
    types: [UPDATE_AUCTION, UPDATE_AUCTION_SUCCESS, UPDATE_AUCTION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteAuction = (id) => {
  return {
    types: [REMOVE_AUCTION, REMOVE_AUCTION_SUCCESS, REMOVE_AUCTION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getAuctionRecordsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_AUCTION_RECORDS_LIST_FOR_AUCTION,
      GET_AUCTION_RECORDS_LIST_FOR_AUCTION_SUCCESS,
      GET_AUCTION_RECORDS_LIST_FOR_AUCTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/auction_records`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetAuctionState = () => {
  return {
    type: RESET_AUCTION_STATE,
  };
};
