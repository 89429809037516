import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Header,
  Loader,
  Button,
  Image,
  Segment,
  Card,
  Breadcrumb,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getGroupings, getArtist } from "../../redux/actions/artist";
import ErrorMessage from "../../components/ErrorMessage";
import {
  ARTIST_PATH,
  DASHBOARD_PATH,
  GROUPINGS_PATH,
} from "../../constants/paths";
import EditGroupingSidePane from "../GroupingDetail/components/EditGroupingSidePane";

const ArtistGroupingList = ({ fetchArtist, fetchGroupings }) => {
  const { artistId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [artist, setArtist] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    try {
      const artistResponse = await fetchArtist(artistId);
      setArtist(artistResponse.payload.data);

      const groupingsResponse = await fetchGroupings(artistId);
      setData(groupingsResponse.payload.data);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!artistId) return;
    fetch();
  }, [artistId]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Segment basic>
            <Breadcrumb>
              <Breadcrumb.Section
                as={Link}
                to={DASHBOARD_PATH}
                content={"Home"}
              />
              <Breadcrumb.Divider />
              <Breadcrumb.Section
                as={Link}
                to={ARTIST_PATH}
                content={"Artists"}
              />
              <Breadcrumb.Divider />
              {artist && artist.name && (
                <Breadcrumb.Section
                  as={Link}
                  to={`${ARTIST_PATH}/${artistId}`}
                  content={artist && artist.name}
                />
              )}
              <Breadcrumb.Divider />
              <Breadcrumb.Section active content={"Collections"} />
            </Breadcrumb>
          </Segment>
        </Grid.Column>
        <Grid.Column textAlign={"right"}>
          <EditGroupingSidePane
            grouping={{
              artist,
            }}
          >
            <Button
              color={"black"}
              icon={"plus"}
              content={"New Collection"}
              labelPosition={"left"}
              floated={"right"}
            />
          </EditGroupingSidePane>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as={"h1"} content={`${artist && artist.name} Collections`} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          {error && <ErrorMessage error={error} />}
          {isLoading && <Loader active inline={"centered"} />}
          {!isLoading && data.length === 0 && (
            <Segment>
              <Header
                textAlign={"center"}
                size={"large"}
                content={"No Series"}
                subheader={
                  "We haven't identified any series for this artist yet"
                }
              />
            </Segment>
          )}
          {!isLoading && data.length > 0 && (
            <Card.Group stackable itemsPerRow={4}>
              {data.map((grouping, index) => (
                <Card
                  key={grouping._id}
                  onClick={() => navigate(`${GROUPINGS_PATH}/${grouping._id}`)}
                >
                  <Image
                    src={grouping.medium_image_url}
                    height={200}
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      width: "100%",
                    }}
                    alt={grouping.title}
                  />
                  <Card.Content>
                    <Card.Header>{grouping.title}</Card.Header>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ firebase, artist }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchArtist: (id) => dispatch(getArtist(id)),
  fetchGroupings: (id) => dispatch(getGroupings(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistGroupingList);
