import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";

import { createMuseum } from "../../../../redux/actions/museum";
// // Components
import EditMuseumForm from "../../../Forms/EditMuseumForm";
import { connect } from "react-redux";
import ErrorMessage from "../../../ErrorMessage";

class Add extends Component {
  state = {
    modalOpen: this.props.open,
    museum: this.props.museum,
  };

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleClose = () => this.setState({ modalOpen: false });
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  onSubmit() {
    let data = this.child.getFormData();

    this.setState({ updating: true, error: null });
    this.props
      .create(data)
      .then((data) => {
        this.setState((state) => ({ updating: false }));
        this.props.onMuseumCreated(data.payload.data);
      })
      .catch((error) =>
        this.setState((state) => ({
          ...state,
          error: error.error.response.data,
          updating: false,
        })),
      );
  }

  render() {
    const { museum } = this.props;
    const { updating, error, modalOpen } = this.state;

    return (
      <Modal
        open={modalOpen}
        trigger={
          <Button
            fluid
            primary
            icon="add"
            content="New"
            labelPosition="left"
            onClick={this.handleOpen}
          />
        }
        closeOnDimmerClick={false}
        onClose={this.props.handleClose}
        size={"large"}
        closeIcon
      >
        <Modal.Header content="Add Museum" />
        <Modal.Content>
          {error && <ErrorMessage error={error} />}
          <EditMuseumForm museum={museum} onRef={(ref) => (this.child = ref)} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            negative
            icon="remove"
            content="Cancel"
            onClick={this.handleClose}
          />
          <Button
            basic
            positive
            icon="checkmark"
            loading={updating}
            content="Submit"
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createMuseum(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
