import {
  GET_AUCTION_HOUSE,
  GET_AUCTION_HOUSE_AUCTION_LIST,
  GET_AUCTION_HOUSE_AUCTION_LIST_FAILURE,
  GET_AUCTION_HOUSE_AUCTION_LIST_SUCCESS,
  GET_AUCTION_HOUSE_FAILURE,
  GET_AUCTION_HOUSE_SUCCESS,
  GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST,
  GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_FAILURE,
  GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_SUCCESS,
  RESET_AUCTION_HOUSE_STATE,
} from "../actions/auction_house";

const INITIAL_STATE = {
  isLoadingAuctionsList: true,
  auctions: [],
  hasMoreAuctions: true,

  isLoadingUpcomingAuctionsList: true,
  upcomingAuctions: [],
  hasMoreUpcomingAuctions: true,

  isLoadingAuctionHouse: true,
  auctionHouse: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_AUCTION_HOUSE_STATE: {
      return { INITIAL_STATE };
    }
    case GET_AUCTION_HOUSE_AUCTION_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state };
      }
      return { ...state };
    case GET_AUCTION_HOUSE_AUCTION_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAuctionsList: false,
        auctions: [...state.auctions, ...action.payload.data],
        hasMoreAuctions: action.payload.data.length === 25,
      };
    case GET_AUCTION_HOUSE_AUCTION_LIST_FAILURE:
      return {
        ...state,
        isLoadingAuctionsList: false,
        error: action.error,
        hasMoreAuctions: false,
      };

    case GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state };
      }
      return { ...state };
    case GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_SUCCESS:
      return {
        ...state,
        isLoadingUpcomingAuctionsList: false,
        upcomingAuctions: [...state.upcomingAuctions, ...action.payload.data],
        hasMoreUpcomingAuctions: action.payload.data.length === 25,
      };
    case GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_FAILURE:
      return {
        ...state,
        isLoadingUpcomingAuctionsList: false,
        error: action.error,
        hasMoreUpcomingAuctions: false,
      };

    case GET_AUCTION_HOUSE:
      return { ...INITIAL_STATE };
    case GET_AUCTION_HOUSE_SUCCESS:
      return {
        ...state,
        isLoadingAuctionHouse: false,
        auctionHouse: action.payload.data,
      };
    case GET_AUCTION_HOUSE_FAILURE:
      return {
        ...state,
        isLoadingAuctionHouse: false,
        error: action.error,
      };

    default:
      return state;
  }
};
