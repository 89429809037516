const url = "/api/v4/complaints";

// Actions
const CREATE_COMPLAINT = "CREATE_COMPLAINT";
const CREATE_COMPLAINT_SUCCESS = "CREATE_COMPLAINT_SUCCESS";
const CREATE_COMPLAINT_FAILURE = "CREATE_COMPLAINT_FAILURE";

const UPDATE_COMPLAINT = "UPDATE_COMPLAINT";
const UPDATE_COMPLAINT_SUCCESS = "UPDATE_COMPLAINT_SUCCESS";
const UPDATE_COMPLAINT_FAILURE = "UPDATE_COMPLAINT_FAILURE";

const FETCH_COMPLAINT = "FETCH_COMPLAINT";
const FETCH_COMPLAINT_SUCCESS = "FETCH_COMPLAINT_SUCCESS";
const FETCH_COMPLAINT_FAILURE = "FETCH_COMPLAINT_FAILURE";

const FETCH_COMPLAINTS = "FETCH_COMPLAINTS";
const FETCH_COMPLAINTS_SUCCESS = "FETCH_COMPLAINTS_SUCCESS";
const FETCH_COMPLAINTS_FAILURE = "FETCH_COMPLAINTS_FAILURE";

const REMOVE_COMPLAINT = "REMOVE_COMPLAINT";
const REMOVE_COMPLAINT_SUCCESS = "REMOVE_COMPLAINT_SUCCESS";
const REMOVE_COMPLAINT_FAILURE = "REMOVE_COMPLAINT_FAILURE";

const BAN_COMPLAINT = "BAN_COMPLAINT";
const BAN_COMPLAINT_SUCCESS = "BAN_COMPLAINT_SUCCESS";
const BAN_COMPLAINT_FAILURE = "BAN_COMPLAINT_FAILURE";

const initialState = {
  loading: true,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_COMPLAINTS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case FETCH_COMPLAINTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        complaints: action.payload.data,
        error: null,
      };
    }

    case FETCH_COMPLAINTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CREATE_COMPLAINT_SUCCESS: {
      return {
        ...state,
        complaints: [action.payload.data, ...state.complaints],
      };
    }

    case UPDATE_COMPLAINT_FAILURE:
    case CREATE_COMPLAINT_FAILURE:
    case REMOVE_COMPLAINT_FAILURE:
    case REMOVE_COMPLAINT:
    case CREATE_COMPLAINT:
    case UPDATE_COMPLAINT:
    case FETCH_COMPLAINT: {
      return {
        ...state,
      };
    }

    case UPDATE_COMPLAINT_SUCCESS:
    case FETCH_COMPLAINT_SUCCESS: {
      return {
        ...state,
        complaints: (state.complaints || [action.payload.data]).map(
          (complaint) => {
            if (complaint._id === action.payload.data._id) {
              return action.payload.data;
            }
            return complaint;
          },
        ),
      };
    }

    case REMOVE_COMPLAINT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        complaints: state.complaints.filter(
          (complaint) => complaint._id !== action.payload.data._id,
        ),
        error: null,
      };
    }

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.complaints && globalState.complaints.loaded;
}

export function create(data) {
  return {
    types: [
      CREATE_COMPLAINT,
      CREATE_COMPLAINT_SUCCESS,
      CREATE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
}

export function update(id, data) {
  return {
    types: [
      UPDATE_COMPLAINT,
      UPDATE_COMPLAINT_SUCCESS,
      UPDATE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
}

export function fetchAll(status = "pending") {
  return {
    types: [
      FETCH_COMPLAINTS,
      FETCH_COMPLAINTS_SUCCESS,
      FETCH_COMPLAINTS_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}?status=${status}`,
      },
    },
  };
}

export function fetch(id) {
  return {
    types: [FETCH_COMPLAINT, FETCH_COMPLAINT_SUCCESS, FETCH_COMPLAINT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
}

export function remove(id) {
  return {
    types: [
      REMOVE_COMPLAINT,
      REMOVE_COMPLAINT_SUCCESS,
      REMOVE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
}

export function ban(id) {
  return {
    types: [BAN_COMPLAINT, BAN_COMPLAINT_SUCCESS, BAN_COMPLAINT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}/ban`,
      },
    },
  };
}
