import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { capitalize } from "lodash";

const NonAuthLayout = ({ children }) => {
  const location = useLocation();
  const title =
    location.pathname === "/" ? "Home" : location.pathname.replace("/", "");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{capitalize(title)}</title>
      </Helmet>
      <div style={{ height: "100%" }}>{children}</div>
    </>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NonAuthLayout;
