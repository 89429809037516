class Artist {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;

    this.name = data.name;

    this.image_url = data.image_url;
    this.medium_image_url = data.medium_image_url;
    this.small_image_url = data.small_image_url;

    this.birth_year = data.birth_year;
    this.birth_date = data.birth_date;
    this.death_year = data.death_year;
    this.death_date = data.death_date;

    this.nationality = data.nationality;
    this.gender = data.gender;
    this.biography = data.biography;
    this.website_url = data.website_url;
    this.email = data.email;
    this.phone = data.phone;
    this.instagram_username = data.instagram_username;
    this.twitter_username = data.twitter_username;
    this.counts = data.counts;
  }
}

export default Artist;
