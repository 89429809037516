import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";

const Additional = ({ piece, onUpdate }) => {
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });

  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <label>Provenance</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="provenance_text"
          placeholder="Provenance"
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Exhibited</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="exhibited_text"
          placeholder="Exhibited"
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Literature</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="literature_text"
          placeholder="Literature"
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="notes"
          placeholder="Notes"
          onChange={handleChange}
        />
      </Form.Group>
    </Form>
  );
};

export default Additional;
