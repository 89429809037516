import {
  GET_ARTWORK_LIST,
  GET_ARTWORK_LIST_FAILURE,
  GET_ARTWORK_LIST_SUCCESS,
} from "../actions/artwork";

const INITIAL_STATE = {
  isLoadingArtworkList: true,
  artworks: [],
  hasMoreArtworks: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTWORK_LIST:
      return {
        ...state,
        isLoadingArtworkList: true,
        hasMoreArtworks: true,
        artworks:
          action.payload.request.params.page === 1 ? [] : state.artworks,
      };
    case GET_ARTWORK_LIST_SUCCESS:
      return {
        ...state,
        isLoadingArtworkList: false,
        artworks: state.artworks.concat(action.payload.data),
        hasMoreArtworks: action.payload.length > 0,
      };

    case GET_ARTWORK_LIST_FAILURE:
      return {
        ...state,
        isLoadingArtworkList: false,
        hasMoreArtworks: false,
      };
    default:
      return state;
  }
};
