const url = "/api/v5/locations";

// Actions

export const RESET_LOCATION_STATE = "RESET_LOCATION_STATE";

export const GET_LOCATION_LIST = "GET_LOCATION_LIST";
export const GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_FAILURE = "GET_LOCATION_LIST_FAILURE";

export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";

export const GET_PIECE_LIST_FOR_LOCATION = "GET_PIECE_LIST_FOR_LOCATION";
export const GET_PIECE_LIST_FOR_LOCATION_SUCCESS =
  "GET_PIECE_LIST_FOR_LOCATION_SUCCESS";
export const GET_PIECE_LIST_FOR_LOCATION_FAILURE =
  "GET_PIECE_LIST_FOR_LOCATION_FAILURE";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILURE = "CREATE_LOCATION_FAILURE";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILURE = "UPDATE_LOCATION_FAILURE";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILURE = "DELETE_LOCATION_FAILURE";

export const SORT_LOCATION_LIST_BY_KEY = "SORT_LOCATION_LIST_BY_KEY";
export const FILTER_LOCATION_LIST_BY_TEXT = "FILTER_LOCATION_LIST_BY_TEXT";

export const SET_LOCATION_ORDER_BY = "SET_LOCATION_ORDER_BY";
export const TOGGLE_LOCATION_LIST_DISPLAY = "TOGGLE_LOCATION_LIST_DISPLAY";

// Networking

export const getLocationList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_LOCATION_LIST,
      GET_LOCATION_LIST_SUCCESS,
      GET_LOCATION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const createLocation = (data) => {
  return {
    types: [CREATE_LOCATION, CREATE_LOCATION_SUCCESS, CREATE_LOCATION_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateLocation = (id, data) => {
  return {
    types: [UPDATE_LOCATION, UPDATE_LOCATION_SUCCESS, UPDATE_LOCATION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteLocation = (id, data) => {
  return {
    types: [DELETE_LOCATION, DELETE_LOCATION_SUCCESS, DELETE_LOCATION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const getLocation = (id) => {
  return {
    types: [GET_LOCATION, GET_LOCATION_SUCCESS, GET_LOCATION_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const getLocationPiecesList = (id) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_LOCATION,
      GET_PIECE_LIST_FOR_LOCATION_SUCCESS,
      GET_PIECE_LIST_FOR_LOCATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pieces`,
      },
    },
  };
};

export const sortLocationListByKey = (clickedColumn) => {
  return {
    type: SORT_LOCATION_LIST_BY_KEY,
    clickedColumn,
  };
};

export const filterLocationListByText = (search) => {
  return {
    type: FILTER_LOCATION_LIST_BY_TEXT,
    search,
  };
};

export const toggleLocationListDisplay = (display) => {
  return {
    type: TOGGLE_LOCATION_LIST_DISPLAY,
    display,
  };
};

export const resetLocationState = () => {
  return {
    type: RESET_LOCATION_STATE,
  };
};

export const setOrderBy = (orderBy) => {
  return {
    type: SET_LOCATION_ORDER_BY,
    orderBy,
  };
};
