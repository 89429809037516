import React from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import { getAuctionRecordsList } from "../../../../../../redux/actions/auction";
import AuctionAuctionRecordTile from "../AuctionAuctionRecordTile";
import InfiniteScroll from "react-infinite-scroller";
import AuctionRecord from "../../../../../../models/auction_record";
import { useCurrencies } from "../../../../../../contexts/CurrenciesContext";

const AuctionAuctionRecords = ({
  auction_records,
  auction,
  user,
  hasMoreItems,
  fetchAuctionRecords,
}) => {
  const { currencies } = useCurrencies();

  const items = (auction_records || []).map((data) => (
    <AuctionAuctionRecordTile
      key={data._id}
      auctionRecord={new AuctionRecord(data)}
      user={user}
      currencies={currencies}
    />
  ));

  return (
    <InfiniteScroll
      initialLoad={true}
      loadMore={(page) => fetchAuctionRecords(auction._id, page)}
      hasMore={hasMoreItems}
      loader={
        <Header
          key={"loader"}
          textAlign={"center"}
          size={"small"}
          content={"Loading ..."}
        />
      }
    >
      {items.length !== 0 && (
        <Table selectable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} content={"Lot"} />
              <Table.HeaderCell width={5} content={"Artwork"} />
              <Table.HeaderCell width={3} content={"Artist"} />
              <Table.HeaderCell width={3} content={"Estimate"} />
              <Table.HeaderCell width={2} content={"Sales Price"} />
            </Table.Row>
          </Table.Header>
          <Table.Body>{items}</Table.Body>
        </Table>
      )}
    </InfiniteScroll>
  );
};

const mapStateToProps = ({ auction }) => ({
  auction: auction.auction,
  hasMoreItems: auction.hasMoreAuctionRecords,
  auction_records: auction.auction_records,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuctionRecords: (auction, page) =>
    dispatch(getAuctionRecordsList(auction, page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuctionAuctionRecords);
