const url = "/api/v5/images";

export const CREATE_IMAGE = "CREATE_IMAGE";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";
export const CREATE_IMAGE_FAILURE = "CREATE_IMAGE_FAILURE";

export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const createImage = (data) => {
  return {
    types: [CREATE_IMAGE, CREATE_IMAGE_SUCCESS, CREATE_IMAGE_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const deleteImage = (id) => {
  return {
    types: [DELETE_IMAGE, DELETE_IMAGE_SUCCESS, DELETE_IMAGE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};
