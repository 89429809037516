import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useUser } from "./UserContext";
import { firestore } from "../utils/firebase";
import { onSnapshot, query, collection } from "firebase/firestore";
import { set } from "lodash";

const LocationsContext = createContext(undefined);

export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
};

export const LocationsProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!currentUser) return;
    const docRef = query(
      collection(
        firestore,
        "locations",
        currentUser.current_user_id || currentUser._id,
        "locations",
      ),
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        const locationsData = docSnapshot.docs.map((doc) => ({
          ...doc.data(),
          _id: doc.id,
        }));
        setLocations(locationsData);
        setLoading(false);
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
        setLoading(false);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  return (
    <LocationsContext.Provider
      value={{
        locations,
        loading,
        error,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
