import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Confirm,
  Grid,
  Header,
  Image,
  Loader,
  Segment,
  Transition,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteLocation,
  getLocation,
  resetLocationState,
} from "../../../../../../redux/actions/location";
import placeholder from "../../../../../../images/profile_placeholder.png";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import EditLocationSidePane from "../../../../components/EditLocationSidePane";
import LocationPieceRecords from "../LocationPieceRecords";
import AuthLayout from "../../../../../../layouts/AuthLayout";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import {
  DASHBOARD_PATH,
  LOCATION_PATH,
} from "../../../../../../constants/paths";
import { useLocations } from "../../../../../../contexts/LocationsContext";

const LocationDetail = ({ fetch, reset, remove }) => {
  const navigate = useNavigate();
  const { locations, loading } = useLocations();
  const { locationId } = useParams();
  const [error, setError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const location = locations.find((location) => location._id === locationId);

  const handleDelete = async () => {
    setError(null);
    try {
      await remove(locationId);
      navigate(-1);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <AuthLayout
      title={location?.name || "Loading..."}
      description={location?.about?.slice(0, 100)}
    >
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                <Breadcrumb>
                  <Breadcrumb.Section
                    as={Link}
                    to={DASHBOARD_PATH}
                    content="Home"
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    as={Link}
                    to={LOCATION_PATH}
                    content="Locations"
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active content={location?.name} />
                </Breadcrumb>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Button
                basic
                floated="right"
                onClick={() => setConfirmOpen(true)}
                content="Delete Location"
                icon="close"
                labelPosition="left"
                aria-label="Delete Location"
              />
              <Confirm
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onConfirm={handleDelete}
                content="Are you sure you want to delete this Location?"
              />
              {location && (
                <EditLocationSidePane location={location}>
                  <Button
                    basic
                    floated="right"
                    icon="edit"
                    content="Edit Location"
                    labelPosition="left"
                    aria-label="Edit Location"
                  />
                </EditLocationSidePane>
              )}
            </Grid.Column>
          </Grid.Row>

          {error && (
            <Grid.Row>
              <Grid.Column width={16}>
                <ErrorMessage error={error} />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column width={8}>
              <Transition.Group
                style={{ maxHeight: "40em", minHeight: "20em" }}
                as={Image}
                animation="fade left"
                duration={600}
                visible={!!location}
                centered
                src={location?.image_url || placeholder}
              />
              <div style={{ textAlign: "center", marginTop: "1em" }}>
                {location?.website_url && (
                  <Button
                    basic
                    icon="globe"
                    labelPosition="left"
                    content="View Website"
                    as="a"
                    href={location.website_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="View Website"
                  />
                )}
                {location?.email && (
                  <Button
                    basic
                    icon="mail"
                    labelPosition="left"
                    content="Contact Location"
                    as="a"
                    href={`mailto:${location.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Contact Location"
                  />
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment basic>
                <Transition.Group
                  animation="fade down"
                  duration={600}
                  visible={!!location}
                >
                  <Header size="huge" content={location?.name} />
                </Transition.Group>
                {location?.about && (
                  <Transition.Group
                    animation="fade up"
                    duration={600}
                    visible={!!location}
                  >
                    <p>{location.about}</p>
                  </Transition.Group>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {location && <LocationPieceRecords locationId={locationId} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, location }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getLocation(id)),
  remove: (id) => dispatch(deleteLocation(id)),
  reset: () => dispatch(resetLocationState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);
