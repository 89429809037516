import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Grid, Header, Image, Table } from "semantic-ui-react";
import { deleteTag } from "../../../../../../redux/actions/tag";
import Tag from "../../../../../../models/tag";
import AddTagModal from "./AddTagModal";
import TagCell from "./TagCell";
import { useTags } from "../../../../../../contexts/TagsContext";

const Tags = ({}) => {
  const { tags } = useTags();

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header size={"huge"} content={"Tags"} dividing />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Table>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell content={"Name"} />
                <Table.HeaderCell content={"Number of Pieces"} />
                <Table.HeaderCell textAlign={"center"}>
                  <AddTagModal />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(tags || [])
                .map((tag) => new Tag(tag))
                .map((tag) => (
                  <TagCell key={tag._id} tag={tag} />
                ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteTag(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
