const url = "/api/v5/analytics";

// Actions

export const GET_ANALYTICS = "GET_ANALYTICS";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAILURE = "GET_ANALYTICS_FAILURE";

export const GET_PREDICTED_VALUE = "GET_PREDICTED_VALUE";
export const GET_PREDICTED_VALUE_SUCCESS = "GET_PREDICTED_VALUE_SUCCESS";
export const GET_PREDICTED_VALUE_FAILURE = "GET_PREDICTED_VALUE_FAILURE";

// Networking

export const getAnalytics = (currency) => {
  return {
    types: [GET_ANALYTICS, GET_ANALYTICS_SUCCESS, GET_ANALYTICS_FAILURE],
    payload: {
      request: {
        url,
        params: {
          currency,
        },
      },
    },
  };
};

export const getPredictedValue = (currency) => {
  return {
    types: [
      GET_PREDICTED_VALUE,
      GET_PREDICTED_VALUE_SUCCESS,
      GET_PREDICTED_VALUE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/predicted_value`,
        params: {
          currency,
        },
      },
    },
  };
};
