import React, { useState } from "react";
import { connect } from "react-redux";
import { auth } from "../../../../utils/firebase";
import { Button, Form, Message, Header } from "semantic-ui-react";
import ErrorMessage from "../../../../components/ErrorMessage";

const PasswordReset = ({ onChangeMode }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const [email, setEmail] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await auth.sendPasswordResetEmail(email);
      setEmailSent(true);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {emailSent && (
        <Message
          positive
          header={"Password Reset"}
          content={
            "An email has been sent to your account with instructions on how to reset your password."
          }
        />
      )}
      {!emailSent && (
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <Header
              size="medium"
              content={"Reset Password?"}
              subheader="Enter your email and we'll send a code to reset your password."
            />
          </Form.Field>
          <Form.Input
            name="email"
            label="Email"
            placeholder="Email"
            onChange={(e, { value }) => setEmail(value)}
          />
          <Form.Field>
            <Button
              fluid
              color="black"
              loading={loading}
              type={"submit"}
              content={"Continue"}
              size={"large"}
            />
          </Form.Field>
          <Form.Field style={{ textAlign: "center" }}>
            <a onClick={() => onChangeMode("login")}>
              Wait, I remember my password
            </a>
          </Form.Field>
        </Form>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
