import {
  GET_PIECE_LIST_FOR_USER,
  GET_PIECE_LIST_FOR_USER_FAILURE,
  GET_PIECE_LIST_FOR_USER_SUCCESS,
  GET_USER,
  GET_USER_FAILURE,
  GET_USER_LIST,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_SUCCESS,
  GET_USER_SUCCESS,
} from "../actions/user";

const INITIAL_STATE = {
  isLoadingUserList: true,
  users: [],
  hasMoreUsers: true,

  isLoadingPieces: true,
  pieces: [],
  hasMorePieces: true,

  error: null,

  isLoadingUser: true,
  user: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
      break;
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isLoadingUserList: false,
        users: [...state.users, ...action.payload.data],
        hasMoreUsers: action.payload.data.length === 25,
      };
      break;
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        isLoadingUserList: false,
        error: action.error,
        hasMoreUsers: false,
      };

    case GET_USER:
      return { ...INITIAL_STATE };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionPieces: true,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoadingUser: false,
        error: action.error,
      };

    case GET_PIECE_LIST_FOR_USER:
      return { ...state };
    case GET_PIECE_LIST_FOR_USER_SUCCESS:
      return {
        ...state,
        isLoadingPieces: false,
        pieces: [...state.pieces, ...action.payload.data],
        hasMorePieces: action.payload.data.length === 25,
      };
    case GET_PIECE_LIST_FOR_USER_FAILURE:
      return {
        ...state,
        isLoadingPieces: false,
        error: action.error,
        hasMorePieces: false,
      };
    default:
      return state;
  }
};
