import { priceString } from "../utils/string";

class Appraisal {
  constructor(data) {
    this._id = data._id;
    this.date = data.date;
    this.url = data.url;
    this.mime_type = data.mime_type;
    this.value = data.value;
    this.file_reference = data.file_reference;
    this.notes = data.notes;
  }

  valueString() {
    return this.value && priceString(this.value.value, this.value.currency);
  }
}

export default Appraisal;
