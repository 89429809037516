import React from "react";
import { Card } from "semantic-ui-react";
import { AUCTION_RECORD_PATH } from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const AuctionRecordsGrid = ({ auctionRecords }) => {
  const navigate = useNavigate();
  return (
    <Card.Group stackable centered itemsPerRow={4}>
      {auctionRecords.map((hit) => (
        <Card
          key={hit._id}
          onClick={(e) => navigate(`${AUCTION_RECORD_PATH}/${hit._id}`)}
        >
          <img
            src={hit.medium_image_url}
            height={386}
            style={{ objectFit: "cover" }}
            alt={hit.title}
          />
          <Card.Content>
            <Card.Header>{hit.title}</Card.Header>
            <Card.Description>{hit.artist && hit.artist.name}</Card.Description>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
};

export default AuctionRecordsGrid;
