import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { clean } from "../../../../../../utils/utils";
import { updateCurrentUser } from "../../../../../../redux/actions/me";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import {
  currencyOptions,
  languageOptions,
  measurementOptions,
} from "../../../../../../common";
import { useUser } from "../../../../../../contexts/UserContext";

const EditPreferences = ({ update }) => {
  const { currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [preferredLanguage, setPreferredLanguage] = useState(
    currentUser && currentUser.preferred_language,
  );
  const [preferredCurrency, setPreferredCurrency] = useState(
    currentUser && currentUser.preferred_currency,
  );
  const [preferredUnits, setPreferredUnits] = useState(
    currentUser && currentUser.preferred_units,
  );

  useEffect(() => {
    setPreferredLanguage(currentUser && currentUser.preferred_language);
    setPreferredCurrency(currentUser && currentUser.preferred_currency);
    setPreferredUnits(currentUser && currentUser.preferred_units);
  }, [currentUser]);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = clean({
      preferred_language: preferredLanguage,
      preferred_currency: preferredCurrency,
      preferred_units: preferredUnits,
    });

    var formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      await update(formData);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header size={"huge"} content={"Preferences"} dividing />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          {error && <ErrorMessage error={error} />}
          <Segment>
            {!currentUser && <Loader />}
            {currentUser && currentUser._id && (
              <Form onSubmit={onSubmit}>
                <Form.Group>
                  <Form.Field width={4}>
                    <label>Language</label>
                  </Form.Field>
                  <Form.Select
                    width={12}
                    name="preferred_language"
                    defaultValue={currentUser.preferred_language}
                    placeholder="Preferred Language"
                    autoComplete={"off"}
                    options={languageOptions}
                    onChange={(e, { value }) => setPreferredLanguage(value)}
                    search
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Field width={4}>
                    <label>Currency</label>
                  </Form.Field>
                  <Form.Select
                    width={12}
                    name="preferred_currency"
                    defaultValue={currentUser.preferred_currency}
                    placeholder="Preferred Currency"
                    autoComplete={"off"}
                    options={currencyOptions}
                    onChange={(e, { value }) => setPreferredCurrency(value)}
                    search
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Field width={4}>
                    <label>Units</label>
                  </Form.Field>
                  <Form.Select
                    width={12}
                    name="preferred_units"
                    defaultValue={currentUser.preferred_units}
                    placeholder="Preferred Units"
                    autoComplete={"off"}
                    options={measurementOptions}
                    onChange={(e, { value }) => setPreferredUnits(value)}
                    search
                  />
                </Form.Group>
                <Form.Button
                  primary
                  type={"submit"}
                  loading={loading}
                  content={"Save"}
                />
              </Form>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPreferences);
