import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { AUCTION_PATH } from "../../../../constants/paths";
import { Image, Table } from "semantic-ui-react";
import profilePlaceholder from "../../../../images/profile_placeholder.png";

const ArtworkTile = ({ name, artworkId, artwork }) => {
  const navigate = useNavigate();

  const goToProject = () => {
    return navigate(`${AUCTION_PATH}/${artworkId}`);
  };

  const { title, small_image_url, artist, museum } = artwork;

  return (
    <Table.Row onClick={goToProject} style={{ cursor: "pointer" }}>
      <Table.Cell>
        <Image src={small_image_url || profilePlaceholder} avatar />
        {title}
      </Table.Cell>
      <Table.Cell>
        {artist && (
          <>
            <Image src={artist.image_url || profilePlaceholder} avatar />
            {artist.name}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {museum && (
          <>
            <Image src={museum.image_url || profilePlaceholder} avatar />
            {museum.name}
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

ArtworkTile.propTypes = {
  artworkId: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  name: PropTypes.string,
};

ArtworkTile.defaultProps = {
  showDelete: true,
};

export default ArtworkTile;
