import React, { useRef } from "react";
import { connectSearchBox } from "react-instantsearch/connectors";
import { Input } from "semantic-ui-react";
import _ from "lodash";

const SearchBox = ({ placeholder, refine, onSearchInputChanged }) => {
  const delayedQuery = useRef(_.debounce((q) => refine(q), 500)).current;
  return (
    <Input
      fluid
      icon={"search"}
      placeholder={placeholder || "Search..."}
      onChange={(e) => {
        onSearchInputChanged(e.target.value);
        delayedQuery(e.target.value);
      }}
    />
  );
};

const ConnectedSearchBox = connectSearchBox(SearchBox);
export default ConnectedSearchBox;
