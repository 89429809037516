import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Confirm,
  Form,
  Grid,
  Header,
  Menu,
  Modal,
} from "semantic-ui-react";

import ErrorMessage from "../../ErrorMessage";
import { updateManyPieces } from "../../../redux/actions/endpoints";
import BasicInfo from "./BasicInfo";
import Additional from "./Additional";
import Location from "./Location";
import Financial from "./Financial";
import Insurance from "./Insurance";

const EditMultiplePiecesModal = ({ update, currentSelectedPieces }) => {
  const [selectedPieces, setSelectedPieces] = useState(
    new Set(currentSelectedPieces) || new Set(),
  );

  const [activeItem, setActiveItem] = useState("overview");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setSelectedPieces(new Set(currentSelectedPieces));
  }, [currentSelectedPieces]);

  const onUpdate = (change) => {
    setData({
      ...data,
      ...change,
    });
  };

  const handleSubmit = async () => {
    if (loading) return;

    setError(null);
    setLoading(true);
    setConfirmOpen(false);

    try {
      await update({
        piece_ids: [...selectedPieces],
        ...data,
      });
      handleClose();
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setModalOpen(false);
  };

  return (
    <Modal
      trigger={
        <Button
          basic
          disabled={selectedPieces.size === 0}
          content={"Edit Multiple"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header content={"Edit Multiple Artworks"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Header
          size={"tiny"}
          content={`You have selected ${selectedPieces.size} artworks`}
        />
        <Menu>
          <Menu.Item
            name="overview"
            active={activeItem === "overview"}
            onClick={(e, { name }) => setActiveItem(name)}
            content="Overview"
          />
          <Menu.Item
            name={"additional"}
            active={activeItem === "additional"}
            onClick={(e, { name }) => setActiveItem(name)}
            content={"Additional"}
          />
          <Menu.Item
            name="financial"
            active={activeItem === "financial"}
            onClick={(e, { name }) => setActiveItem(name)}
            content="Financial"
          />
          <Menu.Item
            name="insurance"
            active={activeItem === "insurance"}
            onClick={(e, { name }) => setActiveItem(name)}
            content="Insurance"
          />
          <Menu.Item
            name={"location"}
            active={activeItem === "location"}
            onClick={(e, { name }) => setActiveItem(name)}
            content="Location"
          />
        </Menu>

        {activeItem === "overview" && <BasicInfo onUpdate={onUpdate} />}

        {activeItem === "additional" && <Additional onUpdate={onUpdate} />}

        {activeItem === "location" && <Location onUpdate={onUpdate} />}

        {activeItem === "financial" && <Financial onUpdate={onUpdate} />}

        {activeItem === "insurance" && <Insurance onUpdate={onUpdate} />}
      </Modal.Content>
      <Modal.Actions>
        <Button basic content={"Back"} onClick={handleClose} />
        <Button
          primary
          loading={loading}
          content={"Update"}
          onClick={() => setConfirmOpen(true)}
        />
        <Confirm
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => handleSubmit()}
          content={`Are you sure you want to change ${selectedPieces.size} records?`}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateManyPieces(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMultiplePiecesModal);
