import React from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Image } from "semantic-ui-react";
import Piece from "../../../models/piece";

const PiecesTable = ({ handleCheck, currentSelectedPieces, currentPieces }) => {

  const handleSelectAll = (checked) => {
    let uncheckedIDs = currentPieces
      .filter((piece) => !currentSelectedPieces.has(piece._id))
      .map((piece) => piece._id);
    if (checked) {
      for (let piece of uncheckedIDs) {
        handleCheck(piece);
      }
    } else {
      for (let piece of currentSelectedPieces) {
        handleCheck(piece);
      }
    }
  };

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            <Checkbox
              checked={currentSelectedPieces.size === currentPieces.length}
              onChange={(e, { checked }) => handleSelectAll(checked)}
            />
          </Table.HeaderCell>
          <Table.HeaderCell width={8} content={"Title"} />
          <Table.HeaderCell width={8} content={"Artist"} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(currentPieces ?? [])
          .map((piece) => new Piece(piece))
          .map((piece) => (
            <Table.Row key={piece._id} onClick={() => handleCheck(piece._id)}>
              <Table.Cell>
                <Checkbox checked={currentSelectedPieces.has(piece._id)} />
              </Table.Cell>
              <Table.Cell>
                <Image src={piece.smallImageUrl()} avatar />
                {piece.title || "Untitled"}
              </Table.Cell>
              <Table.Cell content={piece.artist && piece.artist.name} />
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PiecesTable);
