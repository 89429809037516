import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import SelectLocationModal from "../../../../components/Modals/Location/Select";

const Location = ({ onUpdate }) => {
  const onSelectLocation = (location) => onUpdate({ location });

  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <label>Current Location</label>
        </Form.Field>
        <Form.Field width={12}>
          <SelectLocationModal onSelectLocation={onSelectLocation} />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Location Detail</label>
        </Form.Field>
        <Form.Input
          width={8}
          name={"location_detail"}
          placeholder="Location Detail"
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
        <Form.Field width={4}>
          <span className={"foot-note"}>e.g: Living Room</span>
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Location History</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name={"location_history"}
          placeholder="Location History"
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Location Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name={"location_notes"}
          placeholder="Location Notes"
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>
    </Form>
  );
};

export default Location;
