import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Header,
  Loader,
  Transition,
  Image,
  Segment,
  Button,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getGrouping,
  resetGroupingState,
  refreshGrouping,
  deleteGrouping,
} from "../../redux/actions/grouping";
import ErrorMessage from "../../components/ErrorMessage";
import AuthLayout from "../../layouts/AuthLayout";
import MarketSignals from "./components/MarketSignals";
import AuctionRecords from "./components/AuctionRecords";
import PricePredictions from "./components/PricePredictions";
import EditGroupingSidePane from "./components/EditGroupingSidePane";
import placeholder from "../../images/profile_placeholder.png";
import { ARTIST_PATH } from "../../constants/paths";

const GroupingDetail = ({ user, fetch, reset, loading, refresh, remove }) => {
  const { groupingId } = useParams();
  const navigate = useNavigate();
  const [grouping, setArtistGrouping] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [groupingId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const fetchData = async () => {
    try {
      const { payload } = await fetch(groupingId);
      setArtistGrouping(payload.data);
    } catch (e) {
      setError(e);
    }
  };

  const deleteData = async () => {
    try {
      await remove(groupingId);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <AuthLayout
      title={
        grouping ? `${grouping.title} by ${grouping.artist.name}` : "Loading..."
      }
    >
      {loading && <Loader active inline={"centered"} />}
      {error && <ErrorMessage error={error} />}
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header size="huge">
              <Header.Content content={grouping && grouping.title} />
              {grouping && grouping.artist && (
                <Header.Subheader
                  as={Link}
                  to={`${ARTIST_PATH}/${grouping.artist._id}`}
                  content={grouping.artist.name}
                />
              )}
            </Header>
          </Grid.Column>
          {grouping && user && user.roles && user.roles.includes("admin") && (
            <Grid.Column>
              <Segment basic>
                <Button
                  basic
                  floated={"right"}
                  icon={"trash"}
                  content={"Delete"}
                  onClick={async () => {
                    setError(null);
                    try {
                      await deleteData();
                      navigate(-1);
                    } catch (e) {
                      setError(e);
                    }
                  }}
                />
                <Button
                  basic
                  floated={"right"}
                  icon={"refresh"}
                  content={"Refresh"}
                  loading={isRefreshing}
                  onClick={async () => {
                    setIsRefreshing(true);
                    setError(null);
                    try {
                      await refresh(groupingId);
                    } catch (e) {
                      setError(e);
                    }
                    setIsRefreshing(false);
                  }}
                />
                <EditGroupingSidePane grouping={grouping}>
                  <Button
                    basic
                    floated={"right"}
                    icon={"edit"}
                    content={"Edit Auction Record"}
                    labelPosition={"left"}
                  />
                </EditGroupingSidePane>
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <Transition.Group
              style={{ maxHeight: "40em", minHeight: "20em" }}
              as={Image}
              animation={"fade left"}
              duration={600}
              visible={grouping}
              centered
              src={(grouping && grouping.image_url) || placeholder}
            />
          </Grid.Column>
          <Grid.Column>
            <Transition.Group
              as={Segment}
              basic
              animation={"fade up"}
              duration={600}
              visible={grouping}
            >
              {grouping && <p>{grouping.about}</p>}
            </Transition.Group>
            <Transition.Group
              as={Segment}
              basic
              animation={"fade up"}
              duration={600}
              visible={grouping}
            >
              <MarketSignals />
            </Transition.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <PricePredictions />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <AuctionRecords />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getGrouping(id)),
  refresh: (id) => dispatch(refreshGrouping(id)),
  remove: (id) => dispatch(deleteGrouping(id)),
  reset: () => dispatch(resetGroupingState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupingDetail);
