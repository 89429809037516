import {
  CREATE_PRIVATE_ROOM,
  CREATE_PRIVATE_ROOM_SUCCESS,
  DELETE_PRIVATE_ROOM,
  DELETE_PRIVATE_ROOM_SUCCESS,
  GET_PIECE_LIST_FOR_PRIVATE_ROOM,
  GET_PIECE_LIST_FOR_PRIVATE_ROOM_FAILURE,
  GET_PIECE_LIST_FOR_PRIVATE_ROOM_SUCCESS,
  GET_PRIVATE_ROOM,
  GET_PRIVATE_ROOM_FAILURE,
  GET_PRIVATE_ROOM_SUCCESS,
  UPDATE_PRIVATE_ROOM,
  UPDATE_PRIVATE_ROOM_FAILURE,
  UPDATE_PRIVATE_ROOM_SUCCESS,
} from "../actions/private_room";
import { Mixpanel } from "../../utils/Mixpanel";

const INITIAL_STATE = {
  isLoadingPrivateRoom: true,
  isCreatingPrivateRoom: false,
  isUpdatingPrivateRoom: false,
  isDeletingPrivateRoom: false,

  private_room: null,
  pieces: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRIVATE_ROOM:
      return { ...INITIAL_STATE };
    case GET_PRIVATE_ROOM_SUCCESS:
      return {
        ...state,
        isLoadingPrivateRoom: false,
        private_room: action.payload.data,
      };
    case GET_PRIVATE_ROOM_FAILURE:
      return {
        ...state,
        isLoadingPrivateRoom: false,
        error: action.error,
      };

    case CREATE_PRIVATE_ROOM:
      return {
        ...state,
        isCreatingPrivateRoom: true,
      };
    case CREATE_PRIVATE_ROOM_SUCCESS: {
      Mixpanel.track("private_room_created");

      return {
        ...state,
        isCreatingPrivateRoom: false,
        private_room: action.payload.data,
      };
    }

    case UPDATE_PRIVATE_ROOM:
      return {
        ...state,
        isUpdatingPrivateRoom: true,
      };
    case UPDATE_PRIVATE_ROOM_SUCCESS: {
      Mixpanel.track("private_room_updated");

      return {
        ...state,
        isUpdatingPrivateRoom: false,
        private_room: action.payload.data,
      };
    }
    case UPDATE_PRIVATE_ROOM_FAILURE: {
      return {
        ...state,
        isUpdatingPrivateRoom: false,
        error: action.error,
      };
    }

    case DELETE_PRIVATE_ROOM:
      return {
        ...state,
        isDeletingPrivateRoom: true,
      };
    case DELETE_PRIVATE_ROOM_SUCCESS: {
      Mixpanel.track("private_room_deleted");
      return {
        ...state,
        isDeletingPrivateRoom: false,
        private_room: action.payload.data,
      };
    }

    case GET_PIECE_LIST_FOR_PRIVATE_ROOM:
      return { ...state, isLoadingPieces: true };
    case GET_PIECE_LIST_FOR_PRIVATE_ROOM_SUCCESS: {
      return {
        ...state,
        isLoadingPieces: false,
        pieces: [...state.pieces, ...action.payload.data],
        hasMorePieces: action.payload.data.length === 25,
      };
    }
    case GET_PIECE_LIST_FOR_PRIVATE_ROOM_FAILURE:
      return {
        ...state,
        isLoadingPieces: false,
        error: action.error,
        hasMorePieces: false,
      };

    default:
      return state;
  }
};
