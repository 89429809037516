import React, { useState } from "react";
import {
  Grid,
  Header,
  Menu,
  Modal,
  Segment,
  Select,
  Statistic,
  Table,
  Image,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PieChart from "./PieChart";
import { useUser } from "../../../contexts/UserContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const DashboardModal = ({ pieces }) => {
  const { currentUser: user } = useUser();
  const [year, setYear] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: user?.preferred_currency || "USD",
    minimumFractionDigits: 2,
  });

  const piecesWithPurchaseDates = pieces?.filter(
    (piece) => piece.purchase_date,
  );
  const years = new Set(
    piecesWithPurchaseDates?.map((piece) => moment(piece.purchase_date).year()),
  );
  const yearOptions = [...years]
    .map((year) => ({
      key: year,
      text: year,
      value: year,
    }))
    .sort((a, b) => b.value - a.value);
  const filteredPieces = pieces?.filter((piece) => {
    if (!year) return true;
    return moment(piece.purchase_date).year() === year;
  });

  const cost = (year ? filteredPieces : pieces).reduce((sum, piece) => {
    const updatedSum = sum + (piece.purchase_price || 0);
    return updatedSum;
  }, 0);

  const valuation = (year ? filteredPieces : pieces).reduce((sum, piece) => {
    const updatedSum =
      sum + (piece.valuation_price || piece.purchase_price || 0);
    return updatedSum;
  }, 0);

  return (
    <Modal
      trigger={
        <Menu.Item icon={"pie graph"} onClick={() => setModalOpen(true)} />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header content={"Overview"} />
      <Modal.Content>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Select
                placeholder="Select Year"
                options={yearOptions}
                onChange={(e, { value }) => setYear(value)}
                value={year}
                style={{ marginBottom: "1em" }}
                clearable
              />
              <Segment textAlign={"center"}>
                <Statistic
                  textAlign={"center"}
                  size={"small"}
                  label={"Amount Spent"}
                  value={formatter.format(cost)}
                />
              </Segment>
              <Segment textAlign={"center"}>
                <Statistic
                  textAlign={"center"}
                  size={"small"}
                  label={"Valuation"}
                  value={formatter.format(valuation)}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <PieChart pieces={pieces} />
            </Grid.Column>
          </Grid.Row>
          {year && (
            <Grid.Row>
              <Grid.Column>
                <Header size="small" content={`${year} Purchases`} />
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Title</Table.HeaderCell>
                      <Table.HeaderCell>Purchase Price</Table.HeaderCell>
                      <Table.HeaderCell>Purchase Date</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {filteredPieces?.map((piece) => (
                      <Table.Row
                        key={piece.id}
                        onClick={() => navigate(`/piece/${piece._id}`)}
                      >
                        <Table.Cell>
                          <Image
                            src={
                              piece.images &&
                              piece.images[0] &&
                              piece.images[0].small_image_url
                            }
                            avatar
                          />
                          {piece.title}
                        </Table.Cell>
                        <Table.Cell>
                          {formatter.format(piece.purchase_price || 0)}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(piece.purchase_date).format("MMM DD, YYYY")}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
const mapStateToProps = ({ firebase }) => ({});

export default connect(mapStateToProps)(DashboardModal);
