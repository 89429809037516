import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Modal,
  Table,
} from "semantic-ui-react";
// Images
import SlidingPane from "react-sliding-pane";

import { connect } from "react-redux";
import {
  createGrouping,
  updateGrouping,
} from "../../../redux/actions/grouping";
import DropZone from "../../../components/DropZone";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ErrorMessage from "../../../components/ErrorMessage";
import SelectArtistModal from "../../../components/Modals/Artist/Select";
import { useParams } from "react-router-dom";
import { currencyOptions, groupingCategoryOptions } from "../../../common";

const EditGroupingSidePane = ({ grouping, children, update, create, user }) => {
  const [loading, setLoading] = useState(false);
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [error, setError] = useState(null);

  const { groupingId } = useParams();
  const [artist, setArtist] = useState(grouping.artist || null);
  const [imageUrl] = useState(grouping.image_url || null);
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState(grouping.category || null);
  const [about, setAbout] = useState(grouping.about || "");

  // Shared Details
  const [title, setTitle] = useState(grouping.title || "");
  const [date, setDate] = useState(grouping.date || "");
  const [medium, setMedium] = useState(grouping.medium || "");
  const [size, setSize] = useState(grouping.size || "");
  const [manufacturer, setManufacturer] = useState(grouping.manufacturer || "");
  const [websiteUrl, setWebsiteUrl] = useState(grouping.website_url || "");
  const [editionDetails, setEditionDetails] = useState(
    grouping.edition_details || "",
  );
  const [editionSize, setEditionSize] = useState(grouping.edition_size || null);
  const [originalPrice, setOriginalPrice] = useState(
    grouping.original_price || "",
  );
  const [originalCurrency, setOriginalCurrency] = useState(
    grouping.original_currency || "USD",
  );
  const [referenceId, setReferenceId] = useState(grouping.reference_id || "");

  useEffect(() => {
    if (!grouping) return;
    setArtist(grouping.artist);
  }, [grouping]);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = {
      artist: artist._id,
      about,
      title,
      date,
      medium,
      size,
      edition_details: editionDetails,
      edition_size: editionSize,
      original_price: originalPrice,
      original_currency: originalCurrency,
      manufacturer,
      website_url: websiteUrl,
      category,
      reference_id: referenceId,
    };

    try {
      if (groupingId) {
        await update(groupingId, data);
      } else {
        await create(data);
      }
      setSidePainOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={groupingId ? "Edit Gallery" : "Create Gallery"}
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Form fluid>
          {error && <ErrorMessage error={error} />}

          <Form.Group>
            <Form.Field width={4}>
              <label>Image</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                accept="image/*"
                icon={"image"}
                image={(file && file.preview) || imageUrl}
                placeholder={"Drag artwork image here or click to upload"}
              />
            </Form.Field>
          </Form.Group>

          <Form.TextArea
            name="about"
            defaultValue={about}
            label="About"
            placeholder="About"
            rows={5}
            autoComplete={"off"}
            onChange={(e, { value }) => setAbout(value)}
          />

          <Divider />

          <Header content={"Shared Details"} />

          <Form.Input
            label="Title"
            name="title"
            defaultValue={title}
            placeholder="Title"
            autoComplete={"off"}
            onChange={(e, { value }) => setTitle(value)}
          />

          <Form.Input
            label="Date"
            name="date"
            defaultValue={date}
            placeholder="Date"
            autoComplete={"off"}
            onChange={(e, { value }) => setDate(value)}
          />

          <Form.Select
            width={12}
            label="Category"
            defaultValue={category}
            placeholder="Category"
            autoComplete={"off"}
            options={groupingCategoryOptions}
            onChange={(e, { value }) => setCategory(value)}
            search
          />

          <Form.TextArea
            fluid
            name="medium"
            defaultValue={medium}
            label="Medium"
            placeholder="Medium"
            autoComplete={"off"}
            onChange={(e, { value }) => setMedium(value)}
          />

          <Form.Input
            fluid
            defaultValue={referenceId}
            label="Reference Id"
            placeholder="Reference Id"
            autoComplete={"off"}
            onChange={(e, { value }) => setReferenceId(value)}
          />

          <Form.Input
            fluid
            name="size"
            defaultValue={size}
            label="Size"
            placeholder="Size"
            autoComplete={"off"}
            onChange={(e, { value }) => setSize(value)}
          />

          <Form.Input
            fluid
            defaultValue={editionDetails}
            label="Edition Details"
            placeholder="Edition Details"
            autoComplete={"off"}
            onChange={(e, { value }) => setEditionDetails(value)}
          />

          <Form.Input
            fluid
            defaultValue={editionSize}
            label="Edition Size"
            placeholder="Edition Size"
            autoComplete={"off"}
            onChange={(e, { value }) => setEditionSize(value)}
          />

          <Form.Input
            fluid
            defaultValue={manufacturer}
            label="Manufacturer"
            placeholder="Manufacturer"
            autoComplete={"off"}
            onChange={(e, { value }) => setManufacturer(value)}
          />

          <Form.Input
            fluid
            defaultValue={websiteUrl}
            label="Website URL"
            placeholder="Website URL"
            autoComplete={"off"}
            onChange={(e, { value }) => setWebsiteUrl(value)}
          />

          <Form.Field>
            <label>Original Price</label>
            <Form.Group>
              <Form.Select
                fluid
                placeholder="Currency"
                defaultValue={originalCurrency}
                options={currencyOptions}
                autoComplete={"off"}
                onChange={(e, { value }) => setOriginalCurrency(value)}
                search
                width={4}
              />
              <Form.Input
                fluid
                placeholder="Original Price"
                defaultValue={originalPrice / 100}
                type="number"
                step="0.01"
                autoComplete={"off"}
                onChange={(e, { value }) => setOriginalPrice(value)}
                width={12}
              />
            </Form.Group>
          </Form.Field>
        </Form>
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createGrouping(data)),
  update: (id, data) => dispatch(updateGrouping(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditGroupingSidePane);
