const url = "/api/v4/consignments";

// Actions

export const RESET_CONSIGNMENT_STATE = "RESET_CONSIGNMENT_STATE";

export const GET_CONSIGNMENT_LIST = "GET_CONSIGNMENT_LIST";
export const GET_CONSIGNMENT_LIST_SUCCESS = "GET_CONSIGNMENT_LIST_SUCCESS";
export const GET_CONSIGNMENT_LIST_FAILURE = "GET_CONSIGNMENT_LIST_FAILURE";

export const GET_CONSIGNMENT = "GET_CONSIGNMENT";
export const GET_CONSIGNMENT_SUCCESS = "GET_CONSIGNMENT_SUCCESS";
export const GET_CONSIGNMENT_FAILURE = "GET_CONSIGNMENT_FAILURE";

export const CREATE_CONSIGNMENT = "CREATE_CONSIGNMENT";
export const CREATE_CONSIGNMENT_SUCCESS = "CREATE_CONSIGNMENT_SUCCESS";
export const CREATE_CONSIGNMENT_FAILURE = "CREATE_CONSIGNMENT_FAILURE";

export const UPDATE_CONSIGNMENT = "UPDATE_CONSIGNMENT";
export const UPDATE_CONSIGNMENT_SUCCESS = "UPDATE_CONSIGNMENT_SUCCESS";
export const UPDATE_CONSIGNMENT_FAILURE = "UPDATE_CONSIGNMENT_FAILURE";

export const REMOVE_CONSIGNMENT = "REMOVE_CONSIGNMENT";
export const REMOVE_CONSIGNMENT_SUCCESS = "REMOVE_CONSIGNMENT_SUCCESS";
export const REMOVE_CONSIGNMENT_FAILURE = "REMOVE_CONSIGNMENT_FAILURE";

// Networking

export const getConsignmentList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_CONSIGNMENT_LIST,
      GET_CONSIGNMENT_LIST_SUCCESS,
      GET_CONSIGNMENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getConsignment = (id) => {
  return {
    types: [GET_CONSIGNMENT, GET_CONSIGNMENT_SUCCESS, GET_CONSIGNMENT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createConsignment = (data) => {
  return {
    types: [
      CREATE_CONSIGNMENT,
      CREATE_CONSIGNMENT_SUCCESS,
      CREATE_CONSIGNMENT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateConsignment = (id, data) => {
  return {
    types: [
      UPDATE_CONSIGNMENT,
      UPDATE_CONSIGNMENT_SUCCESS,
      UPDATE_CONSIGNMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteConsignment = (id, data) => {
  return {
    types: [
      REMOVE_CONSIGNMENT,
      REMOVE_CONSIGNMENT_SUCCESS,
      REMOVE_CONSIGNMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const resetConsignmentState = () => {
  return {
    type: RESET_CONSIGNMENT_STATE,
  };
};
