import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header, Image, Segment, Table, Message } from "semantic-ui-react";
import placeholder from "../../../../../images/placeholder.png";
import { GALLERY_PATH } from "../../../../../constants/paths";

const Galleries = () => {
  const topGalleries = useSelector(
    (state) => state.analytics.analytics?.top_galleries || [],
  );
  const navigate = useNavigate();

  return (
    <Segment basic style={{ padding: 0 }}>
      <Header content="Top Galleries" />
      <Table selectable>
        <Table.Body>
          {topGalleries.map((data) => (
            <Table.Row
              key={data.gallery._id}
              onClick={() => navigate(`${GALLERY_PATH}/${data.gallery._id}`)}
            >
              <Table.Cell>
                <Image
                  avatar
                  src={data.gallery.small_image_url || placeholder}
                />
                {data.gallery.name}
              </Table.Cell>
              <Table.Cell textAlign="right">{data.count}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
};

export default Galleries;
