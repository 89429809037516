import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../../../utils/firebase";
import firebase from "firebase/compat/app";
import ErrorMessage from "../../../../components/ErrorMessage";
import { REGISTER_PATH } from "../../../../constants/paths";
import { Form, Header } from "semantic-ui-react";

const Login = ({ onChangeMode }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [verificationId, setVerificationId] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);

  const [resolver, setResolver] = useState(null);

  const captchaRef = useRef(null);

  const onSubmitCode = async () => {
    setLoading("code");
    setError(null);

    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
      resolver
        .resolveSignIn(multiFactorAssertion)
        .catch((err) => handleError(err));
    } catch (e) {
      await handleError(e);
    }

    setLoading(null);
  };

  const onCaptcha = () => {};

  const onSubmit = async () => {
    setLoading("email");
    setError(null);

    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      await handleError(e);
    }

    setLoading(null);
  };

  const handleError = async (error) => {
    switch (error.code) {
      case "auth/multi-factor-auth-required":
        const resolver = error.resolver;
        setResolver(resolver);

        try {
          if (
            resolver.hints[0].factorId ===
            firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
          ) {
            const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
              {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              },
              new firebase.auth.RecaptchaVerifier(captchaRef.current, {
                size: "invisible",
                callback: (response) => {
                  onCaptcha();
                },
              }),
            );
            setVerificationId(verificationId);
          }
        } catch (e) {
          await handleError(e);
        }
        break;
      default:
        setError(error);
        break;
    }
  };

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {verificationId && (
        <Form onSubmit={onSubmitCode}>
          <Form.Field>
            <Header
              size="medium"
              content={"Client Portal"}
              as={"h1"}
              subheader="Please enter the code sent to your device. This code expires in 10 minutes."
            />
          </Form.Field>
          <Form.Input
            label="Code"
            placeholder="Code"
            onChange={(e, { value }) => setVerificationCode(value)}
          />
          <Form.Button
            fluid
            color="black"
            loading={loading === "code"}
            type={"submit"}
            content={"Continue"}
            size={"large"}
          />
        </Form>
      )}
      {!verificationId && (
        <>
          <Form onSubmit={onSubmit}>
            <Form.Field>
              <Header size="medium" as={"h1"} content={"Client Portal"} />
            </Form.Field>
            <Form.Input
              label="Email"
              placeholder="Email"
              onChange={(e, { value }) => setEmail(value)}
            />
            <Form.Input
              label="Password"
              type="password"
              placeholder="Password"
              onChange={(e, { value }) => setPassword(value)}
            />
            <div id={"recaptcha-container"} ref={captchaRef} />
            <Form.Field>
              <Form.Group widths={"equal"}>
                <Form.Field style={{ textAlign: "left" }}>
                  <Link to={REGISTER_PATH}>Do you need an account?</Link>
                </Form.Field>
                <Form.Field style={{ textAlign: "right" }}>
                  <a onClick={() => onChangeMode("recoverPassword")}>
                    Password reset?
                  </a>
                </Form.Field>
              </Form.Group>
            </Form.Field>
            <Form.Button
              fluid
              color="black"
              loading={loading === "email"}
              type={"submit"}
              content={"Login"}
              size={"large"}
            />
          </Form>
        </>
      )}
    </>
  );
};

export default Login;
