import React, { useState } from "react";
import { Image, Modal } from "semantic-ui-react";
// Images
import placeholder from "../../../images/profile_placeholder.png";
// // Components
import SearchMuseum from "../../Search/SearchMuseum";

const SelectMuseum = ({ museum, onSelectMuseum, addingDisabled }) => {
  const [currentMuseum, setCurrentMuseum] = useState(museum);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Modal
      trigger={
        <div onClick={() => setModalOpen(true)}>
          <Image
            src={(currentMuseum && currentMuseum.image_url) || placeholder}
            avatar
          />
          <span>
            <a>{(currentMuseum && currentMuseum.name) || "Select Museum"}</a>
          </span>
        </div>
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header content="Select Museum" />
      <Modal.Content>
        <SearchMuseum
          addingDisabled={addingDisabled}
          onSelectMuseum={(a) => {
            setCurrentMuseum(a);
            onSelectMuseum(a._id);
            setModalOpen(false);
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export default SelectMuseum;
