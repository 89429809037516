import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Segment } from "semantic-ui-react";
import Attachment from "../../../../../../../../components/Attachment";

const Condition = ({ piece }) => {
  const {
    condition_details,
    condition_conservation,
    condition_conservation_attachments,
    condition_materials,
    condition_materials_attachments,
    condition_manufacturer,
    condition_manufacturer_attachments,
  } = piece;

  const hasConditionConservation =
    (condition_conservation && condition_conservation.length > 0) ||
    (condition_conservation_attachments &&
      condition_conservation_attachments.length > 0);
  const hasConditionMaterials =
    (condition_materials && condition_materials.length > 0) ||
    (condition_materials_attachments &&
      condition_materials_attachments.length > 0);
  const hasConditionManufacturer =
    (condition_manufacturer && condition_manufacturer.length > 0) ||
    (condition_manufacturer_attachments &&
      condition_manufacturer_attachments.length > 0);

  if (
    !condition_details &&
    !hasConditionConservation &&
    !hasConditionMaterials &&
    !hasConditionManufacturer
  ) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        {condition_details && (
          <>
            <Header size={"tiny"} content={"Condition"} />
            <p>{condition_details}</p>
          </>
        )}
        {hasConditionConservation && (
          <>
            <Header size={"tiny"} content={"Condition Conservation"} />
            <p>{condition_conservation}</p>
            {(condition_conservation_attachments || []).map((attachment) => (
              <Attachment key={attachment._id} attachment={attachment} />
            ))}
          </>
        )}
        {hasConditionMaterials && (
          <>
            <Header content={"Condition Materials"} />
            <p>{condition_materials}</p>
            {(condition_materials_attachments || []).map((attachment) => (
              <Attachment key={attachment._id} attachment={attachment} />
            ))}
          </>
        )}
        {hasConditionManufacturer && (
          <>
            <Header content={"Condition Manufacturer"} />
            <p>{condition_manufacturer}</p>
            {(condition_manufacturer_attachments || []).map((attachment) => (
              <Attachment key={attachment._id} attachment={attachment} />
            ))}
          </>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

Condition.propTypes = {
  piece: PropTypes.object,
};

export default Condition;
