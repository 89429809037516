const url = "/api/v4/auction_houses";

// Actions
export const RESET_AUCTION_HOUSE_STATE = "RESET_AUCTION_HOUSE_STATE";

export const GET_AUCTION_HOUSE_LIST = "GET_AUCTION_HOUSE_LIST";
export const GET_AUCTION_HOUSE_LIST_SUCCESS = "GET_AUCTION_HOUSE_LIST_SUCCESS";
export const GET_AUCTION_HOUSE_LIST_FAILURE = "GET_AUCTION_HOUSE_LIST_FAILURE";

export const GET_AUCTION_HOUSE = "GET_AUCTION_HOUSE";
export const GET_AUCTION_HOUSE_SUCCESS = "GET_AUCTION_HOUSE_SUCCESS";
export const GET_AUCTION_HOUSE_FAILURE = "GET_AUCTION_HOUSE_FAILURE";

export const CREATE_AUCTION_HOUSE = "CREATE_AUCTION_HOUSE";
export const CREATE_AUCTION_HOUSE_SUCCESS = "CREATE_AUCTION_HOUSE_SUCCESS";
export const CREATE_AUCTION_HOUSE_FAILURE = "CREATE_AUCTION_HOUSE_FAILURE";

export const UPDATE_AUCTION_HOUSE = "UPDATE_AUCTION_HOUSE";
export const UPDATE_AUCTION_HOUSE_SUCCESS = "UPDATE_AUCTION_HOUSE_SUCCESS";
export const UPDATE_AUCTION_HOUSE_FAILURE = "UPDATE_AUCTION_HOUSE_FAILURE";

export const DELETE_AUCTION_HOUSE = "DELETE_AUCTION_HOUSE";
export const DELETE_AUCTION_HOUSE_SUCCESS = "DELETE_AUCTION_HOUSE_SUCCESS";
export const DELETE_AUCTION_HOUSE_FAILURE = "DELETE_AUCTION_HOUSE_FAILURE";

export const GET_AUCTION_HOUSE_AUCTION_LIST = "GET_AUCTION_HOUSE_AUCTION_LIST";
export const GET_AUCTION_HOUSE_AUCTION_LIST_SUCCESS =
  "GET_AUCTION_HOUSE_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_HOUSE_AUCTION_LIST_FAILURE =
  "GET_AUCTION_HOUSE_AUCTION_LIST_FAILURE";

export const GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST =
  "GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST";
export const GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_SUCCESS =
  "GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_FAILURE =
  "GET_AUCTION_HOUSE_UPCOMING_AUCTION_LIST_FAILURE";

// Networking

export const getAuctionHouseList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_AUCTION_HOUSE_LIST,
      GET_AUCTION_HOUSE_LIST_SUCCESS,
      GET_AUCTION_HOUSE_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getAuctionHouse = (id) => {
  return {
    types: [
      GET_AUCTION_HOUSE,
      GET_AUCTION_HOUSE_SUCCESS,
      GET_AUCTION_HOUSE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createAuctionHouse = (data) => {
  return {
    types: [
      CREATE_AUCTION_HOUSE,
      CREATE_AUCTION_HOUSE_SUCCESS,
      CREATE_AUCTION_HOUSE_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAuctionHouse = (id, data) => {
  return {
    types: [
      UPDATE_AUCTION_HOUSE,
      UPDATE_AUCTION_HOUSE_SUCCESS,
      UPDATE_AUCTION_HOUSE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteAuctionHouse = (id, data) => {
  return {
    types: [
      DELETE_AUCTION_HOUSE,
      DELETE_AUCTION_HOUSE_SUCCESS,
      DELETE_AUCTION_HOUSE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const getAuctionHouseAuctionsList = (
  id,
  page = 1,
  limit = 25,
  filter = "past",
) => {
  return {
    types: [
      GET_AUCTION_HOUSE_AUCTION_LIST,
      GET_AUCTION_HOUSE_AUCTION_LIST_SUCCESS,
      GET_AUCTION_HOUSE_AUCTION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/auctions`,
        params: {
          page,
          limit,
          filter,
        },
      },
    },
  };
};

export const resetAuctionHouseState = () => {
  return {
    type: RESET_AUCTION_HOUSE_STATE,
  };
};
