import {
  GET_GALLERY_LIST,
  GET_GALLERY_LIST_FAILURE,
  GET_GALLERY_LIST_SUCCESS,
  TOGGLE_GALLERY_LIST_DISPLAY,
} from "../actions/gallery";

const INITIAL_STATE = {
  display: localStorage.getItem("galleryListDisplay") || "table",
  isLoadingGalleryList: true,
  galleries: [],
  hasMoreGalleries: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GALLERY_LIST:
      return {
        ...state,
        isLoadingGalleryList: true,
        galleries:
          action.payload.request.params.page <= 1 ? [] : state.galleries,
      };

    case GET_GALLERY_LIST_SUCCESS:
      return {
        ...state,
        isLoadingGalleryList: false,
        hasMoreGalleries: action.payload.data.length > 0,
        galleries: state.galleries.concat(action.payload.data),
      };
    case GET_GALLERY_LIST_FAILURE:
      return {
        ...state,
        isLoadingGalleryList: false,
        hasMoreGalleries: false,
        error: action.error,
      };
    case TOGGLE_GALLERY_LIST_DISPLAY:
      localStorage.setItem("galleryListDisplay", action.display);
      return {
        ...state,
        display: action.display,
      };
    default:
      return state;
  }
};
