import React from "react";
import { connect, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { deleteTag } from "../../../../redux/actions/tag";
import { toggleActiveTab } from "../../../../redux/actions/piece";
import Tag from "../../../../models/tag";
import AddTagModal from "../../../../components/Modals/Tag/Add/Modal";
import EditTagsModal from "../../../../components/Modals/Tags/Edit";
import { useTags } from "../../../../contexts/TagsContext";

const TagMenu = ({ activeTab, toggleTab }) => {
  const { tags } = useTags();

  return (
    <Menu pointing secondary>
      <Menu.Item
        name={"all"}
        active={activeTab === "all"}
        onClick={() => toggleTab("all")}
      />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          overflowY: "hidden", // Prevents bottom scroll indicator
          whiteSpace: "nowrap",
          scrollbarWidth: "none", // Hide scrollbar in Firefox
        }}
      >
        {(tags || [])
          .map((tag) => new Tag(tag))
          .map((tag) => (
            <Menu.Item
              key={tag._id}
              name={tag.name}
              active={activeTab === tag._id}
              onClick={() => toggleTab(tag._id)}
            />
          ))}
      </div>
      <AddTagModal />
      <Menu.Menu position={"right"}>
        <EditTagsModal />
      </Menu.Menu>
    </Menu>
  );
};

const mapStateToProps = ({ pieces, firebase }) => ({
  activeTab:
    pieces.activeTab ||
    new Tag({
      _id: "all",
      name: "All",
    }),
});

const mapDispatchToProps = (dispatch) => ({
  delete: (tagID) => dispatch(deleteTag(tagID)),
  toggleTab: (name) => dispatch(toggleActiveTab(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagMenu);
