import {
  CREATE_PIECE,
  CREATE_PIECE_FAILURE,
  CREATE_PIECE_PDF,
  CREATE_PIECE_PDF_FAILURE,
  CREATE_PIECE_PDF_SUCCESS,
  CREATE_PIECE_SUCCESS,
  GET_PIECE,
  GET_PIECE_FAILURE,
  GET_PIECE_SUCCESS,
  RESET_PIECE_STATE,
  UPDATE_PIECE,
  UPDATE_PIECE_FAILURE,
  UPDATE_PIECE_SUCCESS,
} from "../actions/piece";

import {
  CREATE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_SUCCESS,
} from "../actions/attachment";
import {
  CREATE_APPRAISAL_SUCCESS,
  DELETE_APPRAISAL_SUCCESS,
} from "../actions/appraisal";
import { Mixpanel } from "../../utils/Mixpanel";
import Piece from "../../models/piece";

const INITIAL_STATE = {
  display: "table",

  isLoadingPieceList: true,
  pieces: [],
  hasMorePieces: true,

  isLoadingPiece: true,
  isCreatingPiece: false,
  isUpdatingPiece: false,

  isCreatingPiecePDF: false,

  piece: {
    appraisals: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PIECE_STATE:
    case GET_PIECE:
      return { ...INITIAL_STATE };
    case GET_PIECE_SUCCESS:
      return {
        ...state,
        isLoadingPiece: false,
        piece: new Piece(action.payload.data),
      };
    case GET_PIECE_FAILURE:
      return {
        ...state,
        isLoadingPiece: false,
        error: action.error,
      };

    case CREATE_PIECE:
      return {
        ...state,
        error: null,
        isCreatingPiece: true,
      };
    case CREATE_PIECE_SUCCESS: {
      Mixpanel.track("piece_created");
      return {
        ...state,
        isCreatingPiece: false,
        piece: action.payload.data,
      };
    }
    case CREATE_PIECE_FAILURE:
      return {
        ...state,
        isCreatingPiece: false,
        error: action.error,
      };

    case UPDATE_PIECE:
      return {
        ...state,
        isUpdatingPiece: true,
      };
    case UPDATE_PIECE_SUCCESS: {
      const piece = action.payload.data;
      Mixpanel.track("piece_updated", {
        category: piece.category,
        artist: piece.artist && piece.artist._id,
        gallery: piece.gallery && piece.gallery._id,
      });
      return {
        ...state,
        isUpdatingPiece: false,
        piece: new Piece(action.payload.data),
      };
    }
    case UPDATE_PIECE_FAILURE:
      return {
        ...state,
        isUpdatingPiece: false,
        error: action.error,
      };

    case CREATE_PIECE_PDF:
      return {
        ...state,
        isCreatingPiecePDF: true,
      };
    case CREATE_PIECE_PDF_SUCCESS:
    case CREATE_PIECE_PDF_FAILURE:
      return {
        ...state,
        isCreatingPiecePDF: false,
      };

    case CREATE_APPRAISAL_SUCCESS: {
      const appraisals = state.piece.appraisals || [];

      return {
        ...state,
        piece: {
          ...state.piece,
          appraisals: [...appraisals, action.payload.data],
        },
      };
    }
    case DELETE_APPRAISAL_SUCCESS: {
      const appraisals = state.piece.appraisals.filter(
        (appraisal) => appraisal._id !== action.payload.data._id,
      );

      return {
        ...state,
        piece: {
          ...state.piece,
          appraisals,
        },
      };
    }

    case CREATE_ATTACHMENT_SUCCESS: {
      const attachment = action.payload.data;
      let piece = state.piece;
      return {
        ...state,
        piece,
      };
    }

    case DELETE_ATTACHMENT_SUCCESS: {
      const attachment = action.payload.data;
      let piece = state.piece;

      return {
        ...state,
        piece,
      };
    }

    default:
      return state;
  }
};
