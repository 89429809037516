import {
  SET_LOCATION_ORDER_BY,
  TOGGLE_LOCATION_LIST_DISPLAY,
} from "../actions/location";

const INITIAL_STATE = {
  display: "table",
  orderBy: ["name", "asc"],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOCATION_LIST_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    case SET_LOCATION_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
      };
    default:
      return state;
  }
};
