const url = "/api/v4/imports";

// Actions

export const IMPORT_PIECE = "IMPORT_PIECE";
export const IMPORT_PIECE_SUCCESS = "IMPORT_PIECE_SUCCESS";
export const IMPORT_PIECE_FAILURE = "IMPORT_PIECE_FAILURE";

export const PRIME_IMPORT_FILE = "PRIME_IMPORT_FILE";
export const PRIME_IMPORT_FILE_SUCCESS = "PRIME_IMPORT_FILE_SUCCESS";
export const PRIME_IMPORT_FILE_FAILURE = "PRIME_IMPORT_FILE_FAILURE";

export const SECONDARY_IMPORT_FILE = "SECONDARY_IMPORT_FILE";
export const SECONDARY_IMPORT_FILE_SUCCESS = "SECONDARY_IMPORT_FILE_SUCCESS";
export const SECONDARY_IMPORT_FILE_FAILURE = "SECONDARY_IMPORT_FILE_FAILURE";

// Networking

export const importPiece = (data) => {
  return {
    types: [IMPORT_PIECE, IMPORT_PIECE_SUCCESS, IMPORT_PIECE_FAILURE],
    payload: {
      request: {
        url: `${url}/pieces`,
        method: "POST",
        data,
      },
    },
  };
};

export const primeImportFile = (data) => {
  return {
    types: [
      PRIME_IMPORT_FILE,
      PRIME_IMPORT_FILE_SUCCESS,
      PRIME_IMPORT_FILE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/prime`,
        method: "POST",
        data,
      },
    },
  };
};

export const secondaryImportFile = (data) => {
  return {
    types: [
      SECONDARY_IMPORT_FILE,
      SECONDARY_IMPORT_FILE_SUCCESS,
      SECONDARY_IMPORT_FILE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/secondary`,
        method: "POST",
        data,
      },
    },
  };
};

export const importCollectionFromFile = (data) => {
  return {
    types: [
      SECONDARY_IMPORT_FILE,
      SECONDARY_IMPORT_FILE_SUCCESS,
      SECONDARY_IMPORT_FILE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/collection`,
        method: "POST",
        data,
      },
    },
  };
};
