const url = "/api/v4/subscriptions";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_FAILURE = "GET_SUBSCRIPTION_LIST_FAILURE";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const getSubscriptionList = (data) => {
  return {
    types: [
      GET_SUBSCRIPTION_LIST,
      GET_SUBSCRIPTION_LIST_SUCCESS,
      GET_SUBSCRIPTION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
      },
    },
  };
};

export const createSubscription = (data) => {
  return {
    types: [
      CREATE_SUBSCRIPTION,
      CREATE_SUBSCRIPTION_SUCCESS,
      CREATE_SUBSCRIPTION_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const cancelSubscription = (id) => {
  return {
    types: [
      CANCEL_SUBSCRIPTION,
      CANCEL_SUBSCRIPTION_SUCCESS,
      CANCEL_SUBSCRIPTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};
