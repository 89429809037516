import React, { useState } from "react";
import _ from "underscore";
import { Header, Label, Segment, Table } from "semantic-ui-react";
import { ResponsivePie } from "@nivo/pie";
import { capitalize } from "lodash";

const pieceColor = (category) => {
  switch (category) {
    case "automobile":
      return "#F4C444";
    case "painting":
      return "#4873D3";
    case "print":
      return "#22C4AB";
    case "lithograph":
      return "#22C4AB";
    case "giclee":
      return "#22C4AB";
    case "sculpture":
      return "#FFA74E";
    case "photography":
      return "#F75454";
    case "drawing":
      return "#FFDD81";
    case "mixed_media":
      return "#8B3CF7";
    case "installation":
      return "#F2A9EF";
    case "video":
      return "#A8DEF4";
    case "fashion":
      return "#F2A9EF";
    case "jewelry":
      return "#5272CD";
    case "furniture":
      return "#40407a";
    case "other":
      return "#FFFFFF";
    default:
      return "#F2F4F9";
  }
};

const PieChart = ({ pieces }) => {
  const [value, setValue] = useState(false);

  if (!pieces) {
    return null;
  }

  const grouped = _.countBy(pieces, "category");
  const keys = Object.keys(grouped);
  let data = keys.map((key) => ({
    value: grouped[key],
    id: key,
    label: key,
    color: pieceColor(key),
  }));

  return (
    <div style={{ height: 400 }}>
      <ResponsivePie
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        data={data}
        innerRadius={0.5}
        padAngle={0.7}
        activeOuterRadiusOffset={8}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
      />
    </div>
  );
};

export default PieChart;
