import {
  GET_ARTIST_PROPOSAL,
  GET_ARTIST_PROPOSAL_FAILURE,
  GET_ARTIST_PROPOSAL_SUCCESS,
  RESET_ARTIST_PROPOSAL_STATE,
} from "../actions/artist_proposal";

const INITIAL_STATE = {
  isLoadingArtistProposal: true,
  artistProposal: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ARTIST_PROPOSAL_STATE: {
      return { INITIAL_STATE };
    }
    case GET_ARTIST_PROPOSAL:
      return { ...INITIAL_STATE };
    case GET_ARTIST_PROPOSAL_SUCCESS:
      return {
        ...state,
        isLoadingArtistProposal: false,
        artistProposal: action.payload.data,
      };
    case GET_ARTIST_PROPOSAL_FAILURE:
      return {
        ...state,
        isLoadingArtistProposal: false,
        error: action.error,
      };

    default:
      return state;
  }
};
