const url = "/api/v4/appraisal_requests";

// Actions

export const CREATE_APPRAISAL_REQUEST = "CREATE_APPRAISAL_REQUEST";
export const CREATE_APPRAISAL_REQUEST_SUCCESS =
  "CREATE_APPRAISAL_REQUEST_SUCCESS";
export const CREATE_APPRAISAL_REQUEST_FAILURE =
  "CREATE_APPRAISAL_REQUEST_FAILURE";

// Networking

export const createAppraisalRequest = (data) => {
  return {
    types: [
      CREATE_APPRAISAL_REQUEST,
      CREATE_APPRAISAL_REQUEST_SUCCESS,
      CREATE_APPRAISAL_REQUEST_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};
