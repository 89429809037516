import React from "react";
import { Divider, Form, Grid, Header } from "semantic-ui-react";
import PurchaseInfo from "../PurchaseInfo";
import Insurance from "../Insurance";

const Financial = ({ onUpdate }) => {
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });

  return <PurchaseInfo handleChange={handleChange} onUpdate={onUpdate} />;
};

export default Financial;
