import React, { useState } from "react";
import {
  Button,
  Grid,
  Header,
  Label,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import download from "downloadjs";
import sample from "./sample.csv";
import DropZone from "../../../../../components/DropZone";
import { primeImportFile } from "../../../../../redux/actions/import";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../components/ErrorMessage";

const StepOne = ({ onFileUploaded, importFile, onStepOneComplete }) => {
  const [file, setFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleNext = async () => {
    setLoading(true);

    try {
      var formData = new FormData();
      formData.append("file", file);
      const { payload } = await importFile(formData);
      onStepOneComplete(file, payload.data);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header
              size={"small"}
              content={"What I need to know to upload CSV?"}
              subheader={
                "Please prepare a CSV file containing the information about your collection."
              }
            />
            <Button
              basic
              icon="download"
              content="Download Sample"
              labelPosition="left"
              onClick={() => download(sample)}
            />
            <Header
              subheader={
                "*A few of the fields we find important, but are not required, are as follows:"
              }
            />
            <Label.Group>
              <Popup trigger={<Label content="ID" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"128012346"} />
                  <List.Item as="li" content={"A152310"} />
                  <List.Item as="li" content={"1"} />
                </List>
              </Popup>
              <Popup trigger={<Label size={"small"} content="Title" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"The Starry Night"} />
                  <List.Item as="li" content={"Guernica"} />
                  <List.Item as="li" content={"No. 61"} />
                </List>
              </Popup>
              <Popup trigger={<Label size={"small"} content="Creation Date" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"1876"} />
                  <List.Item as="li" content={"June 21, 2000"} />
                  <List.Item as="li" content={"17th Century"} />
                </List>
              </Popup>
              <Popup trigger={<Label size={"small"} content="Artist" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"Vincent Van Gogh"} />
                  <List.Item as="li" content={"Pablo Picasso"} />
                  <List.Item as="li" content={"Mark Rothko"} />
                </List>
              </Popup>

              <Popup trigger={<Label size={"small"} content="Medium" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"Oil on canvas"} />
                  <List.Item as="li" content={"Acrylic on paper"} />
                  <List.Item as="li" content={"Bronze"} />
                </List>
              </Popup>

              <Popup trigger={<Label size={"small"} content="Description" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item
                    as="li"
                    content={"Signed and dated on the reverse."}
                  />
                  <List.Item
                    as="li"
                    content={
                      "Incised with the artist's monogram on the underside."
                    }
                  />
                  <List.Item
                    as="li"
                    content={
                      "This work is registered in the archives of the Calder Foundation."
                    }
                  />
                </List>
              </Popup>

              <Popup trigger={<Label size={"small"} content="Purchase Date" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"23-Feb-07"} />
                  <List.Item as="li" content={"20-May-14"} />
                  <List.Item as="li" content={"14-Feb-16"} />
                </List>
              </Popup>

              <Popup
                trigger={<Label size={"small"} content="Purchase Price" />}
              >
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"$100,000,000"} />
                  <List.Item as="li" content={"$5000,000.00"} />
                  <List.Item as="li" content={"£750,000,000"} />
                </List>
              </Popup>

              <Popup trigger={<Label size={"small"} content="Gallery" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"Library Street Collective"} />
                  <List.Item as="li" content={"White Cube"} />
                  <List.Item as="li" content={"Gagosian"} />
                </List>
              </Popup>

              <Popup trigger={<Label size={"small"} content="Location" />}>
                <Header size="small" dividing content={"Examples"} />
                <List as="ul">
                  <List.Item as="li" content={"House"} />
                  <List.Item as="li" content={"11 W 53rd St, New York, NY"} />
                  <List.Item as="li" content={"MOMA"} />
                </List>
              </Popup>
            </Label.Group>
          </Grid.Column>
          <Grid.Column width={8}>
            <DropZone
              onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
              multiple={false}
              accept=".csv"
              file={file}
              icon={"file"}
              placeholder={"Drag csv file here or click to upload"}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {error && <ErrorMessage error={error} />}
            <Button
              primary
              disabled={!file}
              loading={loading}
              content={"Next"}
              onClick={handleNext}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  importFile: (data) => dispatch(primeImportFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
