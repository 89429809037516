import React from "react";
import Attachments from "../BasicInfo/Attachments";
import PropTypes from "prop-types";

const LiteratureAttachments = ({ attachments, onAttachmentsUpdated }) => (
  <Attachments
    attachments={attachments}
    type={"literature"}
    onAttachmentsUpdated={onAttachmentsUpdated}
  />
);

LiteratureAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
};

export default LiteratureAttachments;
