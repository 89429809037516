import React from "react";
import { Label } from "semantic-ui-react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Attachment = ({ attachment, onAttachmentDeleted }) => {
  let icon = "attach";
  if (attachment.mime_type && attachment.mime_type.startsWith("application"))
    icon = "file pdf";
  if (attachment.mime_type && attachment.mime_type.startsWith("image"))
    icon = "image";
  if (attachment.mime_type && attachment.mime_type.startsWith("video"))
    icon = "video";

  const onClick = async () => {
    if (attachment.file_reference) {
      try {
        const storage = getStorage();
        const url = await getDownloadURL(
          ref(storage, attachment.file_reference),
        );
        window.open(url, "_blank");
      } catch (e) {
        console.error(e);
      }
    } else {
      window.open(attachment.url, "_blank");
    }
  };

  const onRemove = (e) => {
    e.stopPropagation();
    onAttachmentDeleted(attachment);
  };

  return (
    <Label
      as={"a"}
      key={attachment._id}
      icon={icon}
      content={attachment.name}
      onClick={onClick}
      onRemove={onAttachmentDeleted ? onRemove : null}
    />
  );
};
export default Attachment;
