const url = "/api/v4/users";

// Actions

export const RESET_USER_STATE = "RESET_USER_STATE";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAILURE = "REMOVE_USER_FAILURE";

export const GET_PIECE_LIST_FOR_USER = "GET_PIECE_LIST_FOR_USER";
export const GET_PIECE_LIST_FOR_USER_SUCCESS =
  "GET_PIECE_LIST_FOR_USER_SUCCESS";
export const GET_PIECE_LIST_FOR_USER_FAILURE =
  "GET_PIECE_LIST_FOR_USER_FAILURE";

// Networking

export const getUserList = (page = 1, limit = 25) => {
  return {
    types: [GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getUser = (id) => {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const updateUser = (id, data) => {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteUser = (id, data) => {
  return {
    types: [REMOVE_USER, REMOVE_USER_SUCCESS, REMOVE_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const getUserPieceList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_USER,
      GET_PIECE_LIST_FOR_USER_SUCCESS,
      GET_PIECE_LIST_FOR_USER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pieces`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetUserState = () => {
  return {
    type: RESET_USER_STATE,
  };
};
