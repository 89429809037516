import React from "react";
import { Image, Table } from "semantic-ui-react";
import placeholder from "../../../../../../images/profile_placeholder.png";
import {
  ARTIST_PATH,
  ARTWORKS_PATH,
  MUSEUM_PATH,
} from "../../../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const ArtistArtworkTile = ({ artwork }) => {
  const navigate = useNavigate();
  const { _id, title, museum, small_image_url, artist } = artwork;

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Table.Row key={_id} style={{ cursor: "pointer" }}>
      <Table.Cell onClick={(e) => onRowClick(e, `${ARTWORKS_PATH}/${_id}`)}>
        <Image src={small_image_url || placeholder} avatar />
        {title}
      </Table.Cell>
      <Table.Cell
        content={artist && artist.name}
        onClick={(e) => onRowClick(e, `${ARTIST_PATH}/${artist._id}`)}
      />
      <Table.Cell
        content={museum && museum.name}
        onClick={(e) => onRowClick(e, `${MUSEUM_PATH}/${museum._id}`)}
      />
    </Table.Row>
  );
};

export default ArtistArtworkTile;
