import React from "react";
import Attachments from "../BasicInfo/Attachments";
import PropTypes from "prop-types";

const ValuationAttachments = ({ attachments, onAttachmentsUpdated }) => (
  <Attachments
    attachments={attachments}
    type={"valuation"}
    onAttachmentsUpdated={onAttachmentsUpdated}
  />
);

ValuationAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
};

export default ValuationAttachments;
