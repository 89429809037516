import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { usePushNotifications } from "../hooks/usePushNotifications";
import Banners from "../containers/Banners/Banners";
import { useUser } from "../contexts/UserContext";

const AuthLayout = ({ title, description, children, bannersEnabled }) => {
  const dispatch = useDispatch();
  const { currentUser } = useUser();
  const { register: registerForPushNotifications } =
    usePushNotifications(currentUser);

  useEffect(() => {
    registerForPushNotifications();
  }, [dispatch, registerForPushNotifications]);

  useEffect(() => {
    if (currentUser && window && window.drift) {
      window.drift.identify(currentUser._id, {
        email: currentUser.email,
        name: currentUser.name,
        account_level: currentUser.account_level,
      });
    }
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || "ArtCollection.io"}</title>
        {description && <meta name="description" content={description} />}
      </Helmet>
      <Segment
        basic
        style={{ paddingTop: "2em", margin: "auto", marginTop: "3em" }}
      >
        {bannersEnabled && <Banners bannersEnabled />}
        {children}
      </Segment>
    </>
  );
};

AuthLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  bannersEnabled: PropTypes.bool,
};

export default AuthLayout;
