import React, { useState, useEffect } from "react";
import { Button, Grid, Header, Item, Loader, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import BusinessAccount from "./BusinessAccount";
import PremiumAccount from "./PremiumAccount";
import ProAccount from "./ProAccount";
import BasicAccount from "./BasicAccount";
import { getAccountList } from "../../../../../../redux/actions/account";
import EnterpriseAccount from "./EnterpriseAccount";
import ManagedAccount from "./ManagedAccount";
import { useAccount } from "../../../../../../contexts/AccountContext";
import { useUser } from "../../../../../../contexts/UserContext";

const Billing = ({ user, fetchData }) => {
  const { currentUser } = useUser();
  const [account, setAccount] = useState(null);

  const fetch = async () => {
    const { payload } = await fetchData();
    setAccount(payload.data[0]);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [currentUser]);

  const accountComponent = () => {
    const isOwner =
      account && account?.owner && account?.owner._id === currentUser?._id;
    switch (currentUser?.account_level) {
      case "basic":
        return isOwner ? (
          <BasicAccount />
        ) : (
          <ManagedAccount account={account} user={currentUser} />
        );
      case "premium":
        return isOwner ? (
          <PremiumAccount />
        ) : (
          <ManagedAccount account={account} user={currentUser} />
        );
      case "pro":
        return isOwner ? (
          <ProAccount account={account} user={currentUser} />
        ) : (
          <ManagedAccount account={account} />
        );
      case "business":
        return isOwner ? (
          <BusinessAccount account={account} user={currentUser} />
        ) : (
          <ManagedAccount account={account} />
        );
      case "enterprise":
        return account.roles[user._id] !== "user" ? (
          <EnterpriseAccount account={account} user={currentUser} />
        ) : (
          <ManagedAccount account={account} />
        );
      default:
        return null;
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header
            size={"huge"}
            content={"Billing"}
            subheader={`Account-ID: ${account && account?._id}`}
          />
        </Grid.Column>
      </Grid.Row>
      {account && currentUser ? accountComponent() : <Loader active />}
    </Grid>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(getAccountList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
