import React from "react";
import { Button, Item } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { AUCTION_HOUSE_PATH } from "../../../../constants/paths";

const AuctionHouseTile = ({ auctionHouse }) => {
  const navigate = useNavigate();

  const onButtonClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Item
      key={auctionHouse._id}
      header={auctionHouse.name}
      image={auctionHouse.image_url}
      description={auctionHouse.about}
      extra={
        <Button
          primary
          floated={"right"}
          content={"View"}
          onClick={(e) =>
            onButtonClick(e, `${AUCTION_HOUSE_PATH}/${auctionHouse._id}`)
          }
        />
      }
    />
  );
};

export default AuctionHouseTile;
