const url = "/api/v5/pieces";

// Actions

export const RESET_PIECE_STATE = "RESET_PIECE_STATE";

export const GET_PIECE_LIST = "GET_PIECE_LIST";
export const GET_PIECE_LIST_SUCCESS = "GET_PIECE_LIST_SUCCESS";
export const GET_PIECE_LIST_FAILURE = "GET_PIECE_LIST_FAILURE";

export const GET_PIECE = "GET_PIECE";
export const GET_PIECE_SUCCESS = "GET_PIECE_SUCCESS";
export const GET_PIECE_FAILURE = "GET_PIECE_FAILURE";

export const CREATE_PIECE = "CREATE_PIECE";
export const CREATE_PIECE_SUCCESS = "CREATE_PIECE_SUCCESS";
export const CREATE_PIECE_FAILURE = "CREATE_PIECE_FAILURE";

export const UPDATE_PIECE = "UPDATE_PIECE";
export const UPDATE_PIECE_SUCCESS = "UPDATE_PIECE_SUCCESS";
export const UPDATE_PIECE_FAILURE = "UPDATE_PIECE_FAILURE";

export const DELETE_PIECE = "DELETE_PIECE";
export const DELETE_PIECE_SUCCESS = "DELETE_PIECE_SUCCESS";
export const DELETE_PIECE_FAILURE = "DELETE_PIECE_FAILURE";

export const CREATE_PIECE_PDF = "CREATE_PIECE_PDF";
export const CREATE_PIECE_PDF_SUCCESS = "CREATE_PIECE_PDF_SUCCESS";
export const CREATE_PIECE_PDF_FAILURE = "CREATE_PIECE_PDF_FAILURE";

export const DELETE_PIECE_EXHIBITION = "DELETE_PIECE_EXHIBITION";
export const DELETE_PIECE_EXHIBITION_SUCCESS =
  "DELETE_PIECE_EXHIBITION_SUCCESS";
export const DELETE_PIECE_EXHIBITION_FAILURE =
  "DELETE_PIECE_EXHIBITION_FAILURE";

export const FILTER_PIECE_LIST_BY_ARTIST = "FILTER_PIECE_LIST_BY_ARTIST";
export const FILTER_PIECE_LIST_BY_GALLERY = "FILTER_PIECE_LIST_BY_GALLERY";
export const FILTER_PIECE_LIST_BY_TEXT = "FILTER_PIECE_LIST_BY_TEXT";
export const FILTER_PIECE_LIST_BY_TAG = "FILTER_PIECE_LIST_BY_TAG";
export const FILTER_PIECE_LIST_BY_CATEGORY = "FILTER_PIECE_LIST_BY_CATEGORY";

export const SORT_PIECE_LIST_BY_KEY = "SORT_PIECES";
export const TOGGLE_PIECE_LIST_DISPLAY = "TOGGLE_PIECE_LIST_DISPLAY";
export const SET_PIECE_LIST_FILTERS = "SET_PIECE_LIST_FILTERS";
export const TOGGLE_PIECE_ACTIVE_TAB = "TOGGLE_PIECE_ACTIVE_TAB";

export const SET_PIECE_ORDER_BY = "SET_PIECE_ORDER_BY";

// NEW

export const SELECT_PIECES_FIELD = "SELECT_PIECES_FIELD";
export const UNSELECT_PIECES_FIELD = "UNSELECT_PIECES_FIELD";

export const ADD_PIECES_FILTER = "ADD_PIECES_FILTER";
export const REMOVE_PIECES_FILTER = "REMOVE_PIECES_FILTER";
export const CLEAR_PIECES_FILTERS = "CLEAR_PIECES_FILTERS";
export const CHANGE_PIECES_FILTER_FIELD = "CHANGE_PIECES_FILTER_FIELD";
export const CHANGE_PIECES_FILTER_OPERATOR = "CHANGE_PIECES_FILTER_OPERATOR";
export const CHANGE_PIECES_FILTER_VALUE = "CHANGE_PIECES_FILTER_VALUE";

// Networking

export const getPieceList = (
  page = 1,
  limit = 500,
  sort = "-created_at",
  direction,
) => {
  return {
    types: [GET_PIECE_LIST, GET_PIECE_LIST_SUCCESS, GET_PIECE_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
          sort,
          direction,
        },
      },
    },
  };
};

export const getPiece = (id) => {
  return {
    types: [GET_PIECE, GET_PIECE_SUCCESS, GET_PIECE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createPiece = (data) => {
  return {
    types: [CREATE_PIECE, CREATE_PIECE_SUCCESS, CREATE_PIECE_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updatePiece = (id, data) => {
  return {
    types: [UPDATE_PIECE, UPDATE_PIECE_SUCCESS, UPDATE_PIECE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const createPiecePDF = (id, data) => {
  return {
    types: [
      CREATE_PIECE_PDF,
      CREATE_PIECE_PDF_SUCCESS,
      CREATE_PIECE_PDF_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pdf`,
        method: "POST",
        data,
      },
    },
  };
};

export const deletePiece = (id) => {
  return {
    types: [DELETE_PIECE, DELETE_PIECE_SUCCESS, DELETE_PIECE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const deletePieceExhibition = (id, exhibition) => {
  return {
    types: [
      DELETE_PIECE_EXHIBITION,
      DELETE_PIECE_EXHIBITION_SUCCESS,
      DELETE_PIECE_EXHIBITION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/exhibitions/${exhibition}`,
        method: "DELETE",
      },
    },
  };
};

export const filterPieceListByText = (search) => {
  return {
    type: FILTER_PIECE_LIST_BY_TEXT,
    search,
  };
};

export const filterPieceListByArtist = (artist) => {
  return {
    type: FILTER_PIECE_LIST_BY_ARTIST,
    artist,
  };
};

export const filterPieceListByCategory = (category) => {
  return {
    type: FILTER_PIECE_LIST_BY_CATEGORY,
    category,
  };
};

export const filterPieceListByGallery = (gallery) => {
  return {
    type: FILTER_PIECE_LIST_BY_GALLERY,
    gallery,
  };
};

export const filterPieceListByTag = (tag) => {
  return {
    type: FILTER_PIECE_LIST_BY_TAG,
    tag,
  };
};

export const sortPieceListByKey = (clickedColumn) => {
  return {
    type: SORT_PIECE_LIST_BY_KEY,
    clickedColumn,
  };
};

export const togglePieceListDisplay = (display) => {
  return {
    type: TOGGLE_PIECE_LIST_DISPLAY,
    display,
  };
};

export const setPieceListFilters = (params) => {
  return {
    type: SET_PIECE_LIST_FILTERS,
    params,
  };
};

export const resetPiece = () => {
  return {
    type: RESET_PIECE_STATE,
  };
};

export function selectField(field) {
  return { type: SELECT_PIECES_FIELD, field };
}

export function unselectField(field) {
  return { type: UNSELECT_PIECES_FIELD, field };
}

export function addFilter(field) {
  return { type: ADD_PIECES_FILTER, field };
}

export function removeFilter(filterId) {
  return { type: REMOVE_PIECES_FILTER, filterId };
}

export function clearFilters() {
  return { type: CLEAR_PIECES_FILTERS };
}

export function changeFilterField(filterId, fieldName) {
  return { type: CHANGE_PIECES_FILTER_FIELD, filterId, fieldName };
}

export function changeFilterOperator(filterId, operator) {
  return { type: CHANGE_PIECES_FILTER_OPERATOR, filterId, operator };
}

export function changeFilterValue(filterId, value) {
  return { type: CHANGE_PIECES_FILTER_VALUE, filterId, value };
}

export const setOrderBy = (orderBy) => {
  return {
    type: SET_PIECE_ORDER_BY,
    orderBy,
  };
};

export const toggleActiveTab = (tab) => {
  return {
    type: TOGGLE_PIECE_ACTIVE_TAB,
    tab,
  };
};
