import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { firestore } from "../../../../utils/firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import { useUser } from "../../../../contexts/UserContext";

const PieceFilters = ({ configuration }) => {
  const { user: auth } = useAuth();

  const onFieldChecked = async (field, checked) => {
    try {
      await firestore
        .collection("users")
        .doc(auth.uid)
        .set(
          {
            piece_list_configuration: {
              ...configuration,
              [field]: checked,
            },
          },
          {
            merge: true,
          },
        );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dropdown
      text={"Fields"}
      icon={"filter"}
      floating
      labeled
      button
      basic
      className="icon"
    >
      <Dropdown.Menu>
        <Dropdown.Header icon="tags" content="Fields" />
        <Dropdown.Menu scrolling>
          <Dropdown.Item
            key={"inventory_identifier"}
            icon={
              configuration.inventory_identifier
                ? "check square outline"
                : "square outline"
            }
            text={"Inventory Identifier"}
            onClick={() =>
              onFieldChecked(
                "inventory_identifier",
                configuration.inventory_identifier ? false : true,
              )
            }
          />

          <Dropdown.Item
            key={"artist"}
            icon={
              configuration.artist ? "check square outline" : "square outline"
            }
            text={"Artist"}
            onClick={() =>
              onFieldChecked("artist", configuration.artist ? false : true)
            }
          />

          <Dropdown.Item
            key={"gallery"}
            icon={
              configuration.gallery ? "check square outline" : "square outline"
            }
            text={"Gallery"}
            onClick={() =>
              onFieldChecked("gallery", configuration.gallery ? false : true)
            }
          />

          <Dropdown.Item
            key={"location"}
            icon={
              configuration.location ? "check square outline" : "square outline"
            }
            text={"Location"}
            onClick={() =>
              onFieldChecked("location", configuration.location ? false : true)
            }
          />

          <Dropdown.Item
            key={"purchase_price"}
            icon={
              configuration.purchase_price
                ? "check square outline"
                : "square outline"
            }
            text={"Price"}
            onClick={() =>
              onFieldChecked(
                "purchase_price",
                configuration.purchase_price ? false : true,
              )
            }
          />

          <Dropdown.Item
            key={"valuation_price"}
            icon={
              configuration.valuation_price
                ? "check square outline"
                : "square outline"
            }
            text={"Valuation"}
            onClick={() =>
              onFieldChecked(
                "valuation_price",
                configuration.valuation_price ? false : true,
              )
            }
          />
          <Dropdown.Item
            key={"category"}
            icon={
              configuration.category ? "check square outline" : "square outline"
            }
            text={"Category"}
            onClick={() =>
              onFieldChecked("category", configuration.category ? false : true)
            }
          />
          <Dropdown.Item
            key={"status"}
            icon={
              configuration.status ? "check square outline" : "square outline"
            }
            text={"Status"}
            onClick={() =>
              onFieldChecked("status", configuration.status ? false : true)
            }
          />
          <Dropdown.Item
            key={"owned_by"}
            icon={
              configuration.owned_by ? "check square outline" : "square outline"
            }
            text={"Owned By"}
            onClick={() =>
              onFieldChecked("owned_by", configuration.owned_by ? false : true)
            }
          />
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PieceFilters;
