import {
  GET_GALLERY_PROPOSAL_LIST,
  GET_GALLERY_PROPOSAL_LIST_FAILURE,
  GET_GALLERY_PROPOSAL_LIST_SUCCESS,
} from "../actions/gallery_proposal";

const INITIAL_STATE = {
  isLoadingGalleryProposalList: true,
  galleryProposals: [],
  hasMoreGalleryProposals: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GALLERY_PROPOSAL_LIST:
      return {
        ...state,
        isLoadingGalleryProposalList: true,
        hasMoreGalleryProposals: true,
      };
    case GET_GALLERY_PROPOSAL_LIST_SUCCESS:
      return {
        ...state,
        isLoadingGalleryProposalList: false,
        galleryProposals: action.payload,
        hasMoreGalleryProposals: action.payload.length > 0,
      };
    case GET_GALLERY_PROPOSAL_LIST_FAILURE:
      return {
        ...state,
        isLoadingGalleryProposalList: false,
        hasMoreGalleryProposals: false,
      };
    default:
      return state;
  }
};
