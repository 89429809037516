import React, { useState } from "react";
import { Card, Grid, Header, Select } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import { usePieces } from "../../../../../contexts/PiecesContext";
import { useUser } from "../../../../../contexts/UserContext";

const Overview = ({ summary }) => {
  const { user } = useUser();
  const { pieces, year, setYear } = usePieces();

  if (!summary) return null;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: user?.preferred_currency || "USD",
    minimumFractionDigits: 2,
  });

  const piecesWithPurchaseDates = pieces?.filter(
    (piece) => piece.purchase_date,
  );
  const years = new Set(
    piecesWithPurchaseDates?.map((piece) => moment(piece.purchase_date).year()),
  );
  const yearOptions = [...years]
    .map((year) => ({
      key: year,
      text: year,
      value: year,
    }))
    .sort((a, b) => b.value - a.value);
  const filteredPieces = pieces?.filter((piece) => {
    if (!year) return true;
    return moment(piece.purchase_date).year() === year;
  });

  const cost = (year ? filteredPieces : pieces).reduce((sum, piece) => {
    const updatedSum = sum + (piece.purchase_price || 0);
    return updatedSum;
  }, 0);

  const valuation = (year ? filteredPieces : pieces).reduce((sum, piece) => {
    const updatedSum =
      sum + (piece.valuation_price || piece.purchase_price || 0);
    return updatedSum;
  }, 0);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <Header floated={"left"} content="Collection Overview" />
        </Grid.Column>
        <Grid.Column width={4}>
          <Select
            fluid
            placeholder="Select Year"
            options={yearOptions}
            onChange={(e, { value }) => setYear(value)}
            value={year}
            style={{ marginBottom: "1em" }}
            clearable
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Card.Group stackable centered itemsPerRow={3}>
            <Card
              key="number_of_pieces"
              content={
                <Header
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  textAlign={"center"}
                  size={"huge"}
                  content={year ? filteredPieces.length : summary.pieces_count}
                  subheader={"Number of Pieces"}
                />
              }
            />
            <Card
              key="cost"
              content={
                <Header
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  textAlign={"center"}
                  size={"huge"}
                  content={year ? formatter.format(cost) : summary.cost_string}
                  subheader={"Amount Spent"}
                />
              }
            />
            <Card
              key="valuation"
              content={
                <Header
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  textAlign={"center"}
                  size={"huge"}
                  content={
                    year
                      ? formatter.format(valuation)
                      : summary.valuation_string
                  }
                  subheader={"Valuation"}
                />
              }
            />
          </Card.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ analytics }) => ({
  currency: (analytics.analytics && analytics.analytics.currency) || "USD",
  summary: (analytics.analytics && analytics.analytics.summary) || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
