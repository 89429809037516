const url = "/api/v5/appraisals";

// Actions

export const CREATE_APPRAISAL = "CREATE_APPRAISAL";
export const CREATE_APPRAISAL_SUCCESS = "CREATE_APPRAISAL_SUCCESS";
export const CREATE_APPRAISAL_FAILURE = "CREATE_APPRAISAL_FAILURE";

export const UPDATE_APPRAISAL = "UPDATE_APPRAISAL";
export const UPDATE_APPRAISAL_SUCCESS = "UPDATE_APPRAISAL_SUCCESS";
export const UPDATE_APPRAISAL_FAILURE = "UPDATE_APPRAISAL_FAILURE";

export const DELETE_APPRAISAL = "DELETE_APPRAISAL";
export const DELETE_APPRAISAL_SUCCESS = "DELETE_APPRAISAL_SUCCESS";
export const DELETE_APPRAISAL_FAILURE = "DELETE_APPRAISAL_FAILURE";

// Networking

export const createAppraisal = (data) => {
  return {
    types: [
      CREATE_APPRAISAL,
      CREATE_APPRAISAL_SUCCESS,
      CREATE_APPRAISAL_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAppraisal = (id, data) => {
  return {
    types: [
      UPDATE_APPRAISAL,
      UPDATE_APPRAISAL_SUCCESS,
      UPDATE_APPRAISAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deleteAppraisal = (id, data) => {
  return {
    types: [
      DELETE_APPRAISAL,
      DELETE_APPRAISAL_SUCCESS,
      DELETE_APPRAISAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};
