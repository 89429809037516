import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import placeholder from "../../images/placeholder.png";
import { useNavigate } from "react-router-dom";
import { GALLERY_PATH } from "../../constants/paths";

const GalleryCard = ({ gallery }) => {
  const navigate = useNavigate();
  const { _id, name, medium_image_url } = gallery;

  return (
    <Card onClick={() => navigate(`${GALLERY_PATH}/${_id}`)}>
      <img
        src={medium_image_url || placeholder}
        style={{
          objectFit: "cover",
          objectPosition: "top",
          height: "100%",
          maxHeight: 240,
        }}
        alt={name}
      />
      <Card.Content>
        <Card.Header content={name} />
      </Card.Content>
    </Card>
  );
};

GalleryCard.propTypes = {
  gallery: PropTypes.object,
};

export default GalleryCard;
