import React, { Component } from "react";
import { Form, Header, Grid } from "semantic-ui-react";

import { currencyOptions } from "../../common";
import SelectGalleryModal from "../Modals/Gallery/Select";
import FormDatePicker from "./FormDatePicker";
import ValuationAttachments from "../../routes/Pieces/components/EditPieceSidePane/Attachments/ValuationAttachments";

const ValuationInfoForm = ({ piece, onUpdate }) => {
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });

  const {
    valuation_currency,
    valuation_price,
    valuation_notes,
    valuation_attachments,
    owned_by,
    replacement_currency,
    replacement_price,
  } = piece;

  return (
    <Form>
      <Form.Group>
        <Header size="large">Valuation Details</Header>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Market Value</label>
        </Form.Field>
        <Form.Select
          fluid
          name="valuation_currency"
          placeholder="$"
          defaultValue={valuation_currency || "USD"}
          options={currencyOptions}
          autoComplete={"off"}
          onChange={handleChange}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="valuation_price"
          placeholder="Market Value"
          defaultValue={valuation_price}
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={handleChange}
          width={10}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Replacement Value</label>
        </Form.Field>
        <Form.Select
          fluid
          name="replacement_currency"
          placeholder="$"
          defaultValue={replacement_currency || "USD"}
          options={currencyOptions}
          autoComplete={"off"}
          onChange={handleChange}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="replacement_price"
          placeholder="Market Value"
          defaultValue={replacement_price}
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={handleChange}
          width={10}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Owned by</label>
        </Form.Field>
        <Form.Input
          fluid
          name={"owned_by"}
          width={12}
          placeholder={"Owned By"}
          defaultValue={owned_by}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name={"valuation_notes"}
          placeholder="Valuation Notes"
          defaultValue={valuation_notes}
          onChange={handleChange}
        />
      </Form.Group>
      <ValuationAttachments
        attachments={valuation_attachments}
        onAttachmentsUpdated={(attachments) =>
          onUpdate({ valuation_attachments: attachments })
        }
      />
    </Form>
  );
};
export default ValuationInfoForm;
