import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_SUCCESS,
} from "../actions/subscription";

import { GET_CURRENT_USER_SUCCESS } from "../actions/me";

const INITIAL_STATE = {
  isCreatingSubscription: false,
  subscription: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isCreatingSubscription: false,
        subscription: action.payload.data && action.payload.data.subscription,
      };
    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        isCreatingSubscription: true,
        error: null,
      };

    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isCreatingSubscription: false,
        subscription: action.payload.data,
      };

    case CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isCreatingSubscription: false,
        error: action.error,
      };

    default:
      return state;
  }
};
