const url = "/api/v5/accounts";

export const GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST";
export const GET_ACCOUNT_LIST_SUCCESS = "GET_ACCOUNT_LIST_SUCCESS";
export const GET_ACCOUNT_LIST_FAILURE = "GET_ACCOUNT_LIST_FAILURE";

export const getAccountList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_ACCOUNT_LIST,
      GET_ACCOUNT_LIST_SUCCESS,
      GET_ACCOUNT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};
