import React from "react";
import PropTypes from "prop-types";
import { Image, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { priceString } from "../../../../../../utils/string";

const PrivateRoomPieceRecordTile = ({ piece, fields }) => {
  const navigate = useNavigate();
  const {
    _id,
    title,
    artist,
    purchase_price,
    currency,
    gallery,
    valuation_price,
    valuation_currency,
  } = piece;

  const purchasePrice = purchase_price
    ? priceString(purchase_price, currency)
    : "";
  const valuationPrice = valuation_price
    ? priceString(valuation_price, valuation_currency)
    : "";

  return (
    <Table.Row
      key={_id}
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/piece/${_id}`)}
    >
      <Table.Cell>
        <Image
          src={
            piece.images && piece.images[0] && piece.images[0].small_image_url
          }
          avatar
        />
        {title}
      </Table.Cell>
      {fields.artist && <Table.Cell content={artist && artist.name} />}
      {fields.gallery && <Table.Cell content={gallery && gallery.name} />}
      {fields.purchase_price && <Table.Cell content={purchasePrice} />}
      {fields.valuation_price && <Table.Cell content={valuationPrice} />}
    </Table.Row>
  );
};

PrivateRoomPieceRecordTile.propTypes = {
  piece: PropTypes.object,
};

export default PrivateRoomPieceRecordTile;
