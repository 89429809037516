import React from "react";
import { Image, Table } from "semantic-ui-react";
import placeholder from "../../images/placeholder.png";
import { useNavigate } from "react-router-dom";
import { GALLERY_PATH } from "../../constants/paths";

const GalleryCell = ({ gallery }) => {
  const navigate = useNavigate();
  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const { _id, name, small_image_url } = gallery;

  return (
    <Table.Row onClick={(e) => onRowClick(e, `${GALLERY_PATH}/${_id}`)}>
      <Table.Cell>
        <Image src={small_image_url || placeholder} avatar />
        {name}
      </Table.Cell>
    </Table.Row>
  );
};

export default GalleryCell;
