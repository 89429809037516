import React from "react";
import { Button, Divider, Header, Table, Grid } from "semantic-ui-react";
import moment from "moment";
import { storage } from "../../../../../../../../utils/firebase";

const Appraisals = ({ piece }) => {
  const { appraisals } = piece;

  const downloadFile = async (fileReference, publicURL) => {
    if (fileReference) {
      const fileURL = await storage.ref(fileReference).getDownloadURL();
      window.open(fileURL);
    } else {
      window.open(publicURL);
    }
  };

  if (!appraisals || appraisals.length === 0) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Appraisals"} dividing />
        <Table striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell width={2} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(appraisals || []).map((appraisal) => {
              return (
                <Table.Row key={appraisal._id}>
                  <Table.Cell>
                    {appraisal.date &&
                      moment(appraisal.date).format("MMM Do YYYY")}
                  </Table.Cell>
                  <Table.Cell>{appraisal.valueString()}</Table.Cell>
                  <Table.Cell>
                    <Button.Group floated={"right"}>
                      <Button
                        basic
                        icon={"download"}
                        onClick={() =>
                          downloadFile(appraisal.file_reference, appraisal.url)
                        }
                      />
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Appraisals;
