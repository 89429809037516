import {
  GET_AUCTION_RECORD,
  GET_AUCTION_RECORD_FAILURE,
  GET_AUCTION_RECORD_SUCCESS,
  RESET_AUCTION_RECORD_STATE,
} from "../actions/auction_result";

import AuctionRecord from "../../models/auction_record";

const INITIAL_STATE = {
  isLoadingAuctionRecord: true,
  auctionRecord: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_AUCTION_RECORD_STATE: {
      return INITIAL_STATE;
    }
    case GET_AUCTION_RECORD:
      return { ...INITIAL_STATE };
    case GET_AUCTION_RECORD_SUCCESS:
      return {
        ...state,
        isLoadingAuctionRecord: false,
        auctionRecord: new AuctionRecord(action.payload.data),
        hasMoreAuctionRecords: true,
        hasMoreAuctionPieces: true,
      };
    case GET_AUCTION_RECORD_FAILURE:
      return {
        ...state,
        isLoadingAuctionRecord: false,
        error: action.error,
      };

    default:
      return state;
  }
};
