import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import LocationCard from "../../../../components/GridCells/LocationCard";

const LocationsGrid = ({ locations, user }) => {
  if (!locations || locations.size === 0) return null;

  return (
    <Card.Group stackable centered itemsPerRow={4}>
      {locations.map((location) => (
        <LocationCard
          key={location._id}
          storageLocation={location}
          user={user}
        />
      ))}
    </Card.Group>
  );
};

LocationsGrid.propTypes = {
  user: PropTypes.object,
  locations: PropTypes.array,
};

export default LocationsGrid;
