import { Provider } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { Loader } from "semantic-ui-react";

import { store } from "../redux/store";
import CoreLayout from "../layouts/CoreLayout";

// Auth Components
import LoginPage from "../routes/Auth/components/FirebasePage";
import RegisterPage from "../routes/Register/components/RegisterPage";

// Dashboard
import DashboardPage from "../routes/Dashboard/components/DashboardPage";

// Artists
import ArtistsList from "../routes/Artists/components/ArtistsList";
import ArtistDetails from "../routes/Artists/routes/Artist/components/ArtistDetails";
import ArtistGroupingList from "../routes/ArtistGroupingList";

// Galleries
import GalleriesList from "../routes/Galleries/components/GalleriesList";
import GalleryDetails from "../routes/Galleries/routes/Gallery/components/GalleryDetails";

// Locations
import LocationsList from "../routes/Locations/components/LocationsList";
import LocationDetails from "../routes/Locations/routes/Location/components/LocationDetails";

// Pieces
import PiecesList from "../routes/Pieces/components/PiecesList";
import PieceDetails from "../routes/Pieces/routes/Piece/components/PieceDetails";

// Auctions
import AuctionsList from "../routes/Auctions/components/AuctionsList";
import AdminAuctionsList from "../routes/Auctions/components/AdminAuctionsList";
import AuctionDetails from "../routes/Auctions/routes/Auction/components/AuctionDetails";
import AuctionRecordsList from "../routes/AuctionRecords/components/AuctionRecordsList/AuctionRecordsList";
import AuctionRecordDetails from "../routes/AuctionRecords/routes/AuctionRecord/components/AuctionRecordDetails";

// Auction Houses
import AuctionHouseList from "../routes/AuctionHouses/components/AuctionHousesList";
import AuctionHouseDetails from "../routes/AuctionHouses/routes/AuctionHouse/components/AuctionHouseDetails";

// Museums
import MuseumList from "../routes/Museums/components/MuseumsList";
import MuseumDetails from "../routes/Museums/routes/Museum/components/MuseumDetails";

// Private Rooms
import PrivateRoomList from "../routes/PrivateRooms/components/PrivateRoomsList";
import PrivateRoomDetails from "../routes/PrivateRooms/routes/PrivateRoom/components/PrivateRoomDetails";

// Products
import ProductsList from "../routes/Product/components/ProductsList";
import ProductDetails from "../routes/Product/routes/Product/components/ProductDetails";

// Artworks
import ArtworksList from "../routes/Artworks/components/ArtworksList";
import ArtworkDetails from "../routes/Artworks/routes/Artwork/components/ArtworkDetails";

// Other Routes
import AppraisalRequest from "../routes/AppraisalRequest/AppraisalRequestPage";
import AccountPage from "../routes/Account/components/AccountPage";
import ImportPage from "../routes/Import/components/ImportPage";
import GroupingDetail from "../routes/GroupingDetail";
import GroupingList from "../routes/GroupingList";
import InvitationDetail from "../routes/Invitation/routes/Invitation/components/InvitationDetail";

// Paths
import {
  ARTIST_PATH,
  LOGIN_PATH,
  GALLERY_PATH,
  LOCATION_PATH,
  PIECE_PATH,
  AUCTION_PATH,
  AUCTION_RECORD_PATH,
  AUCTION_HOUSE_PATH,
  MUSEUM_PATH,
  PRIVATE_ROOM_PATH,
  APPRAISAL_REQUEST_PATH,
  ACCOUNT_PATH,
  PRODUCT_PATH,
  DASHBOARD_PATH,
  REGISTER_PATH,
  ARTWORKS_PATH,
  IMPORT_PATH,
  INVITATIONS_PATH,
  FIREBASE_PATH,
  GROUPINGS_PATH,
  REFERRAL_PATH,
} from "../constants/paths";

// Context Providers
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import { UserProvider } from "../contexts/UserContext";
import { AccountProvider } from "../contexts/AccountContext";
import { PiecesProvider } from "../contexts/PiecesContext";
import { TagsProvider } from "../contexts/TagsContext";
import { LocationsProvider } from "../contexts/LocationsContext";
import { PrivateRoomsProvider } from "../contexts/PrivateRoomsContext";
import { CurrenciesProvider } from "../contexts/CurrenciesContext";

// Utility function for role check
const getUserRole = () => localStorage.getItem("role");

// Authentication Route Guards
const AuthenticatedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const referral = new URLSearchParams(window.location.search).get("referral");

  if (loading) return <Loader active />;

  let to = REGISTER_PATH;
  if (referral) {
    to += `?referral=${referral}`;
  }

  return user ? (
    children
  ) : (
    <Navigate
      to={to}
      replace
      state={{ from: { pathname: location.pathname, search: location.search } }}
    />
  );
};

const UnauthenticatedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <Loader active />;
  return user ? <Navigate to={DASHBOARD_PATH} replace /> : children;
};

// Context Wrapper
const AppProviders = ({ children }) => (
  <AuthProvider>
    <UserProvider>
      <AccountProvider>
        <PiecesProvider>
          <TagsProvider>
            <LocationsProvider>
              <PrivateRoomsProvider>
                <CurrenciesProvider>
                  <Provider store={store}>{children}</Provider>
                </CurrenciesProvider>
              </PrivateRoomsProvider>
            </LocationsProvider>
          </TagsProvider>
        </PiecesProvider>
      </AccountProvider>
    </UserProvider>
  </AuthProvider>
);

const App = () => {
  return (
    <AppProviders>
      <BrowserRouter>
        <CoreLayout>
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <DashboardPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={LOGIN_PATH}
              element={
                <UnauthenticatedRoute>
                  <LoginPage />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path={FIREBASE_PATH}
              element={
                <UnauthenticatedRoute>
                  <LoginPage />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path={REGISTER_PATH}
              element={
                <UnauthenticatedRoute>
                  <RegisterPage />
                </UnauthenticatedRoute>
              }
            />

            <Route
              path={ARTIST_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<ArtistsList />} />
              <Route path=":artistId" element={<ArtistDetails />} />
              <Route
                path=":artistId${GROUPINGS_PATH}"
                element={<ArtistGroupingList />}
              />
            </Route>

            <Route
              path={GALLERY_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<GalleriesList />} />
              <Route path=":galleryId" element={<GalleryDetails />} />
            </Route>

            <Route
              path={LOCATION_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<LocationsList />} />
              <Route path=":locationId" element={<LocationDetails />} />
            </Route>

            <Route
              path={PIECE_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<PiecesList />} />
              <Route path=":pieceId" element={<PieceDetails />} />
            </Route>

            <Route
              path={AUCTION_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route
                index
                element={
                  getUserRole() === "admin" ? (
                    <AdminAuctionsList />
                  ) : (
                    <AuctionsList />
                  )
                }
              />
              <Route path=":auctionId" element={<AuctionDetails />} />
            </Route>

            <Route
              path={AUCTION_RECORD_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<AuctionRecordsList />} />
              <Route
                path=":auctionRecordId"
                element={<AuctionRecordDetails />}
              />
            </Route>

            <Route
              path={AUCTION_HOUSE_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<AuctionHouseList />} />
              <Route path=":auctionHouseId" element={<AuctionHouseDetails />} />
            </Route>

            <Route
              path={MUSEUM_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<MuseumList />} />
              <Route path=":museumId" element={<MuseumDetails />} />
            </Route>

            <Route
              path={PRIVATE_ROOM_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<PrivateRoomList />} />
              <Route path=":privateRoomId" element={<PrivateRoomDetails />} />
            </Route>

            <Route path={PRODUCT_PATH} element={<Outlet />}>
              <Route index element={<ProductsList />} />
              <Route
                path=":productId"
                element={
                  <AuthenticatedRoute>
                    <ProductDetails />
                  </AuthenticatedRoute>
                }
              />
            </Route>

            <Route
              path={ARTWORKS_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<ArtworksList />} />
              <Route path=":artworkId" element={<ArtworkDetails />} />
            </Route>

            <Route
              path={ACCOUNT_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<AccountPage />} />
              <Route path="*" element={<AccountPage />} />
            </Route>

            <Route
              path={APPRAISAL_REQUEST_PATH}
              element={
                <AuthenticatedRoute>
                  <AppraisalRequest />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={INVITATIONS_PATH}
              element={
                <AuthenticatedRoute>
                  <InvitationDetail />
                </AuthenticatedRoute>
              }
            />

            <Route
              path={GROUPINGS_PATH}
              element={
                <AuthenticatedRoute>
                  <Outlet />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<GroupingList />} />
              <Route path=":groupingId" element={<GroupingDetail />} />
            </Route>

            <Route
              path={IMPORT_PATH}
              element={
                <AuthenticatedRoute>
                  <ImportPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="*"
              element={
                <AuthenticatedRoute>
                  <DashboardPage />
                </AuthenticatedRoute>
              }
            />
          </Routes>
        </CoreLayout>
      </BrowserRouter>
    </AppProviders>
  );
};

export default App;
