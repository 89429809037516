import {
  SET_PIECE_ORDER_BY,
  TOGGLE_PIECE_ACTIVE_TAB,
  TOGGLE_PIECE_LIST_DISPLAY,
} from "../actions/piece";

const INITIAL_STATE = {
  activeTab: "all",
  display: "table",
  orderBy: ["title", "asc"],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_PIECE_LIST_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    case SET_PIECE_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
      };
    case TOGGLE_PIECE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    default:
      return state;
  }
};
