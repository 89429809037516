import React from "react";
import Attachments from "../BasicInfo/Attachments";
import PropTypes from "prop-types";

const PurchaseAttachments = ({ attachments, onAttachmentsUpdated }) => (
  <Attachments
    attachments={attachments}
    type={"purchase"}
    onAttachmentsUpdated={onAttachmentsUpdated}
  />
);

PurchaseAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
};

export default PurchaseAttachments;
