import React from "react";
import { Header, Image, Segment, Table } from "semantic-ui-react";
import placeholder from "../../../../../images/placeholder.png";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ARTIST_PATH } from "../../../../../constants/paths";

const Artists = ({ topArtists }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header content="Top Artists" />
      <Segment basic style={{ padding: 0 }}>
        <Table selectable>
          <Table.Body>
            {(topArtists || []).map((data) => {
              return (
                <Table.Row
                  key={data.artist._id}
                  onClick={() => navigate(`${ARTIST_PATH}/${data.artist._id}`)}
                >
                  <Table.Cell>
                    <Image
                      avatar
                      src={data.artist.small_image_url || placeholder}
                    />
                    {data.artist.name}
                  </Table.Cell>
                  <Table.Cell textAlign="right">{data.count}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

const mapStateToProps = ({ analytics }) => ({
  topArtists: (analytics.analytics && analytics.analytics.top_artists) || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
