import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import FormDatePicker from "../../../../../components/Forms/FormDatePicker";
import { createAppraisal } from "../../../../../redux/actions/appraisal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Appraisal from "../../../../../models/appraisal";
import { currencyOptions } from "../../../../../common";
import DropZone from "../../../../../components/DropZone";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { useUser } from "../../../../../contexts/UserContext";

const AddAppraisalModal = ({ icon, floated, create, onAppraisalCreated }) => {
  const { currentUser } = useUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(new Date());
  const [currency, setCurrency] = useState(
    currentUser.preferred_currency || "USD",
  );
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState(null);

  const handleSubmit = async () => {
    const data = new FormData();
    if (file) data.append("file", file);
    if (date) data.append("date", date);
    if (value) data.append("value", value);
    if (currency) data.append("currency", currency);
    if (notes) data.append("notes", notes);

    setError(null);
    setLoading(true);

    try {
      const { payload } = await create(data);
      onAppraisalCreated(new Appraisal(payload.data));

      setNotes(null);
      setFile(null);
      setDate(null);
      setCurrency(null);
      setValue(null);
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Modal
      trigger={
        <Button
          basic
          icon={icon}
          content={"Add Appraisal"}
          labelPosition={icon ? "left" : null}
          floated={floated}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header size="huge" content="Add Appraisal" />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <label>File</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                file={file}
                icon={"file outline"}
                placeholder={"Drag file here or click to upload"}
                accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Appraisal Date</label>
            </Form.Field>
            <Form.Field width={12}>
              <FormDatePicker
                placeholder="Appraisal Date"
                autoComplete={"off"}
                handleChange={(e, { value }) => setDate(value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Value</label>
            </Form.Field>
            <Form.Field width={4}>
              <Form.Select
                search
                placeholder="Currency"
                options={currencyOptions}
                autoComplete={"off"}
                onChange={(e, { value }) => setCurrency(value)}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Form.Input
                placeholder="Value"
                type="number"
                step="0.01"
                autoComplete={"off"}
                onChange={(e, { value }) => setValue(value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Notes</label>
            </Form.Field>
            <Form.Field width={12}>
              <Form.TextArea
                placeholder="Notes"
                autoComplete={"off"}
                onChange={(e, { value }) => setNotes(value)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content={"Close"} onClick={() => setModalOpen(false)} />
        <Button
          primary
          content={"Submit"}
          loading={loading}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

AddAppraisalModal.propTypes = {
  create: PropTypes.func,
  handleClose: PropTypes.func,
  onAppraisalCreated: PropTypes.func,
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createAppraisal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAppraisalModal);
