import React, { useState } from "react";
import { Button, Grid, Header, Message, Table } from "semantic-ui-react";
import { importCollectionFromFile } from "../../../../../redux/actions/import";
import { getPieceList } from "../../../../../redux/actions/piece";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../../../components/ErrorMessage";

const StepThree = ({
  objects,
  file,
  mapping,
  onBack,
  importCollection,
  fetchPieces,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [importingAll, setImportingAll] = useState(false);

  const handleImportingAll = async () => {
    setError(null);
    setImportingAll(true);

    try {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("mapping", JSON.stringify(mapping));
      const { payload } = await importCollection(formData);
      await fetchPieces();
      navigate("/piece");
    } catch (e) {
      setError(e);
    }

    setImportingAll(false);
  };

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          {importingAll && (
            <Message
              header={"Import In Progress"}
              content={"Please do not leave this screen."}
            />
          )}
          {error && <ErrorMessage error={error} />}
          <Header
            size={"huge"}
            content={"Confirm Import"}
            subheader={
              "Below is a sample of the data you are about to import. If everything looks good, please hit the Import Artworks button below."
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Table fluid>
            <Table.Header>
              <Table.Row>
                {Object.values(mapping).map((key) => (
                  <Table.HeaderCell content={key} />
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {objects.map((object) => (
                <Table.Row key={object.id}>
                  {Object.keys(mapping).map((key) => (
                    <Table.Cell content={object[key]} />
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button content={"Back"} disabled={importingAll} onClick={onBack} />
          <Button
            loading={importingAll}
            black
            primary
            content={`Import Artworks`}
            onClick={handleImportingAll}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  importCollection: (data) => dispatch(importCollectionFromFile(data)),
  fetchPieces: () => dispatch(getPieceList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
