import React, { useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Button, Grid, Item, List, Message, Select } from "semantic-ui-react";
import { clean } from "../../../../../utils/utils";
import { pieceCriteria } from "../../../../../common";
import {
  primeImportFile,
  secondaryImportFile,
} from "../../../../../redux/actions/import";

const StepTwo = ({ objects, file, onBack, onStepTwoComplete }) => {
  const [keys, setKeys] = useState(Object.keys(objects[0]));
  const [loading, setLoading] = useState(false);
  const [criteria, setCriteria] = useState({
    title: null,
    date: null,
    artist: null,
    gallery: null,
    medium: null,
    description: null,
    purchase_price: null,
    valuation: null,
    purchase_date: null,
    location: null,
    size: null,
    width: null,
    height: null,
    inventory_identifier: null,
  });

  const handleNext = async () => {
    setLoading(true);

    try {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("mapping", JSON.stringify(clean(criteria)));
      const { payload } = await secondaryImportFile(formData);
      onStepTwoComplete(payload.data, clean(criteria));
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <Grid celled>
      {objects && objects.length === 0 && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <Message
              error
              header={"There was an issue with the file you uploaded."}
              content={
                "The file is either corrupted or contains 0 elements. Please verify the format of your file and try again"
              }
            />
          </Grid.Column>
        </Grid.Row>
      )}
      {objects && objects.length !== 0 && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <Item.Group divided>
              {keys.map((key) => (
                <Item key={key}>
                  <Item.Content verticalAlign="middle">
                    <Item.Header content={key} />
                    <Item.Description>
                      <List as="ul" size={"mini"}>
                        {objects
                          .filter((o) => o[key])
                          .slice(0, 5)
                          .map((s) => (
                            <List.Item
                              as="li"
                              key={uuidv4()}
                              content={s[key].substring(0, 300)}
                            />
                          ))}
                      </List>
                    </Item.Description>
                    <Item.Extra>
                      <Select
                        name={key}
                        options={pieceCriteria}
                        search
                        clearable
                        placeholder={"Select Field"}
                        onChange={(e, { name, value }) =>
                          setCriteria({
                            ...criteria,
                            [name]: value,
                          })
                        }
                      />
                      <Button
                        icon={"trash"}
                        content={"Delete Field"}
                        onClick={() => setKeys(keys.filter((a) => a !== key))}
                      />
                    </Item.Extra>
                  </Item.Content>
                </Item>
              ))}
            </Item.Group>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          {Object.values(clean(criteria)).length === 0 && (
            <p>*Please map the fields to their appropriate property</p>
          )}
          <Button content={"Back"} onClick={onBack} />
          <Button
            primary
            disabled={Object.values(clean(criteria)).length === 0}
            content={"Next"}
            onClick={handleNext}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  primeImportFile: (data) => dispatch(primeImportFile(data)),
  secondaryImportFile: (data) => dispatch(secondaryImportFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
