import React, { useEffect } from "react";
import { Item, Label } from "semantic-ui-react";
import { AUCTION_RECORD_PATH } from "../../../../constants/paths";
import placeholder from "../../../../images/placeholder.png";
import moment from "moment";
import AuctionRecord from "../../../../models/auction_record";
import { Link, useNavigate } from "react-router-dom";
import { useCurrencies } from "../../../../contexts/CurrenciesContext";

const AuctionRecordsTable = ({ auctionRecords, user, currencies }) => {
  const navigate = useNavigate();

  return (
    <Item.Group divided>
      {auctionRecords.map((auctionRecord, index) => {
        const record = new AuctionRecord(auctionRecord);
        return (
          <Item key={index}>
            <Item.Image
              src={record.medium_image_url || record.image_url || placeholder}
              size="tiny"
              onClick={() => navigate(`${AUCTION_RECORD_PATH}/${record._id}`)}
            />
            <Item.Content>
              <Item.Header
                as="a"
                onClick={() => navigate(`${AUCTION_RECORD_PATH}/${record._id}`)}
              >
                {record.title}
              </Item.Header>
              <Item.Meta>
                <Link to={`/artists/${record.artist._id}`} className="cinema">
                  {record.artist.name}
                </Link>
              </Item.Meta>
              <Item.Description>
                {record.medium && (
                  <p>
                    <strong>Medium</strong>: {record.medium}
                  </p>
                )}
                {record.date && (
                  <p>
                    <strong>Year of Work</strong>:{" "}
                    {moment(record.date).format("YYYY-MM-DD")}
                  </p>
                )}
                {record.size && (
                  <p>
                    <strong>Dimensions</strong>: {record.size}
                  </p>
                )}
                {record.estimatePriceString(currencies, user) && (
                  <p>
                    <strong>Estimate</strong>:{" "}
                    {record.estimatePriceString(currencies, user)}
                  </p>
                )}
                {record.hammer_price &&
                  record.hammerPriceString(currencies, user) && (
                    <p>
                      <strong>Sold For</strong>:{" "}
                      {record.hammerPriceString(currencies, user)}
                    </p>
                  )}
                {record.auction && (
                  <p>
                    <strong>Auction</strong>:{" "}
                    <Link to={`/auction/${record.auction._id}`}>
                      {record.auction.name}
                    </Link>
                  </p>
                )}
                {record.auction && record.auction.auction_house && (
                  <p>
                    <strong>Auction House</strong>:{" "}
                    <Link
                      to={`/auction_house/${record.auction.auction_house._id}`}
                    >
                      {record.auction.auction_house.name}
                    </Link>
                  </p>
                )}
              </Item.Description>
            </Item.Content>
          </Item>
        );
      })}
    </Item.Group>
  );
};

export default AuctionRecordsTable;
