import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import placeholder from "../../../../../../../../images/placeholder.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PieceImages = ({ piece, onImageSelected }) => {
  const { images } = piece;

  if (!images) {
    return null;
  }

  return (
    <Carousel>
      {(piece.images || []).map((image) => (
        <div key={image._id} onClick={() => onImageSelected(image._id)}>
          <img
            style={{ maxHeight: "40em", maxWidth: "40em" }}
            key={image._id}
            src={image.image_url || placeholder}
            alt={image._id}
          />
        </div>
      ))}
    </Carousel>
  );
};

PieceImages.propTypes = {
  piece: PropTypes.object,
};

export default PieceImages;
