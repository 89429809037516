import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useAuth } from "./AuthContext";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../utils/firebase";
import { useUser } from "./UserContext";

const AccountContext = createContext(undefined);

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within a AccountProvider");
  }
  return context;
};

export const AccountProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!currentUser) return;
    const docRef = doc(
      collection(firestore, "accounts"),
      currentUser.current_account_id,
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        setLoading(false);
        if (docSnapshot.exists()) {
          setAccount({ id: docSnapshot.id, ...docSnapshot.data() });
        } else {
          setError("Account not found.");
        }
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  return (
    <AccountContext.Provider value={{ account, loading }}>
      {children}
    </AccountContext.Provider>
  );
};
