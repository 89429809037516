import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Loader } from "semantic-ui-react";
import { getAnalytics } from "../../../../redux/actions/analytics";
import DashboardArtists from "./components/DashboardArtists";
import DashboardGalleries from "./components/DashboardGalleries";
import DashboardOverview from "./components/DashboardOverview";
import UpcomingLots from "./components/UpcomingLots";
import PastLots from "./components/PastLots";
import ErrorMessage from "../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../utils/Mixpanel";
import AuthLayout from "../../../../layouts/AuthLayout";
import OnboardingModal from "../../../../components/Modals/Onboarding";

const DashboardPage = ({ fetchAnalytics }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      await fetchAnalytics();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    Mixpanel.page("dashboard");
    fetchData();
  }, []);

  return (
    <AuthLayout title={"Dashboard"} bannersEnabled={true}>
      <OnboardingModal />
      <Grid stackable>
        {loading && <Loader active />}
        {error && (
          <Grid.Row>
            <Grid.Column width={16}>
              <ErrorMessage error={error} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={12}>
            <DashboardOverview />
            <UpcomingLots />
            <PastLots />
          </Grid.Column>
          <Grid.Column width={4}>
            <DashboardArtists />
            <DashboardGalleries />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ analytics }) => ({
  analytics: analytics.analytics,
  loading: analytics.isLoadingAnalytics,
  currency: analytics.currency || "USD",
});

const mapDispatchToProps = (dispatch) => ({
  fetchAnalytics: (currency) => dispatch(getAnalytics(currency)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
