import React from "react";
import { connectAutoComplete } from "react-instantsearch/connectors";
import { Input, Search } from "semantic-ui-react";
import placeholder from "../images/placeholder.png";
import { searchClient } from "../utils/algolia";
import { Configure, Index, InstantSearch } from "react-instantsearch/dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const SearchBox = (props) => {
  const navigate = useNavigate();
  let results = {};

  for (var i in props.hits) {
    let index = props.hits[i];
    let name = index.index;

    if (index.hits && index.hits.length > 0) {
      results[name] = {
        key: name,
        id: name,
        name: name.charAt(0).toUpperCase() + name.slice(1),
        results: index.hits.map((hit) => {
          let object = {};
          object.id = hit.objectID;
          object.key = hit.objectID;
          object.title = hit.name || hit.title;
          object.description = hit.artist && hit.artist.name;
          object.value = `/${name}/${hit.objectID}`;
          object.image = hit.small_image_url || placeholder;
          return object;
        }),
      };
    }
  }

  return (
    <Search
      category
      style={{ flexGrow: 2, borderWidth: 0 }}
      input={
        <Input
          className={"nav-search-box"}
          fluid
          icon={"search"}
          iconPosition={"left"}
          placeholder={"Search by artist, gallery, piece, or location"}
          autoComplete={"off"}
        />
      }
      onResultSelect={(e, { result }) => navigate(`${result.value}`)}
      onSearchChange={(event) => props.refine(event.currentTarget.value)}
      results={results}
    />
  );
};

const ConnectedSearchBox = connectAutoComplete(SearchBox);

const Autocomplete = (props) => {
  return (
    <InstantSearch indexName={"artist"} searchClient={searchClient}>
      <Configure hitsPerPage={6} />
      <Index indexName={"artist"} />
      <Index indexName={"gallery"} />
      <ConnectedSearchBox {...props} />
    </InstantSearch>
  );
};

export default connect()(Autocomplete);
