import {
  GET_MUSEUM,
  GET_MUSEUM_ARTWORK_LIST,
  GET_MUSEUM_ARTWORK_LIST_FAILURE,
  GET_MUSEUM_ARTWORK_LIST_SUCCESS,
  GET_MUSEUM_FAILURE,
  GET_MUSEUM_LIST,
  GET_MUSEUM_LIST_FAILURE,
  GET_MUSEUM_LIST_SUCCESS,
  GET_MUSEUM_SUCCESS,
  RESET_MUSEUM_STATE,
} from "../actions/museum";

const INITIAL_STATE = {
  isLoadingMuseumList: true,
  museums: [],
  hasMoreMuseums: true,

  artworks: [],
  hasMoreArtworks: true,
  isLoadingArtworksList: true,

  isLoadingMuseum: true,
  museum: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_MUSEUM_STATE: {
      return { INITIAL_STATE };
    }
    case GET_MUSEUM_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_MUSEUM_LIST_SUCCESS:
      return {
        ...state,
        isLoadingMuseumList: false,
        museums: [...state.museums, ...action.payload.data],
        hasMoreMuseums: action.payload.data.length === 25,
      };
    case GET_MUSEUM_LIST_FAILURE:
      return {
        ...state,
        isLoadingMuseumList: false,
        error: action.error,
        hasMoreMuseums: false,
      };

    case GET_MUSEUM:
      return { ...INITIAL_STATE };
    case GET_MUSEUM_SUCCESS:
      return {
        ...state,
        isLoadingMuseum: false,
        museum: action.payload.data,
      };
    case GET_MUSEUM_FAILURE:
      return {
        ...state,
        isLoadingMuseum: false,
        error: action.error,
      };

    case GET_MUSEUM_ARTWORK_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_MUSEUM_ARTWORK_LIST_SUCCESS:
      return {
        ...state,
        isLoadingMuseumsList: false,
        artworks: [...state.artworks, ...action.payload.data],
        hasMoreMuseums: action.payload.data.length === 25,
      };
    case GET_MUSEUM_ARTWORK_LIST_FAILURE:
      return {
        ...state,
        isLoadingMuseumsList: false,
        error: action.error,
        hasMoreMuseums: false,
      };

    default:
      return state;
  }
};
