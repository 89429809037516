import React, { useState, useEffect } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import loginImage from "../../../../images/login.jpg";
import Login from "../Login";
import PasswordReset from "../PasswordReset";
import PasswordRecover from "../PasswordRecover";
import VerifyEmail from "../VerifyEmail";
import NonAuthLayout from "../../../../layouts/NonAuthLayout";
import { useSelector } from "react-redux";
import { DASHBOARD_PATH } from "../../../../constants/paths";
import { useAuth } from "../../../../contexts/AuthContext";

const FirebasePage = () => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const initialMode = searchParams.get("mode") || "login";
  const initialOobCode = searchParams.get("oobCode");
  const [mode, setMode] = useState(initialMode);
  const [oobCode] = useState(initialOobCode);
  const navigate = useNavigate();

  const handleModeChange = (newMode) => setMode(newMode);

  useEffect(() => {
    if (user && mode !== "verifyEmail") {
      navigate(DASHBOARD_PATH);
    }
  }, [mode, navigate]);

  return (
    <NonAuthLayout
      title="Client Portal | ArtCollection.io"
      description="Login to your account with Art Collection."
    >
      <Grid
        stackable
        verticalAlign="middle"
        style={{ margin: 0, height: "100vh", backgroundColor: "white" }}
      >
        <Grid.Column
          width={8}
          only="computer tablet"
          style={{
            backgroundImage: `url(${loginImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
          }}
        />
        <Grid.Column computer={8} tablet={12} mobile={16}>
          <Segment style={{ textAlign: "left", padding: 0, margin: 0 }}>
            <div style={{ padding: "3em", margin: 0 }}>
              <Header
                as="h2"
                content="Art Collection"
                style={{
                  fontSize: "2em",
                  fontWeight: "bold",
                  lineHeight: "1em",
                  marginTop: "0.5em",
                }}
              />
              {!mode || mode === "login" ? (
                <Login onChangeMode={handleModeChange} />
              ) : mode === "resetPassword" ? (
                <PasswordReset
                  oobCode={oobCode}
                  onChangeMode={handleModeChange}
                />
              ) : mode === "recoverPassword" ? (
                <PasswordRecover onChangeMode={handleModeChange} />
              ) : mode === "verifyEmail" ? (
                <VerifyEmail
                  oobCode={oobCode}
                  onChangeMode={handleModeChange}
                />
              ) : null}
              {mode !== "verifyEmail" && (
                <Segment basic textAlign="center">
                  <p>
                    By providing your information and clicking Login, you agree
                    to our{" "}
                    <a
                      href="https://www.artcollection.io/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.artcollection.io/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </Segment>
              )}
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </NonAuthLayout>
  );
};

export default FirebasePage;
