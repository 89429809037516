const url = "/api/v5/attachments";

export const CREATE_ATTACHMENT = "CREATE_ATTACHMENT";
export const CREATE_ATTACHMENT_SUCCESS = "CREATE_ATTACHMENT_SUCCESS";
export const CREATE_ATTACHMENT_FAILURE = "CREATE_ATTACHMENT_FAILURE";

export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";
export const UPDATE_ATTACHMENT_SUCCESS = "UPDATE_ATTACHMENT_SUCCESS";
export const UPDATE_ATTACHMENT_FAILURE = "UPDATE_ATTACHMENT_FAILURE";

export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_FAILURE = "DELETE_ATTACHMENT_FAILURE";

export const createAttachment = (data) => {
  return {
    types: [
      CREATE_ATTACHMENT,
      CREATE_ATTACHMENT_SUCCESS,
      CREATE_ATTACHMENT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAttachment = (id, data) => {
  return {
    types: [
      UPDATE_ATTACHMENT,
      UPDATE_ATTACHMENT_SUCCESS,
      UPDATE_ATTACHMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteAttachment = (id) => {
  return {
    types: [
      DELETE_ATTACHMENT,
      DELETE_ATTACHMENT_SUCCESS,
      DELETE_ATTACHMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};
