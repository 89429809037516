const url = "/api/v5/appraisals";

const initialState = {
  loaded: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}
