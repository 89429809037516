const url = "/api/v5/artists";

// Actions

export const RESET_ARTIST_STATE = "RESET_ARTIST_STATE";

export const GET_ARTIST_LIST = "GET_ARTIST_LIST";
export const GET_ARTIST_LIST_SUCCESS = "GET_ARTIST_LIST_SUCCESS";
export const GET_ARTIST_LIST_FAILURE = "GET_ARTIST_LIST_FAILURE";

export const GET_ARTIST = "GET_ARTIST";
export const GET_ARTIST_SUCCESS = "GET_ARTIST_SUCCESS";
export const GET_ARTIST_FAILURE = "GET_ARTIST_FAILURE";

export const CREATE_ARTIST = "CREATE_ARTIST";
export const CREATE_ARTIST_SUCCESS = "CREATE_ARTIST_SUCCESS";
export const CREATE_ARTIST_FAILURE = "CREATE_ARTIST_FAILURE";

export const UPDATE_ARTIST = "UPDATE_ARTIST";
export const UPDATE_ARTIST_SUCCESS = "UPDATE_ARTIST_SUCCESS";
export const UPDATE_ARTIST_FAILURE = "UPDATE_ARTIST_FAILURE";

export const DELETE_ARTIST = "DELETE_ARTIST";
export const DELETE_ARTIST_SUCCESS = "DELETE_ARTIST_SUCCESS";
export const DELETE_ARTIST_FAILURE = "DELETE_ARTIST_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_ARTIST =
  "GET_AUCTION_RECORDS_LIST_FOR_ARTIST";
export const GET_AUCTION_RECORDS_LIST_FOR_ARTIST_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_ARTIST_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_ARTIST_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_ARTIST_FAILURE";

export const GET_ARTWORKS_LIST_FOR_ARTIST = "GET_ARTWORKS_LIST_FOR_ARTIST";
export const GET_ARTWORKS_LIST_FOR_ARTIST_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_ARTIST_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_ARTIST_FAILURE =
  "GET_ARTWORKS_LIST_FOR_ARTIST_FAILURE";

export const GET_PIECE_LIST_FOR_ARTIST = "GET_PIECE_LIST_FOR_ARTIST";
export const GET_PIECE_LIST_FOR_ARTIST_SUCCESS =
  "GET_PIECE_LIST_FOR_ARTIST_SUCCESS";
export const GET_PIECE_LIST_FOR_ARTIST_FAILURE =
  "GET_PIECE_LIST_FOR_ARTIST_FAILURE";

export const GET_MY_PIECE_LIST_FOR_ARTIST = "GET_MY_PIECE_LIST_FOR_ARTIST";
export const GET_MY_PIECE_LIST_FOR_ARTIST_SUCCESS =
  "GET_MY_PIECE_LIST_FOR_ARTIST_SUCCESS";
export const GET_MY_PIECE_LIST_FOR_ARTIST_FAILURE =
  "GET_MY_PIECE_LIST_FOR_ARTIST_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_ARTIST =
  "GET_SELL_THROUGH_RATES_FOR_ARTIST";
export const GET_SELL_THROUGH_RATES_FOR_ARTIST_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_ARTIST_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_ARTIST_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_ARTIST_FAILURE";

export const TOGGLE_ARTIST_LIST_DISPLAY = "TOGGLE_ARTIST_LIST_DISPLAY";

export const GET_SLPAE_FOR_ARTIST = "GET_SLPAE_FOR_ARTIST";
export const GET_SLPAE_FOR_ARTIST_SUCCESS = "GET_SLPAE_FOR_ARTIST_SUCCESS";
export const GET_SLPAE_FOR_ARTIST_FAILURE = "GET_SLPAE_FOR_ARTIST_FAILURE";

export const GET_PRICE_CHART_FOR_ARTIST = "GET_PRICE_CHART_FOR_ARTIST";
export const GET_PRICE_CHART_FOR_ARTIST_SUCCESS =
  "GET_PRICE_CHART_FOR_ARTIST_SUCCESS";
export const GET_PRICE_CHART_FOR_ARTIST_FAILURE =
  "GET_PRICE_CHART_FOR_ARTIST_FAILURE";

export const GET_SUMMARY_FOR_ARTIST = "GET_SUMMARY_FOR_ARTIST";
export const GET_SUMMARY_FOR_ARTIST_SUCCESS = "GET_SUMMARY_FOR_ARTIST_SUCCESS";
export const GET_SUMMARY_FOR_ARTIST_FAILURE = "GET_SUMMARY_FOR_ARTIST_FAILURE";

export const GET_MARKET_SIGNALS_ARTIST = "GET_MARKET_SIGNALS_ARTIST";
export const GET_MARKET_SIGNALS_ARTIST_SUCCESS =
  "GET_MARKET_SIGNALS_ARTIST_SUCCESS";
export const GET_MARKET_SIGNALS_ARTIST_FAILURE =
  "GET_MARKET_SIGNALS_ARTIST_FAILURE";

export const GET_ARTIST_NOTES = "GET_ARTIST_NOTES";
export const GET_ARTIST_NOTES_SUCCESS = "GET_ARTIST_NOTES_SUCCESS";
export const GET_ARTIST_NOTES_FAILURE = "GET_ARTIST_NOTES_FAILURE";

export const GET_ARTIST_GROUPINGS = "GET_ARTIST_GROUPINGS";
export const GET_ARTIST_GROUPINGS_SUCCESS = "GET_ARTIST_GROUPINGS_SUCCESS";
export const GET_ARTIST_GROUPINGS_FAILURE = "GET_ARTIST_GROUPINGS_FAILURE";

// NETWORKING

export const getArtistList = (page = 1, limit = 250) => {
  return {
    types: [GET_ARTIST_LIST, GET_ARTIST_LIST_SUCCESS, GET_ARTIST_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArtist = (id) => {
  return {
    types: [GET_ARTIST, GET_ARTIST_SUCCESS, GET_ARTIST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createArtist = (data) => {
  return {
    types: [CREATE_ARTIST, CREATE_ARTIST_SUCCESS, CREATE_ARTIST_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateArtist = (id, data) => {
  return {
    types: [UPDATE_ARTIST, UPDATE_ARTIST_SUCCESS, UPDATE_ARTIST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const patchArtist = (id, data) => {
  return {
    types: [UPDATE_ARTIST, UPDATE_ARTIST_SUCCESS, UPDATE_ARTIST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deleteArtist = (id) => {
  return {
    types: [DELETE_ARTIST, DELETE_ARTIST_SUCCESS, DELETE_ARTIST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getArtistRecordsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_AUCTION_RECORDS_LIST_FOR_ARTIST,
      GET_AUCTION_RECORDS_LIST_FOR_ARTIST_SUCCESS,
      GET_AUCTION_RECORDS_LIST_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/auction_records`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArtworksList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_ARTWORKS_LIST_FOR_ARTIST,
      GET_ARTWORKS_LIST_FOR_ARTIST_SUCCESS,
      GET_ARTWORKS_LIST_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/artworks`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArtistPieceList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_ARTIST,
      GET_PIECE_LIST_FOR_ARTIST_SUCCESS,
      GET_PIECE_LIST_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pieces`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArtistMyPieceList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_MY_PIECE_LIST_FOR_ARTIST,
      GET_MY_PIECE_LIST_FOR_ARTIST_SUCCESS,
      GET_PIECE_LIST_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/my_pieces`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getNumberOfArtworksSold = (id) => {
  return {
    types: [
      GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST,
      GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST_SUCCESS,
      GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/number_of_artworks_sold`,
      },
    },
  };
};

export const getSLPAE = (id) => {
  return {
    types: [
      GET_SLPAE_FOR_ARTIST,
      GET_SLPAE_FOR_ARTIST_SUCCESS,
      GET_SLPAE_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/slpae`,
      },
    },
  };
};

export const getSellThroughRates = (id) => {
  return {
    types: [
      GET_SELL_THROUGH_RATES_FOR_ARTIST,
      GET_SELL_THROUGH_RATES_FOR_ARTIST_SUCCESS,
      GET_SELL_THROUGH_RATES_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/sell_through_rates`,
      },
    },
  };
};

export const getPrice = (id) => {
  return {
    types: [
      GET_PRICE_CHART_FOR_ARTIST,
      GET_PRICE_CHART_FOR_ARTIST_SUCCESS,
      GET_PRICE_CHART_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/price`,
      },
    },
  };
};

export const getSummary = (id) => {
  return {
    types: [
      GET_SUMMARY_FOR_ARTIST,
      GET_SUMMARY_FOR_ARTIST_SUCCESS,
      GET_SUMMARY_FOR_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/summary`,
      },
    },
  };
};

export const getMarketSignals = (id) => {
  return {
    types: [
      GET_MARKET_SIGNALS_ARTIST,
      GET_MARKET_SIGNALS_ARTIST_SUCCESS,
      GET_MARKET_SIGNALS_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/market_signals`,
      },
    },
  };
};

export const resetArtistState = () => {
  return {
    type: RESET_ARTIST_STATE,
  };
};

export const toggleArtistListDisplay = (display) => {
  return {
    type: TOGGLE_ARTIST_LIST_DISPLAY,
    display,
  };
};

export const getArtistNotes = (id) => {
  return {
    types: [
      GET_ARTIST_NOTES,
      GET_ARTIST_NOTES_SUCCESS,
      GET_ARTIST_NOTES_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/notes`,
      },
    },
  };
};

export const getGroupings = (id) => {
  return {
    types: [
      GET_ARTIST_GROUPINGS,
      GET_ARTIST_GROUPINGS_SUCCESS,
      GET_ARTIST_GROUPINGS_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/groupings`,
      },
    },
  };
};
