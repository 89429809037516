import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  Grid,
  Header,
  Image,
  Loader,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import { connect } from "react-redux";
import Invitations from "../Invitations";
import ManageBilling from "../ManageBilling";

const BusinessAccount = ({ account, user }) => {
  const numberOfUsers = Object.values(account.roles).filter(
    (a) => a !== "owner",
  ).length;

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Header size={"large"} content={"Business Plan"} />
            <p>
              You are currently paying for {numberOfUsers} seats + your
              subscription.
            </p>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {account && (
        <Invitations accountID={user.current_account_id || user.account} />
      )}
      {account && account.owner._id === user._id && <ManageBilling />}
    </>
  );
};

BusinessAccount.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessAccount);
