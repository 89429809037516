import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Form,
  Grid,
  Header,
  Image,
  Modal,
  Table,
} from "semantic-ui-react";
import download from "downloadjs";

import ErrorMessage from "../../ErrorMessage";
import { currencyOptions } from "../../../common";
import { exportDocument } from "../../../redux/actions/export";
import PiecesTable from "./PiecesTable";
import empty_state from "../../../images/empty_state/waiting.png";

const ExportModal = ({
  createExport,
  defaultSection,
  currentSelectedPieces,
  currentPieces,
}) => {
  const [type, setType] = useState("pdf");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [section, setSection] = useState(defaultSection || "pieces");
  const [message, setMessage] = useState(null);

  const [selectedPieces, setSelectedPieces] = useState(
    new Set(currentSelectedPieces) || new Set(),
  );

  const [title, setTitle] = useState(true);
  const [date, setDate] = useState(true);

  const [medium, setMedium] = useState(true);
  const [description, setDescription] = useState(true);

  const [dimensions, setDimensions] = useState(true);

  const [artist, setArtist] = useState(true);
  const [gallery, setGallery] = useState(true);
  const [purchasePrice, setPurchasePrice] = useState(true);
  const [purchaseDate, setPurchaseDate] = useState(true);
  const [valuationPrice, setValuationPrice] = useState(true);

  const [provenance, setProvenance] = useState(false);
  const [publication, setPublication] = useState(false);

  const [edition, setEdition] = useState(false);
  const [location, setLocation] = useState(false);
  const [locationHistory, setLocationHistory] = useState(false);
  const [inventoryIdentifier, setInventoryIdentifier] = useState(true);
  const [notes, setNotes] = useState(false);

  const [includeSummary, setIncludeSummary] = useState(true);
  const [summaryCurrency, setSummaryCurrency] = useState("USD");

  useEffect(() => {
    setSelectedPieces(new Set(currentSelectedPieces));
  }, [currentSelectedPieces]);

  const handleCheck = (id) => {
    if (selectedPieces.has(id)) {
      selectedPieces.delete(id);
    } else {
      selectedPieces.add(id);
    }

    setSelectedPieces(new Set(selectedPieces));
  };

  const handleSubmit = async () => {
    const data = {
      pieces: Array.from(selectedPieces),
      include_summary: includeSummary,
      summary_currency: summaryCurrency,
      fields: {
        title,
        date,
        artist,
        description,
        medium,
        dimensions,
        edition,
        location,
        location_history: locationHistory,
        provenance,
        publication,
        gallery,
        purchase_price: purchasePrice,
        purchase_date: purchaseDate,
        valuation_price: valuationPrice,
        inventory_identifier: inventoryIdentifier,
        notes,
      },
    };

    setError(null);
    setLoading(true);

    try {
      const { payload } = await createExport({ ...data, type });
      if (payload.data.url) {
        download(payload.data.url);
        handleClose();
      } else {
        setMessage(payload.data.message);
        setSection("processing");
      }
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setSection(defaultSection || "pieces");
    setModalOpen(false);
    setSelectedPieces(new Set());
    setMessage(null);
  };

  return (
    <Modal
      trigger={
        <Button
          basic
          icon={"file pdf outline"}
          content={"Export"}
          labelPosition={"left"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon
    >
      {section === "pieces" && (
        <>
          <Modal.Header
            content={"Please confirm the pieces you would like to export"}
          />
          <Modal.Content scrolling>
            <Grid.Row>
              <Grid.Column width={16}>
                {error && <ErrorMessage error={error} />}
                <Header
                  size={"tiny"}
                  content={`You have selected ${selectedPieces.size} artworks`}
                />
                <PiecesTable
                  currentPieces={currentPieces}
                  currentSelectedPieces={selectedPieces}
                  handleCheck={handleCheck}
                />
              </Grid.Column>
            </Grid.Row>
          </Modal.Content>
          <Modal.Actions>
            <Button basic content={"Cancel"} onClick={() => handleClose()} />
            <Button
              primary
              disabled={selectedPieces.size == 0}
              content={"Continue"}
              onClick={() => setSection("fields")}
            />
          </Modal.Actions>
        </>
      )}
      {section === "fields" && (
        <>
          <Modal.Header content={"What fields would you like to export?"} />
          <Modal.Content>
            {error && <ErrorMessage error={error} />}
            <Form>
              <Grid>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Header content={"Type"} />
                    <Card.Group>
                      <Card
                        key={"pdf"}
                        raised={type === "pdf"}
                        style={{
                          backgroundColor: type === "pdf" ? "#D1B491" : "white",
                        }}
                        header={
                          <Header
                            inverted
                            content={"PDF"}
                            style={{
                              color: type === "pdf" ? "white" : "black",
                            }}
                          />
                        }
                        onClick={() => setType("pdf")}
                      />
                      <Card
                        key={"table"}
                        raised={type === "table"}
                        style={{
                          backgroundColor:
                            type === "table" ? "#D1B491" : "white",
                        }}
                        header={
                          <Header
                            inverted
                            content={"Inventory Report"}
                            style={{
                              color: type === "table" ? "white" : "black",
                            }}
                          />
                        }
                        onClick={() => setType("table")}
                      />
                      <Card
                        key={"csv"}
                        raised={type === "csv"}
                        style={{
                          backgroundColor: type === "csv" ? "#D1B491" : "white",
                        }}
                        header={
                          <Header
                            inverted
                            content={"CSV"}
                            style={{
                              color: type === "csv" ? "white" : "black",
                            }}
                          />
                        }
                        onClick={() => setType("csv")}
                      />
                    </Card.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header content="Overview" />
                    <Form.Checkbox
                      defaultChecked={inventoryIdentifier}
                      label={"Inventory Identifier"}
                      onChange={(e, { checked }) =>
                        setInventoryIdentifier(checked)
                      }
                    />
                    <Form.Checkbox
                      defaultChecked={title}
                      label="Title"
                      onChange={(e, { checked }) => setTitle(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={date}
                      label={"Date"}
                      onChange={(e, { checked }) => setDate(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={artist}
                      label="Artist"
                      onChange={(e, { checked }) => setArtist(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={medium}
                      label="Medium"
                      onChange={(e, { checked }) => setMedium(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={description}
                      label="Description"
                      onChange={(e, { checked }) => setDescription(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={dimensions}
                      label="Dimensions"
                      onChange={(e, { checked }) => setDimensions(checked)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Header content="Financial Details" />
                    <Form.Checkbox
                      defaultChecked={purchasePrice}
                      label="Purchase Price"
                      onChange={(e, { checked }) => setPurchasePrice(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={purchaseDate}
                      label="Purchase Date"
                      onChange={(e, { checked }) => setPurchaseDate(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={gallery}
                      label="Purchase Location"
                      onChange={(e, { checked }) => setGallery(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={valuationPrice}
                      label={"Market Value"}
                      onChange={(e, { checked }) => setValuationPrice(checked)}
                    />

                    <>
                      <Header content="Additional Information" />
                      <Form.Checkbox
                        defaultChecked={location}
                        label="Current Location"
                        onChange={(e, { checked }) => setLocation(checked)}
                      />
                      <Form.Checkbox
                        defaultChecked={locationHistory}
                        label="Location History"
                        onChange={(e, { checked }) =>
                          setLocationHistory(checked)
                        }
                      />
                      <Form.Checkbox
                        defaultChecked={edition}
                        label="Edition Information"
                        onChange={(e, { checked }) => setEdition(checked)}
                      />
                      <Form.Checkbox
                        defaultChecked={notes}
                        label="Notes"
                        onChange={(e, { checked }) => setNotes(checked)}
                      />
                      {type === "pdf" && (
                        <>
                          <Form.Checkbox
                            defaultChecked={provenance}
                            label="Provenance"
                            onChange={(e, { checked }) =>
                              setProvenance(checked)
                            }
                          />
                          <Form.Checkbox
                            defaultChecked={publication}
                            label="Publications"
                            onChange={(e, { checked }) =>
                              setPublication(checked)
                            }
                          />
                        </>
                      )}
                    </>

                    {type === "table" && (
                      <>
                        <Header content="Summary" />
                        <Form.Checkbox
                          defaultChecked={includeSummary}
                          label={"Include Summary"}
                          onChange={(e, { checked }) =>
                            setIncludeSummary(checked)
                          }
                        />
                        <Form.Select
                          floated={"right"}
                          placeholder="Select Currency"
                          search
                          defaultValue={summaryCurrency}
                          options={currencyOptions}
                          onChange={(e, { name, value }) =>
                            setSummaryCurrency(value)
                          }
                        />
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              content={"Back"}
              onClick={() => setSection("pieces")}
            />
            <Button
              primary
              loading={loading}
              content={"Export"}
              onClick={handleSubmit}
            />
          </Modal.Actions>
        </>
      )}

      {section === "processing" && (
        <>
          <Modal.Content>
            {error && <ErrorMessage error={error} />}
            <Grid>
              <Grid.Row centered>
                <Grid.Column width={8} centered textAlign="center">
                  <Image size={"small"} src={empty_state} centered />
                  <Header
                    size="huge"
                    content={"Export Processing"}
                    subheader={
                      message ??
                      "Your export is currently processing and should be done soon. Please check your email for a download link."
                    }
                    textAlign="center"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              content={"Back"}
              onClick={() => setSection("fields")}
            />
            <Button primary content={"Close"} onClick={handleClose} />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  createExport: (data) => dispatch(exportDocument(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
