import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Item, Segment } from "semantic-ui-react";
import { updateCurrentUser } from "../../../../../../redux/actions/me";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { useUser } from "../../../../../../contexts/UserContext";

const AccountNotifications = ({ user, update }) => {
  const { currentUser } = useUser();
  const [error, setError] = useState(null);
  const [updatingAuctionResultAlerts, setUpdatingAuctionResultAlerts] =
    useState(false);

  const onUpdateAuctionResultAlerts = async () => {
    setUpdatingAuctionResultAlerts(true);
    try {
      await update({ past_auctions_email: !currentUser.past_auctions_email });
    } catch (e) {
      setError(e);
    }
    setUpdatingAuctionResultAlerts(false);
  };

  if (!currentUser) return null;

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header size={"huge"} content={"Notifications"} dividing />
          {error && <ErrorMessage error={error} />}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Item.Group>
              <Item
                header={"Auction Results Alerts"}
                description={
                  "We notify you about auction results for artists in your collection."
                }
                extra={
                  <Button
                    floated={"right"}
                    loading={updatingAuctionResultAlerts}
                    primary={currentUser.past_auctions_email}
                    secondary={!currentUser.past_auctions_email}
                    onClick={onUpdateAuctionResultAlerts}
                    content={
                      currentUser.upcoming_auctions_email
                        ? "Turn Off"
                        : "Turn On"
                    }
                  />
                }
              />
            </Item.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ me, firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountNotifications);
