import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import "firebase/compat/performance";
import "firebase/compat/app-check";

firebase.initializeApp({
  apiKey: "AIzaSyDI5l4VOE8TPsoUOXxkSUqjkQJslTo9cbk",
  authDomain: "artcollection-86b56.firebaseapp.com",
  databaseURL: "https://artcollection-86b56.firebaseio.com",
  projectId: "artcollection-86b56",
  storageBucket: "artcollection-86b56.appspot.com",
  messagingSenderId: "139274612307",
  appId: "1:139274612307:web:e7443e0a7718bd751ba0a6",
  measurementId: "G-448TLNE4BW",
});

export const app = firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const storage = firebase.storage();
export const performance = firebase.performance();
export const messaging = firebase.messaging();
export const FieldValue = firebase.firestore.FieldValue;
