import React from "react";
import { Grid, Header, Image, List } from "semantic-ui-react";
import placeholder from "../../../../../../../images/placeholder.png";
import { Link } from "react-router-dom";

export const Cell = ({ header, content, attachments = [], image, link }) => {
  const handleAttachmentClick = async (attachment) => {
    try {
      const url = attachment.file_reference
        ? await getDownloadURL(ref(getStorage(), attachment.file_reference))
        : attachment.url;
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening attachment:", error);
    }
  };

  return (
    <Grid.Column>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            <List.Description>
              {image && <Image src={image || placeholder} avatar />}
              {link ? (
                <Link to={link}>{content || "N/A"}</Link>
              ) : (
                content || "N/A"
              )}
            </List.Description>
            {attachments.length > 0 && (
              <List.Description>
                <List bulleted>
                  {attachments.map(({ _id, name }) => (
                    <List.Item
                      as="a"
                      key={_id}
                      content={name}
                      onClick={() => handleAttachmentClick(attachment)}
                    />
                  ))}
                </List>
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};
