const url = "/api/v4/export";

export const EXPORT_CSV = "EXPORT_CSV";
export const EXPORT_CSV_SUCCESS = "EXPORT_CSV_SUCCESS";
export const EXPORT_CSV_FAILURE = "EXPORT_CSV_FAILURE";

export const exportDocument = (data) => {
  return {
    types: [EXPORT_CSV, EXPORT_CSV_SUCCESS, EXPORT_CSV_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};
