class Attachment {
  constructor(data) {
    this._id = data._id;
    this.type = data.type;
    this.name = data.name;
    this.url = data.url;
    this.mime_type = data.mime_type;
    this.file_reference = data.file_reference;
  }
}

export default Attachment;
