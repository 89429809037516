import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, Image, Table } from "semantic-ui-react";

const PieceCell = ({ piece, configuration, isSelected, onSelectCell }) => {
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    setChecked(isSelected);
  }, [isSelected]);

  const {
    _id,
    title,
    small_image_url,
    date,
    artist,
    gallery,
    inventory_identifier,
    status,
    location,
  } = piece;

  return (
    <Table.Row
      key={_id}
      style={{ cursor: "pointer" }}
      onClick={() => onSelectCell(_id)}
    >
      <Table.Cell>
        <Checkbox checked={checked} />
      </Table.Cell>
      <Table.Cell>
        <Image src={small_image_url} avatar />
        {title}
      </Table.Cell>

      {configuration.artist && (
        <Table.Cell content={(artist && artist.name) || ""} />
      )}
      {configuration.gallery && (
        <Table.Cell content={(gallery && gallery.name) || ""} />
      )}
      {configuration.location && (
        <Table.Cell content={(location && location.name) || ""} />
      )}
      {configuration.purchase_price && (
        <Table.Cell content={piece.purchasePriceString()} />
      )}
      {configuration.valuation_price && (
        <Table.Cell content={piece.valuationPriceString()} />
      )}
    </Table.Row>
  );
};

PieceCell.propTypes = {
  piece: PropTypes.object,
};

export default PieceCell;
