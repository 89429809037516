const url = "/api/v5/groupings";

// Actions
export const RESET_GROUPING_STATE = "RESET_GROUPING_STATE";

export const GET_GROUPING = "GET_GROUPING";
export const GET_GROUPING_SUCCESS = "GET_GROUPING_SUCCESS";
export const GET_GROUPING_FAILURE = "GET_GROUPING_FAILURE";

export const GET_GROUPING_LIST = "GET_GROUPING_LIST";
export const GET_GROUPING_LIST_SUCCESS = "GET_GROUPING_LIST_SUCCESS";
export const GET_GROUPING_LIST_FAILURE = "GET_GROUPING_LIST_FAILURE";

export const CREATE_GROUPING = "CREATE_GROUPING";
export const CREATE_GROUPING_SUCCESS = "CREATE_GROUPING_SUCCESS";
export const CREATE_GROUPING_FAILURE = "CREATE_GROUPING_FAILURE";

export const UPDATE_GROUPING = "UPDATE_GROUPING";
export const UPDATE_GROUPING_SUCCESS = "UPDATE_GROUPING_SUCCESS";
export const UPDATE_GROUPING_FAILURE = "UPDATE_GROUPING_FAILURE";

export const REFRESH_GROUPING = "REFRESH_GROUPING";
export const REFRESH_GROUPING_SUCCESS = "REFRESH_GROUPING_SUCCESS";
export const REFRESH_GROUPING_FAILURE = "REFRESH_GROUPING_FAILURE";

export const REMOVE_GROUPING = "REMOVE_GROUPING";
export const REMOVE_GROUPING_SUCCESS = "REMOVE_GROUPING_SUCCESS";
export const REMOVE_GROUPING_FAILURE = "REMOVE_GROUPING_FAILURE";

export const GET_GROUPING_AUCTION_RECORDS = "GET_GROUPING_AUCTION_RECORDS";
export const GET_GROUPING_AUCTION_RECORDS_SUCCESS =
  "GET_GROUPING_AUCTION_RECORDS_SUCCESS";
export const GET_GROUPING_AUCTION_RECORDS_FAILURE =
  "GET_GROUPING_AUCTION_RECORDS_FAILURE";

export const GET_GROUPING_MARKET_SIGNALS = "GET_GROUPING_MARKET_SIGNALS";
export const GET_GROUPING_MARKET_SIGNALS_SUCCESS =
  "GET_GROUPING_MARKET_SIGNALS_SUCCESS";
export const GET_GROUPING_MARKET_SIGNALS_FAILURE =
  "GET_GROUPING_MARKET_SIGNALS_FAILURE";

export const GET_GROUPING_PRICE_PREDICTIONS = "GET_GROUPING_PRICE_PREDICTIONS";
export const GET_GROUPING_PRICE_PREDICTIONS_SUCCESS =
  "GET_GROUPING_PRICE_PREDICTIONS_SUCCESS";
export const GET_GROUPING_PRICE_PREDICTIONS_FAILURE =
  "GET_GROUPING_PRICE_PREDICTIONS_FAILURE";

// Networking

export const createGrouping = (data) => {
  return {
    types: [CREATE_GROUPING, CREATE_GROUPING_SUCCESS, CREATE_GROUPING_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const getGroupings = (page) => {
  return {
    types: [
      GET_GROUPING_LIST,
      GET_GROUPING_LIST_SUCCESS,
      GET_GROUPING_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}`,
        params: {
          page,
        },
      },
    },
  };
};

export const getGrouping = (id) => {
  return {
    types: [GET_GROUPING, GET_GROUPING_SUCCESS, GET_GROUPING_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const getGroupingAuctionRecords = (id) => {
  return {
    types: [
      GET_GROUPING_AUCTION_RECORDS,
      GET_GROUPING_AUCTION_RECORDS_SUCCESS,
      GET_GROUPING_AUCTION_RECORDS_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/auction_records`,
      },
    },
  };
};

export const getGroupingPricePredictions = (id) => {
  return {
    types: [
      GET_GROUPING_PRICE_PREDICTIONS,
      GET_GROUPING_PRICE_PREDICTIONS_SUCCESS,
      GET_GROUPING_PRICE_PREDICTIONS_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/price_predictions`,
      },
    },
  };
};

export const getGroupingMarketSignals = (id) => {
  return {
    types: [
      GET_GROUPING_MARKET_SIGNALS,
      GET_GROUPING_MARKET_SIGNALS_SUCCESS,
      GET_GROUPING_MARKET_SIGNALS_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/market_signals`,
      },
    },
  };
};

export const updateGrouping = (id, data) => {
  return {
    types: [UPDATE_GROUPING, UPDATE_GROUPING_SUCCESS, UPDATE_GROUPING_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteGrouping = (id, data) => {
  return {
    types: [REMOVE_GROUPING, REMOVE_GROUPING_SUCCESS, REMOVE_GROUPING_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const refreshGrouping = (id) => {
  return {
    types: [
      REFRESH_GROUPING,
      REFRESH_GROUPING_SUCCESS,
      REFRESH_GROUPING_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/refresh`,
        method: "POST",
      },
    },
  };
};

export const resetGroupingState = () => {
  return {
    type: RESET_GROUPING_STATE,
  };
};
