import {
  GET_GALLERY_PROPOSAL,
  GET_GALLERY_PROPOSAL_FAILURE,
  GET_GALLERY_PROPOSAL_SUCCESS,
  RESET_GALLERY_PROPOSAL_STATE,
} from "../actions/gallery_proposal";

const INITIAL_STATE = {
  isLoadingArtistProposal: true,
  galleryProposal: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_GALLERY_PROPOSAL_STATE: {
      return { INITIAL_STATE };
    }
    case GET_GALLERY_PROPOSAL:
      return { ...INITIAL_STATE };
    case GET_GALLERY_PROPOSAL_SUCCESS:
      return {
        ...state,
        isLoadingArtistProposal: false,
        galleryProposal: action.payload.data,
      };
    case GET_GALLERY_PROPOSAL_FAILURE:
      return {
        ...state,
        isLoadingArtistProposal: false,
        error: action.error,
      };

    default:
      return state;
  }
};
