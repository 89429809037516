import _ from "lodash";
import {
  CREATE_GALLERY,
  CREATE_GALLERY_FAILURE,
  CREATE_GALLERY_SUCCESS,
  DELETE_GALLERY,
  DELETE_GALLERY_FAILURE,
  DELETE_GALLERY_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_FAILURE,
  GET_GALLERY_SUCCESS,
  GET_PIECE_LIST_FOR_GALLERY,
  GET_PIECE_LIST_FOR_GALLERY_FAILURE,
  GET_PIECE_LIST_FOR_GALLERY_SUCCESS,
  RESET_GALLERY_STATE,
  UPDATE_GALLERY,
  UPDATE_GALLERY_FAILURE,
  UPDATE_GALLERY_SUCCESS,
} from "../actions/gallery";

import { Mixpanel } from "../../utils/Mixpanel";
import Gallery from "../../models/gallery";

const INITIAL_STATE = {
  isLoadingGallery: true,
  gallery: null,

  isLoadingPieces: true,
  pieces: [],
  hasMorePieces: true,

  isDeletingGallery: false,
  isUpdatingGallery: false,
  isCreatingGallery: false,

  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_GALLERY_STATE:
    case GET_GALLERY:
      return { ...INITIAL_STATE };
    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingGallery: false,
        gallery: new Gallery(action.payload.data),
      };
    case GET_GALLERY_FAILURE:
      return {
        ...state,
        isLoadingGallery: false,
        error: action.error,
      };

    case GET_PIECE_LIST_FOR_GALLERY:
      return { ...state };
    case GET_PIECE_LIST_FOR_GALLERY_SUCCESS:
      const data = [...state.pieces, ...action.payload.data];
      const pieces = _.uniqBy(data, "_id");

      return {
        ...state,
        isLoadingPieces: false,
        pieces,
        hasMorePieces: action.payload.data.length === 25,
      };
    case GET_PIECE_LIST_FOR_GALLERY_FAILURE:
      return {
        ...state,
        isLoadingPieces: false,
        error: action.error,
        hasMorePieces: false,
      };

    case CREATE_GALLERY:
      return {
        ...state,
        isCreatingGallery: true,
      };

    case CREATE_GALLERY_SUCCESS:
      Mixpanel.track("gallery_created");
      return {
        ...state,
        isCreatingGallery: false,
      };
    case CREATE_GALLERY_FAILURE:
      Mixpanel.track("gallery_created_failure");
      return {
        ...state,
        isCreatingGallery: false,
      };

    case UPDATE_GALLERY:
      return {
        ...state,
        isUpdatingGallery: true,
      };

    case UPDATE_GALLERY_SUCCESS:
      Mixpanel.track("gallery_updated");
      return {
        ...state,
        isUpdatingGallery: false,
      };
    case UPDATE_GALLERY_FAILURE:
      Mixpanel.track("gallery_updated_failure");
      return {
        ...state,
        isUpdatingGallery: false,
      };

    case DELETE_GALLERY:
      return {
        ...state,
        isDeletingGallery: true,
      };

    case DELETE_GALLERY_SUCCESS:
      Mixpanel.track("gallery_deleted");
      return {
        ...state,
        isDeletingGallery: false,
      };
    case DELETE_GALLERY_FAILURE:
      Mixpanel.track("gallery_deleted_failure");
      return {
        ...state,
        isDeletingGallery: false,
      };
    default:
      return state;
  }
};
