class PrivateRoom {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.name = data.name;

    this.image_url = data.image_url;
    this.medium_image_url = data.medium_image_url;
    this.small_image_url = data.small_image_url;

    this.about = data.about;

    this.user = data.user;

    this.fields = data.fields;

    this.pieces = data.pieces;
  }
}

export default PrivateRoom;
