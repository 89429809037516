const url = "/api/v4/plans";

export const GET_PLAN_LIST = "GET_PLAN_LIST";
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS";
export const GET_PLAN_LIST_FAILURE = "GET_PLAN_LIST_FAILURE";

export const getPlanList = () => {
  return {
    types: [GET_PLAN_LIST, GET_PLAN_LIST_SUCCESS, GET_PLAN_LIST_FAILURE],
    payload: {
      request: {
        url,
      },
    },
  };
};
