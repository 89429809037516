import {
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  GET_PIECE_LIST_FOR_TAG,
  GET_PIECE_LIST_FOR_TAG_FAILURE,
  GET_PIECE_LIST_FOR_TAG_SUCCESS,
  GET_TAG,
  GET_TAG_FAILURE,
  GET_TAG_SUCCESS,
  RESET_TAG_STATE,
  UPDATE_TAG,
  UPDATE_TAG_FAILURE,
  UPDATE_TAG_SUCCESS,
} from "../actions/tag";
import { Mixpanel } from "../../utils/Mixpanel";
import Tag from "../../models/tag";

const INITIAL_STATE = {
  isLoadingTagList: true,
  hasMoreTags: true,

  isLoadingTag: true,
  isCreatingTag: false,
  isUpdatingTag: false,
  isDeletingTag: false,

  tag: null,
  pieces: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_TAG_STATE:
      return INITIAL_STATE;

    case GET_TAG:
      return { ...INITIAL_STATE };
    case GET_TAG_SUCCESS:
      return {
        ...state,
        isLoadingTag: false,
        tag: new Tag(action.payload.data),
      };
    case GET_TAG_FAILURE:
      return {
        ...state,
        isLoadingTag: false,
        error: action.error,
      };

    case CREATE_TAG:
      return {
        ...state,
        isCreatingTag: true,
      };
    case CREATE_TAG_SUCCESS: {
      Mixpanel.track("tag_created");

      return {
        ...state,
        isCreatingTag: false,
        tag: action.payload.data,
      };
    }

    case UPDATE_TAG:
      return {
        ...state,
        isUpdatingTag: true,
      };
    case UPDATE_TAG_SUCCESS: {
      Mixpanel.track("tag_updated");
      return {
        ...state,
        isUpdatingTag: false,
        tag: new Tag(action.payload.data),
      };
    }
    case UPDATE_TAG_FAILURE: {
      return {
        ...state,
        isUpdatingTag: false,
        error: action.error,
      };
    }

    case DELETE_TAG:
      return {
        ...state,
        isDeletingTag: true,
      };
    case DELETE_TAG_SUCCESS: {
      Mixpanel.track("tag_deleted");
      return {
        ...state,
        isDeletingTag: false,
        tag: action.payload.data,
      };
    }

    case GET_PIECE_LIST_FOR_TAG:
      return { ...state, isLoadingPieces: true };
    case GET_PIECE_LIST_FOR_TAG_SUCCESS: {
      return {
        ...state,
        isLoadingPieces: false,
        pieces: [...state.pieces, ...action.payload.data],
        hasMorePieces: action.payload.data.length === 25,
      };
    }
    case GET_PIECE_LIST_FOR_TAG_FAILURE:
      return {
        ...state,
        isLoadingPieces: false,
        error: action.error,
        hasMorePieces: false,
      };

    default:
      return state;
  }
};
