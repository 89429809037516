import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Header, Table } from "semantic-ui-react";

import { getAuctionList } from "../../../../redux/actions/auction";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import { Mixpanel } from "../../../../utils/Mixpanel";
import ErrorMessage from "../../../../components/ErrorMessage";
import AuctionTile from "../AuctionTile";
import UpcomingAuctionsList from "../UpcomingAuctionsList";
import AuthLayout from "../../../../layouts/AuthLayout";

const AuctionsList = ({ auctions, fetchAll, error, hasMoreItems }) => {
  useEffect(() => {
    Mixpanel.page("auction");
  }, []);

  const futureAuctions = auctions
    .filter((auction) => moment(auction.end_date).isSameOrAfter(moment()))
    .reverse();
  const pastAuctions = auctions.filter((auction) =>
    moment(auction.end_date).isBefore(moment()),
  );

  return (
    <AuthLayout title={"Auctions"} bannersEnabled={true}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header size="huge" content="Upcoming Auctions" />
            {error && <ErrorMessage error={error} />}
            <UpcomingAuctionsList auctions={futureAuctions} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <InfiniteScroll
              initialLoad={true}
              loadMore={(page) => fetchAll(page)}
              hasMore={hasMoreItems}
              loader={
                <Header
                  key={"loader"}
                  textAlign={"center"}
                  size={"small"}
                  content={"Loading ..."}
                />
              }
            >
              <Table striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={8} content={"Name"} />
                    <Table.HeaderCell content={"Auction House"} />
                    <Table.HeaderCell content={"Date"} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {pastAuctions.map((auction) => (
                    <AuctionTile key={auction._id} auction={auction} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ auctions }) => ({
  auctions: auctions.auctions || [],
  error: auctions.error,
  hasMoreItems: auctions.hasMoreAuctions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: (page) => dispatch(getAuctionList(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsList);
