import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, Image, Loader, Menu } from "semantic-ui-react";
import placeholder from "../../images/profile_placeholder.png";
import { useUser } from "../../contexts/UserContext";

const AccountMenu = ({ handleLogout, switchRole }) => {
  const { currentUser } = useUser();
  const role = currentUser?.role || "user";

  if (!currentUser || Object.keys(currentUser).length === 0)
    return <Loader active />;

  const roleOptions = [
    { key: "professional", text: "Professional", value: "professional" },
    { key: "user", text: "User", value: "user" },
    { key: "admin", text: "Admin", value: "admin" },
    { key: "intern", text: "Intern", value: "intern" },
    { key: "auction_house", text: "Auction House", value: "auction_house" },
  ];

  const hasRole = (r) => currentUser.roles?.includes(r);
  const canSwitchToRole = (r) => role !== r && hasRole(r);

  return (
    <>
      {(hasRole("admin") || hasRole("intern")) && (
        <Menu.Item>
          <Dropdown text="Role">
            <Dropdown.Menu>
              {roleOptions
                .filter(({ key }) => canSwitchToRole(key))
                .map(({ key, text }) => (
                  <Dropdown.Item
                    key={key}
                    text={text}
                    onClick={() => switchRole(key)}
                  />
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      )}

      <Menu.Item>
        <Dropdown
          trigger={
            <span>
              <Image avatar src={currentUser.image_url || placeholder} />
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/account">
              Account Information
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/private_room">
              Private Rooms
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/appraisal_request">
              Appraisals
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
};

AccountMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  switchRole: PropTypes.func.isRequired,
};

export default AccountMenu;
