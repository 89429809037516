import React, { useState, useCallback } from "react";
import { Form, Image } from "semantic-ui-react";
import { categoryOptions, statusOptions } from "../../../../../common";
import SelectArtistModal from "../../../../../components/Modals/Artist/Select";
import DimensionsEditionForm from "../../../../../components/Forms/DimensionsEditionForm";
import { useDispatch, useSelector } from "react-redux";
import DropZone from "../../../../../components/DropZone";
import TagFormField from "../../../../../components/Forms/TagFormField";
import { createImage } from "../../../../../redux/actions/image";

const Overview = ({ piece, onUpdate }) => {
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [images, setImages] = useState(piece.images || []);
  const dispatch = useDispatch();

  const createImageAsset = useCallback(
    (data) => dispatch(createImage(data)),
    [dispatch],
  );

  const onAddImages = async (assets) => {
    setIsUploadingImage(true);
    const newImages = await Promise.all(
      assets.map(async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const response = await createImageAsset(formData);
        return response.payload.data;
      }),
    );
    const updatedImages = [...images, ...newImages];
    setImages(updatedImages);
    onUpdate({ images: updatedImages });
    setIsUploadingImage(false);
  };

  const onRemoveImage = useCallback(
    (imageID) => {
      const updatedImages = images.filter(({ _id }) => _id !== imageID);
      setImages(updatedImages);
      onUpdate({ images: updatedImages });
    },
    [images, onUpdate],
  );

  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <label>Images</label>
        </Form.Field>
        <Form.Field width={4}>
          <DropZone
            loading={isUploadingImage}
            multiple
            onDrop={onAddImages}
            accept="image/*"
            icon="images"
            placeholder="Drag images here or click to upload"
          />
        </Form.Field>
        <Form.Field width={8}>
          <Image.Group>
            {images.map(({ _id, image_url, preview }) => (
              <Image
                key={_id}
                size="small"
                src={preview || image_url}
                label={{
                  as: "a",
                  corner: "right",
                  icon: "remove",
                  color: "red",
                  onClick: () => onRemoveImage(_id),
                }}
              />
            ))}
          </Image.Group>
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Artwork Title</label>
        </Form.Field>
        <Form.Input
          fluid
          name={"title"}
          width={9}
          placeholder={"Title"}
          defaultValue={piece.title}
          autoComplete={"off"}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
        <Form.Input
          fluid
          name={"date"}
          width={3}
          placeholder={"Year"}
          defaultValue={piece.date}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Artist</label>
        </Form.Field>
        <Form.Field width={12}>
          <SelectArtistModal
            artist={piece.artist}
            onSelectArtist={(value) => onUpdate({ artist: value })}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Category & Medium</label>
        </Form.Field>
        <Form.Select
          width={6}
          name="category"
          search
          placeholder="Category"
          defaultValue={piece.category}
          options={categoryOptions}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
        <Form.Input
          width={6}
          name="medium"
          placeholder="Medium"
          defaultValue={piece.medium}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Description</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="description"
          placeholder="Description"
          defaultValue={piece.description}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Inventory Identifier</label>
        </Form.Field>
        <Form.Input
          fluid
          name={"inventory_identifier"}
          width={12}
          placeholder={"Identifier"}
          defaultValue={piece.inventory_identifier}
          autoComplete={"off"}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Status</label>
        </Form.Field>
        <Form.Select
          width={12}
          name="status"
          placeholder="Status"
          defaultValue={piece.status}
          options={statusOptions}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Tags</label>
        </Form.Field>
        <TagFormField
          width={12}
          defaultValue={(piece.tags || []).map((tag) => tag._id)}
          onChange={(tags) => onUpdate({ tags })}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Signed</label>
        </Form.Field>
        <Form.Field width={12}>
          <Form.Checkbox
            name="is_signed"
            label={"The artwork is signed"}
            defaultChecked={piece.is_signed === true}
            onChange={(e, { name, checked }) => onUpdate({ [name]: checked })}
          />
        </Form.Field>
      </Form.Group>

      <DimensionsEditionForm
        piece={piece}
        handleChange={(e, { name, value }) => onUpdate({ [name]: value })}
        handleCheck={(e, { name, checked }) => onUpdate({ [name]: checked })}
        onUpdate={onUpdate}
      />
    </Form>
  );
};

export default Overview;
