class Tag {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.name = data.name;
    this.color = data.color || "black";
    this.number_of_pieces = data.number_of_pieces || 0;
  }
}

export default Tag;
