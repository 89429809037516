const url = "/api/v5/galleries";

// Actions

export const GET_GALLERY_LIST = "GET_GALLERY_LIST";
export const GET_GALLERY_LIST_SUCCESS = "GET_GALLERY_LIST_SUCCESS";
export const GET_GALLERY_LIST_FAILURE = "GET_GALLERY_LIST_FAILURE";

export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";

export const CREATE_GALLERY = "CREATE_GALLERY";
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS";
export const CREATE_GALLERY_FAILURE = "CREATE_GALLERY_FAILURE";

export const UPDATE_GALLERY = "UPDATE_GALLERY";
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS";
export const UPDATE_GALLERY_FAILURE = "UPDATE_GALLERY_FAILURE";

export const DELETE_GALLERY = "DELETE_GALLERY";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_FAILURE = "DELETE_GALLERY_FAILURE";

export const GET_PIECE_LIST_FOR_GALLERY = "GET_PIECE_LIST_FOR_GALLERY";
export const GET_PIECE_LIST_FOR_GALLERY_SUCCESS =
  "GET_PIECE_LIST_FOR_GALLERY_SUCCESS";
export const GET_PIECE_LIST_FOR_GALLERY_FAILURE =
  "GET_PIECE_LIST_FOR_GALLERY_FAILURE";

export const GET_GALLERY_NOTES = "GET_GALLERY_NOTES";
export const GET_GALLERY_NOTES_SUCCESS = "GET_GALLERY_NOTES_SUCCESS";
export const GET_GALLERY_NOTES_FAILURE = "GET_GALLERY_NOTES_FAILURE";

export const RESET_GALLERY_STATE = "RESET_GALLERY_STATE";
export const TOGGLE_GALLERY_LIST_DISPLAY = "TOGGLE_GALLERY_LIST_DISPLAY";

// Networking

export const getGalleryList = (page = 1, limit = 200) => {
  return {
    types: [
      GET_GALLERY_LIST,
      GET_GALLERY_LIST_SUCCESS,
      GET_GALLERY_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getGallery = (id) => {
  return {
    types: [GET_GALLERY, GET_GALLERY_SUCCESS, GET_GALLERY_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createGallery = (data) => {
  return {
    types: [CREATE_GALLERY, CREATE_GALLERY_SUCCESS, CREATE_GALLERY_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateGallery = (id, data) => {
  return {
    types: [UPDATE_GALLERY, UPDATE_GALLERY_SUCCESS, UPDATE_GALLERY_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteGallery = (id, data) => {
  return {
    types: [DELETE_GALLERY, DELETE_GALLERY_SUCCESS, DELETE_GALLERY_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const getGalleryPieceList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_GALLERY,
      GET_PIECE_LIST_FOR_GALLERY_SUCCESS,
      GET_PIECE_LIST_FOR_GALLERY_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/my_pieces`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getGalleryNotes = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_GALLERY_NOTES,
      GET_GALLERY_NOTES_SUCCESS,
      GET_GALLERY_NOTES_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/notes`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetGalleryState = () => {
  return {
    type: RESET_GALLERY_STATE,
  };
};

export const toggleGalleryListDisplay = (display) => {
  return {
    type: TOGGLE_GALLERY_LIST_DISPLAY,
    display,
  };
};
