import React from "react";
import { Grid, Image, Table } from "semantic-ui-react";
import { Configure, InstantSearch } from "react-instantsearch/dom";
import { connectHits } from "react-instantsearch/connectors";
import placeholder from "../../images/profile_placeholder.png";
import SearchBox from "../SearchBox";
import AddMuseumModal from "../Modals/Museum/Add/Modal";
import { searchClient } from "../../utils/algolia";

const CustomHits = connectHits(({ hits, onSelectMuseum }) => (
  <Table.Body>
    {hits.map((hit) => (
      <Table.Row key={hit.objectID} onClick={(e) => onSelectMuseum(hit)}>
        <Table.Cell>
          <Image src={hit.small_image_url || placeholder} avatar />
          {hit.name}
        </Table.Cell>
      </Table.Row>
    ))}
  </Table.Body>
));

const SearchMuseum = ({ onSelectMuseum, addingDisabled }) => {
  return (
    <InstantSearch indexName="museum" searchClient={searchClient}>
      <Configure hitsPerPage={7} />
      <Table selectable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={16}>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={addingDisabled ? 16 : 13}>
                    <SearchBox />
                  </Grid.Column>
                  {!addingDisabled && (
                    <Grid.Column width={3}>
                      <AddMuseumModal
                        onMuseumCreated={(museum) => onSelectMuseum(museum)}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <CustomHits
          onSelectMuseum={(hit) =>
            onSelectMuseum({
              _id: hit._id || hit.objectID,
              name: hit.name,
              image_url: hit.image_url,
              medium_image_url: hit.medium_image_url,
              small_image_url: hit.small_image_url,
            })
          }
        />
      </Table>
    </InstantSearch>
  );
};

export default SearchMuseum;
