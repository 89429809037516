import React from "react";
import { useSelector } from "react-redux";
import { Header, Card, Grid, Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { GROUPINGS_PATH } from "../../../../../../constants/paths";
import { useUser } from "../../../../../../contexts/UserContext";

const AuctionRecordGroupings = () => {
  const { currentUser } = useUser();
  const groupings = currentUser?.groupings || [];
  const navigate = useNavigate();

  if (!groupings.length) return null;

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header as="h3" content="Collections" />
        <Card.Group stackable itemsPerRow={4}>
          {groupings.map((grouping) => (
            <Card
              key={grouping._id}
              onClick={() => navigate(`${GROUPINGS_PATH}/${grouping._id}`)}
            >
              <Image
                src={grouping.medium_image_url}
                alt={grouping.title}
                style={{
                  height: "200px",
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "100%",
                }}
              />
              <Card.Content>
                <Card.Header>{grouping.title}</Card.Header>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Grid.Column>
    </Grid.Row>
  );
};

export default AuctionRecordGroupings;
