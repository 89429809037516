import { priceString } from "../utils/string";
import Artist from "./artist";
import Auction from "./auction";
import Grouping from "./grouping";

class AuctionRecord {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.title = data.title;

    this.auction_identifier = data.auction_identifier;
    this.lot_number = data.lot_number;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.website_url = data.website_url;

    this.category = data.category;
    this.lot_performance = data.lot_performance;
    this.sale_date = data.sale_date;

    this.medium = data.medium;
    this.about = data.about;
    this.date = data.date;
    this.size = data.size;

    this.artist = data.artist && new Artist(data.artist);
    this.auction = data.auction && new Auction(data.auction);

    this.hammer_currency = data.hammer_currency;
    this.hammer_price = data.hammer_price;

    this.estimate_currency = data.estimate_currency;
    this.estimate_low = data.estimate_low;
    this.estimate_high = data.estimate_high;

    this.measurement_units = data.measurement_units;
    this.width = data.width;
    this.height = data.height;
    this.depth = data.depth;
    this.weight = data.depth;

    this.literature = data.literature;
    this.exhibited = data.exhibited;
    this.provenance = data.provenance;
    this.condition_report = data.condition_report;

    this.is_signed = data.is_signed;
    this.is_framed = data.is_framed;

    this.width_cm = data.width_cm;
    this.height_cm = data.height_cm;
    this.depth_cm = data.depth_cm;
    this.price_per_cm_squared = data.price_per_cm_squared;

    this.groupings =
      data.groupings && data.groupings.map((d) => new Grouping(d));
  }

  hammerPriceString(currencies, user) {
    if (this.hammer_price) {
      return priceString(
        this.hammer_price / 100,
        this.hammer_currency,
        user,
        currencies,
      );
    }

    if (!this.lot_performance) return "NA";

    switch (this.lot_performance) {
      case "upcoming":
        return "Upcoming";
      case "pulled":
        return "Withdrawn";
      case "not_sold":
        return "Bought In";
      default:
        return priceString(
          this.hammer_price / 100,
          this.hammer_currency,
          user,
          currencies,
        );
    }
  }

  estimatePriceString(currencies, user) {
    let estimate = "Estimate Upon Request";

    if (
      this.estimate_low &&
      this.estimate_high &&
      this.estimate_low !== 0 &&
      this.estimate_high !== 0
    ) {
      estimate = `${priceString(
        Math.round(this.estimate_low / 100),
        this.estimate_currency,
        user,
        currencies,
      )} - ${priceString(
        Math.round(this.estimate_high / 100),
        this.estimate_currency,
        user,
        currencies,
      )}`;
    }

    return estimate;
  }

  pricePercentageChangeString() {
    if (this.pricePercentageChange() === null) return null;
    return `${this.pricePercentageChange()}%`;
  }

  pricePercentageChange() {
    if (!this.hammer_price || !this.estimate_low || !this.estimate_high)
      return null;
    const averageEstimate = (this.estimate_low + this.estimate_high) / 2;
    const percentageChange =
      (this.hammer_price - averageEstimate) / averageEstimate;
    return Math.round(percentageChange * 100);
  }

  medianEstimate() {
    if (!this.estimate_low || !this.estimate_high) return 0;
    return (this.estimate_low + this.estimate_high) / 2;
  }

  toFormData() {
    const formData = new FormData();
    formData.append("title", this.title || "Untitled");
    return formData;
  }
}

export default AuctionRecord;
