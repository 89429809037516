import {
  GET_ARTWORK,
  GET_ARTWORK_FAILURE,
  GET_ARTWORK_SUCCESS,
  RESET_ARTWORK_STATE,
} from "../actions/artwork";

const INITIAL_STATE = {
  isLoadingArtwork: true,
  artwork: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ARTWORK_STATE: {
      return INITIAL_STATE;
    }
    case GET_ARTWORK:
      return { ...INITIAL_STATE };
    case GET_ARTWORK_SUCCESS:
      return {
        ...state,
        isLoadingArtwork: false,
        artwork: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionPieces: true,
      };
    case GET_ARTWORK_FAILURE:
      return {
        ...state,
        isLoadingArtwork: false,
        error: action.error,
      };

    default:
      return state;
  }
};
