import {
  CREATE_IMPORT_PIECE,
  CREATE_IMPORT_PIECE_FAILURE,
  CREATE_IMPORT_PIECE_SUCCESS,
  DELETE_IMPORT_PIECE,
  DELETE_IMPORT_PIECE_FAILURE,
  DELETE_IMPORT_PIECE_SUCCESS,
  GET_IMPORT_PIECE,
  GET_IMPORT_PIECE_FAILURE,
  GET_IMPORT_PIECE_SUCCESS,
  RESET_IMPORT_PIECE_STATE,
  UPDATE_IMPORT_PIECE,
  UPDATE_IMPORT_PIECE_FAILURE,
  UPDATE_IMPORT_PIECE_SUCCESS,
} from "../actions/import_piece";

import { Mixpanel } from "../../utils/Mixpanel";

const INITIAL_STATE = {
  isLoadingImportPiece: true,
  import_piece: null,
  auctionResults: [],
  pieces: [],

  isCreatingImportPiece: false,
  isUpdatingImportPiece: false,
  isDeletingImportPiece: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_IMPORT_PIECE_STATE:
    case GET_IMPORT_PIECE:
      return { ...INITIAL_STATE };
    case GET_IMPORT_PIECE_SUCCESS:
      return {
        ...state,
        isLoadingImportPiece: false,
        import_piece: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionPieces: true,
      };
    case GET_IMPORT_PIECE_FAILURE:
      return {
        ...state,
        isLoadingImportPiece: false,
        error: action.error,
      };
    case CREATE_IMPORT_PIECE:
      return {
        ...state,
        isCreatingImportPiece: true,
      };

    case CREATE_IMPORT_PIECE_SUCCESS:
      Mixpanel.track("import_piece_created");
      return {
        ...state,
        isCreatingImportPiece: false,
      };
    case CREATE_IMPORT_PIECE_FAILURE:
      Mixpanel.track("import_piece_created_failure");
      return {
        ...state,
        isCreatingImportPiece: false,
      };

    case UPDATE_IMPORT_PIECE:
      return {
        ...state,
        isUpdatingImportPiece: true,
      };

    case UPDATE_IMPORT_PIECE_SUCCESS:
      Mixpanel.track("import_piece_updated");
      return {
        ...state,
        isUpdatingImportPiece: false,
      };
    case UPDATE_IMPORT_PIECE_FAILURE:
      Mixpanel.track("import_piece_updated_failure");
      return {
        ...state,
        isUpdatingImportPiece: false,
      };

    case DELETE_IMPORT_PIECE:
      return {
        ...state,
        isDeletingImportPiece: true,
      };

    case DELETE_IMPORT_PIECE_SUCCESS:
      Mixpanel.track("import_piece_deleted");
      return {
        ...state,
        isDeletingImportPiece: false,
      };
    case DELETE_IMPORT_PIECE_FAILURE:
      Mixpanel.track("import_piece_deleted_failure");
      return {
        ...state,
        isDeletingImportPiece: false,
      };
    default:
      return state;
  }
};
