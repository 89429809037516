import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";

const Insurance = ({ onUpdate }) => {
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });
  const handleCheck = (e, { name, checked }) => onUpdate({ [name]: checked });

  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <label>Insured</label>
        </Form.Field>
        <Form.Field width={12}>
          <Form.Checkbox
            name={"is_insured"}
            label={"This item is insured"}
            onChange={handleCheck}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name={"insurance_notes"}
          placeholder="Insurance Notes"
          onChange={handleChange}
        />
      </Form.Group>
    </Form>
  );
};

export default Insurance;
