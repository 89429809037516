import React from "react";
import { useNavigate } from "react-router-dom";
import { Image, Table } from "semantic-ui-react";
import placeholder from "../../../../../../images/placeholder.png";
import { ARTIST_PATH, ARTWORKS_PATH } from "../../../../../../constants/paths";

const MuseumArtworkTile = ({ artwork }) => {
  const navigate = useNavigate();

  const { _id, title, small_image_url, artist } = artwork;

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Table.Row key={_id} style={{ cursor: "pointer" }}>
      <Table.Cell onClick={(e) => onRowClick(e, `${ARTWORKS_PATH}/${_id}`)}>
        <Image src={small_image_url || placeholder} avatar />
      </Table.Cell>
      <Table.Cell
        content={title}
        onClick={(e) => onRowClick(e, `${ARTWORKS_PATH}/${_id}`)}
      />
      <Table.Cell
        content={artist && artist.name}
        onClick={(e) => onRowClick(e, `${ARTIST_PATH}/${artist._id}`)}
      />
    </Table.Row>
  );
};

export default MuseumArtworkTile;
