const url = "/api/v4/daily_events";

export const RESET_DAILY_EVENT_STATE = "RESET_DAILY_EVENT_STATE";

export const CREATE_DAILY_EVENT = "CREATE_DAILY_EVENT";
export const CREATE_DAILY_EVENT_SUCCESS = "CREATE_DAILY_EVENT_SUCCESS";
export const CREATE_DAILY_EVENT_FAILURE = "CREATE_DAILY_EVENT_FAILURE";

export const UPDATE_DAILY_EVENT = "UPDATE_DAILY_EVENT";
export const UPDATE_DAILY_EVENT_SUCCESS = "UPDATE_DAILY_EVENT_SUCCESS";
export const UPDATE_DAILY_EVENT_FAILURE = "UPDATE_DAILY_EVENT_FAILURE";

export const DELETE_DAILY_EVENT = "DELETE_DAILY_EVENT";
export const DELETE_DAILY_EVENT_SUCCESS = "DELETE_DAILY_EVENT_SUCCESS";
export const DELETE_DAILY_EVENT_FAILURE = "DELETE_DAILY_EVENT_FAILURE";

export const GET_DAILY_EVENT_LIST = "GET_DAILY_EVENT_LIST";
export const GET_DAILY_EVENT_LIST_SUCCESS = "GET_DAILY_EVENT_LIST_SUCCESS";
export const GET_DAILY_EVENT_LIST_FAILURE = "GET_DAILY_EVENT_LIST_FAILURE";

export const GET_DAILY_EVENT = "GET_DAILY_EVENT";
export const GET_DAILY_EVENT_SUCCESS = "GET_DAILY_EVENT_SUCCESS";
export const GET_DAILY_EVENT_FAILURE = "GET_DAILY_EVENT_FAILURE";

export const getDailyEventList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_DAILY_EVENT_LIST,
      GET_DAILY_EVENT_LIST_SUCCESS,
      GET_DAILY_EVENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getDailyEvent = (id) => {
  return {
    types: [GET_DAILY_EVENT, GET_DAILY_EVENT_SUCCESS, GET_DAILY_EVENT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createDailyEvent = (data) => {
  return {
    types: [
      CREATE_DAILY_EVENT,
      CREATE_DAILY_EVENT_SUCCESS,
      CREATE_DAILY_EVENT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateDailyEvent = (id, data) => {
  return {
    types: [
      UPDATE_DAILY_EVENT,
      UPDATE_DAILY_EVENT_SUCCESS,
      UPDATE_DAILY_EVENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deleteDailyEvent = (id, data) => {
  return {
    types: [
      DELETE_DAILY_EVENT,
      DELETE_DAILY_EVENT_SUCCESS,
      DELETE_DAILY_EVENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const resetDailyEventState = () => {
  return {
    type: RESET_DAILY_EVENT_STATE,
  };
};
