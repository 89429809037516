import {
  GET_AUCTION_HOUSE_LIST,
  GET_AUCTION_HOUSE_LIST_FAILURE,
  GET_AUCTION_HOUSE_LIST_SUCCESS,
} from "../actions/auction_house";

const INITIAL_STATE = {
  display: localStorage.getItem("auctionHouseDisplay") || "table",
  isLoadingAuctionHouseList: true,
  auctionHouses: [],
  hasMoreAuctionHouses: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AUCTION_HOUSE_LIST:
      return {
        ...state,
        isLoadingAuctionHouseList: true,
        hasMoreAuctionHouses: true,
        auctionHouses:
          action.payload.request.params.page === 1 ? [] : state.auctionHouses,
      };
    case GET_AUCTION_HOUSE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAuctionHouseList: false,
        auctionHouses: state.auctionHouses.concat(action.payload.data),
        hasMoreAuctionHouses: action.payload.length > 0,
      };
    case GET_AUCTION_HOUSE_LIST_FAILURE:
      return {
        ...state,
        isLoadingAuctionHouseList: false,
        hasMoreAuctionHouses: false,
        error: action.error,
      };
    default:
      return state;
  }
};
