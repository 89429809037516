import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useAuth } from "./AuthContext";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../utils/firebase";

const UserContext = createContext(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) return;
    const docRef = doc(collection(firestore, "users"), user.uid);
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        setLoading(false);
        if (docSnapshot.exists()) {
          setCurrentUser({ id: docSnapshot.id, ...docSnapshot.data() });
        } else {
          setError("User not found.");
        }
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <UserContext.Provider value={{ currentUser, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};
