import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header, Image, List } from "semantic-ui-react";
import { Cell } from "../Cell";
import Attachment from "../../../../../../../../components/Attachment";
import { GALLERY_PATH } from "../../../../../../../../constants/paths";

const Acquisition = ({ piece }) => {
  const {
    gallery,
    purchase_date,
    purchase_price,
    purchase_attachments,
    purchaseDateString,
    purchase_notes,
    replacement_price,
    owned_by,
    valuation_price,
    valuation_currency,
    valuationDateString,
    valuation_attachments,
    valuation_notes
  } = piece;

  const hasPurchaseInfo = gallery || purchase_date || purchase_price || (purchase_attachments && purchase_attachments.length > 0) || purchase_notes;
  const hasValuationInfo = valuation_price || valuation_currency || valuationDateString || (valuation_attachments && valuation_attachments.length > 0) || valuation_notes || owned_by || replacement_price;

  if (!hasPurchaseInfo && !hasValuationInfo) return null;

  return (
    <>
        {hasPurchaseInfo && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header content="Purchase Information" dividing />
            </Grid.Column>
            {gallery && (
              <Cell
                header="Purchase Location"
                content={gallery.name}
                image={gallery.image_url}
                link={`${GALLERY_PATH}/${gallery._id}`}
              />
            )}
            {purchase_date && (
              <Cell header="Purchase Date" content={purchaseDateString} />
            )}
            {purchase_price && (
              <Cell
                header="Purchase Price"
                content={piece.purchasePriceString()}
              />
            )}
            {purchase_notes && (
              <Cell header="Purchase Notes" content={purchase_notes} />
            )}
            {purchase_attachments && purchase_attachments.length > 0 && (
              <Grid.Column>
                {purchase_attachments.map((attachment) => (
                  <Attachment key={attachment._id} attachment={attachment} />
                ))}
              </Grid.Column>
            )}
          </Grid.Row>
        )}

        {hasValuationInfo && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header content="Current / Market Information" dividing />
            </Grid.Column>
            {valuation_price && (
              <Cell
                header="Market Value"
                content={piece.valuationPriceString()}
              />
            )}
            {replacement_price && (
              <Cell
                header="Replacement Value"
                content={piece.replacementPriceString()}
              />
            )}
            {valuationDateString && (
              <Cell header="Valuation Date" content={valuationDateString} />
            )}
            {valuation_notes && (
              <Cell header="Valuation Notes" content={valuation_notes} />
            )}
            {valuation_attachments && valuation_attachments.length > 0 && (
              <Grid.Column>
                {valuation_attachments.map((attachment) => (
                  <Attachment key={attachment._id} attachment={attachment} />
                ))}
              </Grid.Column>
            )}
            {owned_by && <Cell header="Owned By" content={owned_by} />}
          </Grid.Row>
        )}
    </>
  );
};

Acquisition.propTypes = {
  piece: PropTypes.object.isRequired,
};

export default Acquisition;