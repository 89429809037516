const url = "/api/v4/artist_proposals";

// Actions
export const RESET_ARTIST_PROPOSAL_STATE = "RESET_ARTIST_PROPOSAL_STATE";

export const GET_ARTIST_PROPOSAL_LIST = "GET_ARTIST_PROPOSAL_LIST";
export const GET_ARTIST_PROPOSAL_LIST_SUCCESS =
  "GET_ARTIST_PROPOSAL_LIST_SUCCESS";
export const GET_ARTIST_PROPOSAL_LIST_FAILURE =
  "GET_ARTIST_PROPOSAL_LIST_FAILURE";

export const GET_ARTIST_PROPOSAL = "GET_ARTIST_PROPOSAL";
export const GET_ARTIST_PROPOSAL_SUCCESS = "GET_ARTIST_PROPOSAL_SUCCESS";
export const GET_ARTIST_PROPOSAL_FAILURE = "GET_ARTIST_PROPOSAL_FAILURE";

export const CREATE_ARTIST_PROPOSAL = "CREATE_ARTIST_PROPOSAL";
export const CREATE_ARTIST_PROPOSAL_SUCCESS = "CREATE_ARTIST_PROPOSAL_SUCCESS";
export const CREATE_ARTIST_PROPOSAL_FAILURE = "CREATE_ARTIST_PROPOSAL_FAILURE";

export const UPDATE_ARTIST_PROPOSAL = "UPDATE_ARTIST_PROPOSAL";
export const UPDATE_ARTIST_PROPOSAL_SUCCESS = "UPDATE_ARTIST_PROPOSAL_SUCCESS";
export const UPDATE_ARTIST_PROPOSAL_FAILURE = "UPDATE_ARTIST_PROPOSAL_FAILURE";

export const REMOVE_ARTIST_PROPOSAL = "REMOVE_ARTIST_PROPOSAL";
export const REMOVE_ARTIST_PROPOSAL_SUCCESS = "REMOVE_ARTIST_PROPOSAL_SUCCESS";
export const REMOVE_ARTIST_PROPOSAL_FAILURE = "REMOVE_ARTIST_PROPOSAL_FAILURE";

// Networking

export const getArtistProposalList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_ARTIST_PROPOSAL_LIST,
      GET_ARTIST_PROPOSAL_LIST_SUCCESS,
      GET_ARTIST_PROPOSAL_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArtistProposal = (id) => {
  return {
    types: [
      GET_ARTIST_PROPOSAL,
      GET_ARTIST_PROPOSAL_SUCCESS,
      GET_ARTIST_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createArtistProposal = (data) => {
  return {
    types: [
      CREATE_ARTIST_PROPOSAL,
      CREATE_ARTIST_PROPOSAL_SUCCESS,
      CREATE_ARTIST_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateArtistProposal = (id, data) => {
  return {
    types: [
      UPDATE_ARTIST_PROPOSAL,
      UPDATE_ARTIST_PROPOSAL_SUCCESS,
      UPDATE_ARTIST_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteArtistProposal = (id, data) => {
  return {
    types: [
      REMOVE_ARTIST_PROPOSAL,
      REMOVE_ARTIST_PROPOSAL_SUCCESS,
      REMOVE_ARTIST_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const resetArtistProposalState = () => {
  return {
    type: RESET_ARTIST_PROPOSAL_STATE,
  };
};
