import {
  CREATE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_SOURCE_SUCCESS,
  GET_PAYMENT_SOURCE_LIST,
  GET_PAYMENT_SOURCE_LIST_FAILURE,
  GET_PAYMENT_SOURCE_LIST_SUCCESS,
} from "../actions/payment_source";

const INITIAL_STATE = {
  isGettingPaymentSourceList: false,
  payment: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_SOURCE_LIST:
      return {
        ...state,
        isGettingPaymentSourceList: true,
        error: null,
      };

    case GET_PAYMENT_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        isGettingPaymentSourceList: false,
        payment_sources: action.payload.data,
      };

    case GET_PAYMENT_SOURCE_LIST_FAILURE:
      return {
        ...state,
        isGettingPaymentSourceList: false,
        error: action.error,
      };

    case CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        payment_sources: [action.payload.data, ...state.payment_sources],
      };
    }

    case DELETE_PAYMENT_SOURCE_SUCCESS: {
      const payment_sources = (state.payment_sources || []).filter(
        (c) => c.id !== action.payload.data.id,
      );

      return {
        ...state,
        payment_sources,
      };
    }

    default:
      return state;
  }
};
