import _ from "lodash";

export const auctionStateOptions = [
  { key: "documented", value: "documented", text: "Documented" },
  { key: "researched", value: "researched", text: "Researched" },
  { key: "completed", value: "completed", text: "Completed" },
];

export const accountTypeOptions = [
  { key: "collector", value: "collector", text: "Collector" },
  { key: "artist", value: "artist", text: "Artist" },
  { key: "gallery", value: "gallery", text: "Gallery" },
  { key: "advisor", value: "advisor", text: "Advisor" },
  { key: "appraiser", value: "appraiser", text: "Appraiser" },
  { key: "auction_house", value: "auction_house", text: "Auction House" },
  { key: "museum", value: "museum", text: "Museum" },
  { key: "other", value: "other", text: "Other" },
];

export const jobStatusOptions = [
  {
    key: "pending",
    value: "pending",
    text: "Open",
    label: { color: "green", empty: true, circular: true },
  },
  {
    key: "in_progress",
    value: "in_progress",
    text: "In Progress",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "canceled",
    value: "canceled",
    text: "Canceled",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "completed",
    value: "completed",
    text: "Completed",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "professional_completed",
    value: "professional_completed",
    text: "Professional Completed",
    label: { color: "blue", empty: true, circular: true },
  },
];

export const availableFieldOptions = [
  { key: "title", value: "title", text: "Title" },
  { key: "artist", value: "artist", text: "Artist" },
  { key: "gallery", value: "gallery", text: "Gallery" },
  { key: "purchase_price", value: "purchase_price", text: "Purchase Price" },
];

export const artistFilterOptions = [
  { key: "all", value: "all", text: "All Artists" },
  { key: "my_artists", value: "my_artists", text: "Artists I Collect" },
];

export const lotFilterOptions = [
  { key: "all", value: "all", text: "All Lots" },
  { key: "upcoming", value: "upcoming", text: "Upcoming Lots" },
  { key: "sold", value: "sold", text: "Sold Lots" },
  { key: "unsold", value: "unsold", text: "Unsold Lots" },
];

export const pieceCriteria = [
  { key: "inventory_identifier", value: "inventory_identifier", text: "ID" },
  { key: "title", value: "title", text: "Title" },
  { key: "date", value: "date", text: "Creation Date" },
  { key: "artist", value: "artist", text: "Artist" },
  { key: "medium", value: "medium", text: "Medium" },
  { key: "description", value: "description", text: "Description" },
  { key: "purchase_price", value: "purchase_price", text: "Purchase Price" },
  { key: "valuation", value: "valuation", text: "Current Valuation" },
  { key: "purchase_date", value: "purchase_date", text: "Purchase Date" },
  { key: "gallery", value: "gallery", text: "Purchase Location" },
  { key: "location", value: "location", text: "Current Location" },
  { key: "size", value: "size", text: "Size Dimensions" },
  { key: "width", value: "width", text: "Width" },
  { key: "height", value: "height", text: "Height" },
  { key: "insurance_notes", value: "insurance_notes", text: "Insurance Notes" },
];

export const languageOptions = [
  { key: "en", value: "en", text: "English" },
  { key: "es", value: "es", text: "Spanish" },
  { key: "fr", value: "fr", text: "French" },
  { key: "pt", value: "pt", text: "Portuguese" },
  { key: "zh", value: "zh", text: "Chinese" },
  { key: "ru", value: "ru", text: "Russian" },
  { key: "ja", value: "ja", text: "Japanese" },
];

export const appraisalVisibilityOptions = [
  { key: "anyone", value: "anyone", text: "Any Appraiser" },
  { key: "invite_only", value: "invite_only", text: "Invite Only" },
];

export const publicationOptions = [
  { key: "book", value: "book", text: "Book" },
  { key: "magazine", value: "magazine", text: "Magazine / Catalogue" },
  { key: "website", value: "website", text: "Website" },
  { key: "other", value: "other", text: "Other" },
];

export const exhibitionOptions = [
  { key: "museum", value: "museum", text: "Museum" },
  { key: "gallery", value: "gallery", text: "Gallery" },
];

export const appraisalOptions = [
  { key: "valuation", value: "valuation", text: "Valuation" },
  {
    key: "insurance_coverage",
    value: "insurance_coverage",
    text: "Insurance Coverage",
  },
  {
    key: "charitable_contribution",
    value: "charitable_contribution",
    text: "Charitable Contribution",
  },
  {
    key: "estate_inheritance_taxes",
    value: "estate_inheritance_taxes",
    text: "Estate or Inheritance Taxes",
  },
  { key: "estate_planning", value: "estate_planning", text: "Estate Planning" },
  {
    key: "equitable_distribution",
    value: "equitable_distribution",
    text: "Equitable Distribution",
  },
  { key: "damage_loss", value: "damage_loss", text: "Damage/Loss" },
];

export const serviceOptions = [
  {
    key: "appraisal",
    value: "appraisal",
    text: "Appraisals",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "sales_assistance",
    value: "sales_assistance",
    text: "Sales Assistance",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "art_advisement",
    value: "art_advisement",
    text: "Art Advisory",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "framing",
    value: "framing",
    text: "Framing",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "transportation",
    value: "transportation",
    text: "Transportation",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "storage",
    value: "storage",
    text: "Storage",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "legal",
    value: "lega ",
    text: "Legal",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "conservation",
    value: "conservation",
    text: "Conservation",
    label: { color: "blue", empty: true, circular: true },
  },
];

export const measurementOptions = [
  { key: "imperial", value: "imperial", text: "inches" },
  { key: "metric", value: "metric", text: "cm" },
];

export const galleryTypeOptions = [
  { key: "gallery", value: "gallery", text: "Gallery" },
  { key: "auction_house", value: "auction_house", text: "Auction House" },
  { key: "store", value: "store", text: "Store" },
  { key: "artist", value: "artist", text: "Artist" },
  { key: "museum", value: "museum", text: "Museum" },
  { key: "fair_festival", value: "fair_festival", text: "Fair/Festival" },
];

export const auctionRecordCategoryOptions = [
  {
    key: "painting",
    value: "painting",
    text: "Painting",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "furniture",
    value: "furniture",
    text: "Furniture",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "print",
    value: "print",
    text: "Print",
    label: { color: "teal", empty: true, circular: true },
  },
  {
    key: "lithograph",
    value: "lithograph",
    text: "Lithograph",
    label: { color: "teal", empty: true, circular: true },
  },
  {
    key: "giclee",
    value: "giclee",
    text: "Giclee",
    label: { color: "teal", empty: true, circular: true },
  },
  {
    key: "sculpture",
    value: "sculpture",
    text: "Sculpture",
    label: { color: "orange", empty: true, circular: true },
  },
  {
    key: "photography",
    value: "photography",
    text: "Photography",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "drawing",
    value: "drawing",
    text: "Drawing",
    label: { color: "yellow", empty: true, circular: true },
  },
];

export const lotPerformanceOptions = [
  {
    key: "above",
    value: "above",
    text: "Above",
    label: { color: "green", empty: true, circular: true },
  },
  {
    key: "within",
    value: "within",
    text: "Within",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "below",
    value: "below",
    text: "Below",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "pulled",
    value: "pulled",
    text: "Pulled",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "not_sold",
    value: "not_sold",
    text: "Not Sold",
    label: { color: "purple", empty: true, circular: true },
  },
];

export const groupOptions = [
  {
    key: "month",
    value: "month",
    text: "Month",
    label: { color: "black", empty: true, circular: true },
  },
  {
    key: "year",
    value: "year",
    text: "Year",
    label: { color: "black", empty: true, circular: true },
  },
];

export const statusOptions = [
  {
    key: "owned",
    value: "owned",
    text: "Owned",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "sold",
    value: "sold",
    text: "Sold",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "on_loan",
    value: "on_loan",
    text: "On Loan",
    label: { color: "yellow", empty: true, circular: true },
  },
];

export const categoryOptions = [
  {
    key: "painting",
    value: "painting",
    text: "Painting",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "print",
    value: "print",
    text: "Print",
    label: { color: "teal", empty: true, circular: true },
  },
  {
    key: "sculpture",
    value: "sculpture",
    text: "Sculpture",
    label: { color: "orange", empty: true, circular: true },
  },
  {
    key: "photography",
    value: "photography",
    text: "Photography",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "drawing",
    value: "drawing",
    text: "Drawing",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "furniture",
    value: "furniture",
    text: "Furniture",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "mixed_media",
    value: "mixed_media",
    text: "Mixed Media",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "toy",
    value: "toy",
    text: "Toy",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "installation",
    value: "installation",
    text: "Installation",
    label: { color: "pink", empty: true, circular: true },
  },
  {
    key: "video",
    value: "video",
    text: "Video",
    label: { color: "teal", empty: true, circular: true },
  },
  {
    key: "architecture",
    value: "architecture",
    text: "Architecture",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "jewelry",
    value: "jewelry",
    text: "Jewelry",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "other",
    value: "other",
    text: "Other",
    label: { color: "black", empty: true, circular: true },
  },
  {
    key: "unknown",
    value: "unknown",
    text: "Unknown",
    label: { color: "black", empty: true, circular: true },
  },
  {
    key: "automobile",
    value: "automobile",
    text: "Automobile",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "ceramics",
    value: "ceramics",
    text: "Ceramics",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "native_american",
    value: "native_american",
    text: "Native American",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "decorative_arts",
    value: "decorative_arts",
    text: "Decorative Arts",
    label: { color: "yellow", empty: true, circular: true },
  },

  {
    key: "cultural",
    value: "cultural",
    text: "Cultural",
    label: { color: "red", empty: true, circular: true },
  },

  {
    key: "antiques",
    value: "antiques",
    text: "Antiques",
    label: { color: "purple", empty: true, circular: true },
  },
];

export const currencyOptions = [
  { key: "USD", value: "USD", text: "USD" },
  { key: "EUR", value: "EUR", text: "EUR" },
  { key: "JPY", value: "JPY", text: "JPY" },
  { key: "GBP", value: "GBP", text: "GBP" },
  { key: "AUD", value: "AUD", text: "AUD" },
  { key: "CAD", value: "CAD", text: "CAD" },
  { key: "CHF", value: "CHF", text: "CHF" },
  { key: "CNY", value: "CNY", text: "CNY" },
  { key: "SEK", value: "SEK", text: "SEK" },
  { key: "HKD", value: "HKD", text: "HKD" },
  { key: "MXN", value: "MXN", text: "MXN" },
  { key: "SGD", value: "SGD", text: "SGD" },
  { key: "BRL", value: "BRL", text: "BRL" },
  { key: "RUB", value: "RUB", text: "RUB" },
  { key: "NZD", value: "NZD", text: "NZD" },
  { key: "DKK", value: "DKK", text: "DKK" },
  { key: "NOK", value: "NOK", text: "NOK" },
  { key: "NGN", value: "NGN", text: "NGN" },
];

export const pieceSortOptions = [
  { key: "artist_a_z", value: "artist_a_z", text: "Artist (A-Z)" },
  { key: "artist_z_a", value: "artist_z_a", text: "Artist (Z-A)" },
  { key: "title_a_z", value: "title_a_z", text: "Title (A-Z)" },
  { key: "title_z_a", value: "title_z_a", text: "Title (Z-A)" },
  { key: "price_low_high", value: "price_low_high", text: "Price low to high" },
  { key: "price_high_low", value: "price_high_low", text: "Price high to low" },
];

export const stateOptions = [
  { key: "AL", value: "AL", text: "Alabama" },
  { key: "AK", value: "AK", text: "Alaska" },
  { key: "AZ", value: "AZ", text: "Arizona" },
  { key: "AR", value: "AR", text: "Arkansas" },
  { key: "CA", value: "CA", text: "California" },
  { key: "CO", value: "CO", text: "Colorado" },
  { key: "CT", value: "CT", text: "Connecticut" },
  { key: "DE", value: "DE", text: "Delaware" },
  { key: "DC", value: "DC", text: "District Of Columbia" },
  { key: "FL", value: "FL", text: "Florida" },
  { key: "GA", value: "GA", text: "Georgia" },
  { key: "HI", value: "HI", text: "Hawaii" },
  { key: "ID", value: "ID", text: "Idaho" },
  { key: "IL", value: "IL", text: "Illinois" },
  { key: "IN", value: "IN", text: "Indiana" },
  { key: "IA", value: "IA", text: "Iowa" },
  { key: "KS", value: "KS", text: "Kansas" },
  { key: "KY", value: "KY", text: "Kentucky" },
  { key: "LA", value: "LA", text: "Louisiana" },
  { key: "ME", value: "ME", text: "Maine" },
  { key: "MD", value: "MD", text: "Maryland" },
  { key: "MA", value: "MA", text: "Massachusetts" },
  { key: "MI", value: "MI", text: "Michigan" },
  { key: "MN", value: "MN", text: "Minnesota" },
  { key: "MS", value: "MS", text: "Mississippi" },
  { key: "MO", value: "MO", text: "Missouri" },
  { key: "MT", value: "MT", text: "Montana" },
  { key: "NE", value: "NE", text: "Nebraska" },
  { key: "NV", value: "NV", text: "Nevada" },
  { key: "NH", value: "NH", text: "New Hampshire" },
  { key: "NJ", value: "NJ", text: "New Jersey" },
  { key: "NM", value: "NM", text: "New Mexico" },
  { key: "NY", value: "NY", text: "New York" },
  { key: "NC", value: "NC", text: "North Carolina" },
  { key: "ND", value: "ND", text: "North Dakota" },
  { key: "OH", value: "OH", text: "Ohio" },
  { key: "OK", value: "OK", text: "Oklahoma" },
  { key: "OR", value: "OR", text: "Oregon" },
  { key: "PA", value: "PA", text: "Pennsylvania" },
  { key: "RI", value: "RI", text: "Rhode Island" },
  { key: "SC", value: "SC", text: "South Carolina" },
  { key: "SD", value: "SD", text: "South Dakota" },
  { key: "TN", value: "TN", text: "Tennessee" },
  { key: "TX", value: "TX", text: "Texas" },
  { key: "UT", value: "UT", text: "Utah" },
  { key: "VT", value: "VT", text: "Vermont" },
  { key: "VA", value: "VA", text: "Virginia" },
  { key: "WA", value: "WA", text: "Washington" },
  { key: "WV", value: "WV", text: "West Virginia" },
  { key: "WI", value: "WI", text: "Wisconsin" },
  { key: "WY", value: "WY", text: "Wyoming" },
];

export const friendOptions = [
  {
    key: "Jenny Hess",
    text: "Jenny Hess",
    value: "Jenny Hess",
    image: { avatar: true, src: "/assets/images/avatar/small/jenny.jpg" },
  },
  {
    key: "Elliot Fu",
    text: "Elliot Fu",
    value: "Elliot Fu",
    image: { avatar: true, src: "/assets/images/avatar/small/elliot.jpg" },
  },
  {
    key: "Stevie Feliciano",
    text: "Stevie Feliciano",
    value: "Stevie Feliciano",
    image: { avatar: true, src: "/assets/images/avatar/small/stevie.jpg" },
  },
  {
    key: "Christian",
    text: "Christian",
    value: "Christian",
    image: { avatar: true, src: "/assets/images/avatar/small/christian.jpg" },
  },
  {
    key: "Matt",
    text: "Matt",
    value: "Matt",
    image: { avatar: true, src: "/assets/images/avatar/small/matt.jpg" },
  },
  {
    key: "Justen Kitsune",
    text: "Justen Kitsune",
    value: "Justen Kitsune",
    image: { avatar: true, src: "/assets/images/avatar/small/justen.jpg" },
  },
];

export const countryOptions = [
  { key: "af", value: "af", flag: "af", text: "Afghanistan" },
  { key: "ax", value: "ax", flag: "ax", text: "Aland Islands" },
  { key: "al", value: "al", flag: "al", text: "Albania" },
  { key: "dz", value: "dz", flag: "dz", text: "Algeria" },
  { key: "as", value: "as", flag: "as", text: "American Samoa" },
  { key: "ad", value: "ad", flag: "ad", text: "Andorra" },
  { key: "ao", value: "ao", flag: "ao", text: "Angola" },
  { key: "ai", value: "ai", flag: "ai", text: "Anguilla" },
  { key: "ag", value: "ag", flag: "ag", text: "Antigua" },
  { key: "ar", value: "ar", flag: "ar", text: "Argentina" },
  { key: "am", value: "am", flag: "am", text: "Armenia" },
  { key: "aw", value: "aw", flag: "aw", text: "Aruba" },
  { key: "au", value: "au", flag: "au", text: "Australia" },
  { key: "at", value: "at", flag: "at", text: "Austria" },
  { key: "az", value: "az", flag: "az", text: "Azerbaijan" },
  { key: "bs", value: "bs", flag: "bs", text: "Bahamas" },
  { key: "bh", value: "bh", flag: "bh", text: "Bahrain" },
  { key: "bd", value: "bd", flag: "bd", text: "Bangladesh" },
  { key: "bb", value: "bb", flag: "bb", text: "Barbados" },
  { key: "by", value: "by", flag: "by", text: "Belarus" },
  { key: "be", value: "be", flag: "be", text: "Belgium" },
  { key: "bz", value: "bz", flag: "bz", text: "Belize" },
  { key: "bj", value: "bj", flag: "bj", text: "Benin" },
  { key: "bm", value: "bm", flag: "bm", text: "Bermuda" },
  { key: "bt", value: "bt", flag: "bt", text: "Bhutan" },
  { key: "bo", value: "bo", flag: "bo", text: "Bolivia" },
  { key: "ba", value: "ba", flag: "ba", text: "Bosnia" },
  { key: "bw", value: "bw", flag: "bw", text: "Botswana" },
  { key: "bv", value: "bv", flag: "bv", text: "Bouvet Island" },
  { key: "br", value: "br", flag: "br", text: "Brazil" },
  { key: "vg", value: "vg", flag: "vg", text: "British Virgin Islands" },
  { key: "bn", value: "bn", flag: "bn", text: "Brunei" },
  { key: "bg", value: "bg", flag: "bg", text: "Bulgaria" },
  { key: "bf", value: "bf", flag: "bf", text: "Burkina Faso" },
  { key: "bi", value: "bi", flag: "bi", text: "Burundi" },
  { key: "tc", value: "tc", flag: "tc", text: "Caicos Islands" },
  { key: "kh", value: "kh", flag: "kh", text: "Cambodia" },
  { key: "cm", value: "cm", flag: "cm", text: "Cameroon" },
  { key: "ca", value: "ca", flag: "ca", text: "Canada" },
  { key: "cv", value: "cv", flag: "cv", text: "Cape Verde" },
  { key: "ky", value: "ky", flag: "ky", text: "Cayman Islands" },
  { key: "cf", value: "cf", flag: "cf", text: "Central African Republic" },
  { key: "td", value: "td", flag: "td", text: "Chad" },
  { key: "cl", value: "cl", flag: "cl", text: "Chile" },
  { key: "cn", value: "cn", flag: "cn", text: "China" },
  { key: "cx", value: "cx", flag: "cx", text: "Christmas Island" },
  { key: "cc", value: "cc", flag: "cc", text: "Cocos Islands" },
  { key: "co", value: "co", flag: "co", text: "Colombia" },
  { key: "km", value: "km", flag: "km", text: "Comoros" },
  { key: "cg", value: "cg", flag: "cg", text: "Congo Brazzaville" },
  { key: "cd", value: "cd", flag: "cd", text: "Congo" },
  { key: "ck", value: "ck", flag: "ck", text: "Cook Islands" },
  { key: "cr", value: "cr", flag: "cr", text: "Costa Rica" },
  { key: "ci", value: "ci", flag: "ci", text: "Cote Divoire" },
  { key: "hr", value: "hr", flag: "hr", text: "Croatia" },
  { key: "cu", value: "cu", flag: "cu", text: "Cuba" },
  { key: "cy", value: "cy", flag: "cy", text: "Cyprus" },
  { key: "cz", value: "cz", flag: "cz", text: "Czech Republic" },
  { key: "dk", value: "dk", flag: "dk", text: "Denmark" },
  { key: "dj", value: "dj", flag: "dj", text: "Djibouti" },
  { key: "dm", value: "dm", flag: "dm", text: "Dominica" },
  { key: "do", value: "do", flag: "do", text: "Dominican Republic" },
  { key: "ec", value: "ec", flag: "ec", text: "Ecuador" },
  { key: "eg", value: "eg", flag: "eg", text: "Egypt" },
  { key: "sv", value: "sv", flag: "sv", text: "El Salvador" },
  { key: "gb", value: "gb", flag: "gb", text: "England" },
  { key: "gq", value: "gq", flag: "gq", text: "Equatorial Guinea" },
  { key: "er", value: "er", flag: "er", text: "Eritrea" },
  { key: "ee", value: "ee", flag: "ee", text: "Estonia" },
  { key: "et", value: "et", flag: "et", text: "Ethiopia" },
  { key: "eu", value: "eu", flag: "eu", text: "European Union" },
  { key: "fk", value: "fk", flag: "fk", text: "Falkland Islands" },
  { key: "fo", value: "fo", flag: "fo", text: "Faroe Islands" },
  { key: "fj", value: "fj", flag: "fj", text: "Fiji" },
  { key: "fi", value: "fi", flag: "fi", text: "Finland" },
  { key: "fr", value: "fr", flag: "fr", text: "France" },
  { key: "gf", value: "gf", flag: "gf", text: "French Guiana" },
  { key: "pf", value: "pf", flag: "pf", text: "French Polynesia" },
  { key: "tf", value: "tf", flag: "tf", text: "French Territories" },
  { key: "ga", value: "ga", flag: "ga", text: "Gabon" },
  { key: "gm", value: "gm", flag: "gm", text: "Gambia" },
  { key: "ge", value: "ge", flag: "ge", text: "Georgia" },
  { key: "de", value: "de", flag: "de", text: "Germany" },
  { key: "gh", value: "gh", flag: "gh", text: "Ghana" },
  { key: "gi", value: "gi", flag: "gi", text: "Gibraltar" },
  { key: "gr", value: "gr", flag: "gr", text: "Greece" },
  { key: "gl", value: "gl", flag: "gl", text: "Greenland" },
  { key: "gd", value: "gd", flag: "gd", text: "Grenada" },
  { key: "gp", value: "gp", flag: "gp", text: "Guadeloupe" },
  { key: "gu", value: "gu", flag: "gu", text: "Guam" },
  { key: "gt", value: "gt", flag: "gt", text: "Guatemala" },
  { key: "gw", value: "gw", flag: "gw", text: "Guinea-Bissau" },
  { key: "gn", value: "gn", flag: "gn", text: "Guinea" },
  { key: "gy", value: "gy", flag: "gy", text: "Guyana" },
  { key: "ht", value: "ht", flag: "ht", text: "Haiti" },
  { key: "hm", value: "hm", flag: "hm", text: "Heard Island" },
  { key: "hn", value: "hn", flag: "hn", text: "Honduras" },
  { key: "hk", value: "hk", flag: "hk", text: "Hong Kong" },
  { key: "hu", value: "hu", flag: "hu", text: "Hungary" },
  { key: "is", value: "is", flag: "is", text: "Iceland" },
  { key: "in", value: "in", flag: "in", text: "India" },
  { key: "io", value: "io", flag: "io", text: "Indian Ocean Territory" },
  { key: "id", value: "id", flag: "id", text: "Indonesia" },
  { key: "ir", value: "ir", flag: "ir", text: "Iran" },
  { key: "iq", value: "iq", flag: "iq", text: "Iraq" },
  { key: "ie", value: "ie", flag: "ie", text: "Ireland" },
  { key: "il", value: "il", flag: "il", text: "Israel" },
  { key: "it", value: "it", flag: "it", text: "Italy" },
  { key: "jm", value: "jm", flag: "jm", text: "Jamaica" },
  { key: "jp", value: "jp", flag: "jp", text: "Japan" },
  { key: "jo", value: "jo", flag: "jo", text: "Jordan" },
  { key: "kz", value: "kz", flag: "kz", text: "Kazakhstan" },
  { key: "ke", value: "ke", flag: "ke", text: "Kenya" },
  { key: "ki", value: "ki", flag: "ki", text: "Kiribati" },
  { key: "kw", value: "kw", flag: "kw", text: "Kuwait" },
  { key: "kg", value: "kg", flag: "kg", text: "Kyrgyzstan" },
  { key: "la", value: "la", flag: "la", text: "Laos" },
  { key: "lv", value: "lv", flag: "lv", text: "Latvia" },
  { key: "lb", value: "lb", flag: "lb", text: "Lebanon" },
  { key: "ls", value: "ls", flag: "ls", text: "Lesotho" },
  { key: "lr", value: "lr", flag: "lr", text: "Liberia" },
  { key: "ly", value: "ly", flag: "ly", text: "Libya" },
  { key: "li", value: "li", flag: "li", text: "Liechtenstein" },
  { key: "lt", value: "lt", flag: "lt", text: "Lithuania" },
  { key: "lu", value: "lu", flag: "lu", text: "Luxembourg" },
  { key: "mo", value: "mo", flag: "mo", text: "Macau" },
  { key: "mk", value: "mk", flag: "mk", text: "Macedonia" },
  { key: "mg", value: "mg", flag: "mg", text: "Madagascar" },
  { key: "mw", value: "mw", flag: "mw", text: "Malawi" },
  { key: "my", value: "my", flag: "my", text: "Malaysia" },
  { key: "mv", value: "mv", flag: "mv", text: "Maldives" },
  { key: "ml", value: "ml", flag: "ml", text: "Mali" },
  { key: "mt", value: "mt", flag: "mt", text: "Malta" },
  { key: "mh", value: "mh", flag: "mh", text: "Marshall Islands" },
  { key: "mq", value: "mq", flag: "mq", text: "Martinique" },
  { key: "mr", value: "mr", flag: "mr", text: "Mauritania" },
  { key: "mu", value: "mu", flag: "mu", text: "Mauritius" },
  { key: "yt", value: "yt", flag: "yt", text: "Mayotte" },
  { key: "mx", value: "mx", flag: "mx", text: "Mexico" },
  { key: "fm", value: "fm", flag: "fm", text: "Micronesia" },
  { key: "md", value: "md", flag: "md", text: "Moldova" },
  { key: "mc", value: "mc", flag: "mc", text: "Monaco" },
  { key: "mn", value: "mn", flag: "mn", text: "Mongolia" },
  { key: "me", value: "me", flag: "me", text: "Montenegro" },
  { key: "ms", value: "ms", flag: "ms", text: "Montserrat" },
  { key: "ma", value: "ma", flag: "ma", text: "Morocco" },
  { key: "mz", value: "mz", flag: "mz", text: "Mozambique" },
  { key: "na", value: "na", flag: "na", text: "Namibia" },
  { key: "nr", value: "nr", flag: "nr", text: "Nauru" },
  { key: "np", value: "np", flag: "np", text: "Nepal" },
  { key: "an", value: "an", flag: "an", text: "Netherlands Antilles" },
  { key: "nl", value: "nl", flag: "nl", text: "Netherlands" },
  { key: "nc", value: "nc", flag: "nc", text: "New Caledonia" },
  { key: "pg", value: "pg", flag: "pg", text: "New Guinea" },
  { key: "nz", value: "nz", flag: "nz", text: "New Zealand" },
  { key: "ni", value: "ni", flag: "ni", text: "Nicaragua" },
  { key: "ne", value: "ne", flag: "ne", text: "Niger" },
  { key: "ng", value: "ng", flag: "ng", text: "Nigeria" },
  { key: "nu", value: "nu", flag: "nu", text: "Niue" },
  { key: "nf", value: "nf", flag: "nf", text: "Norfolk Island" },
  { key: "kp", value: "kp", flag: "kp", text: "North Korea" },
  { key: "mp", value: "mp", flag: "mp", text: "Northern Mariana Islands" },
  { key: "no", value: "no", flag: "no", text: "Norway" },
  { key: "om", value: "om", flag: "om", text: "Oman" },
  { key: "pk", value: "pk", flag: "pk", text: "Pakistan" },
  { key: "pw", value: "pw", flag: "pw", text: "Palau" },
  { key: "ps", value: "ps", flag: "ps", text: "Palestine" },
  { key: "pa", value: "pa", flag: "pa", text: "Panama" },
  { key: "py", value: "py", flag: "py", text: "Paraguay" },
  { key: "pe", value: "pe", flag: "pe", text: "Peru" },
  { key: "ph", value: "ph", flag: "ph", text: "Philippines" },
  { key: "pn", value: "pn", flag: "pn", text: "Pitcairn Islands" },
  { key: "pl", value: "pl", flag: "pl", text: "Poland" },
  { key: "pt", value: "pt", flag: "pt", text: "Portugal" },
  { key: "pr", value: "pr", flag: "pr", text: "Puerto Rico" },
  { key: "qa", value: "qa", flag: "qa", text: "Qatar" },
  { key: "re", value: "re", flag: "re", text: "Reunion" },
  { key: "ro", value: "ro", flag: "ro", text: "Romania" },
  { key: "ru", value: "ru", flag: "ru", text: "Russia" },
  { key: "rw", value: "rw", flag: "rw", text: "Rwanda" },
  { key: "sh", value: "sh", flag: "sh", text: "Saint Helena" },
  { key: "kn", value: "kn", flag: "kn", text: "Saint Kitts and Nevis" },
  { key: "lc", value: "lc", flag: "lc", text: "Saint Lucia" },
  { key: "pm", value: "pm", flag: "pm", text: "Saint Pierre" },
  { key: "vc", value: "vc", flag: "vc", text: "Saint Vincent" },
  { key: "ws", value: "ws", flag: "ws", text: "Samoa" },
  { key: "sm", value: "sm", flag: "sm", text: "San Marino" },
  { key: "gs", value: "gs", flag: "gs", text: "Sandwich Islands" },
  { key: "st", value: "st", flag: "st", text: "Sao Tome" },
  { key: "sa", value: "sa", flag: "sa", text: "Saudi Arabia" },
  { key: "sn", value: "sn", flag: "sn", text: "Senegal" },
  { key: "cs", value: "cs", flag: "cs", text: "Serbia" },
  { key: "rs", value: "rs", flag: "rs", text: "Serbia" },
  { key: "sc", value: "sc", flag: "sc", text: "Seychelles" },
  { key: "sl", value: "sl", flag: "sl", text: "Sierra Leone" },
  { key: "sg", value: "sg", flag: "sg", text: "Singapore" },
  { key: "sk", value: "sk", flag: "sk", text: "Slovakia" },
  { key: "si", value: "si", flag: "si", text: "Slovenia" },
  { key: "sb", value: "sb", flag: "sb", text: "Solomon Islands" },
  { key: "so", value: "so", flag: "so", text: "Somalia" },
  { key: "za", value: "za", flag: "za", text: "South Africa" },
  { key: "kr", value: "kr", flag: "kr", text: "South Korea" },
  { key: "es", value: "es", flag: "es", text: "Spain" },
  { key: "lk", value: "lk", flag: "lk", text: "Sri Lanka" },
  { key: "sd", value: "sd", flag: "sd", text: "Sudan" },
  { key: "sr", value: "sr", flag: "sr", text: "Suriname" },
  { key: "sj", value: "sj", flag: "sj", text: "Svalbard" },
  { key: "sz", value: "sz", flag: "sz", text: "Swaziland" },
  { key: "se", value: "se", flag: "se", text: "Sweden" },
  { key: "ch", value: "ch", flag: "ch", text: "Switzerland" },
  { key: "sy", value: "sy", flag: "sy", text: "Syria" },
  { key: "tw", value: "tw", flag: "tw", text: "Taiwan" },
  { key: "tj", value: "tj", flag: "tj", text: "Tajikistan" },
  { key: "tz", value: "tz", flag: "tz", text: "Tanzania" },
  { key: "th", value: "th", flag: "th", text: "Thailand" },
  { key: "tl", value: "tl", flag: "tl", text: "Timorleste" },
  { key: "tg", value: "tg", flag: "tg", text: "Togo" },
  { key: "tk", value: "tk", flag: "tk", text: "Tokelau" },
  { key: "to", value: "to", flag: "to", text: "Tonga" },
  { key: "tt", value: "tt", flag: "tt", text: "Trinidad" },
  { key: "tn", value: "tn", flag: "tn", text: "Tunisia" },
  { key: "tr", value: "tr", flag: "tr", text: "Turkey" },
  { key: "tm", value: "tm", flag: "tm", text: "Turkmenistan" },
  { key: "tv", value: "tv", flag: "tv", text: "Tuvalu" },
  { key: "ug", value: "ug", flag: "ug", text: "Uganda" },
  { key: "ua", value: "ua", flag: "ua", text: "Ukraine" },
  { key: "ae", value: "ae", flag: "ae", text: "United Arab Emirates" },
  { key: "us", value: "us", flag: "us", text: "United States" },
  { key: "uy", value: "uy", flag: "uy", text: "Uruguay" },
  { key: "um", value: "um", flag: "um", text: "Us Minor Islands" },
  { key: "vi", value: "vi", flag: "vi", text: "Us Virgin Islands" },
  { key: "uz", value: "uz", flag: "uz", text: "Uzbekistan" },
  { key: "vu", value: "vu", flag: "vu", text: "Vanuatu" },
  { key: "va", value: "va", flag: "va", text: "Vatican City" },
  { key: "ve", value: "ve", flag: "ve", text: "Venezuela" },
  { key: "vn", value: "vn", flag: "vn", text: "Vietnam" },
  { key: "wf", value: "wf", flag: "wf", text: "Wallis and Futuna" },
  { key: "eh", value: "eh", flag: "eh", text: "Western Sahara" },
  { key: "ye", value: "ye", flag: "ye", text: "Yemen" },
  { key: "zm", value: "zm", flag: "zm", text: "Zambia" },
  { key: "zw", value: "zw", flag: "zw", text: "Zimbabwe" },
];

// export const languageOptions = [
// 	{ key: 'Arabic', text: 'Arabic', value: 'Arabic' },
// 	{ key: 'Chinese', text: 'Chinese', value: 'Chinese' },
// 	{ key: 'Danish', text: 'Danish', value: 'Danish' },
// 	{ key: 'Dutch', text: 'Dutch', value: 'Dutch' },
// 	{ key: 'English', text: 'English', value: 'English' },
// 	{ key: 'French', text: 'French', value: 'French' },
// 	{ key: 'German', text: 'German', value: 'German' },
// 	{ key: 'Greek', text: 'Greek', value: 'Greek' },
// 	{ key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
// 	{ key: 'Italian', text: 'Italian', value: 'Italian' },
// 	{ key: 'Japanese', text: 'Japanese', value: 'Japanese' },
// 	{ key: 'Korean', text: 'Korean', value: 'Korean' },
// 	{ key: 'Lithuanian', text: 'Lithuanian', value: 'Lithuanian' },
// 	{ key: 'Persian', text: 'Persian', value: 'Persian' },
// 	{ key: 'Polish', text: 'Polish', value: 'Polish' },
// 	{ key: 'Portuguese', text: 'Portuguese', value: 'Portuguese' },
// 	{ key: 'Russian', text: 'Russian', value: 'Russian' },
// 	{ key: 'Spanish', text: 'Spanish', value: 'Spanish' },
// 	{ key: 'Swedish', text: 'Swedish', value: 'Swedish' },
// 	{ key: 'Turkish', text: 'Turkish', value: 'Turkish' },
// 	{ key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' }
// ];

export const tagOptions = [
  {
    key: "Important",
    text: "Important",
    value: "Important",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "Announcement",
    text: "Announcement",
    value: "Announcement",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "Cannot Fix",
    text: "Cannot Fix",
    value: "Cannot Fix",
    label: { color: "black", empty: true, circular: true },
  },
  {
    key: "News",
    text: "News",
    value: "News",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "Enhancement",
    text: "Enhancement",
    value: "Enhancement",
    label: { color: "orange", empty: true, circular: true },
  },
  {
    key: "Change Declined",
    text: "Change Declined",
    value: "Change Declined",
    label: { empty: true, circular: true },
  },
  {
    key: "Off Topic",
    text: "Off Topic",
    value: "Off Topic",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "Interesting",
    text: "Interesting",
    value: "Interesting",
    label: { color: "pink", empty: true, circular: true },
  },
  {
    key: "Discussion",
    text: "Discussion",
    value: "Discussion",
    label: { color: "green", empty: true, circular: true },
  },
];

export const colorOptions = [
  {
    key: "red",
    text: "Red",
    value: "red",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "blue",
    text: "Blue",
    value: "blue",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "black",
    text: "Black",
    value: "black",
    label: { color: "black", empty: true, circular: true },
  },
  {
    key: "purple",
    text: "Purple",
    value: "purple",
    label: { color: "purple", empty: true, circular: true },
  },
  {
    key: "orange",
    text: "Orange",
    value: "orange",
    label: { color: "orange", empty: true, circular: true },
  },
  {
    key: "yellwo",
    text: "Yellow",
    value: "yellow",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "pink",
    text: "Pink",
    value: "pink",
    label: { color: "pink", empty: true, circular: true },
  },
  {
    key: "green",
    text: "Green",
    value: "green",
    label: { color: "green", empty: true, circular: true },
  },
];

export const accountLevels = [
  { key: "basic", value: "basic", text: "Basic" },
  { key: "premium", value: "premium", text: "Premium" },
  { key: "pro", value: "pro", text: "Pro" },
];

export const groupingCategoryOptions = [
  {
    key: "singular-artwork",
    value: "singular-artwork",
    text: "Singular Artwork",
  },
  { key: "original", value: "original", text: "Original" },
  { key: "multiple", value: "multiple", text: "Multiple" },
  { key: "photography", value: "photography", text: "Photography" },
];

export const getOptions = (number, prefix = "Choice ") =>
  _.times(number, (index) => ({
    key: index,
    text: `${prefix}${index}`,
    value: index,
  }));

export const basicBenefits = [
  {
    enabled: true,
    header: "Inventory Management",
    content:
      "Document the pieces you own, the artists you collect, and the galleries you use to build your collection.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Prints & editions functionality",
    content:
      "Document editions easily by providing edition number and edition size.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Mobile Applications",
    content: "Access your collection anywhere, anytime.",
    icon: "check",
  },
  {
    enabled: false,
    header: "Auction Alerts",
    content: `Receive alerts detailing upcoming auctions featuring works by artists you collect and follow.`,
    icon: "minus",
  },
  {
    enabled: false,
    header: "Price Database",
    content: `Access ArtCollection.io's auction price database, including estimates, realized prices, dates, and venues.`,
    icon: "minus",
  },
  {
    enabled: false,
    header: "Follow Artists",
    content: `Analyze an individual artist's performance over time according to various metrics.`,
    icon: "minus",
  },
  {
    enabled: false,
    header: "Peformance Analytics",
    content: `Analyze an individual artist's performance over time according to various metrics.`,
    icon: "minus",
  },
];

export const premiumBenefits = [
  {
    enabled: true,
    header: "Inventory Management",
    content:
      "Document the pieces you own, the artists you collect, and the galleries you use to build your collection.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Prints & editions functionality",
    content:
      "Document editions easily by providing edition number and edition size.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Mobile Applications",
    content: "Access your collection anywhere, anytime.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Auction Alerts",
    content: `Receive alerts detailing upcoming auctions featuring works by artists you collect and follow.`,
    icon: "plus",
  },
  {
    enabled: true,
    header: "Price Database",
    content: `Access ArtCollection.io's auction price database, including estimates, realized prices, dates, and venues.`,
    icon: "plus",
  },
  {
    enabled: false,
    header: "Follow Artists",
    content: `In addition to the artists you collect, stay up to date on the ones you'd like to hear more about`,
    icon: "minus",
  },
  {
    enabled: false,
    header: "Performance Analytics",
    content: `Analyze an individual artist's performance over time according to various metrics.`,
    icon: "minus",
  },
];

export const proBenefits = [
  {
    enabled: true,
    header: "Inventory Management",
    content:
      "Document the pieces you own, the artists you collect, and the galleries you use to build your collection.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Prints & editions functionality",
    content:
      "Document editions easily by providing edition number and edition size.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Mobile Applications",
    content: "Access your collection anywhere, anytime.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Auction Alerts",
    content: `Receive alerts detailing upcoming auctions featuring works by artists you collect and follow.`,
    icon: "check",
  },
  {
    enabled: true,
    header: "Price Database",
    content: `Access ArtCollection.io's auction price database, including estimates, realized prices, dates, and venues.`,
    icon: "check",
  },
  {
    enabled: true,
    header: "Follow Artists",
    content: `In addition to the artists you collect, stay up to date on the ones you'd like to hear more about`,
    icon: "plus",
  },
  {
    enabled: true,
    header: "Performance Analytics",
    content: `Analyze an individual artist's performance over time according to various metrics.`,
    icon: "plus",
  },
];
export const businessBenefits = [
  {
    enabled: true,
    header: "Manage Multiple Accounts",
    content:
      "Document the pieces you own, the artists you collect, and the galleries you use to build your collection.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Inventory Management",
    content:
      "Document the pieces you own, the artists you collect, and the galleries you use to build your collection.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Prints & editions functionality",
    content:
      "Document editions easily by providing edition number and edition size.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Mobile Applications",
    content: "Access your collection anywhere, anytime.",
    icon: "check",
  },
  {
    enabled: true,
    header: "Auction Alerts",
    content: `Receive alerts detailing upcoming auctions featuring works by artists you collect and follow.`,
    icon: "check",
  },
  {
    enabled: true,
    header: "Price Database",
    content: `Access ArtCollection.io's auction price database, including estimates, realized prices, dates, and venues.`,
    icon: "check",
  },
  {
    enabled: true,
    header: "Follow Artists",
    content: `In addition to the artists you collect, stay up to date on the ones you'd like to hear more about`,
    icon: "plus",
  },
  {
    enabled: true,
    header: "Performance Analytics",
    content: `Analyze an individual artist's performance over time according to various metrics.`,
    icon: "plus",
  },
];
