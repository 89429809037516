import React, { useState } from "react";
import { Button, Grid, Header, Item, Segment } from "semantic-ui-react";
import { createBillingPortalSession } from "../../../../../../../redux/actions/billing_portal";
import { connect } from "react-redux";
import stripeLogo from "../../../../../../../images/billing/stripe.png";

const ManageBilling = ({ user, createBilling }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onBillingSessionButtonPressed = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await createBilling({
        return_url: window.location.href,
      });
      const { url } = response.payload.data;
      window.open(url, "_self");
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Grid.Row>
      <Grid.Column>
        <Segment attached>
          <Item.Group divided>
            <Item>
              <Item.Image size={"small"} src={stripeLogo} />
              <Item.Content>
                <Item.Header content={"Stripe"} />
                <Item.Description>
                  <p>
                    ArtCollection.io has partnered with Stripe to bring you
                    secure management of your billing information. Please click
                    on the button below to access your invoices, update credit
                    cards, and change subscription plans.
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <Segment attached={"bottom"}>
          <Button
            loading={loading}
            content={"Manage Billing"}
            labelPosition={"right"}
            icon={"angle right"}
            onClick={onBillingSessionButtonPressed}
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  createBilling: (data) => dispatch(createBillingPortalSession(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBilling);
