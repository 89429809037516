import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { clean } from "../../../../../../utils/utils";
import { updateCurrentUser } from "../../../../../../redux/actions/me";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import ChangePasswordModal from "./components/ChangePasswordModal";
import Security from "../Security";
import { useUser } from "../../../../../../contexts/UserContext";

const EditUser = ({ update }) => {
  const { currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirsName] = useState(
    currentUser && currentUser.first_name,
  );
  const [lastName, setLastName] = useState(
    currentUser && currentUser.last_name,
  );
  const [email, setEmail] = useState(currentUser && currentUser.email);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = clean({
      first_name: firstName,
      last_name: lastName,
      email,
    });

    var formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      await update(formData);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header size={"huge"} content={"Profile"} />
        </Grid.Column>
        <Grid.Column>
          <Button
            floated={"right"}
            icon={isEditing ? "close" : "edit"}
            content={isEditing ? "Close" : "Edit"}
            onClick={() =>
              isEditing ? setIsEditing(false) : setIsEditing(true)
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          {error && <ErrorMessage error={error} />}
          <Segment>
            {!currentUser && <Loader />}
            {currentUser && currentUser._id && (
              <Form onSubmit={onSubmit}>
                <Form.Group>
                  <Form.Field width={4}>
                    <label>Name</label>
                  </Form.Field>
                  <Form.Input
                    disabled={!isEditing}
                    defaultValue={currentUser.first_name}
                    name="first_name"
                    width={6}
                    placeholder={"First Name"}
                    autoComplete={"off"}
                    onChange={(e, { value }) => setFirsName(value)}
                  />
                  <Form.Input
                    disabled={!isEditing}
                    defaultValue={currentUser.last_name}
                    name="last_name"
                    width={6}
                    placeholder={"Last Name"}
                    autoComplete={"off"}
                    onChange={(e, { value }) => setLastName(value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Field width={4}>
                    <label>Email</label>
                  </Form.Field>
                  <Form.Input
                    disabled={!isEditing}
                    defaultValue={currentUser.email}
                    name="email"
                    width={12}
                    placeholder={"Email"}
                    onChange={(e, { value }) => setEmail(value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Field width={4}>
                    <label>Password</label>
                  </Form.Field>
                  {!isEditing && (
                    <Form.Input disabled defaultValue={"••••••••"} />
                  )}
                  {isEditing && (
                    <Form.Field width={12}>
                      <ChangePasswordModal />
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group>
                  {isEditing && (
                    <Form.Button
                      primary
                      floated={"right"}
                      type={"submit"}
                      loading={loading}
                      content={"Save"}
                    />
                  )}
                </Form.Group>
              </Form>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Security />
    </Grid>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
