import React from "react";
import { Table } from "semantic-ui-react";
import LocationTile from "../LocationTile";

const LocationsTable = ({ locations, handleOrderByChange, orderBy }) => {
  if (!locations || locations.length === 0) {
    return <p>No locations available.</p>; // Or replace with an EmptyState component if desired
  }

  const sortedOrder = (key) => {
    if (orderBy[0] === key) {
      return orderBy[1] === "asc" ? "ascending" : "descending";
    }
    return null;
  };

  const handleSort = (key) => {
    const direction =
      orderBy[0] === key && orderBy[1] === "asc" ? "desc" : "asc";
    handleOrderByChange([key, direction]);
  };

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            width={12}
            content={"Name"}
            sorted={sortedOrder("name")}
            onClick={() => handleSort("name")}
          />
          <Table.HeaderCell
            width={4}
            content={"Number of Pieces"}
            sorted={sortedOrder("number_of_pieces")}
            onClick={() => handleSort("number_of_pieces")}
          />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {locations.map((location) => (
          <LocationTile
            key={location._id}
            locationId={location._id}
            storageLocation={location}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default LocationsTable;
