import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Confirm,
  Grid,
  Header,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  getInvitationList,
  updateInvitation,
} from "../../../../../../../redux/actions/invitation";
import ErrorMessage from "../../../../../../../components/ErrorMessage";

const InvitationRequests = ({ fetch, update }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState(null);
  const [confirmingInvitation, setConfirmingInvitation] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch();
      setInvitations(response.payload.data);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  if (!invitations || invitations.length == 0) return null;

  return (
    <Grid.Row>
      <Grid.Column width={16} centered>
        <Segment loading={loading}>
          <Header size={"huge"} content={"Invitations"} />
          {error && <ErrorMessage error={error} />}
          <Table>
            <Table.Body>
              {(invitations || []).map(({ _id, created_by, status }) => {
                return (
                  <Table.Row key={_id}>
                    <Table.Cell content={created_by && created_by.name} />
                    <Table.Cell>
                      {status === "pending" && (
                        <>
                          <Button
                            floated={"right"}
                            positive
                            content={"Accept"}
                            onClick={() => setConfirmingInvitation(true)}
                          />
                          <Confirm
                            open={confirmingInvitation}
                            onCancel={() => setConfirmingInvitation(false)}
                            onConfirm={async () => {
                              await update(_id, { status: "accepted" });
                              await fetchData();
                              setConfirmingInvitation(false);
                            }}
                            content={
                              "Are you sure you want to accept this invitation?"
                            }
                          />
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetch: (status) => dispatch(getInvitationList(status)),
  update: (id, data) => dispatch(updateInvitation(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationRequests);
