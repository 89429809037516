import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useUser } from "./UserContext";
import { firestore } from "../utils/firebase";
import { onSnapshot, query, collection } from "firebase/firestore";
import Piece from "../models/piece";

const PiecesContext = createContext(undefined);

export const usePieces = () => {
  const context = useContext(PiecesContext);
  if (!context) {
    throw new Error("usePieces must be used within a PiecesProvider");
  }
  return context;
};

export const PiecesProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [pieces, setPieces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!currentUser) return;
    const docRef = query(
      collection(
        firestore,
        "pieces",
        currentUser.current_user_id || currentUser._id,
        "pieces",
      ),
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        const piecesData = docSnapshot.docs.map((doc) => ({
          ...doc.data(),
          _id: doc.id,
        }));
        setPieces(piecesData.map((piece) => new Piece(piece)));
        setLoading(false);
      },
      (err) => {
        setError(`Failed to listen to user changes: ${err.message}`);
        setLoading(false);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  return (
    <PiecesContext.Provider
      value={{
        pieces,
        loading,
        error,
        setYear,
        year,
      }}
    >
      {children}
    </PiecesContext.Provider>
  );
};
