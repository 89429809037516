import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import placeholder from "../../images/placeholder.png";
import { useNavigate } from "react-router-dom";
import { LOCATION_PATH } from "../../constants/paths";

const LocationCard = ({ storageLocation }) => {
  const navigate = useNavigate();
  const { _id, image_url, name, number_of_pieces } = storageLocation;

  return (
    <Card onClick={() => navigate(`${LOCATION_PATH}/${_id}`)}>
      <img
        src={image_url || placeholder}
        style={{
          objectFit: "cover",
          objectPosition: "top",
          height: "100%",
          maxHeight: 240,
        }}
        alt={name}
      />
      <Card.Content>
        <Card.Header>{name}</Card.Header>
        <Card.Description>{number_of_pieces}</Card.Description>
      </Card.Content>
    </Card>
  );
};

LocationCard.propTypes = {
  storageLocation: PropTypes.object,
};

export default LocationCard;
