import React from "react";
import Attachments from "../BasicInfo/Attachments";
import PropTypes from "prop-types";

const ProvenanceAttachments = ({ attachments, onAttachmentsUpdated }) => (
  <Attachments
    attachments={attachments}
    type={"provenance"}
    onAttachmentsUpdated={onAttachmentsUpdated}
  />
);

ProvenanceAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsUpdated: PropTypes.func,
};

export default ProvenanceAttachments;
