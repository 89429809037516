import React, { useState } from "react";
import { Button, Form, Grid, Header } from "semantic-ui-react";
// Images
import SlidingPane from "react-sliding-pane";

import { connect } from "react-redux";
import {
  createArtwork,
  updateArtwork,
} from "../../../../../../redux/actions/artwork";
import DropZone from "../../../../../../components/DropZone";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SelectArtistModal from "../../../../../../components/Modals/Artist/Select";
import SelectMuseumModal from "../../../../../../components/Modals/Museum/Select";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const EditArtworkSidePain = ({ artwork, children, update, create }) => {
  const [loading, setLoading] = useState(false);
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [error, setError] = useState(null);

  const [id] = useState(artwork._id);
  const [imageUrl] = useState(artwork.image_url || null);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState(artwork.title || "");
  const [date, setDate] = useState(artwork.date || "");
  const [medium, setMedium] = useState(artwork.medium || "");
  const [category, setCategory] = useState(artwork.category || "");
  const [about, setAbout] = useState(artwork.about || "");
  const [size, setSize] = useState(artwork.size || "");
  const [websiteUrl, setWebsiteUrl] = useState(artwork.website_url || "");

  const [artist, setArtist] = useState(artwork.artist || null);
  const [museum, setMuseum] = useState(artwork.museum || null);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = {
      file,
      title,
      date,
      about,
      size,
      medium,
      category,
      website_url: websiteUrl,
      artist: artist._id,
      museum: museum._id,
    };

    var formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      if (id) {
        await update(id, formData);
      } else {
        await create(formData);
      }

      setSidePainOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={id ? "Edit Artwork" : "Create Artwork"}
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Form fluid>
          {error && <ErrorMessage error={error} />}

          <Form.Group>
            <Form.Field width={4}>
              <label>Image</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                accept="image/*"
                icon={"image"}
                image={(file && file.preview) || imageUrl}
                placeholder={"Drag artwork image here or click to upload"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Title</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={title}
              width={8}
              placeholder="Title"
              autoComplete={"off"}
              onChange={(e, { value }) => setTitle(value)}
            />
            <Form.Input
              fluid
              defaultValue={date}
              width={4}
              placeholder="Date"
              autoComplete={"off"}
              onChange={(e, { value }) => setDate(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Artist</label>
            </Form.Field>
            <Form.Field width={12}>
              <SelectArtistModal
                artist={artist}
                onSelectArtist={(artist) => setArtist(artist)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Museum</label>
            </Form.Field>
            <Form.Field width={12}>
              <SelectMuseumModal
                museum={museum}
                onSelectMuseum={(museum) => setMuseum(museum)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Category</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={category}
              width={12}
              placeholder="Category"
              autoComplete={"off"}
              onChange={(e, { value }) => setCategory(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Website</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={websiteUrl}
              width={12}
              placeholder="Website"
              autoComplete={"off"}
              onChange={(e, { value }) => setWebsiteUrl(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Medium</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={medium}
              width={12}
              placeholder="Medium"
              autoComplete={"off"}
              onChange={(e, { value }) => setMedium(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Size</label>
            </Form.Field>
            <Form.TextArea
              fluid
              defaultValue={size}
              rows={6}
              width={12}
              placeholder="Size"
              autoComplete={"off"}
              onChange={(e, { value }) => setSize(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>About</label>
            </Form.Field>
            <Form.TextArea
              fluid
              defaultValue={about}
              rows={6}
              width={12}
              placeholder="About"
              autoComplete={"off"}
              onChange={(e, { value }) => setAbout(value)}
            />
          </Form.Group>
        </Form>
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createArtwork(data)),
  update: (id, data) => dispatch(updateArtwork(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditArtworkSidePain);
