import {
  GET_TAG_LIST,
  GET_TAG_LIST_FAILURE,
  GET_TAG_LIST_SUCCESS,
} from "../actions/tag";

const INITIAL_STATE = {
  display: localStorage.getItem("tagDisplay") || "table",
  isLoadingTagList: true,
  tags: [],
  hasMoreTags: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TAG_LIST:
      return {
        ...state,
        isLoadingTagList: true,
        tags: action.payload.request.params.page <= 1 ? [] : state.tags,
      };
    case GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        isLoadingTagList: false,
        tags: state.tags.concat(action.payload.data),
        hasMoreTags: action.payload.data.length === 25,
      };
    case GET_TAG_LIST_FAILURE:
      return {
        ...state,
        isLoadingTagList: false,
        error: action.error,
        hasMoreTags: false,
      };
    default:
      return state;
  }
};
