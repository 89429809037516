import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
// Components
import PrivateRoomsTable from "../PrivateRoomsTable";
import empty_state from "../../../../images/empty_state/privateRoom.png";
import PrivateRoomsGrid from "../PrivateRoomsGrid";
import {
  createPrivateRoom,
  getPrivateRoomList,
  togglePrivateRoomListDisplay,
} from "../../../../redux/actions/private_room";
import ErrorMessage from "../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../utils/Mixpanel";
import EmptyState from "../../../../components/EmptyState";
import AuthLayout from "../../../../layouts/AuthLayout";
import PrivateRoom from "../../../../models/private_room";
import CreatePrivateRoomSidePane from "../../components/CreatePrivateRoomSidePane";
import UpgradeModal from "../../../../components/UpgradeModal";
import { usePrivateRooms } from "../../../../contexts/PrivateRoomsContext";

const PrivateRoomsList = ({ display, toggleDisplay, create }) => {
  const [error, setError] = useState(null);
  const [currentPrivateRoom, setCurrentPrivateRoom] = useState(null);
  const { privateRooms, loading } = usePrivateRooms();

  useEffect(() => {
    Mixpanel.page("private_room");
  }, []);

  const onCreate = async () => {
    try {
      const { payload } = await create({});
      setCurrentPrivateRoom(new PrivateRoom(payload.data));
    } catch (e) {
      setError(e);
    }
  };

  return (
    <AuthLayout title={"Private Rooms"}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={12}>
            <Header size="huge" content="Private Rooms" />
          </Grid.Column>
          <Grid.Column width={4}>
            {currentPrivateRoom && (
              <CreatePrivateRoomSidePane
                currentPrivateRoom={currentPrivateRoom}
                modalOpen={true}
                onCloseModal={() => setCurrentPrivateRoom(null)}
              />
            )}
            <UpgradeModal>
              <Button
                basic
                icon="plus"
                content={"New Private Room"}
                labelPosition="left"
                floated={"right"}
                onClick={() => onCreate()}
              />
            </UpgradeModal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={14}>
            Found {privateRooms && privateRooms.size} Private Rooms
          </Grid.Column>
          <Grid.Column width={2}>
            View
            <Button.Group floated={"right"}>
              <Button
                basic
                icon={"grid layout"}
                active={display === "grid"}
                onClick={() => toggleDisplay("grid")}
              />
              <Button
                basic
                icon={"list"}
                active={display === "table"}
                onClick={() => toggleDisplay("table")}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {error && <ErrorMessage error={error} />}
            <Segment basic loading={loading}>
              {!loading &&
                privateRooms &&
                privateRooms.length !== 0 &&
                display === "table" && (
                  <PrivateRoomsTable privateRooms={privateRooms} />
                )}

              {!loading &&
                privateRooms &&
                privateRooms.length !== 0 &&
                display === "grid" && (
                  <PrivateRoomsGrid privateRooms={privateRooms} />
                )}

              {!loading && privateRooms && privateRooms.length !== 0 && (
                <EmptyState
                  image={empty_state}
                  title={"No PrivateRooms"}
                  detail={"Please document a piece of art to get started"}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ privateRooms, firebase }) => ({
  display: privateRooms.display,
  loading: privateRooms.isLoadingPrivateRoomList,
  error: privateRooms.error,
  privateRooms: privateRooms.privateRooms,
  hasMore: privateRooms.hasMorePrivateRooms,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPrivateRoom(data)),
  toggleDisplay: (name) => dispatch(togglePrivateRoomListDisplay(name)),
  getPrivateRooms: () => dispatch(getPrivateRoomList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoomsList);
