import moment from "moment";
import AuctionHouse from "./auction_house";

class Auction {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.name = data.name;

    this.image_url = data.image_url;
    this.medium_image_url = data.medium_image_url;
    this.small_image_url = data.small_image_url;

    this.about = data.about;
    this.identifier = data.identifier;

    this.start_date = data.start_date;
    this.end_date = data.end_date;

    this.auction_house =
      data.auction_house && new AuctionHouse(data.auction_house);

    this.website_url = data.website_url;
  }

  dateString() {
    if (this.end_date) {
      return moment(this.end_date).format("MMM D, YYYY");
    } else {
      return "";
    }
  }
}

export default Auction;
