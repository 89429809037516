const url = "/api/v4/auth";

export const SWITCH_ROLE = "SWITCH_ROLE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";

export function switchRole(role = "user") {
  return {
    type: SWITCH_ROLE,
    role,
  };
}

export const logout = () => {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    payload: {
      request: {
        url: `${url}/logout`,
        method: "POST",
      },
    },
  };
};

export const login = (data) => {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE],
    payload: {
      request: {
        url: `${url}/login`,
        method: "POST",
        data,
      },
    },
  };
};

export const register = (data) => {
  return {
    types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE],
    payload: {
      request: {
        url: `${url}/register`,
        method: "POST",
        data,
      },
    },
  };
};

export const loginWithFacebook = (data) => {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE],
    payload: {
      request: {
        url: `${url}/facebook`,
        method: "POST",
        data,
      },
    },
  };
};

export const loginWithAppleCode = (data) => {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE],
    payload: {
      request: {
        url: `${url}/apple_code`,
        method: "POST",
        data,
      },
    },
  };
};

export const recoverPassword = (data) => {
  return {
    types: [
      RECOVER_PASSWORD,
      RECOVER_PASSWORD_SUCCESS,
      RECOVER_PASSWORD_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/password_recover`,
        method: "POST",
        data,
      },
    },
  };
};

export const resetPassword = (data) => {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    payload: {
      request: {
        url: `${url}/password_reset`,
        method: "POST",
        data,
      },
    },
  };
};
