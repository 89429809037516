const url = "/api/v5/endpoints";

// Actions

export const UPDATE_MANY_PIECES = "UPDATE_MANY_PIECES";
export const UPDATE_MANY_PIECES_SUCCESS = "UPDATE_MANY_PIECES_SUCCESS";
export const UPDATE_MANY_PIECES_FAILURE = "UPDATE_MANY_PIECES_FAILURE";

// Networking

export const updateManyPieces = (data) => {
  return {
    types: [
      UPDATE_MANY_PIECES,
      UPDATE_MANY_PIECES_SUCCESS,
      UPDATE_MANY_PIECES_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/updateManyPieces`,
        method: "POST",
        data,
      },
    },
  };
};
