import React, { useState } from "react";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
// Images
import SlidingPane from "react-sliding-pane";

import { connect } from "react-redux";
import { createArtist, updateArtist } from "../../../../redux/actions/artist";
import DropZone from "../../../../components/DropZone";
import FormDatePicker from "../../../../components/Forms/FormDatePicker";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ErrorMessage from "../../../../components/ErrorMessage";
import { useUser } from "../../../../contexts/UserContext";

const EditArtistSidePain = ({ artist, children, update, create }) => {
  const { currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const [id] = useState(artist._id);
  const [imageUrl] = useState(artist.image_url || null);
  const [file, setFile] = useState(null);
  const [name, setName] = useState(artist.name || "");
  const [birthYear, setBirthYear] = useState(artist.birth_year || "");
  const [birthDate, setBirthDate] = useState(artist.birth_date);
  const [deathDate, setDeathDate] = useState(artist.death_date);
  const [deathYear, setDeathYear] = useState(artist.death_year || "");
  const [nationality, setNationality] = useState(artist.nationality || "");
  const [biography, setBiography] = useState(artist.biography || "");
  const [phone, setPhone] = useState(artist.phone || "");
  const [email, setEmail] = useState(artist.email || "");
  const [gender, setGender] = useState(artist.gender || "");
  const [websiteUrl, setWebsiteUrl] = useState(artist.website_url || "");
  const [instagramUsername, setInstagramUsername] = useState(
    artist.instagram_username || "",
  );
  const [twitterUsername, setTwitterUsername] = useState(
    artist.twitter_username || "",
  );
  const [source, setSource] = useState(artist.source || "");

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = {
      name,
      file,
      birth_year: birthYear,
      death_year: deathYear,
      nationality,
      biography,
      phone,
      email,
      gender,
      website_url: websiteUrl,
      instagram_username: instagramUsername,
      twitter_username: twitterUsername,

      birth_date: birthDate,
      death_date: deathDate,
      source,
    };

    var formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      if (id) {
        await update(id, formData);
      } else {
        await create(formData);
      }

      setModalOpen(true);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={id ? "Edit Artist" : "Create Artist"}
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Form fluid>
          {error && <ErrorMessage error={error} />}

          <Modal open={modalOpen}>
            <Modal.Header size="huge" content="Thank you" />
            <Modal.Content>
              <p>
                Our team will review the proposed changes and make the necessary
                adjustments.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                content="Okay"
                onClick={() => {
                  setModalOpen(false);
                  setSidePainOpen(false);
                }}
              />
            </Modal.Actions>
          </Modal>

          <Form.Group>
            <Form.Field width={4}>
              <label>Image</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                accept="image/*"
                icon={"image"}
                image={(file && file.preview) || imageUrl}
                placeholder={"Drag artist image here or click to upload"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={name}
              width={12}
              placeholder="Name"
              autoComplete={"off"}
              onChange={(e, { value }) => setName(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Website</label>
            </Form.Field>
            <Form.Input
              fluid
              name="website_url"
              defaultValue={websiteUrl}
              width={12}
              placeholder="Website"
              autoComplete={"off"}
              onChange={(e, { value }) => setWebsiteUrl(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Birth & Death Year</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={birthYear}
              width={6}
              placeholder="Birth Year"
              autoComplete={"off"}
              onChange={(e, { value }) => setBirthYear(value)}
            />
            <Form.Input
              fluid
              defaultValue={deathYear}
              width={6}
              placeholder="Death Year"
              autoComplete={"off"}
              onChange={(e, { value }) => setDeathYear(value)}
            />
          </Form.Group>

          {currentUser &&
            currentUser.roles &&
            (currentUser.roles.includes("admin") ||
              currentUser.roles.includes("intern")) && (
              <Form.Group>
                <Form.Field width={4}>
                  <label>Birth & Death Date</label>
                </Form.Field>
                <FormDatePicker
                  width={6}
                  placeholder="Birth Date"
                  value={birthDate}
                  autoComplete={"off"}
                  handleChange={(e, { value }) => setBirthDate(value)}
                />
                <FormDatePicker
                  width={6}
                  placeholder="Death Date"
                  value={deathDate}
                  autoComplete={"off"}
                  handleChange={(e, { value }) => setDeathDate(value)}
                />
              </Form.Group>
            )}

          <Form.Group>
            <Form.Field width={4}>
              <label>Nationality & Gender</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={nationality}
              width={6}
              placeholder="Nationality"
              autoComplete={"off"}
              onChange={(e, { value }) => setNationality(value)}
            />
            <Form.Input
              fluid
              defaultValue={gender}
              width={6}
              placeholder="Gender"
              autoComplete={"off"}
              onChange={(e, { value }) => setGender(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Email & Phone</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={email}
              width={6}
              placeholder="Email"
              autoComplete={"off"}
              onChange={(e, { value }) => setEmail(value)}
            />
            <Form.Input
              fluid
              defaultValue={phone}
              width={6}
              placeholder="Phone"
              autoComplete={"off"}
              onChange={(e, { value }) => setPhone(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Social</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={instagramUsername}
              width={6}
              placeholder="Instagram Username"
              autoComplete={"off"}
              onChange={(e, { value }) => setInstagramUsername(value)}
            />
            <Form.Input
              fluid
              defaultValue={twitterUsername}
              width={6}
              placeholder="Twitter Username"
              autoComplete={"off"}
              onChange={(e, { value }) => setTwitterUsername(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Biography</label>
            </Form.Field>
            <Form.TextArea
              defaultValue={biography}
              width={12}
              placeholder="Biography"
              rows={8}
              autoComplete={"off"}
              onChange={(e, { value }) => setBiography(value)}
            />
          </Form.Group>

          {currentUser &&
            currentUser.roles &&
            (currentUser.roles.includes("admin") ||
              currentUser.roles.includes("intern")) && (
              <Form.Group>
                <Form.Field width={4}>
                  <label>Source</label>
                </Form.Field>
                <Form.Input
                  width={12}
                  placeholder="Source"
                  defaultValue={source}
                  autoComplete={"off"}
                  onChange={(e, { value }) => setSource(value)}
                />
              </Form.Group>
            )}
        </Form>
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createArtist(data)),
  update: (id, data) => dispatch(updateArtist(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditArtistSidePain);
