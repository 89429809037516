import React, { useState } from "react";
import { Table, Checkbox, Image } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import Piece from "../../../../../../models/piece";
import { usePieces } from "../../../../../../contexts/PiecesContext";

const PiecesTable = ({ selectedPieces, handleCheck, handleSelectAll }) => {
  const [orderBy, setOrderBy] = useState(["title", "asc"]);
  const { pieces } = usePieces();

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            <Checkbox
              onChange={() => handleSelectAll(pieces.map((p) => new Piece(p)))}
            />
          </Table.HeaderCell>
          <Table.HeaderCell
            content={"Title"}
            sorted={
              orderBy[0] === "title"
                ? orderBy[1] === "asc"
                  ? "ascending"
                  : "descending"
                : null
            }
            onClick={() =>
              setOrderBy(
                orderBy[1] === "asc" ? ["title", "desc"] : ["title", "asc"],
              )
            }
          />
          <Table.HeaderCell
            content={"Artist"}
            sorted={
              orderBy[0] === "artist.name"
                ? orderBy[1] === "asc"
                  ? "ascending"
                  : "descending"
                : null
            }
            onClick={() =>
              setOrderBy(
                orderBy[1] === "asc"
                  ? ["artist.name", "desc"]
                  : ["artist.name", "asc"],
              )
            }
          />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {pieces &&
          pieces
            .map((p) => new Piece(p))
            .map((piece) => (
              <Table.Row key={piece._id} onClick={() => handleCheck(piece._id)}>
                <Table.Cell>
                  <Checkbox
                    checked={selectedPieces.has(piece._id)}
                    onClick={() => handleCheck(piece._id)}
                    onChange={() => handleCheck(piece._id)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Image src={piece.small_image_url} avatar />
                  {piece.title || "Untitled"}
                </Table.Cell>
                <Table.Cell content={piece.artist && piece.artist.name} />
              </Table.Row>
            ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = ({ firebase }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PiecesTable);
