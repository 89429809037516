import React, { useState } from "react";
import PropTypes from "prop-types";
import { Image, Checkbox, Table, Label, Icon } from "semantic-ui-react";
import { PIECE_PATH } from "../../constants/paths";
import { categoryOptions, statusOptions } from "../../common";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { updatePiece } from "../../redux/actions/piece";

const PieceCell = ({
  piece,
  configuration,
  selected,
  handleSelectPieceID,
  update,
}) => {
  const [isFlagged, setIsFlagged] = useState(piece.is_flagged ?? false);
  const navigate = useNavigate();

  const {
    _id,
    title,
    small_image_url,
    artist,
    gallery,
    inventory_identifier,
    status,
    location,
    category,
  } = piece;

  const categoryOption = categoryOptions.filter(
    (option) => option.key === category,
  )[0];
  const statusOption = statusOptions.filter(
    (option) => option.key === status,
  )[0];

  const handleFlagPress = () => {
    update(_id, {
      ...piece,
      is_flagged: !isFlagged,
    });
    setIsFlagged(!isFlagged);
  };

  return (
    <Table.Row key={_id} style={{ cursor: "pointer" }}>
      <Table.Cell textAlign={"center"}>
        <Checkbox
          checked={selected}
          onChange={(_, { checked }) => handleSelectPieceID(_id)}
        />
      </Table.Cell>
      {/* {is_flagged && ( */}
      <Table.Cell
        textAlign={"center"}
        verticalAlign={"middle"}
        style={{ width: "1%" }}
        onClick={handleFlagPress}
      >
        {isFlagged && <Icon name="flag" color="red" />}
      </Table.Cell>
      {configuration.inventory_identifier && (
        <Table.Cell
          content={inventory_identifier}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}

      <Table.Cell onClick={() => navigate(`${PIECE_PATH}/${_id}`)}>
        <Image src={small_image_url} avatar />
        {title}
      </Table.Cell>

      {configuration.artist && (
        <Table.Cell
          content={artist && artist.name}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}
      {configuration.gallery && (
        <Table.Cell
          content={gallery && gallery.name}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}
      {configuration.location && (
        <Table.Cell
          content={location && location.name}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}
      {configuration.purchase_price && (
        <Table.Cell
          content={piece.purchasePriceString()}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}
      {configuration.valuation_price && (
        <Table.Cell
          content={piece.valuationPriceString()}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}
      {configuration.category && (
        <Table.Cell onClick={() => navigate(`${PIECE_PATH}/${_id}`)}>
          {categoryOption && (
            <Label
              color={categoryOption.label.color}
              content={categoryOption.text}
            />
          )}
        </Table.Cell>
      )}
      {configuration.status && (
        <Table.Cell onClick={() => navigate(`${PIECE_PATH}/${_id}`)}>
          {statusOption && (
            <Label
              color={statusOption.label.color}
              content={statusOption.text}
            />
          )}
        </Table.Cell>
      )}
      {configuration.owned_by && (
        <Table.Cell
          content={piece.owned_by}
          onClick={() => navigate(`${PIECE_PATH}/${_id}`)}
        />
      )}
    </Table.Row>
  );
};

PieceCell.propTypes = {
  piece: PropTypes.object,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  update: (id, data) => dispatch(updatePiece(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PieceCell);
