const url = "/api/v4/gallery_proposals";

// Actions
export const RESET_GALLERY_PROPOSAL_STATE = "RESET_GALLERY_PROPOSAL_STATE";

export const GET_GALLERY_PROPOSAL_LIST = "GET_GALLERY_PROPOSAL_LIST";
export const GET_GALLERY_PROPOSAL_LIST_SUCCESS =
  "GET_GALLERY_PROPOSAL_LIST_SUCCESS";
export const GET_GALLERY_PROPOSAL_LIST_FAILURE =
  "GET_GALLERY_PROPOSAL_LIST_FAILURE";

export const GET_GALLERY_PROPOSAL = "GET_GALLERY_PROPOSAL";
export const GET_GALLERY_PROPOSAL_SUCCESS = "GET_GALLERY_PROPOSAL_SUCCESS";
export const GET_GALLERY_PROPOSAL_FAILURE = "GET_GALLERY_PROPOSAL_FAILURE";

export const CREATE_GALLERY_PROPOSAL = "CREATE_GALLERY_PROPOSAL";
export const CREATE_GALLERY_PROPOSAL_SUCCESS =
  "CREATE_GALLERY_PROPOSAL_SUCCESS";
export const CREATE_GALLERY_PROPOSAL_FAILURE =
  "CREATE_GALLERY_PROPOSAL_FAILURE";

export const UPDATE_GALLERY_PROPOSAL = "UPDATE_GALLERY_PROPOSAL";
export const UPDATE_GALLERY_PROPOSAL_SUCCESS =
  "UPDATE_GALLERY_PROPOSAL_SUCCESS";
export const UPDATE_GALLERY_PROPOSAL_FAILURE =
  "UPDATE_GALLERY_PROPOSAL_FAILURE";

export const REMOVE_GALLERY_PROPOSAL = "REMOVE_GALLERY_PROPOSAL";
export const REMOVE_GALLERY_PROPOSAL_SUCCESS =
  "REMOVE_GALLERY_PROPOSAL_SUCCESS";
export const REMOVE_GALLERY_PROPOSAL_FAILURE =
  "REMOVE_GALLERY_PROPOSAL_FAILURE";

// Networking

export const getGalleryProposalList = (page = 1, limit = 25) => {
  return {
    types: [
      GET_GALLERY_PROPOSAL_LIST,
      GET_GALLERY_PROPOSAL_LIST_SUCCESS,
      GET_GALLERY_PROPOSAL_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getGalleryProposal = (id) => {
  return {
    types: [
      GET_GALLERY_PROPOSAL,
      GET_GALLERY_PROPOSAL_SUCCESS,
      GET_GALLERY_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createGalleryProposal = (data) => {
  return {
    types: [
      CREATE_GALLERY_PROPOSAL,
      CREATE_GALLERY_PROPOSAL_SUCCESS,
      CREATE_GALLERY_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateGalleryProposal = (id, data) => {
  return {
    types: [
      UPDATE_GALLERY_PROPOSAL,
      UPDATE_GALLERY_PROPOSAL_SUCCESS,
      UPDATE_GALLERY_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteGalleryProposal = (id, data) => {
  return {
    types: [
      REMOVE_GALLERY_PROPOSAL,
      REMOVE_GALLERY_PROPOSAL_SUCCESS,
      REMOVE_GALLERY_PROPOSAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const resetGalleryProposalState = () => {
  return {
    type: RESET_GALLERY_PROPOSAL_STATE,
  };
};
