const url = "/api/v4/products";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const GET_PRODUCT_PLAN_LIST = "GET_PRODUCT_PLAN_LIST";
export const GET_PRODUCT_PLAN_LIST_SUCCESS = "GET_PRODUCT_PLAN_LIST_SUCCESS";
export const GET_PRODUCT_PLAN_LIST_FAILURE = "GET_PRODUCT_PLAN_LIST_FAILURE";

export const getProductList = () => {
  return {
    types: [
      GET_PRODUCT_LIST,
      GET_PRODUCT_LIST_SUCCESS,
      GET_PRODUCT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
      },
    },
  };
};

export const getProduct = (id) => {
  return {
    types: [GET_PRODUCT, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const getProductPlansList = (id) => {
  return {
    types: [
      GET_PRODUCT_PLAN_LIST,
      GET_PRODUCT_PLAN_LIST_SUCCESS,
      GET_PRODUCT_PLAN_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/plans`,
      },
    },
  };
};
