import React, { useState } from "react";
import { Button, Confirm, Table } from "semantic-ui-react";
import moment from "moment";
import PropTypes from "prop-types";
import { deleteInvitation } from "../../../../../../../redux/actions/invitation";
import { connect } from "react-redux";

const InvitationCell = ({ invitation, remove }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <Table.Row>
      <Table.Cell>{invitation.email}</Table.Cell>
      <Table.Cell>{invitation.role}</Table.Cell>
      <Table.Cell>{invitation.status}</Table.Cell>
      <Table.Cell>
        <Button.Group floated={"right"}>
          <Button
            basic
            icon="close"
            floated="right"
            onClick={() => setConfirmDelete(true)}
          />
          <Confirm
            open={confirmDelete}
            onCancel={() => setConfirmDelete(false)}
            onConfirm={() => remove(invitation._id)}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

InvitationCell.propTypes = {
  invitation: PropTypes.object,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteInvitation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationCell);
